import axios from 'axios';
// import './baseInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;

export const Utils = {
  // Consultar estado de app. Mantenimiento
  getEstadoApp() {
    const instance = axios.create(); // para evitar los interceptores axios
    return instance.get(PASARELA + "/administracion/estado-mantenimiento")
  },
}
