<template>
  <!-- Footer de las demas paginas -->
  <!-- <v-footer dark padless style="background-color: #00b1eb;">
    <v-row
      class="justify-center"
      style="max-width: 1440px; margin: -50px auto 60px; max-height: 100px;"
      no-gutters
    >
      <v-col cols="12" md="5" class="NRe">
        <v-row><h2>NUESTRAS REDES</h2></v-row>
        <v-row style="padding-top: 3%"
          ><div
            class="d-flex justify-start align-start flex-lg-column d-md-inline"
            style="gap: 30px; align-content: flex-start;"
          >
            <a
              :href="icon.link"
              v-for="icon in icons"
              :key="icon.value"
              class="d-flex align-start justify-start gap-2 mb-2"
            >
              <v-icon size="24px">{{ icon.value }}</v-icon>
              <p class="mb-0 ml-2 d-none d-sm-inline">{{ icon.text }}</p>
            </a>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" md="5">
        <v-row><h2>CONTACTO</h2></v-row>

        <v-row style="padding-top: 2%">
          <h4>Dirección: Dr. R. Carrillo 444 | Piso 5</h4>
        </v-row>
        <v-row>
          <h4>W3400 BNC, Corrientes</h4>
        </v-row>
        <v-row>
          <h4>E-mail: reclamoswee@telco.com.ar</h4>
        </v-row>
        <v-row>
          <h4>0810-555-8352</h4>
        </v-row>
      </v-col>
    </v-row>
  </v-footer> -->

  <div style="background: #00b1eb !important;">
    <footer
      class="flex flex-col gap-8 md:flex-row justify-between pt-5 pb-10 px-14"
      style="max-width: 1340px; margin: auto;"
    >
      <div>
        <h2 class="mb-2">NUESTRAS REDES</h2>
        <a
          :href="icon.link"
          v-for="icon in icons"
          :key="icon.value"
          class="flex gap-2 mb-2 text-white"
        >
          <v-icon size="24px" class="text-white">{{ icon.value }}</v-icon>
          <p class="mb-0 ml-2  text-lg">{{ icon.text }}</p>
        </a>
      </div>
      <div>
        <h2 class="mb-2">CONTACTO</h2>
        <p class="text-lg mb-2">
          <span class="mr-2">Dirección:</span> Dr. R. Carrillo 444 | Piso 5
        </p>
        <p class="text-lg mb-2">W3400 BNC, Corrientes</p>
        <p class="text-lg mb-2">
          <span class="mr-2">E-mail:</span> reclamoswee@telco.com.ar
        </p>
        <p class="text-lg mb-2">
          <span class="mr-2">Teléfono:</span> 0810-555-8352
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",

  data: () => ({
    icons: [
      {
        text: "Facebook",
        link: "https://www.facebook.com/weepagos",
        align: "start",
        sortable: false,
        value: "mdi-facebook",
      },
      {
        text: "Twitter",
        link: "https://twitter.com/Wee_pagos",
        align: "start",
        sortable: false,
        value: "mdi-twitter",
      },
      {
        text: "Instagram",
        link: "https://www.instagram.com/weepagos",
        align: "start",
        sortable: false,
        value: "mdi-instagram",
      },
      {
        text: "LinkedIn",
        link: "https://www.linkedin.com/company/weepagos",
        align: "start",
        sortable: false,
        value: "mdi-linkedin",
      },
    ],
  }),
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap");
</style>
<style scoped>
footer {
  font-family: "Montserrat", sans-serif;
  color: white;
  text-align: left;
}

h2 {
  font-weight: 900;
  font-size: 1.5em;
}

h4 {
  padding-top: 10px;
}

@media (max-width: 1240px) {
  Nre {
    margin-right: 5%;
    margin-left: 0%;
  }
}
@media (max-width: 420px) {
  h2 {
    font-size: larger;
  }
  h4 {
    font-size: small;
  }
  a {
    font-size: small;
  }
}
</style>
