<template>
  <div class="content">
    <div class="row align-items-center">
      <div class="col-md-2 ms-3" align="left">
        <v-btn fab dark x-small color="primary">
          <router-link :to="`/cliente/${cliente_id}/cuentas`">
            <v-icon dark> arrow_back</v-icon>
          </router-link>
        </v-btn>
      </div>

      <div class="col" align="right">
        <v-dialog v-model="showForm" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> {{edit ? 'Editar Punto de Venta' : 'Punto de venta'}}</span>
              <v-spacer></v-spacer>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="cerrar_edit_punto()"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form_create" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="currentItem.nombre"
                    :counter="25"
                    :rules="namecuenta"
                    label="Nombre de Punto de venta"
                    required
                  ></v-text-field>                  
                  <!-- <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    :rules="fecha_alta"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(currentItem.fecha_alta)"
                        label="Fecha de alta"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="currentItem.fecha_alta"
                      :min="fechaMinima"
                      no-title
                      scrollable
                      locale="es-ES"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu> -->
                  <div>
                    <v-row style="gap: 20px;width: 100%;margin-inline: auto;">
                      <v-text-field
                        v-model="currentItem.calle"
                        :counter="25"
                        :rules="edit? [] : calle"
                        label="Calle"
                        required
                        style="flex: 2"
                      ></v-text-field>
                      <v-text-field
                        style="flex: 1"
                        v-model="currentItem.numero_calle"
                        :counter="25"
                        :rules="edit ? [] : nroCalle"
                        label="Número"                        
                        required
                      ></v-text-field>
                    </v-row>
                  </div>
                  <v-text-field
                    v-model="currentItem.codigo_postal"
                    :counter="25"
                    :rules="edit ? [] : codigoPostal"
                    label="Código postal"
                    v-show="!edit"
                    required
                  ></v-text-field>
                  <v-checkbox
                    v-model="currentItem.activo"
                    v-show="edit"
                    class="ckeck"
                    label="Activo/Inactivo"
                  ></v-checkbox>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="edit ? cerrar_edit_punto() : cerrar_create()"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!valid"
                @click="edit ? handleUpdate() : handleStore()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <modalCaja
          :showCaja="showCaja"
          :sucursal="sucursal"
          :usuarios="usuarios || []"
          @closeModalCaja="cerrar_caja()"
        />
      </div>
      <div class="col" align="right">
        <div class="col-md-12" align="right">
          <v-btn color="primary" dark @click="openNewPunto()">
            Nuevo
          </v-btn>
        </div>
      </div>
    </div>

    <LayoutPage :title="'Puntos de venta'">
      <v-app>
        <v-main>
          <section v-if="errored">
            <v-alert
              border="left"
              class="mt-4 mx-3 elevation-3"
              color="red lighten-2"
              dark
            >
              Lo sentimos, no es posible obtener la información en este momento,
              por favor intente nuevamente mas tarde.
            </v-alert>
          </section>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="itemsPuntos"
            :loading="cargando"
            :items-per-page="itemsPerPage"
            hide-default-footer
            disable-sort
            loading-text="Cargando... Espere por favor"
            no-results-text="No se encontraron registros"
            no-data-text="No se encontraron registros"
            class="elevation-1 trow"
          >
            <template v-slot:no-results>
              <v-alert color="error" icon="warning">
                found no results.
              </v-alert>
            </template>
            <!-- ACCIONES BOTONES -->

            <template v-slot:[`item.actions`]="{ item }">
              <v-row class="ms-2">
                <!-- 1 - boton caja -->
                <v-tooltip top color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      :dark="item.activo"
                      x-small
                      color="green"
                      @click="openCaja(item)"    
                      :disabled="!item.activo"                  
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> mdi-cash-register </v-icon>
                    </v-btn>
                  </template>
                  <span>Cajas</span>
                </v-tooltip>

                <!-- 2 - boton editar -->
                <v-tooltip top color="orange" minWidth="120px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      :dark="moment().isAfter(moment(item.fecha_alta, 'DD-MM-YYYY').add(2, 'days'))"
                      :disabled="moment().isBefore(moment(item.fecha_alta, 'DD-MM-YYYY').add(2, 'days'))"
                      x-small
                      color="orange"
                      @click="openEditPunto(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> create </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar punto de venta</span>
                </v-tooltip>

                <!-- 3 - boton eliminar punto de venta -->
                <!-- <v-tooltip top color="red" minWidth="130px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      color="red"
                      @click="deletePunto(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar punto de venta</span>
                </v-tooltip> -->
              </v-row>
            </template>
            <template v-slot:[`item.activo`]="{ item }">
              <v-chip
                dark
                :color="item.activo? 'green' : 'red'"
              >
                {{ item.activo? 'Activo' : 'Inactivo' }}
              </v-chip>
            </template>
            <template v-slot:footer>
              <v-row class="no-gutters mt-2 mx-2">
                <v-flex xs12 class="align-items-center">
                  <span>Mostrar</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn dark text color="primary" class="ml-2" v-on="on">
                        {{ rowsPerPage }}
                        <v-icon>arrow_drop_down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(number, index) in rowsPerPageArray"
                        :key="index"
                        @click="updateRowsPerPage(number)"
                      >
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-row>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pagination.total"
              :total-visible="pagination.visible"
              @input="onPageChange"
            ></v-pagination>
          </div>
        </v-main>
      </v-app>
    </LayoutPage>
  </div>
</template>

<script>
import LayoutPage from "../../../LayoutPage.vue";
import moment from "moment";
import { Cuentas } from "../../../../services/cuentas";
import { Users } from "../../../../services/users";
import formatDate from "../../../../utils/formatDate";
// import autoAnimate from "@formkit/auto-animate";
import modalCaja from "./modal/modalCaja.vue";

export default {
  components: {
    name: "PuntoDeVenta",
    LayoutPage,
    modalCaja,
  },
  data: function() {
    return {
      cliente_id: this.$route.params.cliente_id,
      cuentas_id: this.$route.params.cuenta_id,
      createCuenta: false,
      showForm: false,
      showFormCaja: false,
      showCaja: false,
      sucursal: {},
      usuarios: [],
      errored: false,
      menu: false,
      menu2: false,      
      formatDate,
      currentCaja: {
        descripcion: "",
        fecha_alta: "",
        correo: "",
      },
      currentItem: {
        activo: false,
        calle: "",
        codigo_postal: "",
        codigo_sucursal_link: "",
        cuentas_id: "",
        fecha_alta: "",
        fecha_creacion: "",
        id: "",
        nombre: "",
        numero_calle: "",
        codigo_sucursal:"",
        provincia_id: "",
      },
      fechaMinima: moment().format("YYYY-MM-DD"),
      // page: 1,
      search: "",
      valid: false,
      edit: false,
      editCaja: false,
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Fecha de alta", value: "fecha_alta" },
        { text: "Código de sucursal", value: "codigo_sucursal_link" },
        { text: "Estado", value: "activo" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      headersCaja: [
        { text: "Nombre", value: "descipcion" },
        { text: "Fecha de alta", value: "fecha_alta" },
        { text: "Código de caja", value: "codigo_caja" },
        { text: "Código de link pos", value: "codigo_pos_link" },
      ],
      itemsPerPage: 10,
      pageCount: 0,
      // paginacion api
      pagination: {
        page: 1,
        total: 0,
        visible: 7,
      },
      //  numero de registros por pagina - Permisos.vue
      rowsPerPageArray: [10, 20, 30],
      rowsPerPage: 10,
      cargando: false,
      cargandoCaja: false,
      itemsPuntos: [],
      itemsCaja: [],
      namecuenta: [
        (v) => !!v || "Cuenta es requerido",
        (v) =>
          (v && v.length <= 45) || "Cuenta no debe superar los 45 caracteres",
        (v) =>
          /^[A-Za-z0-9.-\s]+$/.test(v) ||
          "Solo numero y letras, sin ñ o acentos.",
      ],
      fecha_alta: [(v) => !!v || "La fecha es requerida"],
      codigo_sucursal: [
        (v) => !!v || "El código de sucursal es requerido",
        (v) =>
          (v && v.length <= 50) ||
          "El código de sucursal no debe superar los 50 caracteres",
        (v) =>
          /^[A-Za-z0-9.-\s]+$/.test(v) ||
          "Solo numero y letras, sin ñ o acentos.",
      ],
      codigoPostal: [
        (v) => !!v || "El código postal es requerido",
        (v) =>
        (v && v.length <= 25) ||
        "El código postal no debe superar los 25 caracteres",
        (v) =>
        /^[0-9\s]+$/.test(v) ||
        "Solo números",
      ],
      calle: [
        (v) => !!v || "La calle es requerida",
        (v) =>
          (v && v.length <= 145) ||
          "La calle no debe superar los 145 caracteres",
        (v) =>
          /^[A-Za-z0-9.-\s]+$/.test(v) ||
          "Solo numero y letras, sin ñ o acentos.",
      ],
      nroCalle: [
        (v) => !!v || "El Nro de calle es requerido",
        (v) =>
          (v && v.length <= 15) ||
          "El Nro de calle no debe superar los 15 caracteres",
        (v) =>
          /^[0-9\s]+$/.test(v) ||
          "Solo números",
      ],
      correo: [
        (v) => !!v || "Correo es requerido",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Correo debe ser válido",
      ],
    };
  },
  created() {
    this.getDatos();
  },
  methods: {
    onPageChange() {
      this.getDatos();
    },
    resetCurrentItem() {
      this.currentItem = {
        activo: false,
        calle: "",
        codigo_postal: "",
        codigo_sucursal_link: "",
        cuentas_id: "",
        fecha_alta: "",
        fecha_creacion: "",
        id: "",
        id_sucursal_link: "",
        nombre: "",
        numero_calle: "",
        codigo_sucursal:"",
        provincia_id: "",
      };
    },
    async openCaja(item) {
      this.sucursal = item;
      await this.getUsuarios()
      this.showCaja = true;
    },
    cerrar_caja() {
      this.showCaja = false;
      this.cajasID = 0;
    },
    openEditPunto(item) {  
      console.log(item.fecha_alta > `${moment().add(1, 'days').format('DD-MM-YYYY')}`)
      this.showForm = true;
      this.edit = true;
      this.currentItem = { ...item };
      this.currentItem.fecha_alta = moment(
        this.currentItem.fecha_alta,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
    },
    cerrar_create() {
      this.showForm = false;
      this.edit = false;
      this.valid = false;
      this.resetCurrentItem();
      this.$refs.form_create.resetValidation();
    },
    cerrar_edit_punto() {      
      this.showForm = false;
      this.edit = false;
      this.resetCurrentItem();
      this.valid = false;
      this.$refs.form_create.resetValidation();
    },
    openNewPunto() {      
      this.showForm = true;
    },
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.getDatos(number);
    },
    toast(name, msg, type, color) {
      this.$toastr.Add({
        name: name,
        title: "",
        msg: msg,
        clickClose: true,
        type: type,
        preventDuplicates: true,
        style: {
          color: "white",
          backgroundColor: color,
          width: "300px",
        },
      });
    },
    handleStore() {
      if (this.$refs.form_create.validate()) {
        this.store();
      } else {
        this.toast(
          "ErrorRequest",
          "Debe completar todos los datos",
          "info",
          "primary"
        );
      }
    },
    handleUpdate() {
      this.$refs.form_create.resetValidation();
      if (this.$refs.form_create.validate()) {
        this.update();
      } else {
        this.toast(
          "ErrorRequest",
          "Debe completar todos los datos",
          "info",
          "primary"
        );
      }
    },
    formatearFechaSiguiente(fecha) {
      return moment(fecha).add(1, 'days').format("YYYY-MM-DDT00:00:00-00:00");
    },
    async deletePunto(item) {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "Esta acción eliminará el contacto",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed) this.delete(item.id);
    },
    getDatos: function() {
      // this.cargando = true;
      Cuentas.getPuntosdeVenta(
        this.pagination.page,
        this.rowsPerPage,
        this.cuentas_id
      )
        .then((response) => {
          if (!response.data.data.puntos_ventas) {
            this.itemsPuntos = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
          } else {
            this.itemsPuntos = response.data.data.puntos_ventas;
            this.pagination.page = response.data.data.meta.page.currentPage;
            this.pagination.total = response.data.data.meta.page.lastPage;
          }
        })
        .catch(() => {
          // console.error("Error al consultar puntos de venta: ", error);
          this.errored = true;
        })        
    },
    getUsuarios: function async (){
      let params = {
        ClienteId:this.cliente_id,
        SistemaId:process.env.VUE_APP_SISTEMA_ID
      }
      Users.getUsers(params)
      .then((response) => {        
        this.usuarios = response?.data?.data;
      })
      .catch(()=>{
        // console.error("Error al consultar usuarios: ", error);
        this.errored = true;
      })
      .finally(()=>{

      })
    },
    store: function() {
      this.cargando = true;      
      let body = {
        cuentas_id: this.cuentas_id,
        nombre: this.currentItem.nombre,
        fecha_alta: moment().format('YYYY-MM-DD') + 'T00:00:00Z',
        calle: this.currentItem.calle,
        codigo_postal: this.currentItem.codigo_postal,
        numero_calle: this.currentItem.numero_calle,
        provincia_id: 6,
        localidad: "Corrientes",
        // activo: this.currentItem.activo,
      };
      Cuentas.storePuntosdeVenta(body)
        .then((response) => {
          if (response.status == 200) {
            this.toast(
              "SuccessRequest",
              "El punto de venta se creo correctamente",
              "success",
              "green"
            );
          }
        })
        .catch(() => {
          this.$toastr.e("Ocurrio un error al crear el punto de venta");
        })
        .finally(() => {
          this.getDatos();
          this.cerrar_create();
          this.cargando = false;
        });
    },
    update: async function() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "Esta acción actualizará el punto de venta, esto solo puede realizarse una vez cada 48hs hábiles.",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed){
        this.cargando = true;
        this.currentItem.fecha_alta = this.formatearFechaSiguiente(
          moment().format("YYYY-MM-DD")
        );
        let body = {
          id: this.currentItem.id,
          cuentas_id: this.cuentas_id,
          nombre: this.currentItem.nombre,
          fecha_alta: this.currentItem.fecha_alta,
          activo: this.currentItem.activo,     
          sucursal_codigo_to_update: this.currentItem.codigo_sucursal_link,
          sucursal_codigo:this.currentItem.codigo_sucursal,
          calle: this.currentItem.calle,
          numero_calle: this.currentItem.numero_calle,
          codigo_postal: this.currentItem.codigo_postal,
          provincia_id: this.currentItem.provincia_id,
          localidad: this.currentItem.localidad,
        };
        Cuentas.updatePuntodeVenta(body)
          .then((response) => {
            if (response.status == 200) {
              this.$toastr.s("Se actualizaron los datos correctamente");
            }
          })
          .catch(() => {
            this.$toastr.e('Ocurrio un error al actualizar el punto de venta')
          })
          .finally(() => {
            this.getDatos();
            this.cerrar_edit_punto();
            this.cargando = false;
          });
        }
    },
    delete: function(puntoventas_id) {
      this.cargando = true;
      Cuentas.deletePuntodeVenta(puntoventas_id)
        .then((response) => {
          if (response.status == 200) {
            this.$toastr.s("Punto de venta eliminado correctamente");
          }
        })
        .catch(() => {
          this.$toastr.e('Ocurrio un error al eliminar el punto de venta')
        })
        .finally(() => {
          this.cargando = false;
          this.getDatos();
        });
    },
    getDatosCaja: function(id_caja) {
      this.cargandoCaja = true;
      Cuentas.getDatosCaja(id_caja)
        .then((response) => {
          this.itemsCaja = response.data.data.CajasCobro;
        })
        .catch(() => {
          this.errored = true;
        })
        .finally(() => {
          this.cargandoCaja = false;
        });
    },
  },
};
</script>
<style scoped>
a[data-v-1b84c964] {
  color: #e6f1e3;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}
</style>
