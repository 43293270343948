<template>
 <div class="content">
    
    <v-app>
      <v-main>
          <v-container fluid>
         <br>
          <v-row justify="left">
          <v-dialog
            v-model="dialog_estadistica"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Ingresar mes
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Seleccionar mes</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <input type="month" v-model="fecha_inicio" class="form-control">
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog_estadistica = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  v-on:click="datos_estadisticas"
                >
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
          
    <br>
    <!-- DATATABLE -->
          <div class="card">
            <!-- RI CUENTA CLIENTE -->
            <div class="card-header card-header-success">
              <h4 class="card-title">RI Cuenta Cliente</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <div v-if="loading">
               <loader object="#4caf50" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="40" disableScrolling="false" name="box"></loader>
               </div>
               <v-alert
                  border="left"
                  class="mt-3 mx-3 elevation-3"
                  color="red lighten-2"
                  dark
                >
                  Lo sentimos, no es posible obtener la información en este
                  momento, por favor intente nuevamente mas tarde.
                </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <div v-if="loading">
               <loader object="#4caf50" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="40" disableScrolling="false" name="box"></loader>
                  </div>
                <table class="table table-hover">
                   <thead v-if="data.length != 0">
                    <tr>
                      <td  width="5%">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox"  value="" v-model="Retificativa">
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td class="text-left"><p class="card-category">Rectificativa</p></td>
                      </tr>
                  </thead>
                  <thead>
                    <tr>
                    <th><p class="card-category">Cantidad de operaciones</p> </th>
                    <th><p class="card-category">Cod Partida</p></th>
                    <th><p class="card-category">Esquema pago</p></th>
                    <th><p class="card-category">Medio pago</p></th>
                    <th><p class="card-category">Monto Total</p></th>
                  </tr>
                  </thead>
                  <tbody v-for="dat in data" v-bind:key="dat.id">
                    <tr>
                      <td><p class="card-text" v-text="dat.CantOperaciones"></p></td>
                      <td><p class="card-text" v-text="dat.CodigoPartida"></p></td>
                      <td><p class="card-text" v-text="dat.EsquemaPago"></p></td>
                      <td><p class="card-text" v-text="dat.MedioPago"></p></td>
                      <td><p class="card-text" v-text="dat.MontoTotal"></p></td>
                      <!-- <td><p class="card-text" v-if="dat.MedioPago != 'NULO'" > {{dat.MedioPago}} </p><p class="card-text" v-else></p></td>
                      <td><p class="card-text" v-if="dat.MontoTotal != 'NULO'" > {{dat.MontoTotal}} </p><p class="card-text" v-else></p></td> -->
                    </tr>
                  </tbody>
                </table>
            <br>
        
              <div class="row"  v-if="data.length != 0">
                 <div class="col-md-12" align="right">
                 <button v-on:click="enviar_informe_estadistica"  class="btn btn-success mr-2 btn-round">Guardar</button>
                 </div>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import moment from 'moment'
import { Bcra } from '../../services/bcra'
  export default {
    name: 'Estadistica',
    data () {
      return {
            fecha_inicio: '',
            data: [],
            error: false,
            loading: false,
            Retificativa: false,
            dialog_estadistica: false,
            errored: false,
         }
     },
     methods: {
            async datos_estadisticas(){
            const params  = {
              FechaInicio : moment(this.fecha_inicio).startOf('month').format('YYYY-MM-DD')+'T00:00:00.000-00:00',
              FechaFin :  moment(this.fecha_inicio).endOf('month').format('YYYY-MM-DD')+'T00:00:00.000-00:00'  
            }
             this.error = false;
             this.checkinput();
             this.dialog_estadistica = false;
             this.loading = true;
             Bcra.getEstadisticas(params)
             .then(response => { 
                if(response.data == null) {
                this.$toastr.i("El mes selecionado no contiene registros");
                //  inicializar todas las variables
                this.data = [],
                this.error= false,
                this.loading= false,
                this.Retificativa= false,
                this.dialog_estadistica = false,
                 e.preventDefault().finally(() => this.loading = false);
               }else{
                this.dialog_estadistica = false,
                this.data = response.data;
                this.loading = false;
               }        
             })
             .catch(error => {
              var data = error.response.data;
               this.dialog = false,
               this.error = true;
               this.$toastr.e(data.message);
             }).finally(() => this.loading = false); 
           
            },
            //  enviar informes estadisticas
            async enviar_informe_estadistica() {
              var data = {
                RiInfestadistica: this.data,
                Rectificativa: this.Retificativa,
                Opera: true,
                Periodo:moment(this.fecha_inicio).endOf('month').format('YYYY-MM-DD'),
              };
              const formData = new FormData();
              formData.append('data', JSON.stringify(data));
              this.error = false
              this.loading = true,
              Bcra.post_estadisticas(formData)
                .then((response) => {   
                this.data = [],
                this.error= false,
                this.loading= false,
                this.Retificativa= false,
                this.$toastr.s("Se genero el archivo zip correctamente");
                })
                .catch((error) => {
                  var data = error.response.data;
                  this.error = true;
                  this.$toastr.e(data.message);
                }).finally(() => this.loading = false);
              },
             
              //validacion de campos(validacion del input y file)
              checkinput(e){
              this.error = false
              if(!this.fecha_inicio){
                this.error = true
                this.$toastr.e("El campo mes no puede ser vacio");
                // detener el envio del formulario
                e.preventDefault();
              } 
              },
              }
  }
</script>
<style scoped>
.v-application .primary {
    background-color: #66bb6a!important;
    border-color: #66bb6a !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    margin-left: 15px;
}
</style>
