import axios from "axios";

axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.common = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
