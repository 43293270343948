<template>
  <div class="content">
    <v-app class="bg-transparent">
      <div>
        <v-main>
          <!-- ---------------LOADER PROCESANDO DATOS ---------------- -->
          <div v-if="cargando">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>
          <div class="flex justify-end ">
            <v-btn
              class="mb-4 mr-3 text-white"
              color="green"
              @click="dialog = true"
            >
              Nuevo Ticket
              <v-icon class="ml-1" prepend-icon small>mdi-cards-outline</v-icon>
            </v-btn>
          </div>
          <v-container fluid class="bg-white rounded">
            <div class="card">
              <div class="card-header card-header-success">
                <h4 class="card-title">Soportes</h4>
                <p class="card-category"></p>
              </div>
              <template>
                <v-data-table
                  :headers="headers"
                  :items="soportes"
                  :items-per-page="rowsPerPage"
                  :loading="cargando"
                  loading-text="Cargando... Espere por favor"
                  no-results-text="No se encontraron registros"
                  no-data-text="No se encontraron registros"
                  item-key="Id"
                  ref="tabla_soportes"
                  calculate-widths
                  hide-overlay
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <!-- Modal de nuevo ticket -->
                      <v-dialog
                        v-model="dialog"
                        max-width="1000px"
                        @click:outside="resetConsulta()"
                        @click:esc="dialog = false"
                      >
                        <v-card>
                          <v-card-title class="text-h5 grey lighten-2">
                            <v-icon class="mr-1">mdi-cards</v-icon>
                            Crear ticket
                          </v-card-title>

                          <v-card-text>
                            <div class="text-left font-bold mt-2">Consulta</div>
                            <v-col>
                              <v-row>
                                <v-textarea
                                  v-model="consulta"
                                  label="Ingrese Consulta"
                                  :rules="reglaConsulta"
                                ></v-textarea>
                              </v-row>
                              <v-row
                                ><v-checkbox
                                  v-model="archivoE"
                                  :label="`Adjuntar archivo(png,jpg)`"
                                ></v-checkbox
                              ></v-row>
                              <v-row cols="12" v-if="archivoE">
                                <v-file-input
                                  label="Imagen"
                                  v-model="archivoC"
                                  accept="image/png, image/jpeg"
                                  prepend-icon="mdi-camera"
                                ></v-file-input>
                              </v-row>
                            </v-col>
                          </v-card-text>

                          <v-card-actions class="justify-end">
                            <v-btn
                              color="green darken-1 text-white"
                              :loading="cargandobtn"
                              @click="save"
                            >
                              Crear
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <!-- Modal de Eliminar -->
                      <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5"
                          >Seguro desea eliminar este ticket?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog> -->
                    </v-toolbar>
                  </template>

                  <!-- Aqui van elementos de la tabla -->

                  <!-- Header Nro Id -->
                  <template v-slot:[`item.Id`]="{ item }">
                    {{ item.Id.toString().padStart(6, "0") }}
                  </template>

                  <!-- Header Consulta -->
                  <template v-slot:[`item.Consulta`]="{ item }">
                    <div class="decoration-solid text-blue-400">
                      {{ item.Consulta }}
                    </div>
                  </template>
                  <!-- Header Estado -->
                  <template v-slot:[`item.Estado`]="{ item }">
                    <v-chip
                      :class="colorForEstado(item.Estado)"
                      class="pa-2 rounded-xl text-white"
                    >
                      {{ item.Estado | capitalizeFirstLetter }}
                    </v-chip>
                  </template>

                  <template v-slot:[`item.Respuestas`]="{ item }">
                    {{
                      item.Respuestas
                        ? item.Respuestas[item.Respuestas.length - 1].Nombre
                        : "-"
                    }}
                  </template>
                  <!-- Fin de elementos de la tabla -->

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        selectSoporte(item),
                          showDetails(item),
                          resetRespuesta(),
                          (dialogRespuestas = true)
                      "
                    >
                      mdi-message
                    </v-icon>
                  </template>

                  <!-- cantidad de registros por pagina -> actualizar numero de registros por pagina -->
                  <template v-slot:footer>
                    <v-row class="no-gutters mt-2 mx-2">
                      <v-flex xs12>
                        <span>Mostrar</span>
                        <v-menu top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              dark
                              text
                              color="primary"
                              class="ml-2"
                              v-on="on"
                            >
                              {{ rowsPerPage }}
                              <v-icon>arrow_drop_down</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(number, index) in rowsPerPageArray"
                              :key="index"
                              @click="updateRowsPerPage(number)"
                            >
                              <v-list-item-title>{{
                                number
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-flex>
                    </v-row>
                  </template>
                </v-data-table>
                <!-- Paginacion -->
                <div class="text-center mt-2">
                  <v-pagination
                    :value="page"
                    :length="total"
                    :total-visible="visible"
                    @input="onPageChange($event)"
                  ></v-pagination>
                </div>

                <!-- Modal de Editar Soporte -->
                <!-- <v-dialog v-model="dialogEdit" persistent max-width="1200px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Editar Soporte</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="consultaEdit"
                            label="Consulta"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-file-input
                            v-model="archivo"
                            label="Archivo(opcional)"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogEdit = false"
                    >
                      Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="editarSoporte()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
                <!-- Fin de Modal de Editar Soportes -->
              </template>
              <!-- Fin de template de tabla -->

              <!-- Modal Respuestas-->
              <v-dialog
                v-model="dialogRespuestas"
                width="1000"
                @click:outside="resetRespuesta()"
                @click:esc="dialogRespuestas = false"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-icon class="mr-2">mdi-umbrella</v-icon>
                    Ticket Nro:
                    <span>{{ itemConsulta.toString().padStart(6, "0") }}</span>
                    <span class="flex justify-end">
                      <v-select
                        class="justify-end text-white"
                        v-model="Estadoi"
                        label="Estado"
                        :items="estados"
                        @change="editarSoporte(Estadoi)"
                        dense
                        hide-details
                        rounded
                        outlined
                        style="max-width: 180px; max-height: 300px; background-color:white"
                        v-if="isSuperAdmin()"
                      ></v-select>
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <v-list-item class="font-bold text-left">
                      Consulta:
                      <div class="decoration-solid text-blue-400 ml-1">
                        {{ ConsultaI }}
                      </div>

                      <span class="flex justify-end" v-show="ArchivoI"
                        ><v-icon> mdi-camera</v-icon>
                        <span
                          @click="expandirImagen"
                          style="cursor:pointer"
                          class="hover:underline"
                        >
                          Abrir adjunto
                        </span>
                      </span>
                    </v-list-item>
                    <div
                      ref="scrollContainer"
                      class="max-w-300px max-h-400px overflow-y-auto"
                      style="max-height: 400px;"
                    >
                      <v-list>
                        <v-list-item
                          v-for="(consulta, index) in itemConsultas"
                          :key="index"
                          class="text-left"
                          dense
                        >
                          <span>{{ consulta.Fecha }}</span>
                          <span class="font-bold ml-2 mr-1"
                            >{{ consulta.Email }}:</span
                          >
                          <span>{{ consulta.Respuesta }}</span>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-card-text>

                  <v-text-field
                    v-model="respuesta"
                    label="Ingrese su respuesta"
                    :rules="reglaConsulta"
                    class="ml-5 mr-5"
                  ></v-text-field>

                  <v-card-actions class="justify-end">
                    <v-btn
                      color="green darken-1 text-white"
                      :loading="cargandobtn"
                      @click="createRespuestaSoporte()"
                    >
                      Responder
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- fin de modal de respuestas -->

              <!-- Modal de imagen Expandida para visualizar -->
              <v-dialog
                v-model="imagenExpandidaDialog"
                class="rounded-none"
                width="1120"
              >
                <div style="max-width: 70rem;margin:auto">
                  <img
                    :src="`${rutaBackendImg}?archivo=${ArchivoI}`"
                    alt="Imagen Soporte"
                    style="cursor: pointer;"
                    width="100%"
                  />
                </div>
              </v-dialog>
              <!-- fin de modal de imagen expandida -->
            </div>
          </v-container>
        </v-main>
      </div>
    </v-app>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Soportes",
  data: () => ({
    cargandobtn: false,
    itemConsultas: [{ TicketNro: "" }],
    dialogRespuestas: false,
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    itemConsulta: "",
    ConsultaI: "",
    ArchivoI: "",
    Estadoi: "",
    mostrarRegla: false,
    //  numero de registros por pagina
    rowsPerPageArray: [50, 100, 200],
    imagenExpandidaDialog: false,
    footerProps: {
      "items-per-page-options": [5, 10, 20, 30],
      "items-per-page-text": "Mostrar",
      "prev-icon": "",
      "next-icon": "",
      "page-text": "{0}-{1} de {2}",
    },
    // Header de la tabla
    headers: [
      {
        text: "Nro",
        align: "start",
        sortable: false,
        value: "Id",
      },
      { text: "Fecha", sorteble: false, value: "Fecha" },
      { text: "Usuario", sorteble: false, value: "Email" },
      { text: "Consulta", sorteble: false, value: "Consulta" },
      // {
      //   text: "Ultimo Msg",
      //   align: "center",
      //   sorteble: false,
      //   value: "Respuestas",
      // }, //Como hago que me tome el ultimo
      { text: "Estado", sorteble: false, value: "Estado" },
      { text: "Opciones", sorteble: false, value: "actions" },
    ],

    headersConsulta: [
      { text: "Fecha", align: "start", sortable: false, value: "Fecha" }, //ver si trae bien la fecha
      { text: "Usuario", sortable: false, value: "Nombre" },
      { text: "Respuesta", sortable: false, value: "Respuesta" },
      { text: "Archivo", sorteable: false, value: "Archivo" },
    ],

    consultaEdit: "",
    consulta: "",

    archivoE: "",
    archivoC: "",
    estado: "espera",
    visto: false,
    abierto: false,
    email: "",
    respuesta: "",
    id: "",

    estados: [
      { text: "Espera", sortable: false, value: "espera" },
      { text: "Resolviendo", sortable: false, value: "resolviendo" },
      { text: "Resuelta", sortable: false, value: "resuelta" },
      { text: "Rechazada", sortable: false, value: "rechazada" },
      {
        text: "Pendiente de Desarrollo",
        sortable: false,
        value: "pendiente de desarrollo",
      },
    ],

    editedIndex: -1,
    rutaBackendImg:
      process.env.VUE_APP_URLPASARELA + "/administracion/soportes-file",
  }),

  filters: {
    capitalizeFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  computed: {
    paginaActual: {
      get() {
        return this.$store.getters["soportes/pages"];
      },
    },
    existenSoportes() {
      return this.soportes.length > 0;
    },

    ...mapGetters([
      "getCurrentClientName",
      "getCurrentClientEmail",
      "CLIENTE_CUENTA_DATA",
      "getCurrentAccountId",
      "roles",
    ]),

    ...mapState("soportes", [
      "soportes",
      "page",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
    ]),

    reglaConsulta() {
      return this.mostrarRegla
        ? [(v) => !!v || "Este campo no puede estar vacio"]
        : [];
    },
  },

  watch: {
    // Observa los cambios en itemConsultas y realiza scroll al final
    itemConsultas() {
      this.$nextTick(() => {
        this.scrollContainerToBottom();
      });
    },
  },

  created() {
    this.email = this.getCurrentClientEmail;
    this.nombre = this.getCurrentClientName;
    this.getSoportes();
  },

  methods: {
    ...mapActions("soportes", [
      "createSoporte",
      "getSoportes",
      "getSoporteFile",
      "putSoporte",
      "createSoporteRespuesta",
    ]),
    expandirImagen() {
      // Abrir el modal para mostrar la imagen a tamaño completo
      this.imagenExpandidaDialog = true;
    },

    scrollContainerToBottom() {
      // Obtén el elemento del contenedor de referencia
      const container = this.$refs.scrollContainer;
      // Haz scroll hasta el fondo
      container.scrollTop = container.scrollHeight;
    },

    colorForEstado(estado) {
      switch (estado) {
        case "espera":
          return "grey lighten-1"; // Color gris azulado para el estado "espera"
        case "resolviendo":
          return "orange"; // Color naranja para el estado "resolviendo"
        case "resuelta":
          return "green"; // Color verde para el estado "resuelta"
        case "rechazada":
          return "red"; // Color rojo para el estado "rechazada"
        case "pendiente de desarrollo":
          return "purple"; // Color morado para el estado "pendiente De Desarrollo"
        default:
          return ""; // Color por defecto
      }
    },

    isSuperAdmin() {
      if (this.roles.find((r) => r == "psp.config.clientes.wee")) {
        return true; //true
      }
      return false; //false
    },

    resetConsulta() {
      this.consulta = "";
      this.archivoC = "";
      this.mostrarRegla = false;
    },
    resetRespuesta() {
      this.respuesta = ""; // Restablecer a una cadena vacía
      this.mostrarRegla = false;
    },

    //Modal de Respuestas
    async showDetails(item) {
      this.dialogRespuestas = true;
      this.ConsultaI = item.Consulta;
      this.itemConsultas = item.Respuestas || []; // Inicializa con un array vacío si no hay respuestas
      this.ArchivoI = item.Archivo;
    },

    // paginar datatable
    onPageChange(event) {
      this.$store.commit("soportes/setPaginate", event);
      this.getSoportes();
    },

    // registros por paginas (actualizar numero de registros por pagina) (listo)
    updateRowsPerPage(number) {
      this.$store.commit("soportes/setrowsPerPage", number);
      this.$store.commit("soportes/setPaginate", 1);
      this.getSoportes();
    },

    formatFecha(value) {
      var date = new Date(value);
      let fecha = date.toISOString().substring(0, 10);
      var datePart = fecha.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    },

    selectSoporte(item) {
      this.id = item.Id; // Asegúrate de que item contiene el ID del soporte
      this.itemConsulta = item.Id;
      this.ArchivoI = item.Archivo;
      this.Estadoi = item.Estado;
    },

    // Funciones de CRUD

    //Crear Soportes
    async save() {
      try {
        // Verificar si la consulta está vacía
        if (!this.consulta.trim()) {
          // Si la consulta está vacía, muestra un mensaje de error o realiza alguna acción adecuada
          // console.error("La consulta no puede estar vacía");
          this.mostrarRegla = true;
          return;
        }
        const formData = new FormData();
        formData.append("nombre", this.nombre);
        formData.append("email", this.email);
        formData.append("visto", false);
        formData.append("estado", this.estado);
        formData.append("consulta", this.consulta);
        formData.append("abierto", false);
        formData.append("archivo", this.archivoC); // Agregar el archivo seleccionado
        this.cargandobtn = true;
        await this.createSoporte(formData);
        this.cargandobtn = false;
        this.dialog = false;
        this.getSoportes();
        this.resetConsulta();
      } catch (error) {
        // Manejar cualquier error que ocurra durante la creación del soporte
        // console.error("Error creando soporte:", error);
      }
    },

    //Editar Soporte - Estado
    async editarSoporte() {
      try {
        const nuevosDatos = {
          id: this.id,
          visto: true,
          estado: this.Estadoi,
          abierto: true,
        };
        await this.$store.dispatch("soportes/editarSoporte", {
          nuevosDatos,
        });
        // console.log("Soporte editado con éxito");
        this.getSoportes();
      } catch (error) {
        // console.error("Error editando soporte:", error);
      }
    },

    //Guardar Respuesta
    async createRespuestaSoporte() {
      try {
        // Verificar si la respuesta está vacía
        if (!this.respuesta.trim()) {
          // Si la respuesta está vacía, muestra un mensaje de error o realiza alguna acción adecuada
          // console.error("La respuesta no puede estar vacía");
          this.mostrarRegla = true;
          return;
        }
        const respuestaData = new FormData();
        respuestaData.append("soportesId", this.id); // Usa el ID del soporte seleccionado
        respuestaData.append("nombre", this.nombre);
        respuestaData.append("email", this.email);
        respuestaData.append("visto", true);
        respuestaData.append("respuesta", this.respuesta);
        this.cargandobtn = true;
        await this.createSoporteRespuesta(respuestaData);
        this.cargandobtn = false;
        this.itemConsultas = [
          ...this.itemConsultas,
          {
            Email: this.email,
            Nombre: this.nombre,
            Fecha: moment().format("DD-MM-yyyy HH:mm:ss"),
            Visto: true,
            Respuesta: this.respuesta,
          },
        ];
        this.getSoportes();
        // console.log("respuesta creada con exito!");
        this.resetRespuesta();
      } catch (error) {
        this.cargandobtn = false;
        // console.error("Error al crear respuesta de soporte vista:", error);
      }
    },

    // deleteItem(item) {
    //   this.editedIndex = this.soportes1.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // deleteItemConfirm() {
    //   this.soportes1.splice(this.editedIndex, 1);
    //   this.closeDelete();
    // },

    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
  },
};
</script>
