 <template>
  <div data-app class="content">
    <div class="container-fluid">
      <!-- ROW DE TABS DISTINTAS SECCIONES DE CUENTAS -->
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
          <div class="profile-tabs">
            <ul
              class="nav nav-pills nav-pills-icons justify-content-center"
              role="tablist"
            >
              <!-- CLIENTES -->
              <!-- <li class="nav-item">
                <a
                  class="nav-link active show"
                  href="#clientes_tab"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="true"
                >
                  <i class="material-icons">account_box</i> Cuentas
                </a>
              </li> -->
              <!-- Tipos de pago -->
              <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  href="#pagostipos_tab"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                >
                  <i class="material-icons">credit_score</i> Tipos de pago
                </a>
              </li> -->
              <!-- CUENTA COMISION -->
              <!-- <li class="nav-item">
                <a
                  class="nav-link"
                  href="#cuentacomision_tab"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                >
                  <i class="material-icons">monetization_on</i> Cuenta Comisión
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <!-- COMPONENTES DE CUENTAS -->
      <div class="tab-content tab-space">
        <div class="tab-pane text-center gallery active show" id="cuentas_tab">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <Cuentas />
            </div>
          </div>
        </div>

        <!-- <div class="tab-pane text-center gallery" id="pagostipos_tab">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <PagosTipo />
            </div>
          </div>
        </div>

        <div class="tab-pane text-center gallery" id="cuentacomision_tab">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <CuentaComision />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Cuentas from "@/components/Administrar/Cuentas.vue";
// import PagosTipo from "@/components/Administrar/Pagostipo.vue";
// import CuentaComision from "@/components/Administrar/CuentaComision.vue";
export default {
  name: "CuentasIndex",
  components: {
    Cuentas,
    // PagosTipo,
    // CuentaComision,
  },
};
</script>
<style scoped>
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #bb66b8;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(156 39 176 / 60%);
}
</style>