<template>
  <div style="padding-bottom: 40px">
    <div class="main main-raised">
      <div class="container">
        <div class="section text-center">
          <div class="features"></div>
        </div>
        <div>
          <NewPago />
        </div>
        <div class="sharing-area text-center"></div>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import NewPago from "@/components/GenerarPago/NewPago.vue";
export default {
  name: "GenerarPago",
  components: {
    NewPago,
  },
};
</script>

