<template>
  <ul class="nav">
    <br /><br /><br />
    <!-- <li
      class="nav-item"
      :class="{ active: this.$route.path === '/introduccion' }"
    >
      <a class="nav-link">
        <router-link active-class="active" exact to="/introduccion"
          >Introducción</router-link
        >
      </a>
    </li> -->
    <!-- <li
      class="nav-item"
      :class="{ active: this.$route.path === '/cuenta-pagos' }"
    >
      <a class="nav-link">
        <router-link to="/cuenta-pagos">Cuenta wee.ar</router-link>
      </a>
    </li> -->
    <li
      class="nav-item"
      :class="{ active: this.$route.path === '/institucional' }"
    >
      <router-link class="nav-link" to="/institucional">
        Institucional
      </router-link>
    </li>

    <li class="nav-item" :class="{ active: this.$route.path === '/terminos' }">
      <router-link to="/terminos" class="nav-link">
        Términos y Condiciones Generales
      </router-link>
    </li>

    <li
      class="nav-item ml-4"
      v-if="showPlazos"
      :class="{ active: this.$route.path === '/plazos-acreditacion' }"
    >
      <router-link to="/plazos-acreditacion" class="nav-link">
        ⮞ Plazos de acreditación
      </router-link>
    </li>

    <li
      class="nav-item"
      :class="{ active: this.$route.path === '/terminos-pagador' }"
    >
      <router-link class="nav-link" to="/terminos-pagador">
        Términos y Condiciones del Pagador
      </router-link>
    </li>
  </ul>
</template>
<script>
export default {
  name: "Ayuda",
  data() {
    return {
      showPlazos: false,
    };
  },

  mounted() {
    if (
      this.$route.path === "/terminos" ||
      this.$route.path === "/plazos-acreditacion"
    )
      this.showPlazos = true;
  },
};
</script>
<style scoped>
.dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.sidebar[data-color="purple"] li.active > a {
  background: linear-gradient(
    to right top,
    #00b1eb 13.02%,
    #1db6c8 48.96%,
    #c2d501 85.42%
  );
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(156 39 176 / 40%);
}
.sidebar .nav li.active a {
  color: white;
}
.sidebar .nav a {
  color: black;
}
</style>
