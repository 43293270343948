<template>
  <v-app>
    <div class="container-pago">
      <div class="msj-container p-2 p-md-4 ">
        <div class="border-effect  p-5">
          <img
            src="../../../public/assets/img/pago_exitoso.png"
            width="80px"
            height="80px"
          />
          <h2 class="font-weight-bold mt-4">SU PAGO SE REALIZÓ CON ÉXITO!</h2>
        </div>
      </div>
      <img
        src="../../../public/assets/img/wee_logo.png"
        width="90px"
        style="margin-top: 40px;"
        class="wee-logo"
      />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "PagoExitoso",
};
</script>

<style scoped>
.container-pago {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1000 !important;
  top: 0;
  background-image: url("../../../public/assets/img/fondo.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wee-logo {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
}

.msj-container {
  max-width: 650px;
  border-radius: 40px;
  background: #fff;
}

.border-effect {
  background: linear-gradient(#fff 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(90deg, #c2d501 0%, #1db6c8 51.56%, #00b1eb 100%)
      border-box;
  border: 5px solid transparent;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
}

p {
  font-family: "Montserrat", Helvetica;
  font-size: 18px;
  color: #666;
  margin-top: 10px;
}

@media (max-width: 580px) {
  .msj-container {
    border-radius: 20px;
    width: 90%;
  }

  .border-effect {
    border-radius: 20px;
  }

  p {
    font-size: 16px;
  }

  h2 {
    font-size: 23px;
  }

  img {
    transform: scale(0.7);
  }
}
</style>
