<template>
  <nav
    class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          v-if="
            roles.some(
              (r) =>
                r === 'psp.admin.clientes.wee' ||
                r === 'psp.config.clientes.wee'
            )
          "
          class="mx-2"
        >
          <ChangeCliente />
        </div>

        <div class="mx-2">
          <ChangeClienteCuenta />
        </div>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>

      <div class=" navbar-collapse justify-content-end">
        <!-- Notificaciones -->
        <span>
          <v-menu
            open-on-click
            bottom
            offset-y
            transition="scroll-y-transition"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-btn
                class="relative mr-3"
                color="primary"
                dark
                x-small
                fab
                v-bind="attrs"
                v-on="{ ...menu }"
                @click="actualizarAvisos()"
              >
                <v-icon dark small>mdi-bell</v-icon>
                <v-badge
                  color="transparent"
                  overlap
                  v-if="avisosNl.length !== 0"
                >
                  <span
                    class="absolute top-0 right-0 -mt-5 -mr-3 custom-badge"
                    >{{ avisosNl.length }}</span
                  >
                </v-badge>
              </v-btn>
            </template>

            <div>
              <v-card width="250">
                <div class="bg-gray-100">
                  <span class="flex justify-center py-1"
                    ><h5 class="cyan--text">Notificaciones</h5>
                  </span>
                  <v-divider></v-divider>
                </div>
                <div class="overflow-y-auto max-h-124" :loading="cargando">
                  <template v-if="cargando">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="10"
                      :width="2"
                    ></v-progress-circular>
                  </template>
                  <template v-else>
                    <template v-if="avisosNl.length === 0">
                      <h1
                        class="py-2 text-capitalize text-gray"
                        style="font-size: 0.6rem;"
                      >
                        No hay nuevas Notificaciones
                      </h1>
                      <v-divider></v-divider>
                    </template>
                    <div
                      v-for="(aviso, index) in avisosNl"
                      :key="aviso.id"
                      class="mx-auto bg-gray-100"
                      rounded-0
                    >
                      <template v-if="!aviso.leido && index < 4">
                        <v-list-item three-line class="bg-gray-100">
                          <v-list-item-content>
                            <div class="mr-4 flex justify-start">
                              <v-icon>mdi-bullhorn</v-icon>
                              <h1 class="green--text mt-1 ml-2">
                                {{ aviso.nombre | truncate(20) }}
                              </h1>
                            </div>
                            <v-list-item-subtitle class="flex justify-start">
                              <h1 class="whitespace-pre-line text-left">
                                {{ aviso.descripcion | truncate(45) }}
                              </h1>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <div class="bg-gray-100 flex justify-end">
                          <v-btn
                            color="cyan"
                            text
                            small
                            plain
                            @click="abrirModal(aviso)"
                          >
                            <span>
                              <h6
                                class="text-capitalize underline underline-offset-1"
                                style="font-size:0.7rem"
                              >
                                Ver detalles
                              </h6>
                            </span>
                          </v-btn>
                        </div>
                        <div class="py-1"><v-divider></v-divider></div>
                      </template>
                    </div>
                  </template>
                </div>

                <v-btn text to="/notificaciones" color="green" block plain>
                  <span
                    ><h6 class="text-capitalize" style="font-size:0.8rem">
                      Ver Más
                    </h6></span
                  >
                  <v-icon>
                    mdi-arrow-right-circle-outline
                  </v-icon></v-btn
                >
              </v-card>
            </div>
          </v-menu>
        </span>

        <!-- Modal de Card -->
        <v-dialog v-model="vistaModal" max-width="700">
          <v-card>
            <v-card-title class="bg-gray-200">
              <v-icon class="mr-2" large>mdi-bullhorn-outline</v-icon>
              <h1>{{ selectedAviso ? selectedAviso.nombre : "" }}</h1>
              <div class="flex justify-end" v-if="this.leido === false">
                <v-icon>mdi-check-all</v-icon>
              </div>
              <div class="flex justify-end" v-else>
                <v-icon color="blue">mdi-check-all</v-icon>
              </div>
            </v-card-title>
            <v-card-text>
              <div class="flex justify-start py-4">
                <h1 class="break-all whitespace-pre-line text-left">
                  {{ selectedAviso ? selectedAviso.descripcion : "" }}
                </h1>
              </div>
            </v-card-text>

            <v-card-actions>
              <div class="flex justify-end">
                <v-btn
                  color="green darken-1"
                  outlined
                  text
                  @click="
                    cerrarModal(selectedAviso.avisos_id, selectedAviso.leido)
                  "
                  >Cerrar</v-btn
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div>{{ $store.state.email }}</div>

        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              href="javascript:;"
              id="navbarDropdownProfile"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="color: white"
            >
              <i class="material-icons">person</i>
              <p class="d-lg-none d-md-block">Account</p>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="navbarDropdownProfile"
            >
              <a v-show="permisos.some((p) => p === 'usuario.update')">
                <router-link class="dropdown-item" to="/perfil">
                  Perfil
                </router-link>
              </a>
              <a>
                <router-link class="dropdown-item" to="/general">
                  General
                </router-link>
              </a>
              <a v-show="permisos.some((p) => p === 'psp.consultar.cuentas')">
                <router-link class="dropdown-item" to="/preference">
                  Checkout
                </router-link>
              </a>
              <div class="dropdown-divider"></div>
              <a
                href="#"
                class="dropdown-item"
                @click="$store.commit('logout')"
              >
                Cerrar sesión
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ChangeClienteCuenta from "@/components/Administrar/ChangeClienteCuenta.vue";
import ChangeCliente from "@/components/Administrar/ChangeCliente.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import { Notificaciones } from "../../services/notificaciones.js";

export default {
  name: "Nav",
  components: {
    ChangeClienteCuenta,
    ChangeCliente,
  },
  data() {
    return {
      vistaModal: false,
      selectedAviso: null,
      showBadge: true,
      leidos: 0,
      leido: "",
    };
  },

  filters: {
    truncate(value, maxLength) {
      if (value.length > maxLength) {
        return value.substring(0, maxLength) + "...";
      } else {
        return value;
      }
    },
  },

  computed: {
    ...mapGetters(["isSuperAdmin", "permisos", "roles", "getUserId"]),
    ...mapState("notificaciones", [
      "avisosNl",
      "page",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
    ]),
    /*  mostrar() {
      const rutas = [
        "/general",
        "/movimientos",
        "/pagos",
        "/transferencias",
        "/reportes",
        "/contracargos",
        "/preference",
        "/reportes-cobranzas",
        "/reportes-rendiciones",
        "/reportes-retenciones",
        "/reportes-reversiones",
        "/reporte-movimiento",
      ];
      return rutas.includes(this.$route.path);
    }, */
  },
  methods: {
    avisosLeidos() {
      // Filtra los avisos donde no leidos es igual a false
      this.leidos = this.avisosNl.filter((aviso) => !aviso.leidos);
      this.leidos = this.leidos.length;
      // Muestra la cantidad de avisos no leídos en la consola
    },
    actualizarAvisos() {
      this.getAvisosNoleidos();
    },

    ...mapActions("notificaciones", ["getAvisosNoleidos"]),
    //Para abrir Modal de Card
    abrirModal(aviso) {
      this.selectedAviso = aviso;
      this.leido = this.selectedAviso.leido;
      this.vistaModal = true;
    },
    cerrarModal(id, read) {
      this.cambiarLeido(id, read);
      this.selectedAviso = null;
      this.vistaModal = false;
    },
    //Camnbiar a Leido aviso
    async cambiarLeido(id, read) {
      if (!read) {
        try {
          const params = { AvisoId: id, UserId: parseInt(this.getUserId) };
          const response = await Notificaciones.putCambiarEstadoAviso(params);
          this.getAvisos();
          this.getAvisosNoleidos();
          return response;
        } catch (error) {
          // console.error("Error cambiando Estado del Leido Store");
          throw error;
        }
      }
    },
  },
  created() {
    this.getAvisosNoleidos();
  },
  mounted() {
    this.getAvisosNoleidos;
    this.avisosLeidos();
  },
};
</script>
<style scoped>
/* Estilo personalizado para el badge */
.custom-badge {
  background-color: #64b5f6; /* Color celeste */
  color: white; /* Color del texto */
  font-size: 10px; /* Tamaño del texto */
  width: 16px; /* Ancho del badge */
  height: 16px; /* Altura del badge */
  border-radius: 50%; /* Hace que el badge sea circular */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
