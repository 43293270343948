<template>
  <div>
    <Principal />

    <InfoHome />

    <Features />

    <PhoneHome />

    <Empresas />

    <!-- <section id="chips">
      <div>
        <v-chip
          class="ma-2 chip-item"
          large
          v-for="chip in chips"
          :key="chip.label"
          style="padding: 0 25px;"
          :outlined="chip.outline"
          :color="chip.background"
          :style="'color: ' + chip.color"
        >
          {{ chip.label }}
        </v-chip>
      </div>
    </section> -->

    <div style="background: #fff; padding: 6% 0%;">
      <img
        src="../../public/assets/img/grupos.svg"
        alt="chips grupos"
        style="width: 100%;"
        class="chips-image"
      />
    </div>

    <FooterHome />
  </div>
</template>

<script>
import Principal from "../components/Home/Principal.vue";
import InfoHome from "../components/Home/InfoHome.vue";
import Features from "../components/Home/Features.vue";
import PhoneHome from "../components/Home/PhoneHome.vue";
import Empresas from "../components/Home/Empresas.vue";
import FooterHome from "../components/Home/FooterHome.vue";

export default {
  name: "Home1",
  components: {
    Principal,
    InfoHome,
    Features,
    PhoneHome,
    Empresas,
    FooterHome,
  },
  data() {
    return {
      chips: [
        {
          label: "cobros",
          color: "#000",
          background: "#c2d501",
          outline: false,
        },
        { label: "+", color: "#000", background: "#d9d9d9", outline: false },
        {
          label: "ágil",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "pagos electrónicos",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "seguro",
          color: "#fff",
          background: "#009338",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "ágil",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "pagos electrónicos",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "pagos electrónicos",
          color: "#fff",
          background: "#009338",
          outline: false,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#c2d501",
          outline: false,
        },
        {
          label: "interoperabilidad",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "pagos electrónicos",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "seguro",
          color: "#fff",
          background: "#009338",
          outline: false,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#c2d501",
          outline: false,
        },
        {
          label: "ágil",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "interoperabilidad",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#000",
          outline: true,
        },
        { label: "+", color: "#000", background: "#d9d9d9", outline: false },
        {
          label: "ágil",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "pagos electrónicos",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "seguro",
          color: "#000",
          background: "#c2d501",
          outline: false,
        },
        { label: "+", color: "#000", background: "#d9d9d9", outline: false },
        {
          label: "agil",
          color: "#fff",
          background: "#009338",
          outline: false,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "pagos electrónicos",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "agil",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#c2d501",
          outline: false,
        },
        { label: "+", color: "#000", background: "#d9d9d9", outline: false },
        {
          label: "ágil",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "pagos electrónicos",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "seguro",
          color: "#fff",
          background: "#009338",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
        {
          label: "ágil",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "pagos electrónicos",
          color: "#fff",
          background: "#00b1eb",
          outline: false,
        },
        {
          label: "+",
          color: "#000",
          background: "#000",
          outline: true,
        },
        {
          label: "cobros",
          color: "#000",
          background: "#d9d9d9",
          outline: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
/* CHIPS */

#chips {
  background: #fff;
  padding: 70px 0 50px;
}

#chips > div {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 18px;
  column-gap: 30px;

  border-radius: 100px;
  padding: 50px 15px;
}

.chip-item {
  font-size: 0.9vw !important;
}

@media (max-width: 500px) {
  .chips-image {
    width: 800px !important;
    transform: scale(1.4);
    padding: 40px 0 40px;
  }
}
</style>
