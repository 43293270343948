<template class="rounded-xl">
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent width="700px">
      <v-card class="p-3">
        <div class="forgot-container">
          <h5 class="forgot-title text-center">Restablecer contraseña</h5>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p class="forgot-info">
                    Ingresá tu correo para poder continuar con el proceso de
                    restablecimiento de contraseña, una vez enviado el correo.
                    Verificá tu bandeja de entrada y seguí los pasos
                    correspondientes.
                  </p>
                  <input
                    type="email"
                    class="forgot-input-username"
                    v-model="username"
                    required
                  />
                  <div class="alert-container mt-5" v-if="alert.show">
                    <v-alert :type="alert.type" class="m-0 text-sm-body-2">
                      {{ alert.message }}
                    </v-alert>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn btn-round gradient_color"
              @click="$emit('update:showDialog', !showDialog)"
            >
              Cerrar
            </v-btn>
            <v-btn
              class="btn btn-round gradient_color"
              :loading="forgotLoading"
              @click="forgotPassword"
            >
              Enviar Correo
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Login } from "@/services/login.js";

export default {
  name: "ForgotPassword",
  props: {
    showDialog: Boolean,
    validEmail: Function,
  },
  data: () => ({
    forgotLoading: false,
    username: "",

    alert: {
      show: false,
      type: "success",
      message: "",
    },
  }),
  methods: {
    async forgotPassword() {
      if (!this.validEmail(this.username)) {
        this.alert.show = true;
        this.alert.type = "error";
        this.alert.message = "El correo electrónico debe ser válido";

        //ocultando alerta
        setTimeout(() => {
          this.alert.show = false;
        }, 4000);
      } else {
        this.forgotLoading = true;

        const username = {
          Email: this.username,
        };

        await Login.forgotPassword(username)
          .then(() => {
            this.forgotLoading = false;
            this.alert.show = true;
            this.alert.type = "success";
            this.alert.message = "Correo enviado correctamente";
            //ocultando alerta
            setTimeout(() => {
              this.username = "";
              this.alert.show = false;
            }, 4000);
          })
          .catch((error) => {
            this.forgotLoading = false;
            this.alert.show = true;
            this.alert.type = "error";
            this.alert.message = error.response.data.message;

            //ocultando alerta
            setTimeout(() => {
              this.alert.show = false;
            }, 4000);
          });
      }
    },
  },
};
</script>

<style scoped>
.forgot-container {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0px !important;
  margin-top: 0;
  padding: 5%;
  background: linear-gradient(#fff 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(90deg, #c2d501 0%, #1db6c8 51.56%, #00b1eb 100%)
      border-box;
  border: 3px solid transparent;
  border-radius: 42px !important;
}
.forgot-title {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
}
.forgot-info {
  font-size: 23px;
  line-height: 29px;
  color: #000;
}

.forgot-input-username {
  width: 475px;
  font-size: 20px;
  padding: 15px 10px;
  border-radius: 9px;
  margin-top: 15px;
  font-family: "Montserrat", sans-serif !important;
  background: linear-gradient(#fff 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(90deg, #c2d501 0%, #1db6c8 51.56%, #00b1eb 100%)
      border-box;
  border: 2px solid transparent;
}

.gradient_color {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  font-weight: 900;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: linear-gradient(270deg, #c2d501 0%, #1db6c8 50.52%, #00b1eb 100%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
}
.gradient_color:hover {
  background-image: linear-gradient(
    to left,
    #c2d501 0%,
    #1db6c8 50.52%,
    #00b1eb 100%
  );
  color: #000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.alert-container {
  width: 475px;
  margin: auto;
}

@media (max-width: 700px) {
  .forgot-title {
    font-size: 30px;
  }
  .forgot-info {
    font-size: 18px;
  }
  .forgot-input-username,
  .alert-container {
    width: 380px;
    font-size: 18px;
  }

  .gradient_color {
    font-size: 18px;
  }

  @media (max-width: 580px) {
    .forgot-input-username,
    .alert-container {
      width: 300px;
    }
  }

  @media (max-width: 480px) {
    .forgot-title {
      font-size: 25px;
    }

    .forgot-info {
      font-size: 15px;
    }
    .forgot-input-username,
    .alert-container {
      width: 100%;
      padding: 10px 5px;
    }
    .gradient_color {
      font-size: 13px;
    }
  }
}
</style>
