import axios from 'axios';
import './axiosInterceptor';
export const AUTHENTICATION = process.env.VUE_APP_URLAUTHENTICATION;

export const RolPermiso = {

  // vincular nuevo permiso al rol
  vincular_permiso_rol(payload) {
    return axios.post(AUTHENTICATION + "/adm/rol-permiso", payload)
  },

  //  delete se piden parametros por body la opcion correcta es la siguiente
  quitar_permiso_rol(payload) {
    return axios.delete(AUTHENTICATION + "/adm/rol-permiso", { data: payload })
  },
}
