import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Clientes = {
  //Obtener todo los clientes
  getClientes(params) {
    return axios.get(PASARELA + "/administracion/clientes", { params });
  },
  // Obtener toda la data relacionada con un cliente incluyendo sus cuentas y datos relacionados
  getAllDataClienteId(data, config) {
    return axios.post(
      PASARELA + "/administracion/obtener-cliente",
      data,
      config
    );
  },
  // crear nueva cliente
  nuevo_cliente(payload) {
    return axios.post(PASARELA + "/administracion/cliente", payload);
  },

  // actualizar cliente
  actualizar_cliente(payload) {
    return axios.put(PASARELA + "/administracion/cliente", payload);
  },

  //consultar cliente por ID
  getClienteID(data, config) {
    return axios.post(
      PASARELA + "/administracion/obtener-cliente",
      data,
      config
    );
  },

  // delete cliente
  delete_cliente(params) {
    return axios.delete(PASARELA + "/administracion/cliente", { params });
  },

  // consultar cliente o cuenta asociada al usuario
  getClienteCuentaLogin(params) {
    return axios.get(PASARELA + "/administracion/cliente-login", { params });
  },

  //ENVIOS
  actualizarEnvios(payload) {
    return axios.put(PASARELA + "/administracion/update-envios", payload);
  },


  //SPLITS
  // Traer Clientes Splits
  getClienteSplits(params) {
    return axios.get(PASARELA + "/administracion/clientes-splits", { params });
  },

  //Crear Cliente Splits

  crearClienteSplit(params){
    return axios.post(PASARELA + "/administracion/clientes-splits", {params});
  },

  //Eliminar Cliente Splits
  deleteClienteSplit(params){
    return axios.delete(PASARELA + "/administracion/clientes-splits",{params});
  },

};
