<template>
  <v-container>
    <v-row justify="center" style="text-align: left">
      <v-hover v-slot="{ hover }">
        <!-- <v-card width="400" style="z-index: 0" :elevation="hover ? 12 : 2"> -->
          <!-- <v-card-title>
            {{ CLIENTE_CUENTA_DATA.cliente }}
          </v-card-title> -->

          <!-- <v-card-text> -->
            <!-- <div class="font-weight-bold ml-8 mb-2">Datos del Cliente</div> -->

            <!-- <v-timeline align-top dense>
              <v-timeline-item small>
                <div>Razon Social</div>
                <div class="font-weight-normal">
                  <strong> {{ CLIENTE_CUENTA_DATA.razon_social }}</strong>
                </div>
              </v-timeline-item>

              <v-timeline-item small>
                <div>Nombre de Fantasia</div>
                <div class="font-weight-normal">
                  <strong>{{ CLIENTE_CUENTA_DATA.nombre_fantasia }}</strong>
                </div>
              </v-timeline-item>
              <v-timeline-item small>
                <div>Email</div>
                <div class="font-weight-normal blue--text">
                  <strong>{{ CLIENTE_CUENTA_DATA.email }}</strong>
                </div>
              </v-timeline-item>
              <v-timeline-item small>
                <div>CUIT</div>
                <div class="font-weight-normal">
                  <strong>{{ CLIENTE_CUENTA_DATA.cuit }}</strong>
                </div>
              </v-timeline-item>
            </v-timeline> -->
          <!-- </v-card-text> -->
        <!-- </v-card> -->
      </v-hover>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "DatosCliente",
  data: () => ({}),
  computed: {
    ...mapState(["CLIENTE_CUENTA_DATA"]),
  },
};
</script>