import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Obtén el token de tu almacenamiento local

    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Agrega el token a la cabecera de la solicitud
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
