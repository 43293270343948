<template>
  <div id="retenciones">
    <!-- RETENCIONES -->
    <h4 class="bold pt-4 mb-2">Retenciones</h4>
    <p>Retorna todos las retenciones para el día especificado.</p>
    <h4 class="bold my-4">REQUEST</h4>
    <!-- -------------HEADERS--------------- -->
    <h4 class="my-4">Headers</h4>
    <v-simple-table fixed-header height="150px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              PATTERN
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in headersRetenciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>
              <code
                style="background-color: #f5f5f5; padding: 2px 4px; border-radius: 4px; color: #000"
                >{{ item.pattern }}
              </code>
            </td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------PARAMS----------- -->
    <p class="my-4">Path parameters</p>
    <v-simple-table fixed-header height="100px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paramsRetenciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------RESPONSE----------- -->
    <h4 class="bold my-4">RESPONSE</h4>
    <p class="bold">Atributos del objeto de respuesta.</p>
    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in responseRetenciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response----objeto data -->
    <p class="pt-4">Atributos del objeto <span class="italic">data</span>.</p>
    <v-simple-table fixed-header height="240px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objDataRetenciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- GET Obtener Retenciones -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <h4 class="bold mb-4" id="crear-solicitud-pago">
      <span class="metodo-get">GET</span> Obtener Retenciones
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p
        class="
      d-flex
        align-items-center
        justify-content-center
        mb-0
      "
      >
        https://sb-wee.ar/v1/reporte/retenciones?Date=03-01-2023
      </p>
    </div>
    <!-- Headers -->
    <h4 class="bold mt-4">HEADERS</h4>
    <v-divider></v-divider>
    <div>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Content-Type</p>
        </v-col>
        <v-col cols="6">
          <p>application/json</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Authorization</p>
        </v-col>
        <v-col cols="6">
          <p>Bearer <span>{</span>{api_key}<span>}</span></p>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: RESPONSE --------------------- -->
    <div>
      <h6 class="bold mt-4">Example Response</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
{
  "cuenta_id": "000015",
  "fecha_reporte": "2023-11-07T00:00:00Z",
  "total_retenido": 135078,
  "data": [
    {
      "fecha_creacion": "2023-11-07T11:14:56Z",
      "pago_id": 49223,
      "referencia_externa": "1099578447",
      "tipo": "iibb",
      "canal_pago": "CREDIT",
      "monto": 179520,
      "alicuota": 2,
      "importe_retenido": 3590
    },
    {
      "fecha_creacion": "2023-11-07T11:14:56Z",
      "pago_id": 48910,
      "referencia_externa": "1099338355",
      "tipo": "iibb",
      "canal_pago": "CREDIT",
      "monto": 1214400,
      "alicuota": 2,
      "importe_retenido": 24288
    }
  ]
}
    </code>
  </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewAyudaRepRetenciones",
  data() {
    return {
      headersRetenciones: [
        {
          atributo: "Content-Type",
          tipo: "String",
          requerido: "SI",
          pattern: "application/json",
          descripcion:
            "El tipo MIME del contenido del cuerpo enviado. Por defecto JSON es Unicode UTF-8. No se debe configurar otro charset.",
        },
        {
          atributo: "Authorization",
          tipo: "String",
          requerido: "SI",
          pattern: "Bearer",
          descripcion: "ba7943e1-a60c-43e7-8812-xxxxxxxxxxxx",
        },
      ],
      paramsRetenciones: [
        {
          atributo: "Date",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Fecha de retenciones. Solo se puede pedir este reporte para una fecha anterior al día actual. Formato DD-MM-YYYY",
        },
      ],
      responseRetenciones: [
        {
          atributo: "cuenta_id",
          tipo: "Integer",
          descripcion:
            "Identificador de la cuenta de la que se realizó la retención.",
        },
        {
          atributo: "fecha_reporte",
          tipo: "DateTime",
          descripcion: "Fecha en la que se realizó la retención.",
        },
        {
          atributo: "total_retenido",
          tipo: "Integer",
          descripcion: "Total retenido en la cuenta.",
        },
        {
          atributo: "data",
          tipo: "Array[Object]",
          descripcion:
            "Arreglo de objetos con la información de cada retención.",
        },
      ],
      objDataRetenciones: [
        {
          atributo: "fecha_creacion",
          tipo: "DateTime",
          descripcion: "Fecha en la que se informo la retención",
        },
        {
          atributo: "pago_id",
          tipo: "Integer",
          descripcion: "Identificador del pago.",
        },
        {
          atributo: "referencia_externa",
          tipo: "String",
          descripcion:
            "Este atributo es utilizado como referencia para identificar la solicitud y sincronizar con tus sistemas de backend el origen de la operación. ",
        },
        {
          atributo: "tipo",
          tipo: "String",
          descripcion: "Tipo de retención o percepción aplicada.",
        },
        {
          atributo: "canal_pago",
          tipo: "String",
          descripcion: "Nombre del medio de pago utilizado.",
        },
        {
          atributo: "monto",
          tipo: "Integer",
          descripcion: "Monto total del pago.",
        },
        {
          atributo: "alicuota",
          tipo: "Integer",
          descripcion: "Porcentaje de retención aplicada.",
        },
        {
          atributo: "importe_retenido",
          tipo: "Integer",
          descripcion: "Monto retenido.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.metodo-get {
  color: rgb(0, 127, 49);
}
.url-container {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  height: 40px;
  display: flex;
  padding-left: 10px;
}
.code-block {
  font-size: 14px;
}
</style>
