<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <h4 id="solicitar_token" align="left">Solicitar Token</h4>
        <Editor
          v-model="formulario_token"
          @init="editorInit"
          lang="json"
          theme="chrome"
          width="400px"
          height="120px"
          :options="options"
        ></Editor>
        <v-btn color="success" @click="solicitar"> Solicitar </v-btn>
        <h4 align="left">Response Token</h4>
        <Editor
          v-model="respuesta_login"
          v-show="respuesta_login != ''"
          lang="json"
          theme="chrome"
          width="500px"
          height="280px"
          :options="option2"
        ></Editor>

        <v-divider></v-divider>
        <div style="margin-bottom: 60px;"></div>
        <h4 align="left">Consultar Pago Request Body</h4>
        <Editor
          v-model="formulario"
          @init="editorInit"
          lang="json"
          theme="chrome"
          width="500px"
          height="170px"
          :options="options"
        ></Editor>
        <v-btn color="success" @click="test"> Probar </v-btn>
        <h4 id="consultar_pagos" align="left">Consultar Pago Response</h4>
        <Editor
          v-model="respuesta"
          v-show="respuesta != ''"
          lang="json"
          theme="chrome"
          width="500px"
          height="200px"
          :options="option2"
        ></Editor>
        <v-divider></v-divider>
        <v-snackbar
          color="blue-grey"
          v-model="snackbar_success"
          :timeout="timeout"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          color="red accent-2"
          v-model="snackbar_error"
          :timeout="timeout"
        >
          {{ text }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <p style="color: black !important">Código de ejemplo</p>
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-tabs v-model="tab">
                  <v-tab>Javascript</v-tab>
                  <v-tab>Go</v-tab>
                  <v-tab>C#</v-tab>
                  <v-tab>Java</v-tab>
                </v-tabs>
                <v-card-text>
                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <Editor
                        v-model="javascript"
                        lang="javascript"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></Editor>
                    </v-tab-item>
                    <v-tab-item>
                      <Editor
                        v-model="golang"
                        lang="golang"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></Editor>
                    </v-tab-item>
                    <v-tab-item>
                      <Editor
                        v-model="csharp"
                        lang="csharp"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></Editor>
                    </v-tab-item>
                    <v-tab-item>
                      <Editor
                        v-model="java"
                        lang="java"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></Editor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Editor from "vue2-ace-editor";
import endent from "endent";
import moment from "moment";

export default {
  components: { Editor, endent },
  data() {
    const body_token_request = {
      username: "demo-dev@telco.com.ar",
      password: "Pass1234@",
      sistema_id: "10",
    };

    const ejemplo = {
      uuid: "",
      external_reference: "",
      fecha_desde: moment()
        .subtract(7, "days")
        .format("DD-MM-YYYY"),
      fecha_hasta: moment().format("DD-MM-YYYY"),
      uuids: [],
      external_references: [],
    };

    return {
      snackbar_error: false,
      snackbar_success: false,
      text: "El token fue obtenido correctamente",
      timeout: 2000,
      formulario: JSON.stringify(ejemplo, null, 2),
      formulario_token: JSON.stringify(body_token_request, null, 2),
      respuesta: "",
      respuesta_login: "",
      token: "",
      options: {
        autoScrollEditorIntoView: true,
        highlightActiveLine: true,
        highlightSelectedWord: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
      },
      option2: {
        readOnly: true,
        autoScrollEditorIntoView: true,
        highlightActiveLine: true,
        highlightSelectedWord: true,
        wrap: true,
      },
      tab: null,
      javascript: endent`var data = JSON.stringify({
                "fecha_desde":"01-06-2021",
                "fecha_hasta":"07-06-2021"
            });

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
        if (this.readyState === this.DONE) {
            console.log(this.responseText);
        }
        });

        xhr.open("POST", "https://127.0.0.1:3300/administracion/consulta/pago");
        xhr.setRequestHeader("apikey", "123123123123123");
        xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("cache-control", "no-cache");

        xhr.send(data);`,
      golang: endent`package main

        import (
            "fmt"
            "strings"
            "net/http"
            "io/ioutil"
        )

        func main() {

            url := "https://127.0.0.1:3300/administracion/consulta/pago"

            payload := strings.NewReader("{
                \"fecha_desde\":\"01-06-2021\",
                \"fecha_hasta\":\"07-06-2021\"
            }")
            

            req, _ := http.NewRequest("POST", url, payload)

            req.Header.Add("apikey", "123123123123123")
            req.Header.Add("content-type", "application/json")
            req.Header.Add("cache-control", "no-cache")

            res, _ := http.DefaultClient.Do(req)

            defer res.Body.Close()
            body, _ := ioutil.ReadAll(res.Body)

            fmt.Println(res)
            fmt.Println(string(body))

        }`,
      csharp: endent`var client = new RestClient("https://127.0.0.1:3300/administracion/consulta/pago");
        var request = new RestRequest(Method.POST);
        request.AddHeader("cache-control", "no-cache");
        request.AddHeader("content-type", "application/json");
        request.AddHeader("apikey", "123123123123123");
        request.AddParameter("application/json", "{
                \"fecha_desde\":\"01-06-2021\",
                \"fecha_hasta\":\"07-06-2021\"
            }", ParameterType.RequestBody);
        IRestResponse response = client.Execute(request);`,
      java: endent`HttpResponse<String> response = Unirest.post("https://127.0.0.1:3300/administracion/consulta/pago")
        .header("apikey", "123123123123123")
        .header("content-type", "application/json")
        .header("cache-control", "no-cache")
        .body("{
                \"fecha_desde\":\"01-06-2021\",
                \"fecha_hasta\":\"07-06-2021\"
            }")
        .asString();`,
    };
  },
  methods: {
    editorInit: function(editor) {
      editor.setFontSize(14);
      editor.container.style.lineHeight = 1.5;
    },
    test() {
      // Simple POST request with a JSON body using fetch

      const requestOptions = {
        method: "POST",
        headers: {
          apiKey: "123123123123123",
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
        body: this.formulario,
      };
      fetch(
        "https://dev-back-pagos.telco.com.ar/administracion/consulta/pago",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => (this.respuesta = JSON.stringify(data, null, 2)));
    },
    solicitar() {
      var mybody = JSON.parse(this.formulario_token);

      const requestTokenOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mybody),
      };
      fetch("https://devlogin.telco.com.ar/users/login", requestTokenOptions)
        .then((response) => {
          if (!response.ok) {
            throw response;
          }
          return response.json(); //we only get here if there is no error
        })
        .then((data) => (this.respuesta_login = JSON.stringify(data, null, 2)))
        .then(() => {
          let objeto = JSON.parse(this.respuesta_login);
          this.token = objeto.token;
          this.snackbar_success = true;
        })
        .catch(() => {
          this.text = "No se pudo obtener respuesta del servidor";
          this.snackbar_error = true;
        });
    },
  },
};
</script>
<style scoped></style>
