<template>
  <div>
    <!-- su session expiro, vuelva a iniciar sesion  -->
    <v-dialog v-model="dialog_expired" persistent max-width="380">
      <v-card class="py-4">
        <v-card-title
          class="justify-center font-weight-bold text-h5"
          style="font-family: Montserrat !important"
        >
          Wee! Pasarela de Pagos
        </v-card-title>

        <v-card-text
          style="font-family: Montserrat !important"
          class="text-subtitle-1"
        >
          Su sesión ha expirado por inactividad. Por favor, ingrese de nuevo.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            class="font-weight-bold"
            text
            @click="aceptar()"
            style="font-family: Montserrat"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div style="display: none;">
      <v-idle
        @idle="onidle"
        @remind="onremind"
        :loop="false"
        :events="['keypress', 'touchstart', 'mousedown']"
        :duration="expired"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Token",
  data() {
    return {
      expired: this.$store.state.expired,
      dialog_expired: false,
    };
  },
  methods: {
    onidle() {
      this.dialog_expired = true;
      //  alert('Se sesión expiro por inactividad');
    },
    aceptar() {
      this.$router.push("/");
      this.$store.commit("setExpired", 0);
    },
    onremind() {
      // alert seconds remaining to 00:00
      this.$router.push("/");
    },
  },
};
</script>

<style scoped></style>
