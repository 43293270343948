import axios from 'axios';
import './axiosInterceptor';

export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Users = {
  // Obtener todos los usarios de un sistema
  getUsers(params) {
    return axios.get(PASARELA + "/usuario/users", { params })
  },

  nuevo_user(payload) {
    return axios.post(PASARELA + "/usuario/user", payload)
  },


  actualizar_user(payload) {
    return axios.put(PASARELA + "/usuario/user", payload)
  },

  getUserID(params) {
    return axios.get(PASARELA + "/usuario/user", { params })
  },
}
