<template>
  <div data-app class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
          <div class="profile-tabs">
            <ul
              class="nav nav-pills nav-pills-icons justify-content-center"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active show"
                  href="#roles"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                >
                  <i class="material-icons">assignment_ind</i> Roles
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#permisos"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                >
                  <i class="material-icons">verified_user</i> Permisos
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#users"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="true"
                >
                  <i class="material-icons">manage_accounts</i> Usuarios
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- COMPONENTES (COMPONENTS/Transferencias) -->
      <div class="tab-content tab-space">
        <div class="tab-pane text-center gallery active show" id="roles">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <Roles />
            </div>
          </div>
        </div>

        <div class="tab-pane text-center gallery" id="permisos">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <Permisos />
            </div>
          </div>
        </div>

        <div class="tab-pane text-center gallery" id="users">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <Users />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Users from "@/components/Accesos/Users.vue";
import Roles from "@/components/Accesos/Roles.vue";
import Permisos from "@/components/Accesos/Permisos.vue";
export default {
  name: "IndexAdministrar",
  components: {
    Users,
    Roles,
    Permisos,
  },
};
</script>
<style scoped>
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #00B1EB;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(17 82 40 / 60%);
}
</style>