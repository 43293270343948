import axios from 'axios';
import './axiosInterceptorfiles';

export const PASARELA = process.env.VUE_APP_URLPASARELA;

export const Bcra = { 
     getSupervision(params) {
        return  axios.get(PASARELA+"/administracion/informacion-supervision", {params})
    },
    post_supervision(formData) {
        return  axios.post(PASARELA+"/administracion/informacion-supervision",formData)
    },

    getEstadisticas(params) {
        return  axios.get(PASARELA+"/administracion/informacion-estadistica", {params})
    },
     post_estadisticas(formData) {
        return  axios.post(PASARELA+"/administracion/informacion-estadistica",formData)
    },

}
