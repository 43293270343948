<template>
  <div class="content">
    <v-app>
      <v-main>
        <v-container>
          <v-row justify="center" v-if="false">
            <v-col cols="6">
              <!-- Card con los datos principales de la cuenta -->
              <DatosCliente :datos="CLIENTE_CUENTA_DATA" />
            </v-col>
            <!-- Card con los impuestos para este cliente -->
            <!-- <v-col cols="4">
              <Impuestos :impuestos="CLIENTE_CUENTA_DATA.impuestos" />
            </v-col> -->
          </v-row>
          <v-row v-if="false">
            <v-col cols="6">
              <DatosCuentas :cuentas="CLIENTE_CUENTA_DATA.cuentas" />
            </v-col>
          </v-row>

          <!-- <div class="title ml-4" style="text-align: left">Cuentas</div> -->
          <!-- Puede haber mas de una cuenta. Se itera el component DatosCuenta pasando por props cada cuenta item al component -->
          <DatosCuentas
            :cuenta="CLIENTE_CUENTA_DATA.cuenta.find((c) => c.id == cuenta_ID)"
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
// importar componente saldo
import DatosCliente from "@/components/General/DatosCliente.vue";
// import Impuestos from "@/components/General/Impuestos.vue";
import DatosCuentas from "@/components/General/DatosCuentas.vue";
import { mapState } from "vuex";

// import moment from "moment";
export default {
  name: "General",
  components: {
    DatosCliente,
    // Impuestos,
    DatosCuentas,
  },
  data() {
    return {};
  },

  methods: {},
  mounted() {
    this.$store.dispatch("getClientAllData", this.cliente_ID);
  },

  computed: {
    ...mapState(["CLIENTE_CUENTA_DATA", "cliente_ID", "cuenta_ID"]),
  },
};
</script>

<style scoped>
/* .trow {
  transition: transform 0.3s;
}
.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */

.v-application .text-subtitle-2 {
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0.0071428571em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
