import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Saldo = {
     //saldo de la cuenta 
     getSaldoCuenta(params) {
        return  axios.get(PASARELA+"/administracion/saldo-cuenta", {params})
    },

    getSaldoSubCuenta(params) {
        return  axios.get(PASARELA+"/administracion/saldo-subcuentas", params)
    },

}