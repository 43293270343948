import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const DOMINIO = "/administracion";
export const Transferencias = {
  // Todas las tranferencias de un cliente
  getTranseferencias(params) {
    return axios.get(PASARELA + DOMINIO + "/transferencias", { params })
  },

  //listado de transferencias por movimientos
  getMovimientosTransferencias(params) {
    return axios.get(PASARELA + DOMINIO + "/movimiento-transferencia", { params })
  },

  // confirmar nueva transferencia 
  makeTranfers(cuenta_id, payload) {
    return axios.post(PASARELA + DOMINIO + '/transferencia-cliente?cuenta_id=' + cuenta_id, payload)
  },
}
