<template>
  <div class="content">
    <div class="header-container">
      <v-btn fab dark x-small color="primary">
        <a @click="$router.go(-1)">
          <v-icon dark> arrow_back</v-icon>
        </a>
      </v-btn>
      <div>
        <!-- BOTON NUEVA SUB CUENTA -->
        <v-btn
          v-if="subCuentas.length === 0"
          @click="showFormNuevaSub"
          color="green"
          dark
          class="mr-2"
        >
          Nueva Sub Cuenta
        </v-btn>
        <!-- BOTON EDITAR SUB CUENTAS -->
        <v-btn color="green" dark class="mr-2" v-else @click="showFormEditSub">
          Editar
        </v-btn>
      </div>
    </div>
    <LayoutPage :title="titulo">
      <!-- SHOW ERROR SECTION -->
      <section v-if="errored">
        <v-alert
          border="left"
          class="mt-3 mx-3 elevation-3"
          color="red lighten-2"
          dark
        >
          Lo sentimos, no es posible obtener la información en este momento, por
          favor intente nuevamente mas tarde.
        </v-alert>
      </section>
      <!-- FILTRO BUSCAR -->
      <section v-else>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="find_in_page"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <!-- TABLA SUB CUENTAS -->
        <v-data-table
          :headers="headers"
          :search="search"
          :items="subCuentas"
          class="elevation-0 trow"
          :loading="cargando"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
          loading-text="Cargando... Por favor espere"
          no-data-text="No se encontraron registros"
          no-results-text="No se encontraron registros que coincidan"
        >
          <template v-slot:[`item.porcentaje`]="{ item }">
            {{ item.porcentaje }}%
            <!-- {{ convertToPercentage(item.porcentaje) }} -->
          </template>
          <!-- Aplica Porcentaje -->
          <template v-slot:[`item.aplica_porcentaje`]="{ item }">
            <v-chip
              :color="item.aplica_porcentaje ? 'green' : 'red'"
              dark
              small
              class="white--text"
            >
              {{ item.aplica_porcentaje ? "Si" : "No" }}
            </v-chip>
          </template>
          <!-- Aplica Costo Servicio -->
          <template v-slot:[`item.aplica_costo_servicio`]="{ item }">
            <v-chip
              :color="item.aplica_costo_servicio ? 'green' : 'red'"
              dark
              small
              class="white--text"
            >
              {{ item.aplica_costo_servicio ? "Si" : "No" }}
            </v-chip>
          </template>
          <!-- BOTONES DE LA TABLA -->
          <template v-slot:[`item.actions`]="{ item }">
            <!-- 1. Eliminar -->
            <v-tooltip top color="grey darken-1">
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  :dark="subCuentas.length > 1"
                  x-small
                  color="red"
                  @click="showFormDeleteSub(item)"
                  v-on="on"
                  :disabled="subCuentas.length === 1"
                >
                  <v-icon dark> delete </v-icon>
                </v-btn>
              </template>
              <span>Eliminar Sub Cuenta</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- Paginacion -->
        <div class="text-centerp pt-2 pb-8">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
        <!-- FORM sub cuenta (NUEVA, EDITAR y ELIMINAR) -->
        <FormSubCuenta ref="formSubCuenta" @refreshSubCuentas="getSubCuentas" />
      </section>
    </LayoutPage>
  </div>
</template>

<script>
import LayoutPage from "@/components/LayoutPage.vue";
import FormSubCuenta from "@/components/Administrar/Clientes/SubCuentas/FormSubCuenta.vue";
import { Cuentas } from "../../../../services/cuentas.js";
import { SubCuentas } from "../../../../services/subcuentas";
export default {
  name: "SubCuentas",
  components: {
    LayoutPage,
    FormSubCuenta,
  },
  data() {
    return {
      cliente_id: "",
      cargando: false,
      errored: false, //error en mostrar tabla de sub cuentas
      search: "",
      titulo: "",
      subCuentas: [], //tabla sub cuentas
      cuenta: "", //cuenta seleccionada
      cuentaSeleccionada: "",

      //headers tabla sub cuentas
      aplica_porcentaje: false,
      aplica_costo_servicio: false,
      headersG: [
        { text: "Nombre", value: "nombre" },
        { text: "Email", value: "email" },
        // { text: "CBU", value: "cbu" },
        { text: "Tipo", value: "tipo" },
        { text: "Porcentaje %", value: "porcentaje" },
        // { text: "Cuenta Referencia ID", value: "cuentas_id_segun_tipo" },
        {
          text: "Aplica Porcentaje",
          value: "aplica_porcentaje",
          align: "center",
        },
        {
          text: "Aplica Costo Servicio",
          value: "aplica_costo_servicio",
          align: "center",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      //
      cuenta_id: "",

      //paginacion
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    headers() {
      // Filtra los headers según si se deben mostrar o no esas columnas
      return this.headersG.filter((header) => {
        if (header.value === "aplica_porcentaje" && !this.aplica_porcentaje) {
          return false;
        }
        if (
          header.value === "aplica_costo_servicio" &&
          !this.aplica_costo_servicio
        ) {
          return false;
        }
        return true;
      });
    },
  },

  created() {
    this.cliente_id = this.$route.params.cliente_id;
  },

  methods: {
    convertToPercentage(value) {
      if (!value) return "0%";
      return (parseFloat(value) / 100).toFixed(2) + "%";
    },

    showFormNuevaSub() {
      this.$refs.formSubCuenta.$emit("showFormNuevaSub", this.cuenta_id);
    },
    showFormEditSub() {
      this.$refs.formSubCuenta.$emit("showFormEditSub", this.subCuentas);
    },
    showFormDeleteSub(item) {
      this.$refs.formSubCuenta.$emit(
        "showFormDeleteSub",
        item,
        this.subCuentas
      );
    },

    //LISTAR subcuentas
    async getSubCuentas() {
      try {
        const params = {
          number: 1,
          size: 15,
          cuenta: this.cuenta_id,
        };

        this.cargando = true;

        const { data } = await SubCuentas.getSubCuentas(params);

        this.cargando = false;

        if (data.data == null) {
          this.$toastr.i(
            "No se encontraron sub cuentas asocidas a esta cuenta."
          );
          this.subCuentas = [];
        } else {
          this.subCuentas = data.data;

          this.aplica_porcentaje = data.data[0].aplica_porcentaje;
          this.aplica_costo_servicio = data.data[0].aplica_costo_servicio;

          // const cuenta = this.subCuentas[0].cuenta.cuenta;
          // this.titulo = `Configuración Split - ${cuenta}`;
        }
      } catch (error) {
        //this.errored = true;
        this.cargando = false;
        this.$toastr.e(error.response.data.message);
      }
    },

    async consultar_cuenta(item) {
      this.procensando_datos = true;
      const params = {
        id: item,
      };
      Cuentas.getCuentaID(params)
        .then((response) => {
          this.cuentaSeleccionada = response.data.cuenta;
          this.titulo = `Configuración Split - ${this.cuentaSeleccionada}`;
        })
        .catch((error) => {
          var data = error.response.data;
          //mostrar mensaje de error en caso que la variable sea true(tenga datos)
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        });
    },
  },

  mounted() {
    this.cuenta_id = this.$route.params.cuenta_id;
    this.consultar_cuenta(this.cuenta_id);
    this.getSubCuentas();
  },
};
</script>

<style scoped>
.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}

.header-container {
  padding: 10px 10px 20px;
  display: flex;
  justify-content: space-between;
}
</style>
