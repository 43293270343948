export const headers = [
  { text: "Cliente", value: "cliente", width: 210 },
  { text: "Cuenta", value: "cuenta", width: 170 },
  { text: "Tipo de pago", value: "tipo_pago", width: 170 },
  { text: "Referencia externa", value: "referencia_externa", width: 170 },
  { text: "ID pago", value: "pago_id" },
  { text: "Estado", value: "estado" },
  { text: "Medio de pago", value: "medio_pago" },
  { text: "Pagador", value: "pagador",  width: 170 },
  { text: "DNI pagador", value: "dni_pagador" },
  { text: "Cuotas", value: "cuotas" },
  { text: "Fecha de pago", value: "fecha_pago" },
  { text: "Nro de establecimiento", value: "nro_establecimiento" },
  { text: "Nro de liquidacion", value: "nro_liquidacion" },
  { text: "Fecha de presentación", value: "fecha_presentacion" },
  { text: "Fecha de acreditación", value: "fecha_acreditacion" },
  { text: "Fecha de rendición", value: "fecha_rendicion" },
  { text: "Importe a pagar", value: "importe_a_pagar" },
  { text: "Importe pagado", value: "importe_pagado" },
  { text: "Porcentaje retencion iibb", value: "porcentaje_retencion_iibb" },
  { text: "Retencion iibb", value: "retencion_iibb" },
  { text: "Porcentaje retencion iva", value: "porcentaje_retencion_iva" },
  { text: "Retencion iva", value: "retencion_iva" },
  { text: "Porcentaje retencion ganancias", value: "porcentaje_retencion_ganancias" },
  { text: "Retencion ganancias", value: "retencion_ganancias" },
  { text: "Cft coeficiente", value: "cft_coeficiente" },
  { text: "Importe cft", value: "importe_cft" },
  { text: "Iva cft", value: "iva_cft" },
  { text: "Porcentaje comision proveedor", value: "porcentaje_comision_proveedor" },
  { text: "Importe minimo", value: "importe_minimo" },
  { text: "Importe maximo", value: "importe_maximo" },
  { text: "Porcentaje arancel minimo", value: "porcentaje_arancel_minimo" },
  { text: "Porcentaje arancel maximo", value: "porcentaje_arancel_maximo" },
  { text: "Comision proveedor", value: "comision_proveedor" },
  { text: "Iva comision proveedor", value: "iva_comision_proveedor" },
  { text: "Comision porcentaje telco", value: "comision_porcentaje_telco" },
  { text: "Importe minimo telco", value: "importe_minimo_telco" },
  { text: "Importe maximo telco", value: "importe_maximo_telco" },
  { text: "Porcentaje arancel min telco", value: "porcentaje_arancel_min_telco" },
  { text: "Porcentaje arancel max telco", value: "porcentaje_arancel_max_telco" },
  { text: "Alicuota iva", value: "alicuota_iva" },
  { text: "Comision telco", value: "comision_telco" },
  { text: "Iva comision telco", value: "iva_comision_telco" },
  { text: "Importe neto cobrado", value: "importe_neto_cobrado" },
  { text: "Importe rendido", value: "importe_rendido" },
  { text: "Revertido", value: "revertido" },
  { text: "Cantidad de días", value: "cantidad_dias" },
  { text: "Referencia bancaria", value: "referencia_bancaria" },
];
