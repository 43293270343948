import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Channels = {
     //listar clientes 
     getChannels(params) {
        return  axios.get(PASARELA+"/administracion/channels", {params})
    },

    // crear nueva cliente
    nuevo_channel(payload) {
        return  axios.post(PASARELA+"/administracion/channel", payload)
    },

     // actualizar cuenta
     actualizar_channel(payload) {
        return  axios.put(PASARELA+"/administracion/channel", payload)
    },

    //consultar chennel por nombre channel
     getChannelName(params) {
        return  axios.get(PASARELA+"/administracion/channel", {params})
    },

     // delete channels
     delete_channel(params) {
        return  axios.delete(PASARELA+"/administracion/channel", {params})
    },
}
