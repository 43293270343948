<template>
  <div>
    <div class="row">
      <div class="col" align="right">
        <v-row justify="center">
          <!-- MODAL DEL FORMULARIO DE CREAR NUEVO ROL -->
          <v-dialog v-model="createRol" persistent max-width="600px">
            <!-- BOTON NUEVO ROL -->
            <template v-slot:activator="{ on, attrs }">
              <div class="col-md-12" align="right">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="abrir_create()"
                >
                  Nuevo rol
                </v-btn>
              </div>
            </template>
            <!-- END BOTON NUEVO ROL -->

            <!-- CARD DE FORMULARIO CREAR NUEVO ROL -->
            <v-card>
              <v-card-title>
                <span class="text-h5">Nuevo rol</span>
                <v-spacer></v-spacer>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="cerrar_create()"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </v-card-title>
              <div v-if="procensando_datos">
                <loader
                  object="#4caf50"
                  color1="#ffffff"
                  color2="#17fd3d"
                  size="5"
                  speed="2"
                  bg="#343a40"
                  objectbg="#999793"
                  opacity="40"
                  disableScrolling="false"
                  name="box"
                ></loader>
              </div>
              <v-card-text>
                <v-container>
                  <v-form ref="form_create" v-model="valid" lazy-validation>
                    <v-text-field
                      v-model="newrol"
                      :counter="30"
                      :rules="namerol"
                      label="Rol"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="newscope"
                      :counter="30"
                      :rules="namescope"
                      label="Scope"
                      required
                    ></v-text-field>

                    <v-text-field
                      v-model="newdescripcion"
                      :counter="30"
                      :rules="namedescripcion"
                      label="Descripcion"
                      required
                    ></v-text-field>

                    <!-- activar retiros_automaticos -->
                    <v-checkbox
                      v-model="newactivo"
                      class="ckeck"
                      label="Activo/Inactivo"
                    ></v-checkbox>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cerrar_create()">
                  Cerrar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  :loading="dialog"
                  color="blue darken-1"
                  text
                  @click="guardar_rol()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
            <!-- END CARD DE FORMULARIO CREAR NUEVO ROL -->
          </v-dialog>
          <!-- END MODAL DEL FORMULARIO DE CREAR NUEVO ROL -->
        </v-row>
      </div>
    </div>
    <br />
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Roles</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-4 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
                <a class="text-info" @click="intento()"> Volver a intentar </a>
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchRol"
                  append-icon="mdi-magnify"
                  label="Buscar Rol"
                  single-line
                  hide-details
                  clearable
                >
                </v-text-field>
              </v-card-title>

              <!-- END FILTRO BUSCAR -->

              <!-- DATATABLE -->
              <v-data-table
                :headers="headers"
                :items="roles"
                :loading="cargando"
                :items-per-page="rowsPerPage"
                hide-default-footer
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-1"
              >
                <template v-slot:[`item.Activo`]="{ item }">
                  <v-chip :color="getColor(item.Activo)" dark>
                    {{ estado_rol(item.Activo) }}
                  </v-chip>
                </template>

                <!-- ACCIONES EDITAR y ELIMINAR: BOTONES Y MODALES-->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>

                    <!-- MODAL EDITAR  -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar rol {{ editrol }}</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-form
                          ref="form_create"
                          v-model="valid"
                          lazy-validation
                        >
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editrol"
                                    :counter="30"
                                    :rules="namerol"
                                    label="Rol"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editscoperol"
                                    :counter="30"
                                    :rules="namescope"
                                    label="Scope"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editdescripcionrol"
                                    :counter="30"
                                    :rules="namedescripcion"
                                    label="Descripcion"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-checkbox
                                    v-model="editactivorol"
                                    class="ckeck"
                                    label="Activo/Inactivo"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>

                    <!-- MODAL ELIMINAR -->
                    <v-dialog v-model="dialogDelete" max-width="400px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center"
                          >¿Eliminar {{ editrol }}?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Confirmar</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <!-- BOTONES DE ACCIONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="green darken-2"
                        @click="cargar_roles_permisos(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> sync_alt </v-icon>
                      </v-btn>
                    </template>
                    <span>Vincular Permisos</span>
                  </v-tooltip>

                  <v-icon class="mr-2" color="orange" @click="editItem(item)">
                    create
                  </v-icon>

                  <v-icon color="red" @click="deleteItem(item)">
                    delete
                  </v-icon>
                </template>
                <!--END  BOTONES DE LA TABLA -->
                <!-- Paginacion  -->

                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
              <!-- END DATATABLE -->

              <!-- PAGINACION -->
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Roles } from "../../services/roles.js";
import { mapState } from "vuex";
export default {
  data: () => ({
    name: "Roles",
    // *1 array donde se renderizan los roles
    roles: [],
    // efecto cargando en tabla
    cargando: false,
    //   modal de editar y eliminar
    dialog: false,
    dialogDelete: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio POST, UPDATE, DELETE
    procensando_datos: false,
    // Filter models del datatable
    searchRol: "",
    headers: [
      { text: "Rol", value: "Rol" },
      { text: "Scope", value: "Scope" },
      { text: "Descripción", value: "Descripcion" },
      { text: "Activo", value: "Activo" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,

    // nuevo rol POST
    createRol: false,
    newrol: "",
    newscope: "",
    newdescripcion: "",
    newactivo: false,

    // campos a actualizar rol PUT
    editrolID: "",
    editrolSistemasId: "",
    editrol: "",
    editscoperol: "",
    editdescripcionrol: "",
    editactivorol: false,

    // validaciones cuenta
    valid: false,
    namerol: [
      (v) => !!v || "Rol es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo Rol no debe superar los 30 caracteres",
    ],
    namescope: [
      (v) => !!v || "Scope es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo Scope no debe superar los 30 caracteres",
    ],
    namedescripcion: [
      (v) => !!v || "Descripción es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo Descripción no debe superar los 22 caracteres",
    ],
  }), // Fin de data()
  created() {
    this.getRoles();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    searchRol() {
      this.getRoles();
    },
  },
  computed: {
    ...mapState(["SISTEMA_ID"]),
  },
  //listar todos los roles del sistema
  methods: {
    getRoles: function() {
      const params = {
        SistemaId: this.SISTEMA_ID,
        Number: this.pagination.page,
        Size: this.rowsPerPage,
      };

      if (this.searchRol) {
        params.BusquedaRolScopeDescripcion = this.searchRol;
      }

      this.cargando = true;
      Roles.getRoles(params)
        .then((response) => {
          if (response.data.data == null) {
            this.roles = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.roles = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    // paginar
    onPageChange() {
      this.getRoles();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getRoles();
    },

    // personalizar datos de la tabla
    getColor(value) {
      if (value == true) return "#43A047";
      else return "#EF5350";
    },

    estado_rol(value) {
      if (value === true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
    },
    // abrir modal de crear rol.
    // Hace un reset del formulario de nuevo rol
    // limpia propiedades de nuevo rol
    abrir_create() {
      this.createRol = true;
      this.$refs.form_create.reset();
      this.limpiar_campos();
    },
    // cerrar modal de crear rol.
    // Hace un reset del formulario de nuevo rol
    // limpia propiedades de nuevo rol
    cerrar_create() {
      this.createRol = false;
      this.$refs.form_create.reset();
      this.limpiar_campos();
    },

    // guardar nuevo Rol
    async guardar_rol() {
      var payload = {
        SistemasId: parseInt(this.SISTEMA_ID),
        Rol: this.newrol,
        Scope: this.newscope,
        Descripcion: this.newdescripcion,
        Activo: this.newactivo,
      };
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.procensando_datos = true;
        await Roles.nuevo_rol(payload)
          .then((response) => {
            this.$toastr.s("El rol se creo correctamente");
            this.getRoles();
            this.createRol = false;
            this.limpiar_campos();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    //mostrar en modal los datos del rol modificar
    editItem(item) {
      this.dialog = true;
      (this.editrolID = item.Id), (this.editrolSistemasId = item.SistemasId);
      this.editrol = item.Rol;
      this.editscoperol = item.Scope;
      this.editdescripcionrol = item.Descripcion;
      this.editactivorol = item.Activo;
    },

    //  consultar el id de la cuenta a eliminar
    deleteItem(item) {
      this.editrolID = item.Id;
      this.editrol = item.Rol;
      this.editrolSistemasId = item.SistemasId;
      this.dialogDelete = true;
    },
    // confirmar la cuenta a eliminar
    deleteItemConfirm() {
      const params = {
        id: this.editrolID,
        sistemaId: this.editrolSistemasId,
      };
      this.error = false;
      this.loading = true;
      this.procensando_datos = true;
      Roles.delete_rol(params)
        .then((response) => {
          this.$toastr.s("La rol se elimino con éxito");
          this.getRoles();
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.loading = false;
          this.$toastr.e(data.message);
          this.dialog = false;
          this.procensando_datos = false;
        })
        .finally(() => (this.cargando = false));
      // cerrrar modal
      this.closeDelete();
    },

    //confirmar actualizar rol
    async actualizar() {
      var payload = {
        id: this.editrolID,
        SistemasId: this.editrolSistemasId,
        Rol: this.editrol,
        Scope: this.editscoperol,
        Descripcion: this.editdescripcionrol,
        Activo: this.editactivorol,
      };
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.procensando_datos = true;
        await Roles.actualizar_rol(payload)
          .then((response) => {
            this.dialog = false;
            this.$toastr.s("El rol se actualizo correctamente");
            this.getRoles();
            this.close();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form_create.validate();
      this.res = this.$refs.form_create.validate();
    },
    // Limpia los valores de las propeidades del formulario para dar de alta un rol
    limpiar_campos() {
      this.rol = "";
      this.scope = "";
      this.descripcion = "";
      this.activo = false;
    },
    // ir a permisos de un rol
    cargar_roles_permisos(item) {
      // Decirle al router que cambie de ruta para ir al componente de ver permisos de un rol
      this.$router.push({ name: "RolesPermisos", params: { id: item.Id } });
    },
  },
};
</script>
<style scoped>
.trow {
  transition: transform 0.3s;
}

.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
}

a[data-v-1b84c964] {
  color: #3aad1e;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
</style>
