import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Contactos = {
  //listar contactos
  getContactos(params) {
    return axios.get(PASARELA + "/administracion/contactos", { params });
  },

  //actualizar (editar) contacto
  editarContacto(payload) {
    return axios.put(PASARELA + "/administracion/contactos", payload);
  },

  //nuevo contacto
  crearContacto(payload) {
    return axios.post(PASARELA + "/administracion/contactos", payload);
  },

  //eliminar contacto
  eliminarContacto(payload) {
    return axios.delete(PASARELA + "/administracion/contactos", {
      data: payload,
    });
  },

  // Para Contactos de Cuentas
  //Listar Contactos Cuentas
  getContactosCuentaS(params) {
    console.log(params)
    return axios.get(PASARELA + "/administracion/cuentas-contacto/"+ params );
  },

//Listar Contactos Cuentas
  getContactosCuentaIDS(params) {
    return axios.get(PASARELA + "/administracion/cuentas-contactos/", {params});
  },

  //Actualizar (editar) Contacto Cuentas
  editarContactoCuentaS(id, payload) {
    return axios.put(PASARELA + "/administracion/cuentas-contacto/" + id, payload);
  },

  // //Nuevo Contacto Cuenta
  crearContactoCuentaS(payload) {
    return axios.post(PASARELA + "/administracion/cuentas-contacto", payload);
  },

  //Eliminar Contacto Cuenta
  eliminarContactoCuentaS(id) {
    return axios.delete(PASARELA + "/administracion/cuentas-contacto/"+ id);
  },

  
  // middlewares.ValidarPermiso("psp.admin.cuentas")
	// app.Post("/cuentas-contacto", middlewares.ValidarPermiso("psp.admin.cuentas"), postContacto(service))
	// app.Get("/cuentas-contactos", middlewares.ValidarPermiso("psp.admin.cuentas"), getContactosByCuentaID(service))
	// app.Get("/cuentas-contacto/:id", middlewares.ValidarPermiso("psp.admin.cuentas"), getContacto(service))
	// app.Put("/cuentas-contacto/:id", middlewares.ValidarPermiso("psp.admin.cuentas"), putContacto(service))
	// app.Delete("/cuentas-contacto/:id", middlewares.ValidarPermiso("psp.admin.cuentas"), deleteContacto(service))


};
