import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Pagos = {
  //listar cuentas
  getPagos(data, config) {
    return axios.post(PASARELA + "/administracion/pagos", data, config);
  },

  getEstadoPagos() {
    return axios.get(PASARELA + "/administracion/estados-de-pagos");
  },

  getItemsPago(params) {
    return axios.get(PASARELA + "/administracion/items-pagos", { params });
  },
};
