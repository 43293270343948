import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Notificaciones = {

// Obtener todos los avisos
getAvisos(params) {
  return axios.get(PASARELA + "/administracion/avisos", { params });
},

// Para manejar el leido de notificacion
putCambiarEstadoAviso(params) {
  const url = `${PASARELA}/administracion/avisos-leido?AvisoId=${params.AvisoId}&UserId=${params.UserId}`;
  return axios.put(url);
},

// Crear un nuevo aviso
createAviso(payload) {
  return axios.post(PASARELA + "/administracion/avisos", payload);
},

// Actualizar un aviso existente
putAviso(payload) {
  return axios.put(PASARELA + "/administracion/avisos", payload);
},
//Eliminar Aviso
deleteAviso(params) {
  return axios.delete(PASARELA + "/administracion/avisos",  {params} );
},

};