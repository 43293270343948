<template>
        <div data-app class="content">
            <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto">
                    <div class="profile-tabs">
                    <ul class="nav nav-pills nav-pills-icons justify-content-center" role="tablist">
                        <li class="nav-item">
                        <a class="nav-link active show" href="#studio" role="tab" data-toggle="tab" aria-selected="true">
                            <i class="material-icons">account_box</i> Supervisión
                        </a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="#works" role="tab" data-toggle="tab" aria-selected="false">
                            <i class="material-icons">table_view</i> Estadisticas
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <!-- COMPONENTES (COMPONENTS/Transferencias) -->
                <div class="tab-content tab-space">
                    <div class="tab-pane text-center gallery active show" id="studio">
                        <div class="row">
                        <div class="col-md-12 ml-auto">
                            <Supervision/>
                        </div>
                        </div>
                    </div>
                    <div class="tab-pane text-center gallery" id="works">
                    <div class="row">
                    <div class="col-md-12 ml-auto">
                        <Estadistica/>
                    </div>
                    </div>
                    </div>
              </div>
           </div> 
        </div>
</template>
<script>
import Supervision from '@/components/BCRA/Supervision.vue'
import Estadistica from '@/components/BCRA/Estadistica.vue'
  export default {
    name: 'InformesBCRA',
    components: {
    Supervision,
    Estadistica,
    }
  }
</script>
<style scoped>
.nav-pills .nav-item .nav-link.active {
    color: #ffffff;
    background-color: #66bb6a;;
    box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(156 39 176 / 60%);
}
</style>