<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <!-- TABLA DE ACUMULADOS -->
            <div class="card">
              <div class="card-header card-header-success">
                <h4 class="card-title">Nueva transferencia</h4>
                <p class="card-category"></p>
              </div>

              <!-- error al cargar datos api -->
              <section v-if="errored">
                <v-alert
                  border="left"
                  class="mt-3 mx-3 elevation-3"
                  color="red lighten-2"
                  dark
                >
                  Se produjo un error al realizar la transferencia. Por favor
                  intente nuevamente mas tarde.
                </v-alert>
              </section>
              <!-- end error al cargar datos api -->
              <section v-else>
                <div v-if="procensando_datos">
                  <loader
                    object="#4caf50"
                    color1="#ffffff"
                    color2="#17fd3d"
                    size="5"
                    speed="2"
                    bg="#343a40"
                    objectbg="#999793"
                    opacity="40"
                    disableScrolling="false"
                    name="box"
                  ></loader>
                </div>
                <v-row align="baseline" class="pt-8 px-5">
                  <!-- filtro fecha inicio - fecha fin -->
                  <v-col cols="12" sm="3" md="3">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ml-2"
                          label="Fecha Desde"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                          :value="FormatDateDesde"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date1"
                        no-title
                        scrollable
                        locale="es-ar"
                        :max="new Date().toISOString().substr(0, 10)"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="menu1 = false"> Cancelar </v-btn>
                        <v-btn text color="indigo" @click="inicio(date1)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="fechaHasta"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ml-2"
                          :value="FormatDateHasta"
                          label="Fecha Hasta"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        no-title
                        scrollable
                        locale="es-ar"
                        :min="fechaDesde"
                        :max="new Date().toISOString().substr(0, 10)"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="red" @click="menu2 = false">
                          Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="fin(date2)">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- end filtro fecha inicio - fecha fin -->
                  <v-col cols="12" sm="3" md="3">
                    <v-flex text-left>
                      <v-btn outlined color="indigo" @click="getDateRange">
                        Buscar
                      </v-btn>
                    </v-flex>
                  </v-col>

                  <v-col cols="12" sm="3" md="3">
                    <v-flex xs4>
                      <v-switch
                        v-on:click="selectAll(desserts)"
                        v-model="allSelected"
                        class="mt-0"
                        label="Seleccionar todos"
                        color="green lighten-2"
                        hide-details
                      ></v-switch>
                    </v-flex>
                  </v-col>
                </v-row>
                <!-- end filtros -->
                <!-- datos datatable -->
                <v-data-table
                  :headers="headers"
                  :items="desserts.filter((item) => item.acumulado > 0)"
                  :loading="cargandoNewTrans"
                  loading-text="Cargando... Espere por favor"
                  no-results-text="No se encontraron registros"
                  no-data-text="No se encontraron registros"
                  class="elevation-1"
                  :expanded.sync="expanded"
                  item-key="fecha"
                  show-expand
                  :hide-default-footer="true"
                >
                  <!-- FECHA DE LA FILA ACUMULADA -->
                  <template v-slot:[`item.fecha`]="{ item }">
                    {{ moment(item.fecha).format("l") }}
                  </template>
                  <!-- IMPORTE DE LA FILA ACUMULADA -->
                  <template v-slot:[`item.acumulado`]="{ item }">
                    <v-chip class="ma-2" color="success" outlined>
                      {{ acumuladoTransferencias(item.acumulado) }}
                    </v-chip>
                  </template>
                  <!-- CHECKBOX DE LA FILA ACUMULADA -->
                  <template v-slot:[`item.select`]="{ item }">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="montos_acumulados"
                          :value="item.fecha"
                          @click="
                            acumularMontoTransferir(
                              item.acumulado,
                              item.movimientos
                            )
                          "
                        />
                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </template>
                  <!-- CONTENIDO DE LA FILA ACUMULADA -->
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <thead>
                        <tr>
                          <th>Tipo de pago</th>
                          <th>Medio de pago</th>

                          <th>Monto</th>
                          <th>Fecha de pago</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="dat in item.movimientos" v-bind:key="dat.id">
                          <td v-text="dat.pago_tipo" width="30%"></td>
                          <td v-text="dat.medio_pago" width="25%"></td>
                          <td width="35%">
                            <p class="text-success">
                              {{ saldoMovimiento(dat.monto) }}
                            </p>
                          </td>
                          <td width="30%">
                            {{ moment(dat.pago_created_at).format("l") }}
                          </td>
                        </tr>
                      </tbody>
                    </td>
                  </template>
                </v-data-table>
                <!-- end datatable -->

                <!-- MOVIMIENTOS NEGATIVOS -->
                <h6 class="mt-3 mb-2 ms-3">Movimientos Negativos</h6>
                <v-data-table
                  :headers="headersNegativos"
                  :items="movnegativos"
                  class="elevation-1"
                  no-data-text="No se encontraron registros"
                  hide-default-footer
                >
                  <template v-slot:[`item.monto`]="{ item }">
                    <p class="text-danger mb-0">
                      {{ saldoMovimiento(item.monto) }}
                    </p>
                  </template>
                  <template v-slot:[`item.movimiento_created_at`]="{ item }">
                    {{ moment(item.movimiento_created_at).format("l") }}
                  </template>
                  <template v-slot:[`item.reversion`]="{ item }">
                    <v-chip :color="getColor(item.reversion)" dark>
                      {{ item.reversion ? "Si" : "No" }}
                    </v-chip>
                  </template>
                </v-data-table>
                <!-- END MOVIMIENTOS NEGATIVOS -->
              </section>
            </div>
          </div>
          <!-- END TABLA DE ACUMULADOS -->

          <!-- TABLA IMPORTE ACUMULADO Y BOTON TRANSFERIR -->
          <div class="col-lg-4 col-md-12">
            <div class="card card-stats">
              <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">paid</i>
                </div>
                <h3 class="card-title">
                  {{ acumuladoTransferencias(importe) }}
                </h3>
              </div>
              <div class="ex1">
                <p
                  v-if="this.movnegativos.length > 0"
                  class="text-danger"
                  style="text-align: center; font-size: 1.2em"
                >
                  La suma de valores negativos es
                  {{ sumarNegativos(this.movnegativos).toFixed(2) }}
                </p>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <!-- <v-text-field
                    v-model="referencia"
                    :counter="30"
                    :rules="nameRules"
                    label="Referencia"
                    required
                  ></v-text-field> -->
                </v-form>
              </div>
              <div class="ex1">
                <div class="stats">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-6"
                    @click="mostrarDialogTransferencia"
                  >
                    Transferir
                  </v-btn>
                </div>
                <br />
              </div>
            </div>
          </div>
          <!-- END TABLA IMPORTE ACUMULADO Y BOTON TRANSFERIR -->

          <!-- ------ modal de realizar transferencia --------- -->
          <v-dialog v-model="dialog" persistent width="500">
            <v-card>
              <v-card-title>Nueva transferencia</v-card-title>
              <v-card-text>
                <b>
                  Esta por realizar una transferencia por el importe de
                  <strong>{{
                    saldoMovimiento(importeTransferir(importe) * 100)
                  }}</strong>
                </b>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">
                  Cancelar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="confirmar_tranferencia()"
                >
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- confirmacion de transferencia -->
          <div class="text-center">
            <v-dialog v-model="confirmacion" width="500">
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Confirmación de transferencia
                </v-card-title>

                <v-card-text>
                  {{ ref_transferencia }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="confirmacion = false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <!-- mostrar el resultado de la transferencia -->
          <div class="text-center">
            <v-bottom-sheet v-model="sheet" persistent>
              <v-sheet class="text-center" height="200px">
                <v-btn
                  class="mt-6"
                  text
                  color="error"
                  @click="cerrarconfirmacion()"
                >
                  Cerrar
                </v-btn>
                <div class="py-3">
                  <v-icon class="text-success">check</v-icon>
                  <span class="text-success">
                    Transferencia realizada con éxito
                  </span>
                  <span class="text-info">
                    <v-card-text>
                      Nro Referencia bancaria :{{ nro_referencia }}
                    </v-card-text>
                  </span>
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </div>
          <!-- end resultado de la transferencia -->
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Decimal from "decimal.js";
import { mapState, mapActions, mapGetters } from "vuex";
// El objeto servicio Transferencias

export default {
  name: "NuevaTransferencia",
  data() {
    return {
      // POST
      montos_acumulados: [],
      referencia: "",
      importe: 0,
      movimientos_id: [],
      movimientos_id_negativos: [],
      // seleccionar todos los registros
      allSelected: false,

      menu1: false, // atributo que permite desplegar el date picker
      menu2: false, // atributo que permite desplegar el date picker

      // campos para hacer referencia a valor de datapicker
      date1: "",
      date2: "",

      //efectoa loading al procesar peticion
      procensando_datos: false,

      // Datos de la tabla
      expanded: [],
      headers: [
        { text: "", value: "", sortable: false },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Acumulado",
          value: "acumulado",
          sortable: false,
          align: "center",
        },
        { text: "", value: "select", sortable: false },
      ],
      headersNegativos: [
        { text: "Concepto", value: "pago_tipo", sortable: false },
        { text: "Canal", value: "medio_pago" },
        { text: "Monto", value: "monto" },
        { text: "Fecha de Movimiento", value: "movimiento_created_at" },
        { text: "Es Reversion", value: "reversion" },
      ],

      loading: false,
      error: false,
      errored: false,
      valid: true,
      // nameRules: [
      //   v => !!v || 'El campo referencia es requerido',
      //   v => (v && v.length <= 30) || 'El campo referencia debe contener como máximo 30 caracteres',
      // ],
      dialog: false,

      // confirmacion datos de transferencia
      confirmacion: false,
      ref_transferencia: "",

      // mostrar resultado de la transferencia
      sheet: false,
    };
  },
  methods: {
    ...mapActions("transferencias", [
      "getDatosTransferencias",
      "hacerTransferencia",
    ]),
    ...mapActions("movimientos", ["getMovimientos"]),

    getColor(reversion) {
      if (reversion == true) return "green accent-2";
      else return "red accent-2";
    },

    acumuladoTransferencias(monto) {
      var str = monto / 100;
      return str.toLocaleString("es-ar", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2,
      });
    },

    // consultar por fechas
    getDateRange() {
      this.getDatosTransferencias();
    },

    inicio(value) {
      this.$store.commit("transferencias/setFechaInicio", value);
      this.menu1 = false;
    },
    fin(value) {
      this.$store.commit("transferencias/setFechaFin", value);
      this.menu2 = false;
    },

    // seleccionar todos los registros
    selectAll: function(desserts) {
      this.importe = 0;
      this.montos_acumulados = [];
      this.movimientos_id = [];
      if (this.allSelected) {
        desserts.forEach((mov) => {
          mov.movimientos.forEach((dato) => {
            this.movimientos_id.push(dato.id); //
          });
        });
        desserts.forEach((mov) => {
          this.montos_acumulados.push(mov.fecha);
          let a = new Decimal(this.importe);
          this.importe = a.plus(mov.acumulado).toNumber();
        });
      }
    },
    /**
     * Agrega o elimina el monto acumulado de los movimientos de una fecha
     * @param acumulado El monto total acumulado de los movimientos de una fecha
     * @param movimientos Array de objetos de movimientos de importe positivo
     */
    acumularMontoTransferir(acumulado, movimientos) {
      this.allSelected = false;
      if (this.movimientos_id.includes(movimientos[0].id)) {
        // Existen movimientos id en el array, implica poner en cero o restar el monto total a tranferir
        this.eliminar_dinero(acumulado, movimientos);
      } else {
        // No Existen movimientos id, se debe acumular o sumar el importe al monto total a transferir
        this.agregar_dinero(acumulado, movimientos);
      }
    },

    /**
     * Cargar el array de ids de movimientos
     * Sumar al importe total el importe acumulado
     */
    agregar_dinero(acumulado, movimientos) {
      movimientos.forEach((mov) => {
        // agrega en el array de movimientos id cada uno de los id de los movimientos
        this.movimientos_id.push(mov.id);
      });
      let a = new Decimal(acumulado);

      // Setear el importe total a transferir en el atributo importe
      this.importe = a.plus(this.importe).toNumber();
    },
    /**
     * Quitar del array de ids de movimientos los que corresponden al parametro recibido
     * Restar al importe total el importe acumulado
     */
    eliminar_dinero(acumulado, movimientos) {
      movimientos.forEach((mov) => {
        var index = this.movimientos_id.indexOf(mov.id);
        this.movimientos_id.splice(index, 1);
      });
      let a = new Decimal(acumulado);
      let b = new Decimal(this.importe);
      this.importe = b.minus(a).toNumber();
    },

    validate() {
      this.$refs.form.validate();
    },
    // Muestra el dialog para confirmar o cancelar la realizacion de la transferecia
    mostrarDialogTransferencia() {
      this.validate();
      if (this.importe == 0) {
        let mensaje = "El valor del importe no puede ser $" + this.importe;

        this.mostrarToast(mensaje);
      } else if (this.importeTransferir(this.importe) < 0) {
        let mensaje =
          "El valor del importe no puede ser negativo " +
          this.importeTransferir(this.importe);

        this.mostrarToast(mensaje);
      } else {
        this.dialog = true;
      }
    },
    /**
     * Muestra el dialogo de confirmar transferencia con el importe y el mensaje correspondiente
     * @param {string} mensaje - el mensaje que visualiza el usuario al producirse un error en el monto a transferir
     * @param {string} name - nombre del error
     */
    mostrarToast(mensaje, name) {
      this.$toastr.Add({
        name: "ErrorImporte",
        title: "Atención por favor:", // Toast Title
        msg: mensaje, // Toast Message
        clickClose: true,
        timeout: 4000,
        // position: "toast-top-full-width",
        type: "error",
        preventDuplicates: true,
      });
    },
    // Funcion que ejecuta la transferencia
    confirmar_tranferencia() {
      // this.fechaDesde y this.fechaHasta son getters del store de transferencias
      var p_FechaInicio = moment(this.fechaDesde).format();
      var p_FechaFin = moment(this.fechaHasta).format();
      var monto = parseInt(this.importe);
      // Enviar un numero entero con los dos ultimos digitos representando los decimales
      let importeCalculado = this.importeTransferir(monto);

      let a = new Decimal(importeCalculado).mul(100).round();
      var montoFinal = Number(a);

      const payload = {
        transferencia: {
          importe: montoFinal,
          referencia: this.referencia,
        },
        lista_movimientos_id: Array.from(this.movimientos_id.values()),
        lista_movimientos_id_neg: Array.from(
          this.movimientos_id_negativos.values()
        ),
        FechaInicio: p_FechaInicio,
        FechaFin: p_FechaFin,
      };

      // Se carga el id del cliente desde el mapeo del state del Store
      var cuenta_id = this.cuenta_ID;
      this.error = false;
      this.dialog = false;
      this.procensando_datos = true;
      // hacerTransferencia(...) es una actions mapeada desde el state module transferencias
      this.hacerTransferencia({ cuenta_id, payload })
        .then((response) => {
          this.dialog = false;
          this.$toastr.s("Su transferencia fue realizada con éxito");
          this.sheet = true;
          this.getDatosTransferencias();
        })
        .catch((error) => {
          this.errored = true;
          this.dialog = false;
          this.importe = 0;
          this.referencia = 0;
          this.error = true;
        })
        .finally(() => (this.procensando_datos = false));
    },

    cerrarconfirmacion() {
      this.sheet = false;
      this.importe = 0;
      this.referencia = 0;
      this.$store.commit("transferencias/setnro_referencia", "");
      this.getMovimientos();
    },

    saldoMovimiento(monto) {
      var numero = monto / 100;
      return numero.toLocaleString("es-ar", {
        style: "currency",
        currency: "ARS",
        currencySign: "accounting",
        minimumFractionDigits: 2,
      });
    },
    /**
     * Retorna la suma de montos de movimientos negativos
     * @params mov_negativos Es un array de objetos movimientos
     */
    sumarNegativos(mov_negativos) {
      var acumulados_negativos = 0;
      var valor = 0;
      mov_negativos.forEach((mov) => {
        // se divide por cien porque viene como entero con los dos untimos digitos como decimales
        var con_decimal = mov.monto / 100;
        // se convierte ese valor a un objeto Decimal para operar
        valor = Number(new Decimal(con_decimal));
        // se suma y acumula ese valor
        acumulados_negativos += valor;
      }); // fin del ciclo forEach
      // retornar la suma de valores negativos
      return acumulados_negativos;
    },
    /**
     * Retorna el saldo a transferir, que resulta de montos positivos seleccionados menos montos negativos.
     * @param {Number} monto - El valor de la propiedad importe es un numero entero con los dos ultimos digitos representando decimales
     */
    importeTransferir(monto) {
      var monto_decimal = monto / 100;
      var monto_mostrar = 0;
      if (this.movnegativos) {
        monto_mostrar = monto_decimal + this.sumarNegativos(this.movnegativos);
        return monto_mostrar;
      }
      if (!this.movnegativos) {
        return monto_decimal;
      }
    },
    guadarIdsMovsNegativos() {
      if (this.movnegativos) {
        this.movnegativos.forEach((mov) =>
          this.movimientos_id_negativos.push(mov.id)
        );
      }
    },
  },

  watch: {
    desserts() {
      this.$store.commit("transferencias/setCargandoNewTrans", false);
    },
    cuenta_ID() {
      this.movimientos_id = [];
      this.movimientos_id_negativos = [];

      this.allSelected = false;
      this.selectAll(this.desserts);
    },
  },
  mounted() {
    this.getDatosTransferencias().then(() => this.guadarIdsMovsNegativos());
  },
  computed: {
    ...mapState("transferencias", [
      "desserts",
      "movnegativos",
      "cargandoNewTrans",
      "nro_referencia",
    ]),
    ...mapState(["cuenta_ID"]),

    // ...mapState('movimientos' , ['errored']),

    ...mapGetters("transferencias", ["fechaDesde", "fechaHasta"]),

    FormatDateDesde() {
      return this.fechaDesde
        ? moment(this.fechaDesde).format("DD/MM/YYYY")
        : "";
    },
    FormatDateHasta() {
      return this.fechaHasta
        ? moment(this.fechaHasta).format("DD/MM/YYYY")
        : "";
    },
  },
};
</script>
<style scoped>
.className {
  color: red;
}
.outlinenone {
  outline: none;
  background-color: rgb(220, 228, 225);
  border: 0;
}

.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #66bb6a;
}

.theme--light.v-messages {
  color: rgb(245 18 18 / 90%);
}
.form-check .form-check-label span {
  display: block;
  position: absolute;
  left: 7px;
  top: -5px;
  transition-duration: 0.2s;
}
.card .card-title {
  text-align: center;
}
.ex1 {
  margin: auto;
  width: 90%;
  margin-top: 25px;
}

.v-menu__content {
  position: fixed;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
</style>
