<template>
  <v-dialog max-width="520px" v-model="dialog">
    <v-card v-if="comision">
      <h4 class="pt-4 pb-0 font-weight-bold">
        <span class="mr-2">DETALLE:</span>
        {{ comision.nombre }}
      </h4>

      <v-divider></v-divider>
      <v-list dense class="pa-4 pt-0">
        <v-list-item>
          <v-list-item-content>Vigente desde:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ formatDate(comision.vigenciaDesde) }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>Comisión total:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ calcularComision() }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>Importe mínimo total:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ comision.minimo + comision.channelMinimo }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>Importe máximo total:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ comision.maximo + comision.channelMaximo }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import formatDate from "@/utils/formatDate";
export default {
  name: "DetalleCuentaComision",
  data() {
    return {
      dialog: false,
      comision: null,

      formatDate,
    };
  },

  mounted() {
    this.$on("showDetalle", (comision) => {
      this.dialog = true;
      this.comision = comision;
    });
  },

  methods: {
    calcularComision() {
      const { channel, comision, channelImporte } = this.comision;
      const total = channel !== "DEBIN" ? comision + channelImporte : comision;

      return total.toFixed(4);
    },
  },
};
</script>
