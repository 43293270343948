<template>
  <div id="rendiciones">
    <!-- RENDICIONES -->
    <h4 class="bold pt-4 mb-2">Rendiciones</h4>
    <p>
      Retorna el detalle de transacciones que conforman una rendición para el
      día especificado. Solo se realizan rendiciones los días hábiles.
    </p>
    <h4 class="bold my-4">REQUEST</h4>
    <!-- -------------HEADERS--------------- -->
    <h4 class="my-4">Headers</h4>
    <v-simple-table fixed-header height="150px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              PATTERN
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in headersRendiciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>
              <code
                style="background-color: #f5f5f5; padding: 2px 4px; border-radius: 4px; color: #000"
                >{{ item.pattern }}
              </code>
            </td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------PARAMS----------- -->
    <p class="my-4">Path parameters</p>
    <v-simple-table fixed-header height="100px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paramsRendiciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------RESPONSE----------- -->
    <h4 class="bold my-4">RESPONSE</h4>
    <p class="bold">Atributos del objeto de respuesta.</p>
    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in responseRendiciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response ----------: objeto result -->
    <p class="pt-4">Atributos del objeto <span class="italic">result</span>.</p>
    <v-simple-table fixed-header height="340px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objectResultRendiciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response----objeto result.data -->
    <p class="pt-4">Atributos del objeto <span class="italic">data</span>.</p>
    <v-simple-table fixed-header height="240px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objDataRendiciones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- GET Obtener Rendiciones -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <h4 class="bold mb-4" id="crear-solicitud-pago">
      <span class="metodo-get">GET</span> Obtener Rendiciones
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p
        class="
      d-flex
        align-items-center
        justify-content-center
        mb-0
      "
      >
        https://sb-wee.ar/v1/reporte/rendiciones?Date=03-01-2023
      </p>
    </div>
    <!-- Headers -->
    <h4 class="bold mt-4">HEADERS</h4>
    <v-divider></v-divider>
    <div>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Content-Type</p>
        </v-col>
        <v-col cols="6">
          <p>application/json</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Authorization</p>
        </v-col>
        <v-col cols="6">
          <p>Bearer <span>{</span>{api_key}<span>}</span></p>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: RESPONSE --------------------- -->
    <div>
      <h6 class="bold mt-4">Example Response</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
{
    "message": "Solicitud rendiones generada",
    "result": {
        "account_id": "000004",
        "report_date": "2023-01-31T00:00:00Z",
        "total_credits": 3,
        "credit_amount": 27794.02,
        "total_debits": 0,
        "debit_amount": 0,
        "settlement_amount": 27794.02,
        "data": [
            {
                "request_id": 641,
                "external_reference": "JPKdsohhr",
                "credit": 4814.76,
                "debit": 0
            },
            {
                "request_id": 712,
                "external_reference": "ARslPzml1",
                "credit": 11489.63,
                "debit": 0
            },
            {
                "request_id": 716,
                "external_reference": "S5oXveLa4",
                "credit": 11489.63,
                "debit": 0
            }
        ]
    },
    "status": true
}
    </code>
  </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewAyudaRepRendiciones",
  data() {
    return {
      headersRendiciones: [
        {
          atributo: "Content-Type",
          tipo: "String",
          requerido: "SI",
          pattern: "application/json",
          descripcion:
            "El tipo MIME del contenido del cuerpo enviado. Por defecto JSON es Unicode UTF-8. No se debe configurar otro charset.",
        },
        {
          atributo: "Authorization",
          tipo: "String",
          requerido: "SI",
          pattern: "Bearer",
          descripcion: "ba7943e1-a60c-43e7-8812-xxxxxxxxxxxx",
        },
      ],
      paramsRendiciones: [
        {
          atributo: "Date",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Fecha de la rendicion. Solo se muestra información de las rendiciones aprobadas. Formato DD-MM-YYYY",
        },
      ],
      responseRendiciones: [
        {
          atributo: "message",
          tipo: "String",
          descripcion: "Mensaje de la respuesta.",
        },
        {
          atributo: "result",
          tipo: "Object",
          descripcion: "Objeto con la información de la respuesta.",
        },
        {
          atributo: "status",
          tipo: "Boolean",
          descripcion: "Estado de la respuesta.",
        },
      ],
      objectResultRendiciones: [
        {
          atributo: "account_id",
          tipo: "String",
          descripcion: "ID de la cuenta propietaria del Reporte.",
        },
        {
          atributo: "report_date",
          tipo: "DateTime",
          descripcion: "Fecha de rendición.",
        },
        {
          atributo: "total_credits",
          tipo: "Integer",
          descripcion: "Cantidad total de operaciones acreditadas.",
        },
        {
          atributo: "credit_amount",
          tipo: "Float",
          descripcion:
            "Importe total de operaciones acreditadas. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "total_debits",
          tipo: "Integer",
          descripcion: "Cantidad total de débitos.",
        },
        {
          atributo: "debit_amount",
          tipo: "Float",
          descripcion:
            "Importe Total de operaciones debitadas. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "settlement_amount",
          tipo: "Float",
          descripcion:
            "Importe total rendido. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "data",
          tipo: "Array [Object]",
          descripcion: "Arreglo de objetos con detalle de cada rendición.",
        },
      ],
      objDataRendiciones: [
        {
          atributo: "request_id",
          tipo: "Integer",
          descripcion:
            "ID de la solicitud. Solicitud de pago o debito según corresponda.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          descripcion:
            "Este atributo es utilizado como referencia para identificar la solicitud y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "credit",
          tipo: "Float",
          descripcion:
            "Importe acreditado. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "debit",
          tipo: "Float",
          descripcion:
            "Importe debitado. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.metodo-get {
  color: rgb(0, 127, 49);
}
.url-container {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  height: 40px;
  display: flex;
  padding-left: 10px;
}
.code-block {
  font-size: 14px;
}
</style>
