
<template> 
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-profile">
                <Avatar/>
                <div class="card-body">
                   <div id="navigation-pills">
                <div class="title">
                  <h3>Cuentas Wee!</h3>
                </div>
                <div class="title"></div>
                    <div class="row">
                      <div class=" col-md-12 col-xl-12">
                        <div class="row">
                          <div class="col-md-2 col-xl-1">
                            <ul class="nav nav-pills nav-pills-icons" role="tablist">
                              <li class="nav-item">
                                <a class="nav-link active show" href="#movimientos" role="tab" data-toggle="tab" aria-selected="true">
                                  <i class="material-icons">dashboard</i>
                                  Movimientos
                                </a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="#schedule-2" role="tab" data-toggle="tab" aria-selected="true">
                                  <i class="material-icons">schedule</i>
                                   Saldos
                                </a>
                              </li>

                               <li class="nav-item">
                                <a class="nav-link" href="#cobrar" role="tab" data-toggle="tab" aria-selected="true">
                                  <i class="material-icons">schedule</i>
                                   Cobrar
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class=" col-md-10 col-xl-11">
                            <div class="tab-content">
                              <div class="tab-pane active show" id="movimientos">
                                 <div class="card card-nav-tabs">
                                  <div class="card-header card-header-success">
                                    <div class="nav-tabs-navigation">
                                      <div class="nav-tabs-wrapper">
                                        <ul class="nav nav-tabs" data-tabs="tabs">
                                          <li class="nav-item">
                                            <a class="nav-link" href="#" data-toggle="tab">
                                             Movimientos
                                            <div class="ripple-container"></div></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body ">
                                    <div class="tab-content text-center">
                                      <div class="tab-pane active show" id="mov">
                                        <div class="card-body table-responsive">
                                          <p align="left"><strong>Un movimiento es cualquier operación que haya afectado (de manera positiva o negativa) el saldo de su Cuenta.</strong></p>
                                          <p align="left"><p align="left">Existen 5 tipos de movimientos que pueden verse reflejados en su cuenta:</p>

                                          <p align="left" ><strong> * COBRO:</strong> al recibir un pago por cualquier medio habilitado.</p>
                                          <p align="left"><strong> * RETIRO DE SALDO:</strong> al retira Saldo Disponible a una cuenta bancaria o virtual (CBU/CVU) de su misma titularidad.</p>
                                          <p align="left"><strong> * TRANSFERENCIA:</strong> al realizar una transferencia de fondos a una cuenta virtual (CVU) o bancaria (CBU) que de un tercero.</p>
                                          <p align="left"><strong> * PAGO DE SERVICIO:</strong> al realizar el pago de un servicio con tu Saldo Disponible o medios habilitados.</p>
                                          <p align="left"><strong> * CONTRACARGO:</strong>  cuando un Cobro es cancelado, anulado o desconocido por el pagador.</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- saldos -->
                              <div class="tab-pane" id="schedule-2">
                                  <div class="card card-nav-tabs">
                                  <div class="card-header card-header-success">
                                    <div class="nav-tabs-navigation">
                                      <div class="nav-tabs-wrapper">
                                        <ul class="nav nav-tabs" data-tabs="tabs">
                                          <li class="nav-item">
                                            <a class="nav-link" href="#profile" data-toggle="tab">
                                             Saldos
                                            <div class="ripple-container"></div></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body ">
                                    <div class="tab-content text-center">
                                      <div class="tab-pane active show" id="profile">
                                        <div class="card-body table-responsive">
                                           <p align="left"><strong>Al acceder a su cuenta podrá consultar los saldos de la misma.</strong></p>

                                            <table class="table table-hover">
                                            <thead class="text-success">
                                              <tr><th>Saldo Pendiente</th>
                                              <th>Saldo Disponible</th>
                                            </tr></thead>
                                            <tbody>
                                              <tr>
                                                <td align="left">Corresponde a los importes de los pagos recibidos, previa deducción de la tarifa de servicio de Pagos360.com.
                                                  El Saldo Pendiente se libera y es disponibilizado según los el medio de pago utilizado en la transacción, los cuales pueden ser consultados en .</td>
                                                <td>El Saldo Disponible de su Cuenta Pagos360.com puede ser retirado a una cuenta bancaria, transferido a terceros o bien utilizado para realizar pagos.</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <h4 align="left">Retirar Saldo</h4>
                                           <p align="left"><strong>Podrá retirar el Saldo Disponible de su Cuenta Wee! en cualquier momento. Solo deberá indicar el importe y la cuenta bancaria donde desea recibir los fondos. <br>Solo podrá retiar saldo a aquellas cuentas bancarias que sean de su titularidad. Si desea enviar dinero a un tercero, deberá realizar un transferencia de saldo.</strong></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- cobar -->

                                <div class="tab-pane" id="cobrar">
                                  <div class="card card-nav-tabs">
                                    <div class="card-header card-header-success">
                                      <div class="nav-tabs-navigation">
                                        <div class="nav-tabs-wrapper">
                                          <ul class="nav nav-tabs" data-tabs="tabs">
                                            <li class="nav-item">
                                              <a class="nav-link active show" href="#profile11" data-toggle="tab">
                                                <i class="material-icons">face</i>
                                                Introducción
                                              <div class="ripple-container"></div></a>
                                            </li>
                                            <li class="nav-item">
                                              <a class="nav-link" href="#messages" data-toggle="tab">
                                                <i class="material-icons">chat</i>
                                                Solicitar Pago
                                              <div class="ripple-container"></div></a>
                                            </li>
                                            <li class="nav-item">
                                              <a class="nav-link" href="#settings" data-toggle="tab">
                                                <i class="material-icons">build</i>
                                                Débito Automático
                                              <div class="ripple-container"></div></a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card-body ">
                                      <div class="tab-content text-center">
                                        <div class="tab-pane active show" id="profile11">
                                          <h3 align="left"><strong>Cobrar con Wee!</strong><br> </h3>
                                            <p align="left"> Wee! le permite cobrar y recibir pagos de la manera que mejor se adapte a su negocio. <br>
                                         Con las diferentes herramientas disponibles en nuestra plataforma podrá construir estrategias y experiencias de pago totalmente personalizadas.
                                        </p>
                                          <p align="left"><p align="left">Estos son algunos de los casos de uso más utilizados:</p>
                                          <p align="left" ><strong> * Solicitar Pago:</strong> Permite cobrar un importe específico a un cliente a través de email, chat, redes sociales o imprimir un cupón para entregarle al cliente..</p>
                                          <p align="left"><strong> * Boton o Link de Pago:</strong> Permite agregar un botón de pago en un sitio web, aplicación web o app móvil para recibir múltiples pagos a través de nuestro checkout.</p>
                                          <p align="left"><strong> * Solicitar Suscripción:</strong> Adhiera a un cliente a débito automático en cuenta bancaria o en tarjeta de crédito. Su cliente solo tendrá que aceptar la solicitud por email.</p>
                                          <p align="left"><strong> * Boton de Suscripción:</strong> Permite que sus clientes se adhieran a débito automático en cuenta bancaria o en tarjeta de crédito. Se pueden incluir en su sitio web, redes sociales o enviar por email o chat a sus clientes.</p>
                                        </div>
                                        <div class="tab-pane" id="messages">
                                           <h3 align="left"><strong>Solicitar Pago</strong><br> </h3>
                                            <p align="left">Una Solicitud de Pago es la entidad que individualiza la intención de recibir un pago a través de Pagos360.com y es necesaria para ejecutar una transacción de pago. <br>Un pago no podrá existir, si primero no se genera una Solicitud de Pago.
                                              <br>Cuando se crea una Solicitud de Pago (dependiendo del tipo de Cuenta Pagos360.com y los medios de pago habilitados) se generan diferentes herramientas que permitirán pagar la solicitud:
                                        </p>
                                          <p align="left" ><strong> * Link de Pago</strong></p>
                                          <p align="left"><strong> * Código de Barra</strong> </p>
                                          <p align="left"><strong> *Cupón de Pago</strong> </p>
                                        </div>
                                       
                                        <div class="tab-pane" id="settings">
                                           <h3 align="left"><strong>Débito Automático</strong><br> </h3>
                                        </div>
                                         </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>

                              <!-- debito automatico  -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>
<script>
import Avatar from '@/components/Ayuda/Avatar.vue'
 export default {
    name: 'Introduccion',
    components: {
    Avatar,
  },
  }
</script>
<style scoped>
.card .card-title {
     text-align: center;
}
.nav-pills .nav-item .nav-link.active {
    color: #ffffff;
    background-color: #66bb6a;;
    box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(156 39 176 / 60%);
}
</style>