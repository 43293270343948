<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Cambiar contraseña</h4>
            </div>
            <div class="card-body">
              <p style="font-size:0.7rem">Minimo 8 caracteres, una letra mayúscula, un caracter especial (@$!%*?&#), un número</p>
              <section v-if="errored">
                <div v-if="loading">
                  <loader
                    object="#4caf50"
                    color1="#ffffff"
                    color2="#17fd3d"
                    size="5"
                    speed="2"
                    bg="#343a40"
                    objectbg="#999793"
                    opacity="40"
                    disableScrolling="false"
                    name="box"
                  ></loader>
                </div>
                <div class="tim-typo">
                  <span class="tim-note"></span>
                  <p class="text-danger">
                    Lo sentimos, no es posible obtener la información en este
                    momento, por favor intente nuevamente mas tarde.
                    <br />
                    <!-- <a class="text-info" @click="intento()">
                          Volver a intentar
                          </a> -->
                  </p>
                </div>
              </section>
              <section v-else>
                <div v-if="loading">
                  <loader
                    object="#4caf50"
                    color1="#ffffff"
                    color2="#17fd3d"
                    size="5"
                    speed="2"
                    bg="#343a40"
                    objectbg="#999793"
                    opacity="40"
                    disableScrolling="false"
                    name="box"
                  ></loader>
                </div>
                <v-form ref="form_perfil" v-model="valid" lazy-validation>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="contraseña"
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="namecontraseña"
                          :counter="15"
                          label="Nueva contraseña"
                          @click:append="show = !show"
                          :style="show ? 'font-size: 1rem' : 'font-size:1.5rem'"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="repertircontraseña"
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="namerepertircontraseña"
                          :counter="15"
                          label="Repetir contraseña"
                          @click:append="show = !show"
                          :style="show ? 'font-size: 1rem' : 'font-size:1.5rem'"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <br />
                  <v-row>
                    <v-col cols="12" md="12" align="right">
                      <v-btn
                        :disabled="!valid"
                        :loading="dialog"
                        color="success"
                        class="mr-4"
                        @click="actualizar_contraseña()"
                      >
                        Actualizar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </section>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-profile">
            <div class="card-avatar">
              <a href="javascript:;">
                <img class="img" src="/assets/img/iconuser.png" />
              </a>
            </div>
            <div class="card-body">
              <h6 class="card-category text-gray"></h6>
              <h4 class="card-title">{{ $store.state.email }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Perfil } from "../../../services/perfil.js";
import { mapState } from "vuex";
export default {
  name: "Perfil",
  data: () => ({
    show: false, // show / hide passwords
    //   boton guardar inactivo
    dialog: false,
    loading: false,
    error: false,
    errored: false,
    //   respuesta validacion
    res: false,
    valid: false,
    checkpass: false,
    // campo del formulario
    id: "",
    email: "",
    contraseña: "",
    namecontraseña: [
      (value) => !!value || " Campo nueva contraseña es requerido es requerido",
      (value) =>
        (value && value.length <= 15) ||
        "nueva contraseña no debe superar los 15 caracteres",
      (value) =>
        (value &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
            value
          )) ||
        "Minimo 8 caracteres, una letra mayúscula, un caracter especial (@$!%*?&#), un número",
    ],
    repertircontraseña: "",
    namerepertircontraseña: [
      (v) => !!v || " Campo repetir contraseña es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "repetir contraseña no debe superar los 15 caracteres",
    ],
  }),
  methods: {
    async actualizar_contraseña() {
      // this.id = parseInt(this.$store.state.id);
      // this.email = this.$store.state.email;
      // var payload = {
      //   id: this.id,
      //   user: this.email,
      //   password: this.contraseña,
      // };
      var payload = {
        Password: this.contraseña,
        SistemaId: this.SISTEMA_ID,
      };
      this.validate();

      if (this.res === true) {
        if (!this.checkpassword()) {
          this.$toastr.e(
            "nueva contraseña y repetir contraseña deben ser iguales"
          );
          e.preventDefault();
        }
        {
          this.error = false;
          this.loading = true;
          this.dialog = true;
          await Perfil.actualizar_contraseña(payload)
            .then((response) => {
              this.dialog = false;
              this.loading = false;
              this.$toastr.s("la contraseña se actualizo correctamente");
              this.$refs.form_perfil.reset();
            })
            .catch((error) => {
              // this.errored = true;
              var data = error.response.data;
              this.error = true;
              this.loading = false;
              (this.checkpass = false), this.$toastr.e(data.message);
              this.dialog = false;
            })
            .finally(() => (this.loading = false));
        }
      } else {
        this.$toastr.e("Verificar datos de envio");
      }
    },

    // validacion de formulario
    validate() {
      this.$refs.form_perfil.validate();
      this.res = this.$refs.form_perfil.validate();
    },
    checkpassword() {
      return this.contraseña === this.repertircontraseña;
    },

    // error de api , volver a intentar
    intento() {
      this.$router.go();
    },
  },
  computed: {
    ...mapState(["SISTEMA_ID"]),
  },
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--has-bg {
  background-color: #66bb6a;
}
</style>