import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Rubros = {
    // listar rubros
    getRubros(params) {
        return  axios.get(PASARELA+"/administracion/rubros", {params})
    },
     // crear nueva rubro
     nuevo_rubro(payload) {
        return  axios.post(PASARELA+"/administracion/rubro", payload)
    },

      //consultar chennel por nombre channel
      getRubro(params) {
        return  axios.get(PASARELA+"/administracion/rubro", {params})
    },

      // actualizar cuenta
      updateRubro(payload) {
        return  axios.put(PASARELA+"/administracion/rubro", payload)
    },
    

     // actualizar cuenta
     actualizar_channel(payload) {
        return  axios.put(PASARELA+"/administracion/channel", payload)
    },

    //consultar chennel por nombre channel
     getChannelName(params) {
        return  axios.get(PASARELA+"/administracion/channel", {params})
    },

     // delete channels
     delete_channel(params) {
        return  axios.delete(PASARELA+"/administracion/channel", {params})
    },

}
