import axios from 'axios';
import './axiosInterceptor';

export const AUTHENTICATION = process.env.VUE_APP_URLAUTHENTICATION;

export const Permisos = {
  //listar permisos 
  getPermisos(params) {
    return axios.get(AUTHENTICATION + "/adm/permisos", { params })
  },

  // crear nueva permiso
  nuevo_permiso(payload) {
    return axios.post(AUTHENTICATION + "/adm/permiso", payload)
  },

  // actualizar permiso
  actualizar_permiso(payload) {
    return axios.put(AUTHENTICATION + "/adm/permiso", payload)
  },
  // delete permiso
  delete_permiso(SistemaId, editID) {
    return axios.delete(AUTHENTICATION + "/adm/permiso", { data: { SistemaId: SistemaId, PermisoId: editID } })
  },

  //listar permisos disponibles para el rol 
  getPermisosVincular(params) {
    return axios.get(AUTHENTICATION + "/adm/permisos-vincular", { params })
  },

}
