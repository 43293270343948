<template>
  <section class="info-container">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="#c2d501"
        fill-opacity="1"
        d="M0,64L80,106.7C160,149,320,235,480,234.7C640,235,800,149,960,106.7C1120,64,1280,64,1360,64L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>

    <div>
      <div
        style="position: relative; margin-top: -15%;"
        class="animate-fade-in-up  md:animate-scale"
      >
        <img
          src="../../../public/assets/img/image_person1.png"
          alt="persona con computadora"
          class="info-image"
        />
      </div>

      <div class="info-text">
        <p>
          Nuestra plataforma de procesamiento de pagos
          <span class="bold">integra tecnologías innovadoras</span> que
          simplifican los procesos de conciliación y mejoran la experiencia de
          pago del cliente.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InfoHome",
};
</script>

<style scoped>
/* INFO */

.bold {
  font-weight: bold;
}

.info-container {
  background: #c2d501;
  color: #000;
  position: relative;
}

.info-container > div:nth-child(2) {
  display: flex;
  justify-content: space-between;

  max-width: 1350px;
  margin: auto;
  margin-top: 0%;
}

.info-container > svg {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-99%);
  z-index: 0;
}

.info-text {
  max-width: 600px;
  text-align: end;
  align-self: center;
  margin-bottom: 8%;
}

.info-text > p {
  font-size: 30px;
  line-height: 40px;
}

.info-image {
  max-width: 100%;
}

@media (max-width: 1550px) {
  .info-container {
    transform: translate(0, -100px);
  }

  .info-container > div:nth-child(2) {
    max-width: 100%;
    padding: 0 5%;
  }

  .info-text {
    max-width: 450px;
  }

  .info-text > p {
    font-size: 25px;
    line-height: 35px;
  }
}

@media (max-width: 1280px) {
  .info-container {
    transform: translate(0, -150px);
  }

  .info-text {
    max-width: 415px;
  }

  .info-text > p {
    font-size: 20px;
    line-height: 33px;
  }
}

@media (max-width: 1024px) {
  .info-container {
    transform: translate(0, -100px);
  }

  .info-text {
    max-width: 380px;
  }

  .info-text > p {
    font-size: 18px;
    line-height: 29px;
  }
}

@media (max-width: 768px) {
  .info-container {
    transform: translate(0, -80px);
  }

  .info-text {
    max-width: 340px;
  }

  .info-text > p {
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 640px) {
  .info-container {
    transform: translate(0, -60px);
    padding: 0 5%;
  }

  .info-text {
    max-width: 100%;
    text-align: center;
  }

  .info-text > p {
    font-size: 19px;
    line-height: 28px;
  }

  .info-container > div:nth-child(2) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info-image {
    transform: translate(9%, 0);
  }
}

@media (max-width: 450px) {
  .info-container {
    transform: translate(0, 0);
    padding: 0 3%;
  }

  .info-container > div:nth-child(2) {
    transform: translate(0%, -19%);
  }
}
</style>
