import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Soportes = {


// Obtener todos los soportes
getSoportes(params) {
  return axios.get(PASARELA + "/administracion/soportes", { params });
},
 
// Obtener un archivo de soporte
getSoporteFile(archivo) {
  return axios.get(PASARELA + "/administracion/soportes-file", { params: { archivo } });
},

 // Crear un nuevo soporte
 createSoporte(payload) {
  return axios.post(PASARELA + "/administracion/soportes", payload);
},

// Actualizar un soporte existente
putSoporte(payload) {
  return axios.put(PASARELA + "/administracion/soportes", payload);
},


// Crear una nueva respuesta de soporte
createSoporteRespuesta(payload) {
  return axios.post(PASARELA + "/administracion/soportes-respuesta", payload);
},
  
};



// app.Post("/soportes", createSoporte(service))
	// app.Put("/soportes", putSoporte(service))
	// app.Get("/soportes", getSoportes(service))
	// app.Post("/soportes-respuesta", createSoporteRespuesta(service))
	// app.Get("/soportes-file", getSoporteFile(service))