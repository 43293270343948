<template>
  <v-app>
    <v-main>
      <div class="container-fluid">
        <!-- <v-toolbar class="mt-4" dark src="/assets/img/fondo_terminos.jpg">
          <v-toolbar-title>Términos</v-toolbar-title>
          <v-autocomplete
            v-model="select"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Que estás buscando?"
            solo-inverted
          ></v-autocomplete>
        </v-toolbar> -->
        <div class="row">
          <div class="col-md-12">
            <div class="card card-profile">
              <Avatar />
              <!-- <div class="card-avatar">
            <a href="javascript:;">
              <img class="img" src="/assets/img/load.png" />
            </a>
          </div> -->

              <div class="card-body">
                <h3 class="card-category text-gray">
                  Términos y Condiciones del Pagador
                </h3>
                <!-- <h4 class="card-title">Sobre telcopagos.com</h4> -->
                <br /><br />
                <p class="card-description" align="left">
                  Vigentes desde: 20/07/2022 <br />
                </p>
                <p />
                <br />

                <h4 align="left"><strong>Acuerdo</strong><br /></h4>
                <p>
                  1. Este documento constituye los Términos y Condiciones del
                  Pagador que rigen a cualquier persona (en adelante, el
                  “Pagador” o “Pagadores”), que desee acceder y/o usar los
                  servicios de Wee.ar de CORRIENTES TELECOMUNICACIONES
                  S.A.P.E.M. CUIT: 30-71655084-9 (en adelante, el “Acuerdo”).<br />
                  2. El presente Acuerdo forma parte, en un todo, de los
                  Términos y Condiciones de Uso Generales de los servicios de
                  Wee.ar y sus Anexos, y todos aquellos derechos y obligaciones
                  que no fueren previstos en los presentes serán regulados por
                  aquellos.<br />
                  3. Un Pagador es una persona humana o jurídica con capacidad
                  legal para contratar y están debidamente identificados en
                  Wee.ar. No podrán utilizar los servicios personas que hayan
                  sido inhabilitadas de la Plataforma de Wee.ar, temporal o
                  definitivamente.<br />
                  4. Los bienes o servicios que pueden ser pagados a través de
                  Wee.ar son puestos a disposición del Pagador por Clientes que
                  se han vinculado con Wee.ar a través de un acuerdo de gestión
                  o procesamiento de pagos por su cuenta y orden.<br />
                  5. Wee.ar se reserva el derecho de modificar este Acuerdo en
                  cualquier momento mediante la publicación de una versión
                  actualizada y al someterse a este Acuerdo el Pagador también
                  acepta sus modificaciones.<br />
                  6. Cualquier persona que no acepte este Acuerdo, el cual tiene
                  carácter obligatorio y vinculante, deberá abstenerse de
                  utilizar los Servicios de Wee.ar.<br />
                </p>
                <br />
                <h4 align="left"><strong>Servicios</strong><br /></h4>
                <p>
                  1. Wee.ar brinda servicios de procesamiento de pagos y
                  habilita al Pagador la utilización de los medios de pago
                  disponibles y autorizados a tal fin (en adelante, los
                  “Servicios de Wee.ar”), a través de la plataforma brindada por
                  Wee.ar (en adelante, la “Plataforma”).<br />
                  2. Wee.ar no será responsable por pagos equivocados o
                  incompletos en la operación de pago causados por culpa o error
                  del Pagador.<br />
                  3. Wee.ar no garantiza el acceso y uso continuado o
                  ininterrumpido de la Plataforma o de los Servicios de Wee.ar,
                  debido a dificultades técnicas, fallas de Internet o por
                  cualquier otra circunstancia ajena a Wee.ar.<br />
                  4. Wee.ar no garantiza al Pagador tiempos de procesamiento de
                  una transacción. No obstante, pondrá las diligencias
                  necesarias para procesar las transacciones ágilmente. En todo
                  caso, Wee.ar se exime del retardo en el procesamiento de
                  transacciones resultante de horarios o procesos bancarios, o
                  de cualquier tipo, de fallas en el servicio de los demás
                  agentes que intervienen o inciden en el procesamiento de
                  pagos, tales como redes de procesamiento, bancos, franquicias,
                  proveedores de internet y servidores, entre otros, y en
                  general de todo evento que exceda su control en el
                  procesamiento.<br />
                  5. El Pagador no podrá imputarle responsabilidad alguna a
                  Wee.ar, ni exigir resarcimiento alguno, en virtud de
                  perjuicios resultantes de las mencionadas dificultades, así
                  como por cualquier otra clase de daños, incluyendo daños
                  indirectos, especiales o consecuentes que surjan o experimente
                  el Pagador, incluso en el caso que dichas fallas afecten los
                  montos que deban ser pagados.<br />
                  6. Para todos los efectos se entenderá que las obligaciones de
                  Wee.ar en la prestación de los Servicios de Wee.ar son, de
                  medios y no de resultado.<br />
                </p>
                <br />
                <h4 align="left">
                  <strong>Declaraciones del Pagador</strong><br />
                </h4>
                <p>
                  1. El Pagador reconoce haber realizado una lectura
                  pormenorizada de las obligaciones contenidas en este
                  Acuerdo.<br />
                  2. La sola utilización del servicio de procesamiento de pagos,
                  la plataforma y cualquier servicio relacionado que ofrezca
                  Wee.ar implica que el Pagador entiende y acepta sin reservas
                  todos los términos y condiciones contenidos en este
                  Acuerdo.<br />
                  3. El Pagador acepta el uso de las comunicaciones electrónicas
                  para recibir notificaciones y tener contacto con Wee.ar.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Obligaciones del Pagador</strong><br />
                </h4>
                <p>
                  1. El Pagador se obliga a:<br />
                  a. No pagar la adquisición de bienes o servicios objeto de
                  Actividades Prohibidas identificadas en los Términos y
                  Condiciones de Uso Generales.<br />
                  b. No pagar sumas de dinero que no resulten de la voluntad
                  cierta de adquirir un bien o servicio a un Cliente de
                  Wee.ar.<br />
                  c. No utilizar la Plataforma por un método distinto a los
                  autorizados por Wee.ar.<br />
                  2. El Pagador es el único responsable de conocer y cumplir la
                  legislación tributaria, cambiaria o de cualquier otra índole
                  aplicable que surja de la relación con los Clientes.<br />
                  3. Frente al incumplimiento de este Acuerdo, el Pagador o
                  terceros no podrán presumir como renuncia de derechos la no
                  aplicación de sanciones en forma inmediata por parte de
                  Wee.ar.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Limitación de Responsabilidad</strong><br />
                </h4>
                <p>
                  1. Wee.ar no será responsable ni garantizará el cumplimiento
                  de las obligaciones que hubiesen asumido los Clientes con
                  terceros en relación a los pagos a recibir a través de la
                  Plataforma.<br />
                  2. En ningún caso Wee.ar será responsable por daño directo,
                  lucro cesante, o por cualquier otro daño y/o perjuicio que
                  haya podido sufrir el Pagador, debido a las transacciones
                  realizadas o no realizadas a través de la Plataforma.<br />
                  3. La responsabilidad contractual de Wee.ar frente al Pagador
                  se limitará a los daños materiales, directos, cuantificables,
                  comprobables y previsibles, atribuibles a las acciones u
                  omisiones culpables de Wee.ar. El grado de diligencia de
                  Wee.ar será el exigible a los comerciantes en el giro
                  ordinario de sus negocios.<br />
                  4. Wee.ar se eximirá de responder, bajo toda circunstancia, y
                  aun cuando haya podido prever su ocurrencia, por pérdidas
                  catalogadas como lucro cesante, pérdidas de oportunidad de
                  negocio, pérdidas de reputación, daños indirectos,
                  incidentales, consecuenciales, ejemplarizantes, morales o
                  análogos.<br />
                  5. El Pagador mantendrá indemne a Wee.ar de cualquier pérdida
                  (incluidos los honorarios de abogados) sufrida con ocasión de
                  reclamos de terceros en contra de Wee.ar con ocasión de:<br />
                  6. La violación de las obligaciones, declaraciones y
                  manifestaciones del Pagador previstas en este Acuerdo.<br />
                  7. La violación de cualquier regulación aplicable al
                  Pagador.<br />
                  8. El fraude resultante de la sustitución de identidad del
                  Pagador.<br />
                  9. De toda responsabilidad extracontractual en la que incurra
                  Wee.ar, que sea imputable a la acción u omisión del
                  Pagador.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Protección de Privacidad</strong><br />
                </h4>
                <p>
                  1. Wee.ar protegerá la información personal del Pagador, así
                  como de los medios de pago e informáticos utilizados en la
                  operación, conforme con la Política de Privacidad.<br />
                  2. Wee.ar no será responsable por el uso indebido que los
                  Clientes o terceros realicen de los datos autorizados por el
                  Pagador al utilizar los Servicios de Wee.ar.<br />
                </p>

                <br />
                <h4 align="left"><strong>Notificaciones</strong><br /></h4>
                <p>
                  1. El Pagador podrá efectuar cualquier consulta o reclamo en
                  la dirección de correo electrónico wee@telco.com.ar
                </p>

                <br />
                <h4 align="left"><strong>Vigencia</strong><br /></h4>
                <p>
                  1. El Pagador podrá dejar de usar los Servicios de Wee.ar en
                  cualquier momento.<br />
                  2. Wee.ar podrá suspender o cancelar el uso de los Servicios
                  de Wee.ar, en cualquier momento, y sin necesidad de
                  notificación expresa ante las siguientes situaciones:<br />
                  a. Incumplimiento del Pagador de lo dispuesto en el presente
                  Acuerdo.<br />
                  b. Uso o sospecha de uso fraudulento de los Servicios de
                  Wee.ar por parte del Pagador o terceros.<br />
                  c. Modificación del marco regulatorio aplicables al Servicio
                  de Wee.ar.<br />
                </p>

                <br />

                <h4 align="left">
                  <strong>Jurisdicción y Ley aplicable</strong><br />
                </h4>
                <p align="left">
                  1. Este acuerdo estará regido en todos sus puntos por las
                  leyes vigentes en la Argentina. Cualquier disputa o litigio
                  que resulte del presente acuerdo, será sometida a la decisión
                  del Tribunal Ordinario Competente de la Ciudad de Corrientes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Avatar from "../Ayuda/Avatar.vue";
export default {
  name: "Terminos",
  components: { Avatar },
  data() {
    return {
      content: "<h1>Contenido</h1>",
    };
  },
};
</script>
<style scoped>
.card .card-title {
  text-align: center;
}
p {
  text-align: justify;
}
</style>
