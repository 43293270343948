import { Transferencias } from "../../services/transferencias.js";
import { Refresh } from "../../services/refreshToken";
import moment from "moment";
export default {
  namespaced: true,
  state: {
    // state de transferencias
    desserts: [],
    movnegativos: [],
    alltransferencias: [],
    //  filter fechas inicio y fin
    fechaDesde: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    fechaHasta: moment().format("YYYY-MM-DD"),

    //  filter fechas inicio y fin
    fechaDesdeT: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    fechaHastaT: moment().format("YYYY-MM-DD"),

    //  numero de pagina y total de registros
    page: 1,
    total: 0,
    visible: 7,
    // numero de registro por pagina
    rowsPerPage: 10,

    //loading en peticiones
    cargando: false,
    // loader de la tabla nuevas transferencias
    cargandoNewTrans: false,
    //errores de api
    errored: false,
    error: false,

    nro_referencia: "",

    export_data: [], //donde se gaurdaran las transferencias a exportar a un archivo excel
    montoTotal: 0,
  },
  getters: {
    fechaDesde: (state) => state.fechaDesde,
    fechaHasta: (state) => state.fechaHasta,
    data: (state) => state.desserts,

    fechaDesdeT: (state) => state.fechaDesdeT,
    fechaHastaT: (state) => state.fechaHastaT,
    data: (state) => state.alltransferencias,

    export_data: (state) => state.export_data,
    montoTotal: (state) => state.montoTotal,
  },
  mutations: {
    // mutations movimientos
    setTransferencias(state, desserts) {
      state.desserts = desserts;
    },
    setMovimientosNegativos(state, movs) {
      state.movnegativos = movs;
    },

    setCargando(state, value) {
      state.cargando = value;
    },

    setErrord(state, value) {
      state.errored = value;
    },

    setFechaInicio(state, value) {
      state.fechaDesde = value;
    },

    setFechaFin(state, value) {
      state.fechaHasta = value;
    },

    // mostrar las transferencias de un cuentaID
    setAllTransferencias(state, alltransferencias) {
      state.alltransferencias = alltransferencias;
    },
    setrowsPerPage(state, value) {
      state.rowsPerPage = value;
    },

    setPaginate(state, value) {
      state.page = value;
    },

    setTotal(state, value) {
      state.total = value;
    },

    setMontoTotal(state, monto) {
      state.montoTotal = monto;
    },

    setFechaInicioT(state, value) {
      state.fechaDesdeT = value;
    },

    setFechaFinT(state, value) {
      state.fechaHastaT = value;
    },

    setnro_referencia(state, value) {
      state.nro_referencia = value;
    },
    setCargandoNewTrans(state, value) {
      state.cargandoNewTrans = value;
    },
    setExportData(state, data) {
      state.export_data = data;
    },
  },
  /**
   * Consulta las tranferencias segun:
   * - fechaDesde: fecha inicio
   * - fechaHasta: fecha fin
   * - cuenta_ID: la cuenta actual del cliente segun su valor en store
   * rootState: se refiere al state del Store Global
   * state: se refiere al state del Store Local
   * */
  actions: {
    async getDatosTransferencias({ commit, rootState, state }) {
      const idcuenta = rootState.cuenta_ID;
      const fechaI = moment(state.fechaDesde).format();
      const fechaH = moment(state.fechaHasta).format();

      const params = {
        CuentaId: idcuenta,
        FechaInicio: fechaI,
        FechaFin: fechaH,
      };
      commit("setErrord", false);
      commit("setCargandoNewTrans", true);
      await Transferencias.getMovimientosTransferencias(params)
        .then((response) => {
          if (response.data.data == null) {
            commit("setTransferencias", []);
          } else {
            commit("setTransferencias", response.data.data);
          }
          // checkear los movimientos negativos que pueden venir en null
          if (response.data.movnegativos == null) {
            commit("setMovimientosNegativos", []);
          } else {
            commit("setMovimientosNegativos", response.data.movnegativos);
          }
        })
        .catch((error) => {
          var data = error.response.status;
          if (data == 403) {
            async () => {
              try {
                await Refresh.getRefreshToken().then((response) => {
                  //
                });
              } catch (error) {
                //
              }
            };
          } else {
            commit("setErrord", true);
          }
        });
    },

    async getAllTransferencias({ commit, rootState, state }, exportExcel) {
      const idcuenta = rootState.cuenta_ID;
      const number = state.page;
      const size = state.rowsPerPage;
      const fechaIT = moment(state.fechaDesdeT).format();
      const fechaHT = moment(state.fechaHastaT).format();
      const params = {
        CuentaId: idcuenta,
        Number: number,
        Size: size,
        FechaInicio: fechaIT,
        FechaFin: fechaHT,
      };

      if (exportExcel) {
        delete params.Size;
        delete params.FechaInicio;
        delete params.FechaFin;
      }

      commit("setErrord", false);
      commit("setCargando", true);
      await Transferencias.getTranseferencias(params)
        .then(({ data }) => {
          // si la cuenta no contiene movimientos se actualiza store a valor vacio y efecto cargando en false
          if (data.data == null) {
            commit("setAllTransferencias", []);
            commit("setPaginate", 1);
            commit("setTotal", 0);
            commit("setCargando", false);
            commit("setMontoTotal", 0);
          } else {
            // si se desea exportar el archivo excel
            if (exportExcel) {
              return commit("setExportData", data.data);
            }

            const montoTotal = data.total_transferencias
              .replaceAll(".", "")
              .replace(",", ".");

            // si la consulta es distinto de null se actualiza el store mutations movimientos
            commit("setAllTransferencias", data.data);
            commit("setPaginate", data.meta.page.currentPage);
            commit("setTotal", data.meta.page.lastPage);
            commit("setMontoTotal", parseFloat(montoTotal));
          }
        })
        .catch(() => commit("setErrord", true))
        .finally(() => commit("setCargando", false));
    },

    async hacerTransferencia(
      { commit, rootState, state },
      { cuenta_id, payload }
    ) {
      var my_payload = payload;
      var my_cuenta_id = cuenta_id;
      commit("setErrord", false);
      commit("setCargando", true);
      await Transferencias.makeTranfers(my_cuenta_id, my_payload)
        .then((response) => {
          // Cosas por hacer cuando la transferencia se haya realizado correctamente
          commit("setnro_referencia", response.data.numeroReferenciaBancaria);
        })
        .catch((error) => {
          // Cosas por hacer por el lado del error en la consulta
          var errorCode = error.response.status;
          let errorMessage = error.response.data.message;
          switch (errorCode) {
            case 403:
              async () => {
                try {
                  await Refresh.getRefreshToken().then((response) => {
                    //
                  });
                } catch (error) {
                  //
                }
              };
              break;

            default:
              throw error;
              break;
          }
        })
        .finally(() => commit("setCargando", false));
    },
  },
};
