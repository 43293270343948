<template>
  <div class="content">
    <div class="container-fluid">
      <v-app>
        <v-main>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">{{ title }}</h4>
            </div>

            <div class="card-body">
              <slot />
            </div>
          </div>
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutPage",
  props: { title: String },
};
</script>
