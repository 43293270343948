<template>
  <v-app>
    <v-main>
      <div class="container-fluid">
        <!-- <v-toolbar class="mt-4" dark src="/assets/img/fondo_terminos.jpg">
          <v-toolbar-title>Términos</v-toolbar-title>
          <v-autocomplete
            v-model="select"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Que estás buscando?"
            solo-inverted
          ></v-autocomplete>
        </v-toolbar> -->
        <div class="row">
          <div class="col-md-12">
            <div class="card card-profile">
              <Avatar/>
              <!-- <div class="card-avatar">
            <a href="javascript:;">
              <img class="img" src="/assets/img/load.png" />
            </a>
          </div> -->

              <div class="card-body">
                <h3 class="card-category text-gray">
                  Términos y Condiciones Generales de Uso
                </h3>
                <!-- <h4 class="card-title">Sobre telcopagos.com</h4> -->
                <br /><br />
                <p class="card-description" align="left">
                  Vigentes desde: 20/07/2022 <br />
                </p>
                <p />
                <br />

                <h4 align="left"><strong>Acuerdo</strong><br /></h4>
                <p>
                  1. Este documento y sus anexos constituyen los Términos y
                  Condiciones de Uso Generales que rigen a cualquier persona (en
                  adelante, el “Cliente” o “Clientes”), que desee acceder y/o
                  usar los servicios de Wee.ar de CORRIENTES TELECOMUNICACIONES
                  S.A.P.E.M. CUIT: 30-71655084-9 (en adelante, el “Acuerdo”).<br />
                  2. La sola utilización del servicio de procesamiento de pagos,
                  la plataforma y cualquier servicio relacionado que ofrezca
                  Wee.ar implica que el Cliente entiende y acepta sin reservas
                  todos los términos y condiciones contenidos en este
                  Acuerdo.<br />
                  3. Cualquier persona que no acepte este Acuerdo, el cual tiene
                  carácter obligatorio y vinculante, deberá abstenerse de
                  utilizar el sitio y/o los Servicios de Wee.ar.
                </p>
                <br />
                <h4 align="left"><strong>Servicios</strong><br /></h4>
                <p>
                  1. Wee.ar brinda servicios de gestión o procesamiento de pagos
                  por cuenta y orden del Cliente (en adelante, los “Servicios de
                  Wee.ar”) a fin de percibir en su Cuenta Wee.ar una determinada
                  suma de dinero, de acuerdo con sus instrucciones (en adelante,
                  la “Solicitud de Pago” o “Solicitudes de Pago”), utilizando la
                  plataforma brindada por Wee.ar (en adelante, la
                  “Plataforma”).<br />
                  2. Al completar una Solicitud de Pago el Cliente estará
                  celebrando con Wee.ar (conjuntamente, las “Partes”) un
                  Contrato de Gestión de Pagos por el que otorga un mandato
                  irrevocable a Wee.ar para:<br />
                  A. Cobrar, recibir y acreditar en su Cuenta Wee.ar, por su
                  cuenta y orden, una determinada suma de dinero (en adelante,
                  el “Importe”).<br />
                  3. La Solicitud de Pago se iniciará mediante el envío de una
                  solicitud online por parte del Cliente, a través de la
                  Plataforma, donde se detallarán los requerimientos de pago.
                  Ninguna Solicitud de Pago se considerará válida si fuese
                  cursada por medios ajenos a la Plataforma.<br />
                  4. La ejecución de la Solicitud de Pago por parte de Wee.ar se
                  entenderá como aceptación de la misma.<br />
                  5. El Pagador entregará a Wee.ar el dinero necesario para
                  cumplir con la Solicitud de Pago del Cliente mediante la
                  utilización de los medios de pago disponibles y autorizados a
                  tal fin. Caso contrario no se procesará la Solicitud de
                  Pago.<br />
                  6. Wee.ar informará el resultado de los pagos al Cliente
                  dentro de los cuatro (4) días hábiles contados desde recibir
                  la confirmación y/o autorización del medio de pago utilizado
                  en la transacción (en adelante, la “Fecha Informada de
                  Pago”).<br />
                  7. El Cliente es el exclusivo responsable por la Solicitud de
                  Pago y sus consecuencias.<br />
                  8. El Cliente estará obligado a brindar a los pagadores
                  información clara, precisa, y efectiva en relación a la
                  Solicitud de Pago.<br />
                  9. Wee.ar no verificará la causa u obligación que originó la
                  Solicitud de Pago, ni las demás circunstancias relativas a la
                  misma.<br />
                  10. Wee.ar podrá no procesar aquellas Solicitudes de Pago que
                  estén incompletas, presenten discrepancias en los datos
                  provistos por el Cliente o Wee.ar lo considere necesario, sin
                  necesidad de justificar su decisión.<br />
                  11. Wee.ar podrá determinar un importe mínimo y/o máximo para
                  las Solicitudes de Pago, que podrán variar de acuerdo al
                  método de pago elegido, tipo de solicitud y/o a criterio de
                  Wee.ar, e incluso podrá ser modificado en cualquier momento
                  siendo suficiente su notificación al Cliente. <br />
                  12. Wee.ar no será responsable por órdenes, instrucciones,
                  Solicitudes de Pago y/o pagos equivocados o incompletos
                  causados por la introducción errónea del email, información
                  relevante del destinatario o de la operación de pago,
                  efectuados por el Cliente.<br />
                  13. Wee.ar no garantiza la autenticidad o legalidad de las
                  transacciones que se procesan a través de la Plataforma. El
                  riesgo de fraude o el de ilegalidad de una transacción son
                  enteramente asumidos por el Cliente.<br />
                  14. Wee.ar pondrá las diligencias necesarias para validar las
                  transacciones a fin de contribuir a mitigar el riesgo de
                  fraude, el que, sucedido, será asumido total y exclusivamente
                  por el Cliente.<br />
                  15. Wee.ar no garantiza el acceso y uso continuado o
                  ininterrumpido de la Plataforma o de los Servicios de Wee.ar,
                  debido a dificultades técnicas, fallas de Internet o por
                  cualquier otra circunstancia ajena a Wee.ar.<br />
                  16. Wee.ar no garantiza al Cliente tiempos de procesamiento de
                  una transacción. No obstante, pondrá las diligencias
                  necesarias para procesar las transacciones ágilmente. En todo
                  caso, Wee.ar se exime del retardo en el procesamiento de
                  transacciones resultante de horarios o procesos bancarios, o
                  de cualquier tipo, de fallas en el servicio de los demás
                  agentes que intervienen o inciden en el procesamiento de
                  pagos, tales como redes de procesamiento, bancos, franquicias,
                  proveedores de internet y servidores, entre otros, y en
                  general de todo evento que exceda su control en el
                  procesamiento.<br />
                  17. El Cliente no podrá imputarle responsabilidad alguna a
                  Wee.ar, ni exigir resarcimiento alguno, en virtud de
                  perjuicios resultantes de las mencionadas dificultades, así
                  como por cualquier otra clase de daños, incluyendo daños
                  indirectos, especiales o consecuentes que surjan o experimente
                  el Cliente, incluso en el caso que dichas fallas afecten los
                  montos que deban ser pagados o acreditados. <br />
                  18. Para todos los efectos se entenderá que las obligaciones
                  de Wee.ar en la prestación de los Servicios de Wee.ar son, de
                  medios y no de resultado. <br />
                </p>
                <br />
                <h4 align="left"><strong>Registro</strong><br /></h4>
                <p>
                  1. Para comenzar a utilizar los Servicios de Wee.ar el Cliente
                  debe completar el Formulario de Registro y Apertura de Cuenta
                  Wee.ar (en adelante, el “Formulario de Registro”) con datos
                  fidedignos e información personal completa y fiel expresión de
                  la verdad (en adelante, “Datos Personales”) y aceptar este
                  Acuerdo.<br />
                  2. Toda la información y los Datos Personales consignados en
                  el Formulario de Registro tienen carácter de declaración
                  jurada.<br />
                  3. El Cliente autoriza a Wee.ar, directamente o a través de
                  terceros, a realizar todas las consultas que se consideren
                  necesarias para validar su identidad. Dentro de estas
                  consultas se incluye, sin carácter taxativo, requerir al
                  Cliente información adicional o documentación, solicitar un
                  reporte crediticio o comparar su información con bases de
                  datos de terceros o a través de otras fuentes.<br />
                  4. Wee.ar se reserva el derecho de rechazar, cancelar o
                  suspender la solicitud de registro a aquellos Clientes cuyos
                  datos no hayan podido ser confirmados.<br />
                  5. Wee.ar no se responsabiliza por los Datos Personales
                  provistos.<br />
                  6. El Cliente se obliga a actualizar sus datos en la medida en
                  que se hayan modificado.<br />
                  7. Es responsabilidad del Cliente mantener actualizada su
                  casilla de correo electrónico, de manera que Wee.ar pueda
                  comunicarse de manera electrónica.<br />
                  8. El Cliente comprende y acepta que si Wee.ar envía una
                  comunicación electrónica y no es recibida debido a que la
                  dirección de correo electrónico registrada es incorrecta, está
                  desactualizada, está bloqueada por el proveedor de servicio, o
                  por cualquier otro motivo, se considerará que dicha
                  comunicación sí ha sido enviada efectivamente.<br />
                  9. Si la dirección de correo electrónico ya no es válida y
                  genera la devolución de las comunicaciones que Wee.ar envía,
                  se considerará que su Cuenta Wee.ar está inactiva y el Cliente
                  no podrá hacer ningún movimiento hasta que confirme una
                  dirección de correo electrónico válida y en funcionamiento.
                  <br />
                </p>

                <br />
                <h4 align="left"><strong>Cuenta Wee.ar</strong><br /></h4>
                <p>
                  1. Al completar el Formulario de Registro el Cliente abrirá
                  una cuenta en Wee.ar (en adelante, “Cuenta Wee.ar”) que
                  permite percibir pagos.<br />
                  2. El Cliente accederá a su Cuenta Wee.ar desde www.wee.ar
                  mediante el ingreso de su nombre de Cliente o dirección de
                  correo electrónico y contraseña de seguridad establecida.<br />
                  3. Accediendo a su Cuenta Wee.ar el Cliente podrá establecer
                  como método alternativo de autenticación y autorización de
                  peticiones un token de acceso (en adelante, “API Key”).<br />
                  4. El Cliente se obliga a custodiar la confidencialidad de su
                  contraseña y/o API Key, y será el único y exclusivo
                  responsable por todas las operaciones efectuadas en su Cuenta
                  Wee.ar. Cualquier operación realizada con alguno de los
                  métodos de autenticación y autorización establecidos, será
                  válida y vinculante para las Partes.<br />
                  5. Es obligación del Cliente notificar a Wee.ar en forma
                  fehaciente y sin dilación de tiempo, de cualquier ingreso u
                  operación no autorizada en su Cuenta Wee.ar.<br />
                  6. Una Cuenta Wee.ar será asignada a un único Cliente, siendo
                  la misma, personal, única e intransferible.<br />
                  7. El Cliente no podrá vender, transferir o ceder bajo ningún
                  título su Cuenta Wee.ar.<br />
                  8. Cualquier referencia a dinero en Wee.ar se entenderá hecha
                  en la moneda de curso legal en la República Argentina, salvo
                  en los casos que se indique expresamente otra moneda.<br />
                  9. En su Cuenta Wee.ar el Cliente sólo puede recibir pagos a
                  través de las herramientas habilitadas en la Plataforma.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Saldos de la Cuenta Wee.ar</strong><br />
                </h4>
                <p>
                  1. Accediendo a su Cuenta Wee.ar el Cliente podrá consultar
                  los saldos en su Cuenta Wee.ar.<br />
                  2. El Cliente percibirá el Importe de los pagos, previa
                  deducción de la tarifa de Wee.ar (en adelante, el “Saldo
                  Pendiente”).<br />
                  3. El Saldo Pendiente será disponibilizado al Cliente (en
                  adelante, el “Saldo Disponible”), en los plazos definidos en
                  el documento anexo Plazos de Acreditación<br />
                  4. Wee.ar mantendrá el Saldo Disponible a favor del Cliente en
                  cuentas bancarias en entidades del sistema financiero
                  argentino a nombre de Corrientes Telecomunicaciones S.A.P.E.M.
                  (denominadas “Cuentas Recaudadoras”) y no reconoce interés o
                  remuneración alguna en relación con las sumas recaudadas por
                  cuenta del Cliente.<br />
                  5. El Saldo Disponible podrá estar sujeto a retenciones hechas
                  por Wee.ar, resultantes de:<br />
                  a. Reversiones y Contracargos.<br />
                  b. Ajustes derivados de errores u omisiones.<br />
                  c. Cualquier deuda que el Cliente mantuviere con Wee.ar,
                  cualquiera fuera su causa.<br />
                  d. Retenciones con ocasión del Fondo de Garantía, cuando
                  quiera que aplique.<br />
                  6. Toda información sobre el Saldo Pendiente y el Saldo
                  Disponible debe ser siempre verificada por el Cliente
                  accediendo a su Cuenta Wee.ar.<br />
                </p>

                <br />
                <h4 align="left"><strong>Retiro de Saldo</strong><br /></h4>
                <p>
                  1. El Cliente podrá optar por solicitar retirar todo o parte
                  del Saldo Disponible, o mantener el mismo en su Cuenta
                  Wee.ar.<br />
                  2. El retiro de Saldo Disponible, se iniciará mediante el
                  envío de una Solicitud online por parte del Cliente, a través
                  de la Plataforma, donde se detallarán los requerimientos de
                  retiro (en adelante, la “Solicitud de Retiro”).<br />
                  3. La ejecución de la Solicitud de Retiro, por parte de
                  Wee.ar, se entenderá como aceptación de la misma y se
                  realizará desde las Cuentas Recaudadoras mediante
                  transferencia electrónica a la Cuenta Bancaria indicada
                  oportunamente por el Cliente.<br />
                  4. Wee.ar podrá, a su discreción, rechazar una Solicitud de
                  Retiro, si el Cliente incumple este Acuerdo o existan
                  sospechas de que ha utilizado los Servicios de Wee.ar para
                  realizar actividades fraudulentas, ilícitas y/o de violación
                  de preceptos legales por los cuales Wee.ar deba responder.<br />
                  5. Wee.ar limita de forma exclusiva los retiros a cuentas
                  bancarias que sean de titularidad del Cliente.<br />
                  6. Wee.ar pondrá las diligencias necesarias para transferir el
                  importe solicitado a la Cuenta Bancaria del Cliente dentro de
                  las veinticuatro (24) horas hábiles siguientes a la solicitud
                  del Cliente.<br />
                  7. Una transferencia puede tardar hasta veinticuatro (24)
                  horas hábiles adicionales en ser autorizada y acreditada por
                  la entidad financiera en la cuenta bancaria del Cliente.<br />
                  8. Wee.ar no garantiza el cumplimiento de los plazos antes
                  indicados. El Cliente entiende que, por determinados factores,
                  en su mayoría externos a Wee.ar, se pueden originar retrasos,
                  motivo por el cual el Cliente exime a Wee.ar de toda
                  responsabilidad por los inconvenientes o perjuicios
                  derivados.<br />
                  9. Cualquier gasto resultante por la transferencia será
                  asumido por el Cliente.<br />
                  10. En el caso que el Cliente indique como cuenta retiro una
                  cuenta bancaria a nombre de una persona humana no inscripta en
                  AFIP, podrá retirar por mes calendario hasta la suma de
                  $35.000.<br />
                </p>

                <br />
                <h4 align="left"><strong>Tarifas</strong><br /></h4>
                <p>
                  1. Wee.ar le cobrará al Cliente por la prestación del servicio
                  de procesamiento de pagos las tarifas vigentes (en adelante,
                  las “Tarifas”) cada vez que perciba un pago, según se detalla
                  en el documento anexo Tarifas.<br />
                  2. Wee.ar podrá actualizar las Tarifas unilateralmente en
                  cualquier momento cuando considere que resulta necesario a fin
                  de seguir siendo competitivo en el mercado.<br />
                  3. Cualquier modificación en las Tarifas será notificada por
                  Wee.ar al Cliente con un mínimo de treinta (30) días
                  calendario ya sea a su dirección de correo electrónico y/o
                  publicando la modificación en la Plataforma.<br />
                  4. El Cliente se compromete a no aplicar ningún cargo
                  adicional por aceptar pagos o recibir fondos a través de la
                  Plataforma. <br />
                </p>

                <br />
                <h4 align="left"><strong>Reversiones</strong><br /></h4>
                <p>
                  1. Una reversión, contra cargo, cancelación, desconocimiento,
                  anulación o reembolso (en adelante “Reversiones”), es el
                  débito que hace el medio de pago o la red de procesamiento de
                  pagos a Wee.ar, del monto involucrado en una transacción
                  oportunamente pagada, generalmente por instrucción del titular
                  del medio de pago utilizado.<br />
                  2. Wee.ar podrá realizar Reversiones a solicitud del
                  administrador del medio o esquema de pago utilizado.<br />
                  3. El Cliente expresamente autoriza a Wee.ar a debitar el
                  monto necesario para reintegrar las Reversiones de: (i) sus
                  Fondos Disponibles y/o Fondos Pendientes; y en el caso de no
                  ser suficiente, (ii) de cualquier otro ingreso de saldo futuro
                  en su Cuenta Wee.ar, y/o (iii) por débito directo en las
                  Cuentas Bancarias indicadas oportunamente por el Cliente para
                  realizar un Retiro.<br />
                  4. Agotados los mecanismos previstos en el párrafo anterior,
                  Wee.ar procederá a solicitar formalmente al Cliente el pago
                  del monto necesario para reintegrar las Reversiones dentro de
                  los tres (3) días hábiles siguientes, y podrá bloquear
                  temporalmente el acceso del Cliente a los medios de pago, o a
                  sus Fondos, e incluso dar por finalizado unilateralmente el
                  Acuerdo.<br />
                  5. Wee.ar podrá reportar al Cliente ante los operadores de
                  información financiera e iniciar las medidas judiciales o
                  extrajudiciales que estime pertinentes a los efectos de
                  obtener el pago de dichos montos y sus accesorios legales.
                  <br />
                </p>

                <br />
                <h4 align="left"><strong>Fondo de Garantía</strong><br /></h4>
                <p>
                  1. Wee.ar podrá retener, a su discreción, un porcentaje del
                  Saldo Pendiente (en adelante, el “Fondo de Garantía”), como
                  garantía ante los siguientes eventos:<br />
                  a. Si el nivel de Reversiones supera el cinco por ciento (5%)
                  de los pagos recibidos en los últimos noventa (90) días
                  calendario.<br />
                  b. Si el nivel de fraude supera el uno por ciento (1%) de los
                  pagos recibidos en los últimos noventa (90) días
                  calendario.<br />
                  c. Si Wee.ar considera, que la actividad económica del Cliente
                  es altamente susceptible de fraude o de actividades
                  ilícitas.<br />
                  d. Manifestada la voluntad de las Partes de concluir este
                  Acuerdo.<br />
                  2. El Fondo de Garantía se establece inicialmente en un rango
                  que irá entre cinco por ciento (5%) y el quince por ciento
                  (15%) del volumen total de pagos esperados y/o procesados en
                  el mes calendario inmediato anterior.<br />
                  3. Wee.ar liberará el Fondo de Garantía, dentro de los noventa
                  (90) días calendario siguientes a la fecha de retención.<br />
                  4. La retención temporal del Fondo de Garantía será una
                  retención y no un descuento, salvo que se use para cubrir
                  Reversiones. <br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Condiciones Generales</strong><br />
                </h4>
                <p>
                  1. Wee.ar no es entidad financiera. Se deja expresamente
                  aclarado que Wee.ar no es una entidad financiera ni presta al
                  Cliente ningún servicio bancario o cambiario. Wee.ar sólo
                  brinda un servicio de procesamiento de pagos por cuenta y
                  orden del Cliente según las condiciones establecidas en este
                  Acuerdo.<br />
                  2. Wee.ar no es parte ni tiene interés alguno en los contratos
                  celebrados entre el Cliente y sus Pagadores. Ambas partes
                  declaran ser contratistas independientes, sin ninguna relación
                  societaria o comercial fuera de la pactada en el presente
                  acuerdo, y cada una de ellas mantendrá indemne a la otra por
                  cualquier reclamo derivado de las obligaciones impositivas,
                  laborales o previsionales que estuvieran a su cargo. Ninguna
                  de las partes inducirá a error a persona alguna sobre su
                  carácter de parte contractual independiente de la otra ni
                  obligará a la otra parte frente a terceros.<br />
                  3. El mandato que otorga el Cliente a Wee.ar como proveedor de
                  pagos, excluye facultades para cumplir las obligaciones
                  tributarias o cambiarias del Cliente, siendo este último el
                  único responsable de conocer y cumplir la legislación
                  aplicable.<br />
                  4. El Cliente reconoce y acepta que al realizar transacciones
                  con otros Clientes o terceros lo hace por su propia voluntad,
                  prestando su consentimiento libremente y bajo su propio riesgo
                  y responsabilidad.<br />
                  5. El Cliente asume, reconoce y acepta su total
                  responsabilidad por las pérdidas que se originen, en especial
                  si a través de su nombre de Cliente se impartieran
                  instrucciones fraudulentas, duplicadas, no autorizadas o
                  meramente erróneas, así como por los eventuales perjuicios que
                  pueda causar a Wee.ar.<br />
                  6. Sólo podrán celebrar Solicitudes de Pago aquellas personas
                  que tengan capacidad legal para contratar y estén debidamente
                  registrados como Clientes en Wee.ar. No podrán registrarse ni
                  utilizar los servicios menores de edad, personas que no tengan
                  capacidad para contratar o aquellos Clientes que hayan sido
                  suspendidos o inhabilitados de la Plataforma de Wee.ar,
                  temporal o definitivamente.<br />
                  7. La nulidad o inaplicabilidad de una o varias cláusulas de
                  este Acuerdo no afectará la validez y aplicación de las
                  demás.<br />
                  8. El Cliente será el único y exclusivo responsable por los
                  contenidos ofrecidos a los pagadores, incluyendo con respecto
                  a la exhibición de precios o promociones que puede ofrecer a
                  través de Wee.ar.<br />
                  9. El Cliente se obliga a cumplir con la normativa aplicable
                  con ocasión de su actividad comercial.<br />
                  10. Es responsabilidad del Cliente conocer con probidad su
                  negocio a fin de alertar a Wee.ar sobre transacciones que por
                  su número y/o cuantía o cualquier otra condición verificable,
                  se salgan de los parámetros habituales, y que por ende
                  impliquen riesgo de fraude por suplantación de identidad u
                  otras actividades ilegales.<br />
                  11. El Cliente no utilizará los servicios de Wee.ar para
                  transmitir material que constituya un delito o bien que pueda
                  dar lugar, directa o indirectamente a responsabilidades
                  civiles o que infrinjan los Términos y Condiciones Generales
                  de Wee.ar<br />
                  12. Si Wee.ar considera que hay una sospecha o indicio de la
                  utilización de Wee.ar para alguna actividad prohibida por la
                  ley o los Términos y Condiciones de Uso Generales de Wee.ar,
                  podrá rechazar, cancelar o suspender una Solicitud de Pago,
                  y/o bloquear temporalmente el acceso y uso de la Cuenta Wee.ar
                  y/o la utilización o disponibilidad de funcionalidades y/o
                  cancelar definitivamente una Cuenta Wee.ar. En tal caso, el
                  Cliente responderá por los daños y perjuicios que pueda haber
                  ocasionado a Wee.ar, accionistas, funcionarios, empleados,
                  directores, agentes, y/o empleados, a cuyos efectos Wee.ar se
                  reserva el derecho de iniciar las acciones judiciales o
                  extrajudiciales que estime pertinentes.<br />
                  13. El Cliente será exclusiva e ilimitadamente responsable por
                  los perjuicios que su conducta pueda causar a Wee.ar o a los
                  restantes Clientes de los servicios de Wee.ar.<br />
                  14. El Cliente deberá informar a sus pagadores, en su página
                  web, su razón social, número de identificación tributaria,
                  domicilio de notificación judicial, teléfono, dirección de
                  correo electrónico y demás datos de contacto.<br />
                  15. El Cliente se obliga a asumir los reclamos que presenten
                  sus pagadores en cuanto a cantidad, calidad, idoneidad,
                  seguridad y entrega de los bienes o servicios vendidos.<br />
                  16. Es obligación del Cliente acatar los reglamentos y
                  recomendaciones que Wee.ar expida para que su sistema funcione
                  de manera óptima y segura.<br />
                  17. El Cliente deberá abstenerse de adoptar prácticas de uso
                  que impongan una carga desproporcionada a la infraestructura
                  tecnológica de Wee.ar.<br />
                  18. El Cliente opta expresamente que la documentación e
                  información relacionada con el Acuerdo provista por Wee.ar
                  tenga, salvo disposición expresa en contrario, soporte
                  electrónico. En todo caso, el Cliente podrá hacer copias,
                  incluso en soporte físico, de la documentación e información
                  provista por Wee.ar relativa al Acuerdo.<br />
                  19. El Cliente autoriza en forma irrevocable a Wee.ar y/o a
                  quien Wee.ar designe, a grabar las operaciones del Cliente
                  relativas los servicios prestados por Wee.ar, y a utilizar
                  dichas grabaciones como medio probatorio ante autoridades
                  administrativas y/o judiciales.<br />
                  20. El Cliente se obliga a expedir y archivar durante por lo
                  menos dieciocho (18) meses posteriores a una transacción copia
                  del recibo o documento equivalente que evidencie que se
                  entregó el bien o se prestó el servicio al comprador y las
                  facturas derivadas de los contratos de compraventa o de
                  prestación de servicios correspondientes. El Cliente
                  suministrará a Wee.ar dichos soportes, en un plazo máximo de
                  cinco (5) días hábiles contados desde la fecha en que Wee.ar
                  se los requiera.<br />
                  21. Las constancias emitidas por Wee.ar serán consideradas
                  prueba suficiente del cumplimiento de las instrucciones y
                  Solicitud de Pago, y sustituyen la necesidad de cualquier
                  recibo y son plenamente oponibles al Cliente. A tales efectos,
                  el Cliente y Wee.ar acuerdan otorgarle valor probatorio a las
                  constancias que se emitan como consecuencia de las
                  aprobaciones o instrucciones dadas por el Cliente, con el
                  alcance establecido en la legislación vigente.<br />
                  22. El Cliente deberá abstenerse de facilitar que terceros no
                  pagadores, utilicen o se beneficien de la Plataforma, sin
                  autorización expresa de Wee.ar. Solamente se permite utilizar
                  los Servicios en nombre propio y no en representación y/o por
                  cuenta de terceros, estando terminantemente prohibido su
                  reventa o cesión.<br />
                  23. Queda absolutamente prohibida la utilización de Wee.ar
                  para efectuar cualquier operación, por parte de un Cliente, en
                  su propio beneficio y/o entre dos o más Clientes, que pueda
                  considerarse o ser entendida como adelanto de efectivo o
                  préstamo; así como el ingreso de sumas de dinero sin tener un
                  destino específico o relacionado con una transacción
                  comercial.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Limitación de Responsabilidad</strong><br />
                </h4>
                <p>
                  1. Wee.ar no será responsable ni garantizará el cumplimiento
                  de las obligaciones que hubiesen asumido los Clientes con
                  terceros en relación a los pagos a recibir a través de la
                  Plataforma.<br />
                  2. En ningún caso Wee.ar será responsable por daño directo,
                  lucro cesante, o por cualquier otro daño y/o perjuicio que
                  haya podido sufrir el Cliente, debido a las transacciones
                  realizadas o no realizadas a través de la Plataforma.<br />
                  3. La responsabilidad contractual de Wee.ar frente al Cliente
                  se limitará a los daños materiales, directos, cuantificables,
                  comprobables y previsibles, atribuibles a las acciones u
                  omisiones culpables de Wee.ar. El grado de diligencia de
                  Wee.ar será el exigible a los comerciantes en el giro
                  ordinario de sus negocios.<br />
                  4. Wee.ar se eximirá de responder, bajo toda circunstancia, y
                  aun cuando haya podido prever su ocurrencia, por pérdidas
                  catalogadas como lucro cesante, pérdidas de oportunidad de
                  negocio, pérdidas de reputación, daños indirectos,
                  incidentales, consecuenciales, ejemplarizantes, morales o
                  análogos.<br />
                  5. El Cliente mantendrá indemne a Wee.ar de cualquier pérdida
                  (incluidos los honorarios de abogados) sufrida con ocasión de
                  reclamos de terceros en contra de Wee.ar con ocasión de:<br />
                  a. La violación de las obligaciones, declaraciones y
                  manifestaciones del Cliente previstas en este Acuerdo.<br />
                  b. La violación de cualquier regulación aplicable al
                  Cliente.<br />
                  c. El fraude resultante de la sustitución de identidad de
                  pagadores.<br />
                  d. De toda responsabilidad extracontractual en la que incurra
                  Wee.ar, que sea imputable a la acción u omisión del
                  Cliente.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Reclamos o Disputas.</strong><br />
                </h4>
                <p>
                  1. Los Clientes aceptan el mecanismo de Solución de Reclamos y
                  Disputas ofrecido por Wee.ar, sometiendo sus diferencias o
                  conflictos de interés con los Clientes Pagadores a dicha
                  herramienta, en caso de proceder. Cuando, por las
                  características de la controversia, Wee.ar determine que su
                  intervención resulta necesaria, la hará sólo en el rol de
                  tercero que vincula a las verdaderas partes interesadas, no
                  garantizando la satisfacción de los intereses de ninguna de
                  ellas, y sus dictámenes sobre la cuestión serán acatados por
                  los involucrados. En caso de que, por las características de
                  la controversia, no resultare procedente y/o conveniente la
                  intervención de Wee.ar, o que, habiendo intervenido Wee.ar las
                  partes igualmente no llegasen a un acuerdo, Wee.ar no tendrá
                  responsabilidad alguna sobre tal circunstancia, por lo que las
                  partes renuncian expresamente a realizar cualquier reclamo
                  contra Wee.ar. En todo caso, el alcance de la resolución de
                  Wee.ar estará limitado a la procedencia o no de la restitución
                  de los fondos a la cuenta originante, cuando se encontrase
                  debida y fehacientemente acreditada tal decisión.
                </p>

                <br />
                <h4 align="left">
                  <strong>Actividades Restringidas y Prohibidas</strong><br />
                </h4>
                <p>
                  1. El Cliente es el único responsable de verificar que su
                  actividad comercial se ajuste a la legislación aplicable, y
                  que cuenta con los permisos y licencias necesarias para
                  desarrollarla.<br />
                  2. El Cliente se abstendrá de utilizar la Plataforma para
                  desarrollar actividades, o comercializar bienes o servicios,
                  sin cumplir con las obligaciones administrativas y fiscales
                  establecidas para su actividad, o prohibidos por la
                  legislación aplicable, o incluidos en el listado de
                  Actividades Prohibidas de Wee.ar, o que impliquen un riesgo
                  reputacional para Wee.ar.<br />
                  3. En el caso de que la actividad comercial desarrollada por
                  el Cliente quede comprendida dentro del Listado de Actividades
                  Restringidas enumeradas en este apartado, deberá contar con la
                  autorización previa, expresa y por escrito de Wee.ar para la
                  utilización de la Plataforma. Dicha autorización de ninguna
                  manera podrá considerarse como extensiva a otros clientes que
                  desarrollen actividades similares. En idéntico sentido, la
                  autorización particular de la actividad en cuestión no
                  implicará la habilitación general de la misma.<br />
                  4. Son consideradas Actividades Restringidas las desarrolladas
                  por:<br />
                  a. Establecimientos que operan desde apartados postales.<br />
                  b. Establecimientos que solo aceptan órdenes de compra a
                  través de contestador automático.<br />
                  c. Recaudadores de deudas. Agencias recaudadoras, compradoras
                  de créditos, fianzas. Dadores de fianzas<br />
                  d. Servicios financieros, Efectivización de cheques /
                  garantías.<br />
                  e. Organizadores de Apuestas. Casinos. Venta de billetes de
                  Lotería. Tomadores de apuestas, sitios web de apuestas. Bingos
                  y cualquier otro medio para hacer apuestas.<br />
                  f. Casas de empeños.<br />
                  g. Partidos políticos.<br />
                  h. Organizaciones religiosas.<br />
                  i. Agencias de cambio.<br />
                  j. Agencias de detectives y servicios de protección.<br />
                  5. Son consideradas Actividades Prohibidas, y por lo tanto, la
                  utilización de la Plataforma a tales fines queda absolutamente
                  prohibida:<br />
                  a. Los negocios ilegales, o aquellos que aparenten estar
                  comprometidos con actividades ilegales.<br />
                  b. Los negocios que tengan contenido, permitan o realicen
                  cualquier actividad contraria a las disposiciones legales y
                  administrativas, la moral y las buenas costumbres; y/o
                  publiciten o comercialicen actividades o bienes cuya
                  comercialización esté prohibida en los Términos y
                  Condiciones.<br />
                  c. La comercialización de servicios futuros.<br />
                  d. La comercialización de productos o servicios relacionados
                  con la pornografía; sitios web pornográficos. Mercaderías
                  pornográficas, sex shops.<br />
                  e. Los servicios relacionados con la prostitución.<br />
                  f. La comercialización de bienes o servicios afectados por
                  embargos, cesiones, derechos reales a favor de terceros u
                  otras circunstancias que puedan limitar o de algún modo
                  condicionar su libre disposición.<br />
                  g. Las ventas efectuadas bajo un nombre de fantasía o una
                  razón social distinta del autorizado por Wee.ar.<br />
                  h. Las actividades comerciales que promuevan o tengan
                  contenidos de sexo en el que participen menores de 18 años,
                  relacionados con pedofilia, pornografía, desnudos de menores,
                  ya sean reales o simulados, o de cualquier modo involucre
                  menores de 18 años, o se trate de películas o fotografías que
                  hayan sido tomadas de forma ilegal de menores de 18 años o sin
                  el consentimiento de las personas que en ellas aparecen.<br />
                  i. Los negocios que promuevan la violencia de cualquier tipo,
                  la discriminación de cualquier tipo, prostitución, lavado de
                  dinero, tráfico de armas, de personas o de animales, u otras
                  actividades ilegales.<br />
                  j. Las actividades que ofrezcan contenidos, bienes o servicios
                  que violen la legislación vigente, en términos de protección
                  de derechos de propiedad intelectual, piratería de software, u
                  otros.<br />
                  6. El Cliente informará a Wee.ar si, como consecuencia de
                  actualizaciones al listado de Actividades Prohibidas o
                  Restringidas, o por cambios en la legislación aplicable, se
                  encuentra comercializando bienes o servicios prohibidos o
                  restringidos.<br />
                </p>

                <br />
                <h4 align="left">
                  <strong>Licencia y Propiedad Intelectual e Industrial</strong
                  ><br />
                </h4>
                <p>
                  1. Todos los derechos intelectuales e industriales, sobre la
                  Plataforma, desarrollo, software, hardware, dominio, logos,
                  emblemas, logotipos, diseños, estructura, contenidos,
                  información, etc. son de propiedad y uso exclusivo de Wee.ar.
                  En ningún caso se entenderá que el Cliente tendrá algún tipo
                  de derecho sobre los mismos.<br />
                  2. Wee.ar sólo autoriza al Cliente a hacer uso de su propiedad
                  intelectual, en lo referido a las herramientas de la
                  Plataforma puestas a su disposición, para el cumplimiento de
                  las actividades que se derivan del presente Acuerdo. Cualquier
                  otra utilización de tal propiedad intelectual de Wee.ar queda
                  estrictamente prohibida. En la medida en que se cumpla con
                  estas limitaciones, Wee.ar otorga una licencia de uso
                  gratuita, no exclusiva y revocable al Cliente.<br />
                  3. En la difusión, promoción y utilización de la Plataforma de
                  pagos Wee.ar, el Cliente se obliga a utilizar exclusivamente
                  aquellos logos, banners y demás material promocional que sea
                  proporcionado por Wee.ar. El Cliente se abstendrá de utilizar
                  banners o logos diseñados por sí y/o terceros, como cualquier
                  otro material promocional utilizando derechos intelectuales de
                  Wee.ar, sin autorización previa y escrita de Wee.ar.<br />
                  4. El Cliente se compromete a comunicar a su base de Clientes
                  que los pagos son procesados por Wee.ar o Corrientes
                  Telecomunicaciones S.A.P.E.M. en forma indistinta, y a los
                  fines de mitigar cualquier desconocimiento de la transacción
                  por parte de sus pagadores.<br />
                  5. El Cliente debe integrar y/o utilizar la Plataforma
                  cumpliendo con las buenas prácticas de integración de Wee.ar,
                  las cuales incluyen la implementación de la última versión del
                  Checkout de Wee.ar.<br />
                  6. El Cliente otorga a Wee.ar una autorización de uso gratuito
                  y sin límite temporal del nombre comercial, marcas y logos,
                  para su utilización en acciones promocionales, publicitarias
                  y/o comunicaciones relacionadas con el servicio de Wee.ar. La
                  autorización conferida faculta a Wee.ar a exhibir, reproducir,
                  difundir y/o publicar las marcas del Cliente en cualquier
                  medio de comunicación y en cualquier soporte, incluyendo en
                  particular, pero de ningún modo limitándose al sitio web y la
                  Plataforma de Wee.ar.<br />
                  7. Los logos, emblemas, logotipos, diseños, estructura etc. de
                  los medios de pago permitidos por Wee.ar son de propiedad
                  exclusiva de dichos medios de pago. Los Clientes declaran
                  conocer y aceptar que su uso está permitido únicamente con el
                  objeto de servir como medios de pago, y en ningún caso se
                  entenderá que el Cliente tendrá algún tipo de derecho sobre
                  los mismos.<br />
                  8. El Cliente no utilizará las marcas, logos, emblemas,
                  logotipos, diseños, estructuras, etc. de los medios de pago
                  permitidos por Wee.ar ni de ninguna marca comercial de una
                  forma tal que pueda ser entendida como que el producto o
                  servicio ofrecido por el Cliente es patrocinado, promovido,
                  producido, ofrecido y/o vendido por los medios de pago o por
                  las marcas comerciales involucradas. Asimismo, El Cliente
                  deberá abstenerse de realizar cualquier acto que pudiera
                  causar un daño, pérdida de reputación, o disminución del valor
                  de dichas marcas, o utilizarlas en forma contraria a la moral
                  y buenas costumbres.<br />
                </p>

                <br />
                <h4 align="left"><strong>Cesión</strong><br /></h4>
                <p>
                  1. El Cliente no podrá ceder total o parcialmente este
                  Acuerdo, salvo expresa y previa aprobación escrita de
                  Wee.ar.<br />
                  2. Wee.ar podrá ceder total o parcialmente este Acuerdo,
                  previa notificación al Cliente.<br />
                </p>

                <br />
                <h4 align="left"><strong>Confidencialidad</strong><br /></h4>
                <p>
                  1. Las Partes guardarán absoluta reserva sobre la información
                  que se suministren entre sí, que haya sido designada como
                  confidencial, o que por su naturaleza lo sea, incluyendo sin
                  limitación información técnica, financiera, conocimientos,
                  métodos o procesos empresariales.<br />
                  2. Las Partes se abstendrán de utilizar la información
                  confidencial para fines distintos a la ejecución de este
                  Acuerdo y de revelar la misma a terceros sin previa
                  autorización de la parte reveladora.<br />
                  3. El suministro de información confidencial no implica
                  transferencia de derecho alguno sobre la misma.<br />
                  4. Si la parte receptora debe revelar la información
                  confidencial obtenida con ocasión de regulación aplicable o de
                  orden de autoridad competente, informará a la parte reveladora
                  y la revelará sólo en la medida requerida por dicha regulación
                  o autoridad.<br />
                </p>
                <br />
                <h4 align="left">
                  <strong>Modificaciones al Acuerdo</strong><br />
                </h4>
                <p>
                  1. Wee.ar se reserva el derecho de modificar este Acuerdo en
                  cualquier momento mediante la publicación de una versión
                  actualizada.<br />
                  2. El Cliente reconoce que el Acuerdo se encuentra disponible
                  para su lectura en wee.ar/terminos-y-condiciones y es de su
                  plena responsabilidad cumplir con los Términos y Condiciones
                  de Uso vigentes, debiendo mantener indemne a Wee.ar por
                  cualquier daño o reclamo derivado del incumplimiento del
                  Cliente.<br />
                  3. La versión actualizada entrará en vigencia a los quince
                  (15) días calendario de su publicación. Vencido este plazo, se
                  considerará que el Cliente acepta los nuevos términos y el
                  Acuerdo continuará vigente. Caso contrario, el Cliente deberá
                  notificar por email que no acepta las modificaciones y
                  expresar su deseo de resolver el vínculo contractual.<br />
                  4. Wee.ar se reserva el derecho a notificar cualquier
                  modificación a la dirección de email del Cliente.<br />
                </p>
                <br />
                <h4 align="left">
                  <strong>Duración y Terminación</strong><br />
                </h4>
                <p>
                  1. Este Acuerdo tendrá una duración indefinida.<br />
                  2. Las Partes podrán terminarlo en cualquier momento, sin
                  motivo alguno, mediante preaviso escrito con sesenta (60) días
                  calendario.<br />
                  3. El Cliente podrá darlo por terminado en forma anticipada y
                  unilateral, notificando a Wee.ar cuando no esté de acuerdo con
                  las modificaciones al Acuerdo.<br />
                  4. Asimismo, Wee.ar podrá, en caso de incumplimiento del
                  Cliente a las condiciones del presente Acuerdo o a la
                  legislación aplicable en la materia, dar por terminada la
                  prestación de los Servicio de Wee.ar sin ningún tipo de aviso
                  previo, reservándose el derecho de reclamar los daños y
                  perjuicios que tal incumplimiento le haya causado.<br />
                </p>

                <br />
                <h4 align="left"><strong>Notificaciones</strong><br /></h4>
                <p>
                  1. Wee.ar notificará al Cliente en su dirección de correo
                  electrónico principal indicada en el Formula rio de Registro y
                  Apertura de Cuenta Wee.ar, y/o en su Cuenta Wee.ar.<br />
                  2. El Cliente notificará a Wee.ar en el correo electrónico:
                  wee@telco.com.ar.<br />
                  3. Cualquier notificación realizada vía correo electrónico se
                  presumirá efectuada el día de su envío<br />
                </p>

                <br />
                <h4 align="left"><strong>Domicilios</strong><br /></h4>
                <p>
                  1. Se fija como domicilio de Wee.ar calle Dr. Ramón Carrillo
                  N° 444, Piso 5°, de la Ciudad de Corrientes.<br />
                  2. El domicilio constituido por el Cliente es el registrado al
                  completar el Formulario de Registro y Apertura de Cuenta
                  Wee.ar.<br />
                </p>

                <br />
                <h4 align="left"><strong>Anexos</strong><br /></h4>
                <p>
                  1. Forman parte, integral e inseparable, de este Acuerdo, los
                  siguientes documentos:<br />
                  A. Tarifas<br />
                  B. Plazos de Acreditación<br />
                  C. Política de Privacidad<br />
                  D. Términos y Condiciones del Pagador<br />
                </p>

                <h4 align="left">
                  <strong>Jurisdicción y Ley aplicable</strong><br />
                </h4>
                <p align="left">
                  1. Este acuerdo estará regido en todos sus puntos por las
                  leyes vigentes en la Argentina. Cualquier disputa o litigio
                  que resulte del presente acuerdo, será sometida a la decisión
                  del Tribunal Ordinario Competente de la Ciudad de Corrientes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import Avatar from "../Ayuda/Avatar.vue";
export default {
  name: "Terminos",
  components: { Avatar },
  data() {
    return {
      content: "<h1>Contenido</h1>",
    };
  },
};
</script>
<style scoped>
.card .card-title {
  text-align: center;
}
p {
  text-align: justify;
}
</style>
