import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const TelCoCorreo = {

// Obtener todos los avisos
getCorreos(params) {
  return axios.get(PASARELA + "/administracion/telco-correos", { params });
},

createTelCoCorreo(params){
    return axios.post(PASARELA + "/administracion/telco-correos", params)
},

putTelCoCorreo(payload){
    return axios.put(PASARELA + "/administracion/telco-correos", payload)
},

}