<template>
  <div id="reversiones">
    <!-- REVERSIONES -->
    <h4 class="bold pt-4 mb-2">Reversiones</h4>
    <p>Retorna todos las reversiones para el día especificado.</p>
    <!-- -------------HEADERS--------------- -->
    <h4 class="my-4">Headers</h4>
    <v-simple-table fixed-header height="150px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              PATTERN
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in headersReversiones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>
              <code
                style="background-color: #f5f5f5; padding: 2px 4px; border-radius: 4px; color: #000"
                >{{ item.pattern }}
              </code>
            </td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------PARAMS----------- -->
    <p class="my-4">Path parameters</p>
    <v-simple-table fixed-header height="100px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paramsReversiones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------RESPONSE----------- -->
    <h4 class="bold my-4">RESPONSE</h4>
    <p class="bold">Atributos del objeto de respuesta.</p>
    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in responseReversiones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response ----------: objeto result -->
    <p class="pt-4">Atributos del objeto <span class="italic">result</span>.</p>
    <v-simple-table fixed-header height="240px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objectResultReversiones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response----objeto result.data -->
    <p class="pt-4">Atributos del objeto <span class="italic">data</span>.</p>
    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objDataReversiones" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- GET Obtener Cobranzas -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <h4 class="bold mb-4" id="crear-solicitud-pago">
      <span class="metodo-get">GET</span> Obtener Reversiones
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p
        class="
      d-flex
        align-items-center
        justify-content-center
        mb-0
      "
      >
        https://sb-wee.ar/v1/reporte/reversiones?date=03-01-2023
      </p>
    </div>
    <!-- Headers -->
    <h4 class="bold mt-4">HEADERS</h4>
    <v-divider></v-divider>
    <div>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Content-Type</p>
        </v-col>
        <v-col cols="6">
          <p>application/json</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Authorization</p>
        </v-col>
        <v-col cols="6">
          <p>Bearer <span>{</span>{api_key}<span>}</span></p>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: RESPONSE --------------------- -->
    <div>
      <h6 class="bold mt-4">Example Response</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
{
    "message": "Solicitud reversiones generada",
    "result": {
        "account_id": "000000",
        "report_date": "10-07-2023",
        "total_chargeback": 46441.5,
        "data": [
            {
                "informed_date": "15-06-2023 11:53:00",
                "request_id": 3279,
                "external_reference": "757482",
                "payer_name": "NOMBRE APELLIDO",
                "description": "DESCRIPCIÓN",
                "channel": "DEBITO/CREDITO…",
                "reverted_amount": 18399
            },
            {
                "informed_date": "14-06-2023 09:26:00",
                "request_id": 3278,
                "external_reference": "757100",
                "payer_name": "NOMBRE APELLIDO",
                "description": "DESCRIPCIÓN",
                "channel": "DEBITO/CREDITO…",
                "reverted_amount": 22455.32
            },
            {
                "informed_date": "05-06-2023 10:52:00",
                "request_id": 3277,
                "external_reference": "746509",
                "payer_name": "NOMBRE APELLIDO",
                "description": "DESCRIPCIÓN",
                "channel": "DEBITO/CREDITO…",
                "reverted_amount": 5587.18
            }
        ]
    },
    "status": true
}
    </code>
  </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewAyudaRepReversiones",
  data() {
    return {
      headersReversiones: [
        {
          atributo: "Content-Type",
          tipo: "String",
          requerido: "SI",
          pattern: "application/json",
          descripcion:
            "El tipo MIME del contenido del cuerpo enviado. Por defecto JSON es Unicode UTF-8. No se debe configurar otro charset.",
        },
        {
          atributo: "Authorization",
          tipo: "String",
          requerido: "SI",
          pattern: "Bearer",
          descripcion: "ba7943e1-a60c-43e7-8812-xxxxxxxxxxxx",
        },
      ],
      paramsReversiones: [
        {
          atributo: "Date",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Fecha de reversión. Solo se puede pedir este reporte para una fecha anterior al dia actual. Formato DD-MM-YYYY",
        },
      ],
      responseReversiones: [
        {
          atributo: "message",
          tipo: "String",
          descripcion: "Mensaje de la respuesta.",
        },
        {
          atributo: "result",
          tipo: "Object",
          descripcion: "Objeto con la información de la respuesta.",
        },
        {
          atributo: "status",
          tipo: "Boolean",
          descripcion: "Estado de la respuesta.",
        },
      ],
      objectResultReversiones: [
        {
          atributo: "account_id",
          tipo: "String",
          descripcion: "ID de la cuenta propietaria del Reporte.",
        },
        {
          atributo: "report_date",
          tipo: "String",
          descripcion: "Fecha en la que se informaron las reversiones.",
        },
        {
          atributo: "total_chargeback",
          tipo: "Float",
          descripcion:
            "Importe total revertido. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal). Si es 0 significa que para ese día no hubo reversiones.",
        },
        {
          atributo: "data",
          tipo: "Array[Object]",
          descripcion:
            "Arreglo de objetos que contiene el detalle de las reversiones.",
        },
      ],
      objDataReversiones: [
        {
          atributo: "informed_date",
          tipo: "DateTime",
          descripcion: "Fecha en la que se informó la reversión.",
        },
        {
          atributo: "request_id",
          tipo: "Integer",
          descripcion: "ID de la solicitud.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          descripcion:
            "Este atributo es utilizado como referencia para identificar la solicitud y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "payer_name",
          tipo: "String",
          descripcion: "Nombre del pagador.",
        },
        {
          atributo: "description",
          tipo: "String",
          descripcion: "Descripción o concepto.",
        },
        {
          atributo: "channel",
          tipo: "String",
          descripcion: "Nombre del medio de pago utilizado.",
        },
        {
          atributo: "reverted_amount",
          tipo: "Float",
          descripcion:
            "Importe revertido. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.metodo-get {
  color: rgb(0, 127, 49);
}
.url-container {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  height: 40px;
  display: flex;
  padding-left: 10px;
}
.code-block {
  font-size: 14px;
}
</style>
