import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const PagosTipo = {
     //listar pagos tipos  
     getPagosTipo(params) {
        return  axios.get(PASARELA+"/administracion/pagos-tipo", {params})
    },

    // crear nueva pago tipo
    nuevo_pagotipo(payload) {
        return  axios.post(PASARELA+"/administracion/pago-tipo", payload)
    },

     // actualizar pago tipo
     actualizar_pagotipo(payload) {
        return  axios.put(PASARELA+"/administracion/pago-tipo", payload)
    },

    //consultar pago tipo por ID
    getPagosTipoID(params) {
        return  axios.get(PASARELA+"/administracion/pago-tipo", {params})
    },

     // delete pago tipo
     delete_pagotipo(params) {
        return  axios.delete(PASARELA+"/administracion/pago-tipo", {params})
    },

    // actualizar pago tipo general
    actualizar_pagotipogeneral(payload) {
        return  axios.put(PASARELA+"/administracion/pago-tipo-general", payload)
    },
}
