import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Movimientos = {
  //listar movientos
  getMovimientos(data, config) {
    return axios.post(
      PASARELA + "/administracion/movimiento-cuenta",
      data,
      config
    );
  },

  getMovimientoSaldo(params) {
    return axios.get(PASARELA + "/administracion/movimiento-con-saldo", {
      params,
    });
  },

  getMovimientoSaldoSubcuentas(params) {
    return axios.get(PASARELA + "/administracion/movimiento-subcuentas-con-saldo", {
      params,
    });
  },
  
};
