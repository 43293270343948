import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const SubCuentas = {
  //SUBCUENTAS

  //listar subcuentas
  getSubCuentas(params) {
    return axios.get(PASARELA + "/administracion/subcuentas", { params });
  },

  //nueva subcuenta (y actualizar subcuenta)
  nuevaSubCuenta(payload) {
    return axios.post(PASARELA + "/administracion/subcuenta", payload);
  },

  //eliminar subcuenta
  deleteSubCuenta(payload) {
    return axios.post(PASARELA + "/administracion/delete-subcuenta", payload);
  },
};
