import axios from 'axios';
import './axiosInterceptor';
export const AUTHENTICATION = process.env.VUE_APP_URLAUTHENTICATION;

export const UserRolService = {
  getRolUserVincular(params) {
    return axios.get(AUTHENTICATION + "/adm/rol-vincular", { params })
  },

  CreateUserSistemaRol(payload) {
    return axios.post(AUTHENTICATION + "/adm/user-rol", payload)
  },

  getUserID(params) {
    return axios.get(AUTHENTICATION + "/adm/user-rol", { params })
  },

  //  delete se piden parametros por body la opcion correcta es la siguiente
  quitar_rol_users(select_delete_id, id) {
    return axios.delete(AUTHENTICATION + "/adm/user-rol", { data: { RolId: select_delete_id, UsersistemaId: id } })
  },
}
