<template>
  <div class="content">
    <div class="row">
      <div class="col-md-2" align="left">
        <v-btn fab dark x-small color="primary">
          <router-link to="/accesos">
            <v-icon dark> arrow_back</v-icon>
          </router-link>
        </v-btn>
      </div>

      <div class="col" align="right">
        <v-row justify="center">
          <v-dialog v-model="vincular_permiso" persistent max-width="600px">
            <!-- boton nuevo cuenta  -->
            <template v-slot:activator="{ on, attrs }">
              <div class="col-md-12" align="right">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  :disabled="cargando"
                  @click="vincular_permiso_rol()"
                >
                  Vincular rol
                </v-btn>
              </div>
            </template>
            <!-- end boton nuevo cuenta  -->

            <div v-if="procensando_datos">
              <loader
                object="#4caf50"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="40"
                disableScrolling="false"
                name="box"
              ></loader>
            </div>
            <v-card>
              <v-card-title class="text-h6 justify-center"
                >Seleccione el rol que desea vincular al usuario
                <v-spacer></v-spacer>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="cerrar_create()"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-radio-group
                  v-model="select"
                  column
                  v-for="dat in roles_users_disponibles"
                  v-bind:key="dat.Id"
                >
                  <v-radio
                    :label="labelRolVincular(dat)"
                    :value="dat.Id"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <v-btn color="blue darken-1" text @click="cerrar_create()">
                  Cerrar
                </v-btn>
                <v-btn color="blue darken-1" text @click="confirmar()">
                  Confirmar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </div>
    <br />
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Rol del usuario</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-4 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="find_in_page"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="desserts"
                :loading="cargando"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-1 trow"
              >
                <template v-slot:no-results>
                  <v-alert color="warning" icon="warning">
                    No se encontraron registros
                  </v-alert>
                </template>
                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- quitar permiso del rol  -->
                    <v-dialog v-model="dialogDelete" max-width="400px">
                      <v-card>
                        <v-card-title class="text-h5 justify-center"
                          >¿Desea quitar permiso
                          {{ select_delete }}?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Confirmar</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="red" @click="deleteItem(item)">
                    delete
                  </v-icon>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
              </div>
            </section>
          </div>

          <!-- verificacion de cbu  -->
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Roles } from "@/services/roles.js";
import { UserRolService } from "@/services/userrol.js";
import { mapState } from "vuex";
export default {
  data: () => ({
    name: "UserRol",

    // permisos  del rol
    user_sistema_id: 0,
    user_id: 0,
    desserts: [],

    // titulo del rol
    rol: "",

    dialogDelete: false,
    // efecto cargando en tabla
    cargando: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,
    // Filter models.
    search: "",
    headers: [
      { text: "Id", value: "Id" },
      { text: "Rol", value: "Rol" },
      { text: "Scope", value: "Scope" },
      { text: "Descripcion", value: "Descripcion" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    // paginate datatable
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    vincular_permiso: false,
    valid: false,
    dialog: false,

    // listar rol disponibles y select para vincular
    roles_users_disponibles: [],
    select: "",

    //quitar permiso
    select_delete_id: "",
    select_delete: "",
  }),

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.user_sistema_id = this.$route.params.user_sistema_id;
    this.user_id = this.$route.params.user_id;
    this.getdatos();
    this.permisos_rol_user();
  },
  computed: {
    ...mapState(["SISTEMA_ID", "cliente_ID"]),
  },
  //consultar las cuentas del cliente
  methods: {
    labelRolVincular(item) {
      return item.Rol + ": " + item.Descripcion;
    },
    getdatos: function () {
      const params = {
        CargarSistema: 1,
        Number: 1,
        Size: 50,
        SistemaId: this.SISTEMA_ID,
        CargarUserSistemaRol: true,
        UserSistemaId: this.user_sistema_id,
      };
      this.cargando = true;
      Roles.getRoles(params)
        .then((response) => {
          if (response.data.data == null) {
            this.desserts = [];
          } else {
            this.desserts = response.data.data;
            //   this.rol = response.data.Rol;
            //   this.id = response.data.Id;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    permisos_rol_user: function () {
      const params = {
        SistemaId: this.SISTEMA_ID,
        UserId: this.user_id,
      };

      UserRolService.getRolUserVincular(params)
        .then((response) => {
          this.roles_users_disponibles = response.data;
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },

    // vincular permiso a rol
    vincular_permiso_rol() {
      this.vincular_permiso = true;
      this.limpiar_campos();
    },

    cerrar_create() {
      this.vincular_permiso = false;
      this.limpiar_campos();
    },

    confirmar() {
      if (this.select != "") {
        const params = {
          RolId: this.select,
          UsersistemaId: this.user_sistema_id,
        };

        this.error = false;
        this.procensando_datos = true;
        UserRolService.CreateUserSistemaRol(params)
          .then((response) => {
            // mensaje de exito
            this.$toastr.s("Los datos se procesaron con éxito");
            // actualziar tabla de clientes
            this.cerrar_create();
            this.getdatos();
            this.procensando_datos = false;
          })
          .catch((error) => {
            var data = error.response.data;
            this.error = true;
            this.loading = false;
            this.$toastr.e(data.message);
            this.procensando_datos = false;
          });
        // cerrrar modal
      } else {
        this.$toastr.i("Debe seleccionar el permiso que desea vincular");
      }
    },

    //  consultar el id de la cuenta a eliminar
    deleteItem(item) {
      this.select_delete = item.Rol;
      this.select_delete_id = item.Id;
      this.dialogDelete = true;
    },
    // confirmar la cuenta a eliminar
    deleteItemConfirm() {
      this.error = false;
      this.procensando_datos = true;
      UserRolService.quitar_rol_users(
        this.select_delete_id,
        this.user_sistema_id
      )
        .then((response) => {
          // mensaje de exito
          this.$toastr.s("La solicitud se proceso con éxito");
          // actualziar tabla de clientes
          this.getdatos();
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.procensando_datos = false));
      // cerrrar modal
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    limpiar_campos() {
      this.select = "";
    },
  },
};
</script>
<style scoped>
.trow {
  transition: transform 0.3s;
}

.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}

a:link {
  color: rgb(29 148 104 / 59%);
  background-color: transparent;
  text-decoration: none;
}
</style>

