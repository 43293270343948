import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Preferencia = {
  setPreference(payload) {
    return axios.post(PASARELA + "/administracion/preferencias", payload);
  },

  getLastPreference(params) {
    return axios.get(PASARELA + "/administracion/preferencias", { params });
  },

  deleteAllPreferences(params) {
    return axios.delete(PASARELA + "/administracion/preferencias", { params });
  },
};
