<template>
  <div class="wrapper">
    <div
      class="sidebar"
      data-color="purple"
      data-background-color="white"
      data-image=""
    >
      <div class="logo"></div>
      <div
        class="sidebar-wrapper ps-container ps-theme-default"
        data-ps-id="c9bd6856-2b56-3993-3eac-497ad2eabc62"
      >
        <form class="navbar-form">
          <span class="bmd-form-group"
            ><div class="input-group no-border">
              <input
                type="text"
                value=""
                class="form-control"
                placeholder="Buscar..."
              />
              <button
                type="submit"
                class="btn btn-white btn-round btn-just-icon"
              >
                <i class="material-icons">search</i>
                <div class="ripple-container"></div>
              </button></div
          ></span>
        </form>
        <Ayuda />
      </div>
      <div
        class="sidebar-background"
        style="background-image: url(/assets/img/fondo.jpg)"
      ></div>
    </div>
    <div
      class="main-panel ps-container ps-theme-default ps-active-y"
      data-ps-id="63c1fe79-4d9d-fdd2-3114-ed0f7a76544c"
    >
      <div data-app class="content">
        <GeneratePago />
      </div>

      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
    </div>
  </div>
</template>
<script>
import Ayuda from "@/views/Layouts/Ayuda.vue";
import GeneratePago from "../../components/Ayuda/GeneratePago.vue";
export default {
  name: "Pagos",
  components: {
    Ayuda,
    GeneratePago,
  },
};
</script>

