import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const CuentaComision = {
  //listar clientes 
  getCuentaComision(params) {
    return axios.get(PASARELA + "/administracion/cuentas-comision", { params })
  },

  // crear nueva cuenta comision
  nuevo_cuentacomision(payload) {
    return axios.post(PASARELA + "/administracion/cuenta-comision", payload)
  },

  // actualizar cuenta comision
  actualizar_cuentacomision(payload) {
    return axios.put(PASARELA + "/administracion/cuenta-comision", payload)
  },

  //consultar cuenta comision por ID
  getCuentaComisionID(params) {
    return axios.get(PASARELA + "/administracion/cuenta-comision", { params })
  },

  // delete cuenta comision
  delete_cuentacomision(params) {
    return axios.delete(PASARELA + "/administracion/cuenta-comision", { params })
  },

  getChannelsArancel(params){
    return axios.get(PASARELA + "/administracion/channels-arancel", {params})
  }
}
