import { TelCoCorreo } from "../../services/telcocorreo.js";
import { Refresh } from "../../services/refreshToken.js";

export default {
  namespaced: true,
  state: {
    telcocorreos: [],
    number: 1,
    size: 10,
    page: 1,
    total: 0,
    visible: 7,
    rowsPerPage: 100,
    errored: false,
    error: false,
    cargando: false,
    email: "",
    rendiciones: "",
    cobranzas: "",
  },
  mutations: {
    addTelCoCorreo(state, telcoCorreo) {
        state.telcocorreos.push(telcoCorreo);
      },

      actualizarCheck(state, checkActualizado) {
        // Buscar el índice del telcocorreo en el estado basado en su ID
        const indice = state.telcocorreos.findIndex(telcoCorreo => telcoCorreo.id === checkActualizado.id);
        
        // Si se encuentra el telcocorreo en el estado, actualizarlo de manera segura
        if (indice !== -1) {
          // Crear un nuevo objeto telcocorreo con los datos actualizados
          const telcocorreoActualizadoConID = {
            ...state.telcocorreos[indice],  // Copiar los datos del telcocorreo existente
            ...checkActualizado  // Aplicar los datos actualizados
          };
          
          // Crear un nuevo arreglo de telcocorreo con el telcocorreo actualizado
          const nuevosTelcoCorreo = [
            ...state.telcocorreos.slice(0, indice),  // Copiar los telcocorreo anteriores al telcocorreo actualizado
            telcocorreoActualizadoConID,  // Agregar el telcocorreo actualizado
            ...state.telcocorreos.slice(indice + 1)  // Copiar los telcocorreo posteriores al telcocorreo actualizado
          ];
      
          // Asignar el nuevo arreglo de telcocorreo al estado
          state.telcocorreos = nuevosTelcoCorreo;
        }
      },

    setTelCoCorreos(state, telcocorreos) {
      state.telcocorreos = telcocorreos;
    },
    setCargando(state, value) {
      state.cargando = value;
    },

    setEmail(state, value) {
      state.email = value;
    },
    setPaginate(state, value) {
        state.page = value;
      },
  
    setrowsPerPage(state, value) {
      state.rowsPerPage = value;
    },
    setErrord(state, value) {
      state.errored = value;
    },
    setTotal(state, value) {
      state.total = value;
    },
  },
  actions: {

    async getTelCoCorreos({ commit, state }) {
      try {
        const mail = {
          Email: state.email
        };
        commit("setCargando", true);
        const response = await TelCoCorreo.getCorreos(mail);
        if (response?.data?.data?.telco_correos == null) {
          commit("setTelCoCorreos", []);
          commit("setTotal", 0);
        } else {
          commit("setTelCoCorreos", response?.data?.data?.telco_correos);
          commit("setTotal", response.data.data.meta.page.lastPage);
        }
      } catch (error) {
        // console.error("Error al obtener los TelCo-Correos Store:", error);
        if (error.response && error.response.status === 403) {
          try {
            await Refresh.getRefreshToken();
            return await dispatch("getTelCoCorreos");
          } catch (refreshError) {
            // console.error("Error al obtener el token de refresco:", refreshError);
          }
        } else {
          commit("setErrord", true);
        }
      } finally {
        commit("setCargando", false);
      }
    },

    //Crear TelCo-Correo
    async createCorreoTelCo({ commit }, payload) {
        
          const response = await TelCoCorreo.createTelCoCorreo(payload);
          // console.log(response.data);
          commit("addTelCoCorreo", response?.data?.data?.telco_correos);
          return response;
        
      },

       //Editar TelCoCorreo
       async editarTelCoCorreos({ commit },  nuevosDatos ) {
        try {
          const response = await TelCoCorreo.putTelCoCorreo( nuevosDatos);
          // Llamar a una mutación para actualizar el estado del telcocorreo editado
          commit('actualizarCheck', response?.data); 
          return response;
        } catch (error) {
          // console.error("Error editando Check Store:", error);
          throw error;
        }
      },
  },
  getters: {
    telcocorreos: (state) => state.telcocorreos,
    pages: (state) => state.page,
  },
  modules: {},
};
