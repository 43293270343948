<template>
  <div :style="'background:' + bgNav" class="">
    <div class="nav-container ">
      <router-link to="/">
        <img
          src="/assets/img/wblue.png"
          width="100px"
          alt="Wee"
          class="logo-wee"
        />
      </router-link>

      <div class="links-container">
        <router-link class="btn-nav " text :style="'color: ' + textNav" to="/">
          Inicio
        </router-link>

        <router-link
          class="btn-nav"
          text
          :style="'color: ' + textNav"
          to="/sobre-nosotros"
        >
          Sobre nosotros
        </router-link>

        <router-link
          class="btn-nav"
          text
          :style="'color: ' + textNav"
          to="/ayuda"
        >
          Documentacion & ayuda
        </router-link>

        <router-link
          class="btn-nav elevation-0 text-capitalize"
          text
          :style="'color: ' + textNav"
          to="/desarrolladores"
        >
          Desarrolladores
        </router-link>

        <router-link class="btn-login" to="/login">
          Iniciar sesión
        </router-link>
      </div>
    </div>

    <div>
      <input type="checkbox" id="active" ref="checkbox" />
      <label for="active" class="menu-btn">
        <v-icon class="menu-icon">mdi-menu</v-icon>
      </label>
      <div class="wrapper">
        <ul>
          <li>
            <router-link to="/" @click.native="ocultarNav()"
              >Inicio</router-link
            >
          </li>
          <li>
            <router-link to="/sobre-nosotros" @click.native="ocultarNav()">
              Sobre nosotros
            </router-link>
          </li>
          <li>
            <router-link to="/ayuda" @click.native="ocultarNav()">
              Documentación
            </router-link>
          </li>
          <li>
            <router-link to="/desarrolladores" @click.native="ocultarNav()">
              Desarrolladores
            </router-link>
          </li>
          <li style="margin-top: 25px;" class="login-mobile">
            <router-link to="/login" @click.native="ocultarNav()">
              Iniciar sesión
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NewNav2",
  components: {},
  props: {
    bgNav: {
      default: "#01B1EC",
      type: String,
    },
    textNav: {
      default: "#fff",
      type: String,
    },
  },
  computed: {
    ...mapGetters(["getEnvironment"]),
  },

  methods: {
    ocultarNav() {
      const checkbox = this.$refs.checkbox;

      // Cambiar el estado del checkbox
      checkbox.checked = !checkbox.checked;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap");
</style>
<style scoped>
/* Coloco este estilo o media query porque al poner nombres mas largo o mas items en el navbar, se quiebra las lineas de las palabras en el menu */
/* @media (min-width: 960px) {
    .container {
      max-width: 1000px !important;
    }
  } */
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  position: relative !important;
  z-index: 1000 !important;

  max-width: 1700px;
  margin: auto;
}

.links-container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.btn-nav {
  cursor: pointer !important;
  font-size: 20px;
  border-bottom: 4px solid transparent;
  padding: 0 10px;
}

.btn-login {
  background: #c2d501 !important;
  font-weight: bold;
  font-size: 20px;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;

  box-shadow: 0px 4px 4px 0px #00000040;
  margin-left: 10px;

  transition: 0.3s all;
}

.btn-nav:hover {
  border-bottom: 5px solid;
  border-image: linear-gradient(90deg, #c2d501 0%, #1db6c8 50%, #c2d501 100%);
  border-image-slice: 1;
  border-radius: 5px;
}

.btn-login:hover {
  background: #baca0b !important;
  color: #000;
  box-shadow: 10px 10px 10px 0px #00000030;
  transform: scale(1.02);
}

.wrapper {
  visibility: hidden;
}

.menu-btn {
  visibility: hidden;
}

@media (max-width: 1550px) {
  .btn-nav,
  .btn-login {
    font-size: 16.5px;
  }

  .links-container {
    gap: 10px;
  }

  .nav-container {
    margin-left: 2%;
    margin-right: 5%;
    max-width: 100%;
  }
}

@media (max-width: 1250px) {
  .logo-wee {
    transform: scale(0.8);
  }

  .nav-container {
    padding: 0;

    transform: translate(0, -5px);
  }
}

@media (max-width: 1024px) {
  .links-container {
    visibility: hidden;
  }

  .nav-container {
    margin: 0;
  }

  .wrapper {
    visibility: visible;
  }

  .menu-btn {
    visibility: visible;
  }

  .logo-wee {
    min-width: 100px;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  background: linear-gradient(-135deg, #05a0d3, #05a0d3);

  clip-path: circle(25px at calc(100% - 45px) 45px);
  transition: all 0.3s ease-in-out;
  z-index: 10000000;
}
#active:checked ~ .wrapper {
  clip-path: circle(75%);
}
.menu-btn {
  position: fixed;
  z-index: 20000000;
  right: 20px;
  top: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(-135deg, #c2d501, #c2d501);
  transition: all 0.3s ease-in-out;
  border: 1px solid #9eac08;
}

.menu-icon {
  color: #fff;
}

#active:checked ~ .menu-btn {
  background: #c2d501;
}

#active:checked ~ .menu-icon {
  color: #000 !important;
}

.wrapper ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}
.wrapper ul li {
  margin: 10px 0;
}
.wrapper ul li a {
  color: none;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  padding: 5px 20px;
  color: #fff;
  position: relative;
  line-height: 40px;
  transition: all 0.3s ease;
}
.wrapper ul li a:after {
  position: absolute;
  content: "";
  background: #c2d501;
  width: 100%;
  height: 40px;
  left: 0;
  border-radius: 10px;

  transform: scaleY(0);
  z-index: -1;
  transition: transform 0.3s ease;
}
.wrapper ul li a:hover:after {
  transform: scaleY(1);
}

.wrapper ul li a:hover {
  color: #000;
}

input[type="checkbox"] {
  display: none;
}

.login-mobile {
  background: #c2d501;
  padding: 5px 10px;
  border-radius: 10px;
}

.login-mobile > a {
  font-weight: bold !important;
  font-size: 18px !important;
  color: #000 !important;
}
</style>
