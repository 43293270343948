<template>
  <div data-app class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto">
          <div class="profile-tabs">
            <ul
              class="nav nav-pills nav-pills-icons justify-content-center"
              role="tablist"
            >
              <!-- <li class="nav-item">
                <a
                  class="nav-link active show"
                  href="#works"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="true"
                >
                  <i class="material-icons">request_quote</i>
                  Todas sus transferencias
                </a>
              </li> -->
              <li
                class="nav-item"
                v-if="checkPermiso('psp.cliente.transferencia.o')"
              >
                <a
                  class="nav-link show"
                  href="#studio"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                >
                  <i class="material-icons">label_important</i>
                  Nueva transferencia
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- COMPONENTES (COMPONENTS/Transferencias) -->
      <div class="tab-content tab-space">
        <div class="tab-pane text-center active gallery" id="works">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <AllTransfers />
            </div>
          </div>
        </div>

        <div class="tab-pane text-center gallery show" id="studio">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <NuevaTransferencia />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AllTransfers from "@/components/Transferencia/AllTransfers.vue";
import NuevaTransferencia from "@/components/Transferencia/NuevaTransferencia.vue";

import { mapGetters } from "vuex";

export default {
  name: "Transferencias",
  components: {
    AllTransfers,
    NuevaTransferencia,
  },

  computed: {
    ...mapGetters(["permisos", "getCurrentClientName"]),
  },

  methods: {
    checkPermiso(permiso) {
      return this.permisos.some((p) => p === permiso);
    },
  },
};
</script>
<style scoped>
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #66bb6a;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(156 39 176 / 60%);
}
</style>
