import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToastr from "vue-toastr";
/* moment */
import moment from "moment";
import "moment/locale/es";
/* Vuetify */
import vuetify from "./plugins/vuetify";
import "./sass/main.scss";
import loader from "vue-ui-preloader";
import JsonExcel from "vue-json-excel";
/* Ace Editor */
import "brace/ext/language_tools";
import "brace/mode/json";
import "brace/mode/javascript";
import "brace/mode/java";
import "brace/mode/csharp";
import "brace/mode/golang";
import "brace/theme/chrome";
import "brace/snippets/javascript";
import Vidle from "v-idle";
import animations from "@midudev/tailwind-animations";
import VueMask from "v-mask";
import VueNativeSock from "vue-native-websocket";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

import VueSweetalert2 from "vue-sweetalert2";

const options = {
  confirmButtonColor: "#050505",
};

import "sweetalert2/dist/sweetalert2.min.css";
// If you don't need the styles, do not connect

Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueToastr);
Vue.prototype.moment = moment;
Vue.use(moment);
Vue.use(VueSweetalert2, options);
Vue.use(loader);
Vue.use(Vidle);
Vue.use(VueMask);
Vue.use(autoAnimatePlugin);

Vue.use(VueNativeSock, process.env.VUE_APP_URLWEBSOCKET, {
  reconnection: true, // (opcional) activa la reconexión automática
  reconnectionAttempts: 10, // (opcional) número máximo de intentos de reconexión
  reconnectionDelay: 10000, // (opcional) tiempo entre intentos de reconexión en milisegundos
  connectManually: true,
});

window.tailwind.config = {
  theme: {
    extend: {
      colors: {
        clifford: "#da373d",
      },
    },
  },
  plugins: [animations],
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
