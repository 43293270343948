<template>
  <div>
    <v-divider id="obtener-solicitud"></v-divider>
    <h3 class="mb-2">Consultar Estado de Pago</h3>
    <p>Para realizar la consulta de estado un pago o varios pagos.</p>
    <!--------------------- COMENTO SOLICITUD DE TOKEN ------------------- -->
    <!-- <v-divider></v-divider> -->

    <!--     <h4 class="bold mb-2" id="crear-solicitud-pago">
      <span class="metodo-post">POST</span>Solicitar Token
    </h4>
    <p class="pb-2">Utilizando el servicio de login debe solicitar un token.</p> -->
    <!--URL de la solicitud-->
    <!--     <div class="url-container">
      <p
        class="
      d-flex
        align-items-center
        justify-content-center
        mb-0
      "
      >
        https://sb-login.wee.ar/users/login
      </p>
    </div>
    <h4 class="bold my-4">REQUEST</h4>
    <h4 class="my-4">Body</h4>
    <v-simple-table fixed-header height="190px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in bodyToken" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- --------------------BLOQUE CODIGO EJEMPLO: SOLICITUD TOKEN --------------------- -->
    <!--     <div>
      <h6 class="bold mt-4">Example Request</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
      {
        "username": "reclamosap@dpec.com.ar",
        "password": "password123",
        "sistema_id": "10"
      }
    </code>
  </pre>
      </div>
    </div> -->
    <h4 class="bold my-4">REQUEST</h4>

    <!-- -------------HEADERS--------------- -->
    <h4 class="my-4">Headers</h4>
    <v-simple-table fixed-header height="150px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              PATTERN
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in postHeaders" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>
              <code
                style="background-color: #f5f5f5; padding: 2px 4px; border-radius: 4px; color: #000"
                >{{ item.pattern }}
              </code>
            </td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- -------------BODY--------------- -->
    <h4 class="my-4">Body</h4>
    <v-simple-table fixed-header height="290px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in bodyRequest" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- OPCIONES EN EL ENVIO DEL BODY -->
    <p class="pt-4">
      <span class="bold">Opciones en envío del objeto JSON</span>
    </p>
    <p class="mb-4">
      Cuando envía el objeto JSON puede:
    </p>
    <div style="font-size: 14.5px;">
      <ul class="lista">
        <li class="items-lista">
          Enviar solamente el "<span class="bold">uuid</span>" o el "<span
            class="bold"
            >external_reference</span
          >" si desea consultar por el estado de un pago.
        </li>
        <li class="items-lista">
          Enviar un rango de fecha ("<span class="bold">fecha_desde</span>",
          "<span class="bold">fecha_hasta</span>") si lo que desea es obtener el
          estado de los pagos realizados en ese rango de fecha. El rango de
          fecha no debe superar la cantidad de 7 días.
        </li>
        <li class="items-lista">
          Enviar un array de "<span class="bold">uuids</span>" si desea
          consultar por el estado de varios pagos.
        </li>
      </ul>
    </div>
    <!-- ----------------RESPUESTA------------------ -->
    <h4 class="bold mt-4 mb-2">RESPONSE</h4>
    <p class="mb-4">Atributos del objeto de la respuesta.</p>
    <v-simple-table fixed-header height="240px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in responseEstado" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response----array de objetos DATA -->
    <p class="pt-4">Atributos del objeto <span class="italic">data</span>.</p>
    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objDataEstado" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- array de objetos pago_intento -->
    <p class="pt-4">
      Atributos del objeto <span class="italic">pago_Intento</span>.
    </p>
    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objPagoIntento" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- POST CONSULTAR ESTADO DEL PAGO ------- -->
    <v-divider></v-divider>
    <h4 class="bold mb-4" id="crear-solicitud-pago">
      <span class="metodo-post">POST</span>Consultar Estado del Pago
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p
        class="
      d-flex
        align-items-center
        justify-content-center
        mb-0
      "
      >
        https://sb-wee.ar/v1/estados-pagos
      </p>
    </div>
    <!-- Headers -->
    <h4 class="bold mt-4">HEADERS</h4>
    <v-divider></v-divider>
    <div>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Content-Type</p>
        </v-col>
        <v-col cols="6">
          <p>application/json</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">apiKey</p>
        </v-col>
        <v-col cols="6">
          <p>
            <span class="bold">aa7943e1-a60c-99e7-8812-xxxxxxxxxxxx</span>
            (mismo apiKey utilizado para la solicitud de pago).
          </p>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: REQUEST uuids --------------------- -->
    <div>
      <h6 class="mt-4">
        <span class="bold">Example Request:</span> consultando por uuid
      </h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
    {
      "uuid": "4fc0a8ee-6b85-482d-98a8-f267e5766bec"
    }
    </code>
  </pre>
      </div>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: REQUEST uuid--------------------- -->
    <div>
      <h6 class="mt-4">
        <span class="bold">Example Request:</span> consultando por Array de
        uuids
      </h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
    {
      "uuid": ["b744718c-e46c-4838-8fcb-cc99057013af", "b6c50fad-7881-43ef-af5e-f829e551e8b3", "a972d1d8-8c1f-46d4-a254-5911123584c5"]
    }
    </code>
  </pre>
      </div>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: RESPONSE--------------------- -->
    <div>
      <h6 class=" bold mt-4">Example response</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
{
  "data": [
    {
      "solicitudpago_id": 1031,
      "uuid": "a972d1d8-8c1f-46d4-a254-5911123584c5",
      "payment_type": "INTERNET-WIFI",
      "pago_estado": "EXPIRED",
      "estado": "CADUCADO",
      "created_at": "2023-02-23 13:42:50 +0000 UTC",
      "external_reference": "2124",
      "payer_name": "VELAZCO IVAN",
      "payer_email": "velazcoivan2089@gmail.com",
      "description": "SERVICIOS DE INTERNET",
      "first_due_date": "2023-02-23 00:00:00 +0000 UTC",
      "first_total": 5000,
      "pago_Intento": [
          {
            "pagointento_id": 619,
            "channel": "OFFLINE",
            "paid_at": "2023-02-23 13:43:18 +0000 UTC",
            "is_available": false,
            "amount": 5000,
            "GrossFee": 0,
            "net_fee": 0,
            "fee_iva": 0,
            "net_amount": 0
          }
        ]
      },
      {
        "solicitudpago_id": 1023,
        "uuid": "b6c50fad-7881-43ef-af5e-f829e551e8b3",
        "payment_type": "INTERNET-WIFI",
        "pago_estado": "ACCREDITED",
        "estado": "AUTORIZADO",
        "created_at": "2023-02-22 20:30:10 +0000 UTC",
        "external_reference": "2039",
        "payer_name": "LARRACET ERNESTO",
        "payer_email": "sin@correo.com",
        "description": "SERVICIOS DE INTERNET",
        "first_due_date": "2023-02-22 00:00:00 +0000 UTC",
        "first_total": 5000,
        "pago_Intento": [
            {           
              "pagointento_id": 616,
              "channel": "CREDIT",
              "paid_at": "2023-02-22 17:37:00 +0000 UTC",
              "is_available": true,
              "amount": 5000,
              "GrossFee": 96.8,
              "net_fee": 80,
              "fee_iva": 16.8,
              "net_amount": 4903.2
            }
          ]
        },
        {
          "solicitudpago_id": 961,
          "uuid": "b744718c-e46c-4838-8fcb-cc99057013af",
          "payment_type": "INTERNET-WIFI",
          "pago_estado": "ACCREDITED",
          "estado": "AUTORIZADO",
          "created_at": "2023-02-15 15:31:43 +0000 UTC",
          "external_reference": ""
          ,
          "payer_name": "CONSUMIDOR FINAL",
          "payer_email": ""
          ,
          "description": "SERVICIOS DE INTERNET",
          "first_due_date": "2023-02-15 00:00:00 +0000 UTC",
          "first_total": 5000,
          "pago_Intento": [
              {
                "pagointento_id": 592,
                "channel": "CREDIT",
                "paid_at": "2023-02-15 12:36:00 +0000 UTC",
                "is_available": true,
                "amount": 5000,
                "GrossFee": 96.8,
                "net_fee": 80,
                "fee_iva": 16.8,
                "net_amount": 4903.2
              }
            ]
          }
  ],
  "message": "datos enviados",
  "status": true,
  "total": 3
}
    </code>
  </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsultarEstadoPago",

  data() {
    return {
      bodyToken: [
        {
          atributo: "username",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Nombre del usuario que se configuró cuando se dio de alta del usuario.",
        },
        {
          atributo: "password",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Contraseña que se configuró cuando se dio de alta del usuario.",
        },
        {
          atributo: "sistema_id",
          tipo: "String",
          requerido: "SI",
          descripcion: "Identificador del sistema.",
        },
      ],
      postHeaders: [
        {
          atributo: "apiKey",
          tipo: "String",
          requerido: "SI",
          pattern: "API Key",
          descripcion: "aa7943e1-a60c-99e7-8812-xxxxxxxxxxxx",
        },
        {
          atributo: "Content-Type",
          tipo: "String",
          requerido: "SI",
          pattern: "application/json",
          descripcion:
            "El tipo MIME del contenido del cuerpo enviado. Por defecto JSON es Unicode UTF-8. No se debe configurar otro charset.",
        },
      ],
      bodyRequest: [
        {
          atributo: "uuid",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Es un valor recibido en la respuesta del pago realizado.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Es un valor enviado por el cliente al solicitar el pago.",
        },
        {
          atributo: "fecha_desde",
          tipo: "String",
          requerido: "SI",
          descripcion: "DD-MM-AAAA",
        },
        {
          atributo: "fecha_hasta",
          tipo: "String",
          requerido: "SI",
          descripcion: "DD-MM-AAAA",
        },
        {
          atributo: "uuids",
          tipo: "Array [String]",
          requerido: "SI",
          descripcion: "Identificador de la operación.",
        },
      ],
      responseEstado: [
        {
          atributo: "data",
          tipo: "Array [Object]",
          descripcion: "Arreglo de objetos con los datos de la consulta.",
        },
        {
          atributo: "message",
          tipo: "Object",
          descripcion: "Mensaje de la consulta.",
        },
        {
          atributo: "status",
          tipo: "Boolean",
          descripcion: "Estado de la consulta.",
        },
        {
          atributo: "total",
          tipo: "Integer",
          descripcion: "Total de registros de la consulta.",
        },
      ],
      objDataEstado: [
        {
          atributo: "solicitudpago_id",
          tipo: "Integer",
          descripcion: "Identificador único de la solicitud de pago.",
        },
        {
          atributo: "uuid",
          tipo: "String",
          descripcion: "Identificador único de pago.",
        },
        {
          atributo: "payment_type",
          tipo: "String",
          descripcion: "Tipo de pago.",
        },
        {
          atributo: "pago_estado",
          tipo: "String",
          descripcion: "Estado del pago.",
        },
        {
          atributo: "estado",
          tipo: "String",
          descripcion: "Estado de la solicitud de pago.",
        },
        {
          atributo: "created_at",
          tipo: "DateTime",
          descripcion: "Fecha de creación de la solicitud de pago.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          descripcion:
            "Este atributo es utilizado como referencia para identificar la solicitud y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "payer_name",
          tipo: "String",
          descripcion: "Nombre del pagador.",
        },
        {
          atributo: "payer_email",
          tipo: "String",
          descripcion: "Correo electrónico del pagador.",
        },
        {
          atributo: "description",
          tipo: "String",
          descripcion: "Descripción del estado de pago.",
        },
        {
          atributo: "first_due_date",
          tipo: "DateTime",
          descripcion: "Fecha de 1º Vencimiento. Formato dd/mm/aaaa.",
        },
        {
          atributo: "first_total",
          tipo: "Integer",
          descripcion: "Monto total antes de vencimiento.",
        },
        {
          atributo: "pago_Intento",
          tipo: "Array [Object]",
          descripcion: "Intentos de pago.",
        },
      ],
      objPagoIntento: [
        {
          atributo: "pagointento_id",
          tipo: "Integer",
          descripcion: "Identificador único del intento de pago.",
        },
        {
          atributo: "channel",
          tipo: "String",
          descripcion: "Canal de pago.",
        },
        {
          atributo: "paid_at",
          tipo: "DateTime",
          descripcion: "Fecha de pago.",
        },
        {
          atributo: "is_available",
          tipo: "Boolean",
          descripcion: "Disponibilidad del pago.",
        },
        {
          atributo: "amount",
          tipo: "Integer",
          descripcion: "Monto total.",
        },
        {
          atributo: "GrossFee",
          tipo: "Float",
          descripcion:
            "Importe total correspondiente a la tarifa de procesamiento.",
        },
        {
          atributo: "net_fee",
          tipo: "Float",
          descripcion: "Comisión neta.",
        },
        {
          atributo: "fee_iva",
          tipo: "Float",
          descripcion: "Iva de la comisión.",
        },
        {
          atributo: "net_amount",
          tipo: "Float",
          descripcion: "Monto neto.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.metodo-get {
  color: rgb(0, 127, 49);
}
.metodo-post {
  color: rgb(173, 122, 3);
  font-size: 20px;
  margin-right: 10px;
}
.url-container {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  height: 40px;
  display: flex;
  padding-left: 10px;
}
.code-block {
  font-size: 14px;
}
.lista {
  margin-left: 40px;
}
.items-lista {
  margin-bottom: 30px;
}
</style>
