<template>
  <v-app>
    <v-main>
      <!-- cuentas del usuario -->

      <v-dialog v-model="dialog_cuentas_clientes" scrollable max-width="300px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="secondary">
            CUENTA: {{ getAccountNameById }}
            <v-icon color="purple lighten-5">south</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h6 justify-center"
            >Cambiar de cuenta</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text style="height: 200px">
            <v-radio-group
              v-model="cuenta_id_actual"
              column
              v-for="dat in $store.state.cuenta_usuario"
              v-bind:key="dat.id"
            >
              <v-radio :label="dat.cuenta" :value="dat.id"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn
              color="blue darken-1"
              text
              @click="dialog_cuentas_clientes = false"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmar_cambio_cuenta()"
            >
              Ingresar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChangeClienteCuenta",
  data() {
    return {
      cuenta_id_actual: "", // es el id de la cuenta del cliente que se tiene actualmente el en Store
      dialog_cuentas_clientes: false,
    };
  },

  mounted() {
    this.cuenta_id_actual = this.getCurrentAccountId;
  },

  watch: {
    getCurrentAccountId(cuentaActual) {
      this.cuenta_id_actual = cuentaActual;
    },
  },
  methods: {
    // mapeo de actions de los modulos store para obtener los datos segun el cambio de cuenta del cliente
    ...mapActions("saldo", ["getSaldo"]),
    ...mapActions("movimientos", ["getMovimientos"]),
    ...mapActions("pagos", ["getPagos"]),
    ...mapActions("transferencias", [
      "getDatosTransferencias",
      "getAllTransferencias",
    ]),
    confirmar_cambio_cuenta() {
      if (this.cuenta_id_actual != "") {
        // datos del usuario
        this.$store.commit("setCuenta", this.cuenta_id_actual);
        this.$store.dispatch("getAccountChange");
        // this.getSaldo(); // recargar saldo
        // this.getMovimientos(); // recargar movimientos
        // this.getPagos(); // recargar pagos
        // this.getDatosTransferencias(); // recargar transferencias componente principal
        // this.getAllTransferencias(); // recargar transferencias listado para mostrar todas
        this.dialog_cuentas_clientes = false;
      } else {
        this.$toastr.i(
          this.$store.state.email + ", debe seleccionar una cuenta"
        );
      }
    },
  },
  computed: {
    ...mapGetters(["getAccountNameById", "getCurrentAccountId"]),
  },
};
</script>
<style scoped>
.v-application .pink--text {
  color: #76c767 !important;
  caret-color: #824a5d !important;
}

.theme--light.v-application {
  background: rgb(204 204 204 / 4%);
  color: rgba(0, 0, 0, 0.87);
}
</style>
