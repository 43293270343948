<template>
  <!-- Foooter de el inicio -->
  <footer>
    <!-- Info de empresas que usan -->
    <v-row class="pb-5">
      <v-col>
        <v-row cols="12"
          ><h2 class="titulo_landing1">
            Empresas, Organismos y Municipios que lo están usando:
          </h2></v-row
        >
        <v-row><img src="/assets/img/marcas.png" class="img-fluid width-100"/></v-row>
      </v-col>
    </v-row>

    <!-- Grupos -->
    <v-row cols="12">
      <v-row></v-row>
      <v-row>
        <img src="/assets/img/grupos.png" class="img-fluid width-100" />
      </v-row>
    </v-row>

    <div class="pt-5 pb-5">
      <img src="/assets/img/masinfo.png" style="max-width:50%">
    </div>
    <v-row class="pb-5">
      <!-- Primera Columna -->
      <v-col cols="12" sm="6" md="3">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon color="blue">mdi-map-marker-circle</v-icon>
          </v-col>
          <v-col>
            <h5 class="text-left">Dr. R. Carrillo</h5>
          </v-col>
        </v-row>
      </v-col>
      <!-- Segunda Columna -->
      <v-col cols="12" sm="6" md="3">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon color="blue">mdi-phone</v-icon>
          </v-col>
          <v-col>
            <h5 class="text-left">0800-555-8352</h5>
          </v-col>
        </v-row>
      </v-col>
      <!-- Tercera Columna -->
      <v-col cols="12" sm="6" md="3">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon color="blue">mdi-email-outline</v-icon>
          </v-col>
          <v-col>
            <h5 class="text-left">info@telco.com.ar</h5>
            <h5 class="text-left">comercial@telco.com.ar</h5>
          </v-col>
        </v-row>
      </v-col>
      <!-- Cuarta Columna -->
      <v-col cols="12" sm="6" md="3">
        <v-row align="center">
          <v-col cols="auto">
            <v-icon color="blue">mdi-earth</v-icon>
          </v-col>
          <v-col>
            <h5 class="text-left">telco.com.ar</h5>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </footer>
</template>
<script>
export default {
  name: "Footer2",
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap");
</style>
<style scoped>
footer {
  font-family: "Montserrat", sans-serif;
  color: black;
  background-color: white;
  padding: 2%;
}
h2 {
  font-weight: 900;
  font-size: 1.5em;
}
@media (max-width: 420px) {
  h2 {
    font-size: larger;
  }
  h4 {
    font-size: small;
  }
  a {
    font-size: small;
  }
}
</style>
