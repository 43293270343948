import axios from 'axios';
import './axiosInterceptor';

export const AUTHENTICATION = process.env.VUE_APP_URLAUTHENTICATION;

export const Roles = {
  //Obtener roles de servicio de autenticacion
  getRoles(params) {
    return axios.get(AUTHENTICATION + "/adm/roles", { params })
  },

  // crear nuevo rol
  nuevo_rol(payload) {
    return axios.post(AUTHENTICATION + "/adm/rol", payload)
  },

  // actualizar rol
  actualizar_rol(payload) {
    return axios.put(AUTHENTICATION + "/adm/rol", payload)
  },
  // delete rol
  delete_rol(params) {
    return axios.delete(AUTHENTICATION + "/adm/rol", { params })
  },

  //consultar rol por ID
  getRolPermisoID(params) {
    return axios.get(AUTHENTICATION + "/adm/rol", { params })
  },

  // consultar los roles del usuario
  //consultar rol por ID
  //  getRolUserID(params) {
  //     return  axios.get("http://127.0.0.1:3393/adm/roles", {params})
  // },

}
