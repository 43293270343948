<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="text-center title">Generar pago</h2>

            <!-- efecto cargando peticion -->
            <div v-if="loading">
              <loader
                object="#4caf50"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="40"
                disableScrolling="false"
                name="box"
              ></loader>
            </div>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payer_name"
                      label="payer_name"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="description"
                      label="description"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- <v-row>
                            <v-col
                            cols="12"
                            md="12"
                            >
                            <v-text-field
                                v-model="first_total"
                                label="first_total"
                                required
                            ></v-text-field>
                            </v-col>
                        </v-row> -->

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="first_due_date"
                      label="first_due_date"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="external_reference"
                      label="external_reference"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <br />

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="second_due_date"
                      label="second_due_date"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      :value="$store.state.api_key_prueba"
                      label="APi Key"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field
                      v-model="value_api"
                      label="cambiar api key"
                      required
                    >
                      <template v-slot:append-outer>
                        <v-menu style="top: 12px" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              @click="cambiar_api_key(value_api)"
                            >
                              Cambiar api key
                            </v-btn>
                          </template>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payer_email"
                      label="payer_email"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="payment_type"
                      label="payment_type"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-row align="center">
                    <v-col cols="12" sm="12"> </v-col>

                    <v-col cols="12" sm="12">
                      <v-select
                        v-model="e7"
                        item-text="description"
                        :item-value="'amount'"
                        :items="states"
                        label="Seleccionar"
                        multiple
                        chips
                        persistent-hint
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-row>
              </v-container>
              <br />
              <v-row>
                <v-col cols="12" md="12" align="center">
                  <v-btn
                    :loading="dialog"
                    color="success"
                    class="gradient-button gradient-button-1"
                    @click="enviar_solicitud()"
                  >
                    Generar pago
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Prueba } from "../../services/pruebapago.js";
import moment from "moment";
import qrcode from "qrcode.vue";

export default {
  name: "NewPago",

  data: () => ({
    redirect: "",
    dialog: false,
    loading: false,
    error: false,
    errored: false,
    //   respuesta validacion campos}
    value_api: "",
    res: false,
    valid: true,
    //   v-model campos
    payer_name: "Jose Lopez",
    description: "Pago de productos varios",
    //   primer valor calculado
    first_total: 0,
    first_due_date: moment().add(1, "days").format("DD-MM-YYYY"),
    second_due_date: moment().add(11, "days").format("DD-MM-YYYY"),
    external_reference: "15716",
    //   segundo valor calculado
    second_total: 0,
    payer_email: "jose.lopez@telco.com.ar",
    payment_type: "PAGOSTELCO",
    items: [],
    e7: [],
    states: [
      {
        quantity: 1,
        description: "10,00",
        amount: 1000,
      },
      {
        quantity: 1,
        description: "20,00",
        amount: 2000,
      },
      {
        quantity: 1,
        description: "30,00",
        amount: 3000,
      },
      {
        quantity: 1,
        description: "50,00",
        amount: 5000,
      },
      {
        quantity: 1,
        description: "1.000,00",
        amount: 100000,
      },
      {
        quantity: 1,
        description: "3.000,00",
        amount: 300000,
      },
      {
        quantity: 1,
        description: "4.000,00",
        amount: 400000,
      },
      {
        quantity: 1,
        description: "5.000,00",
        amount: 500000,
      },
      {
        quantity: 1,
        description: "500,00",
        amount: 50000,
      },
      {
        quantity: 1,
        description: "200,00",
        amount: 20000,
      },
      {
        quantity: 1,
        description: "150,00",
        amount: 15000,
      },
      {
        quantity: 1,
        description: "55,00",
        amount: 5500,
      },
      {
        quantity: 1,
        description: "35,00",
        amount: 3500,
      },
      {
        quantity: 1,
        description: "10.000,00",
        amount: 1000000,
      },
    ],
  }),
  methods: {
    cambiar_api_key(value) {
      this.$store.commit("setApiKey", value);
    },

    async enviar_solicitud() {
      var items = [];
      var total = 0;
      this.e7.forEach(function (elemento, indice) {
        items.push({
          quantity: 1,
          description: "audio" + indice,
          amount: elemento,
        });
        total = total + elemento;
      });
      this.first_total = total;
      this.second_total = total + 10000;

      this.toPesos(this.first_total);
      var payload = {
        payer_name: this.payer_name,
        description: this.description,
        first_total: this.first_total,
        first_due_date: this.first_due_date,
        external_reference: this.external_reference,
        second_due_date: this.second_due_date,
        second_total: this.second_total,
        payer_email: this.payer_email,
        payment_type: this.payment_type,
        items: items,
      };

      await Prueba.enviar_pago(payload)
        .then((response) => {
          this.$toastr.s(response.data.message);
          this.redirect = response.data.data.checkout_url;
          window.location.href = this.redirect;
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },
    toPesos(num) {
      return Number(num).toLocaleString("es-ar", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2,
      });
    },

    convertFecha(num) {
      return Number(num).toLocaleString("es-ar", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
<style scoped>
.v-text-field >>> .v-input__slot::before {
  background: linear-gradient(to right, #00dbe9, #8c03e5);
  padding: 1px;
}
/* Efectos css para el born de enviar solicitud */
.gradient-button {
  margin: 10px;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size: 15px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 20px #eee;
  width: 200px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
}
.gradient-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 8px 10px 12px;
}
.gradient-button-1 {
  background-image: linear-gradient(
    to right,
    #00dbe9 0%,
    #8c03e5 51%,
    #00dbe9 100%
  );
}
.gradient-button-1:hover {
  background-position: right center;
}
</style>
