<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-profile">
          <Avatar />
          <div class="card-body">
            <!-- <h3 class="card-category text-gray">TELCO PAGOS DOC</h3> -->
            <h4 class="card-title">Sobre WEE!</h4>
            <br /><br />
            <p class="card-description" align="left">
              wee.ar permite aceptar y realizar pagos digitales de forma segura,
              rápida y fácil. <br /><br />
              Nuestra plataforma de pago está diseñada para facilitar
              transacciones con los principales medios de pago.<br />
              <br />
              Nuestros esfuerzos se centran en mejorar la experiencia de pago
              mediante la entrega de un conjunto de soluciones tecnológicas
              innovadoras que responden a los crecientes requerimientos de
              seguridad y cumplimiento de estándares de la industria.
            </p>

            <h3 align="left"><strong>Contacto</strong><br /></h3>
            <p align="left">Dirección: Dr. Ramón Carrillo N° 444, Corrientes</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "./Avatar.vue";
export default {
  name: "Institucional",
  components: { Avatar },
  data() {
    return {
      content: "<h1>Contenido</h1>",
    };
  },
};
</script>
<style scoped>
.card .card-title {
  text-align: center;
}
</style>
