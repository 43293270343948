<template>
  <div>
    <div style="background-color: white;">
      <!-- CONTAINER DISEÑO DE CARD FORM DE RESTABLECIMIENTO DE CONTRASEÑA -->
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xl-4 col-md-6 ml-auto mr-auto">
            <div class="separador_laptop">
              <div class="pl-5 pr-5" style="color: #C2D501;">
                <h1><strong>RESTABLECER CONTRASEÑA</strong></h1>
              </div>
              <div class="card card-profile">
                <div class="card-body">
                  <div class="card card-login">
                    <form class="form" @submit.prevent="RestablecerPass">
                      <p style="font-weight: 400; font-size: 20px">
                        ¡Ingrese su nueva contraseña!
                      </p>
                      <br />
                      <div>
                        <span class="bmd-form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="material-icons">lock_outline</i>
                              </span>
                            </div>
                            <v-text-field
                              :type="show ? 'text' : 'password'"
                              v-model="password"
                              placeholder="Contraseña..."
                              :rules="passwordRules"
                              :append-inner-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                              @click:append-inner="show = !show"
                            />
                          </div>
                        </span>

                        <span class="bmd-form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="material-icons">lock_outline</i>
                              </span>
                            </div>
                            <v-text-field
                              :type="show1 ? 'text' : 'password'"
                              v-model="confirmar_password"
                              placeholder="Confirmar contraseña..."
                              :rules="confirmPasswordRules"
                              :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                              @click:append-inner="show1 = !show1"
                            />
                          </div>
                        </span>
                      </div>


                      <br />
                      <button class="btn btn-round gradient_color">
                        GUARDAR CAMBIOS
                      </button>

                      <!-- mostrar mensaje de error de api autentication service -->
                      <div v-if="error" class="alert alert-danger">
                        <div class="container">
                          <div class="alert-icon">
                            <i class="material-icons">error_outline</i>
                          </div>
                          <b v-html="msg"></b>
                        </div>
                      </div>
                      <!-- mostrar mensaje de error de api autentication service -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ZONA DE MODAL ELEGIR CUENTA y OTROS -->
      <v-app>
        <v-main>
          <v-container fluid>
            <!-- DIALOG DE PROCESAMIENTO -->
            <div class="text-center">
              <v-dialog v-model="dialog1" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text>
                    Procesando solicitud...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-container>
        </v-main>
      </v-app>
    </div>
    <Footer />
  </div>
</template>
<script>
import { Login } from "../services/login.js";
import Footer from "./Layouts/Footer.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Restore",
  components: { Footer },
  data() {
    return {
      dialog1: false, // loading verificando datos del usuario
      dialog2: false,

      //  cuentas del usuario: el usuario debera seleccionar una cuenta de usuario
      dialogm1: "",
      dialog: false,

      password: "",
      confirmar_password: "",
      codigo: "",
      sistema_id: this.$store.state.SISTEMA_ID,

      //variable error en false oculta
      error: false,
      msg: "",

      // mostrar/ocultar valores del text
      show: false,
      show1: false,
      passwordRules: [
        v => !!v || 'La contraseña es requerida.',
        v => v.length >= 8 || 'Debe tener al menos 8 caracteres.',
        v => /[A-Z]/.test(v) || 'Debe contener al menos una letra mayúscula.',
        v => /[a-z]/.test(v) || 'Debe contener al menos una letra minúscula.',
        v => /[0-9]/.test(v) || 'Debe contener al menos un número.',
        v => /[!@#$%^&*(),.?":{}|<>]/.test(v) || 'Debe contener al menos un carácter especial.',
      ],
      confirmPasswordRules: [
        v => !!v || 'La confirmación es requerida.',
        v => v === this.password || 'Las contraseñas no coinciden.',
      ],
    };
  },
  created() {
    // aqui se toma desde la url el codigo asociado al usuario para restablecer la contraseña
    this.codigo = this.$route.params.codigo;
  },
  methods: {
    async RestablecerPass() {
      var payload = {
        Codigo: this.codigo,
        Password: this.confirmar_password,
        SistemaId: this.sistema_id,
      };
      // (se coloca en falso la variable error al enviar una nueva peticion)
      this.error = false;

      if (this.checkform()) {
        this.dialog1 = true;
        await Login.restoreuser(payload)
          .then((response) => {
            if (response.data == true) {
              this.dialog1 = false;
              this.$toastr.s(
                "Contraseña cambiada correctamente, vuelva a iniciar sesión"
              );
              this.$router.push("/login");
            }
          })
          .catch((error) => {
            var data = error.response.data;
            //mostrar mensaje de error en caso que la variable sea true(tenga datos)
            this.error = true;
            this.msg = data.message;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.dialog1 = false));
      } // End this.checkform()
    },
    cerrar() {
      this.limpiar_campos();
    },

    // limpiar_campos
    limpiar_campos() {
      this.dialog = false;
      this.password = "";
      this.confirmar_password = "";
      this.dialogm1 = "";
      this.codigo = "";
    },

    checkform() {
      this.error = false;
      if (!this.password && !this.confirmar_password) {
        this.error = true;
        this.msg = "Los campos son obligatorios";
        return false;
      } else if (this.password != this.confirmar_password) {
        this.error = true;
        this.msg = "Las contraseñas no son iguales, verificar datos ingresados";
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../sass/main.scss";

.card {
  margin-bottom: 0px !important;
}

.form-control {
  padding-left: 10px !important;
}
.separador_laptop {
  max-width: 600px !important;
}
@media (min-width: 280px) and (max-width: 1260px) {
  h1 {
    font-weight: 700 !important;
    text-align: center;
    border-bottom: 5px solid transparent !important;
    border-image: linear-gradient(
        to right,
        #00b1eb 0%,
        #1db6c8 50%,
        #c2d501 100%
      )
      1;
    margin-bottom: 2% !important;
    font-size: 2em !important;
  }
}
h1 {
  font-weight: 900;
  text-align: center;
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(to right, #00b1eb 0%, #1db6c8 50%, #c2d501 100%)
    1;
  margin-bottom: 10%;
  font-size: 3em;
}
.btn-round {
  border-radius: 7px;
}
.card {
  border-radius: 42px;
  padding: 0.5rem 7px;
}
.card-login {
  margin-bottom: 0px !important;
  margin-top: 0;
  padding: 5%;
  background: linear-gradient(#fff 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(90deg, #c2d501 0%, #1db6c8 51.56%, #00b1eb 100%)
      border-box;
  border: 5px solid transparent;
  border-radius: 42px;
}

.form-control {
  padding-left: 10px !important;
}

.theme--light.v-application {
  background: #ffffff00;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 1250px) {
  .separador_laptop {
    margin-top: 5em;
  }
}
.gradient_color {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  font-weight: 900;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: linear-gradient(270deg, #c2d501 0%, #1db6c8 50.52%, #00b1eb 100%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
}
.gradient_color:hover {
  background-image: linear-gradient(
    to left,
    #c2d501 0%,
    #1db6c8 50.52%,
    #00b1eb 100%
  );
  color: #000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 8px 10px 12px;
}

.forgot-password {
  cursor: pointer;
  font-weight: 800;
  color: #444;
}

.forgot-password:hover {
  color: #000;
}
</style>
