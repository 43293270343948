import { Soportes } from "../../services/soportes.js";
import { Refresh } from "../../services/refreshToken";
import moment from "moment";
import store from "../index.js";

export default {
  namespaced: true,
state:{ 
    soportes: [],
//  numero de pagina y total de registros
    number: 1,
    size: 10,
    page: 1,
    total: 0,
    visible: 7,
// numero de registro por pagina
    rowsPerPage: 50,

//errores de api
    errored: false,
    error: false,

    //  filter fechas inicio y fin
    fechaDesde: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    fechaHasta: moment().format("YYYY-MM-DD"),
    usuario: "",
    email:"",
    visto: false,
    nombre2: "",
    consulta: "",
    estado: "",
    abierto: false,
    archivo:"",
    cargando: false,
    notificaciones:"",

},

mutations :{

  actualizarSoporte(state, soporteActualizado) {
    // Buscar el índice del soporte en el estado basado en su ID
    const indice = state.soportes.findIndex(soporte => soporte.id === soporteActualizado.id);
    
    // Si se encuentra el soporte en el estado, actualizarlo de manera segura
    if (indice !== -1) {
      // Crear un nuevo objeto soporte con los datos actualizados
      const soporteActualizadoConID = {
        ...state.soportes[indice],  // Copiar los datos del soporte existente
        ...soporteActualizado  // Aplicar los datos actualizados
      };
      
      // Crear un nuevo arreglo de soportes con el soporte actualizado
      const nuevosSoportes = [
        ...state.soportes.slice(0, indice),  // Copiar los soportes anteriores al soporte actualizado
        soporteActualizadoConID,  // Agregar el soporte actualizado
        ...state.soportes.slice(indice + 1)  // Copiar los soportes posteriores al soporte actualizado
      ];
  
      // Asignar el nuevo arreglo de soportes al estado
      state.soportes = nuevosSoportes;
    }
  },
  

  actualizarArchivo(state, nuevoArchivo) {
    state.archivo = nuevoArchivo;
  },


    // mutations soporte
    setSoportes(state, soportes) {
      state.soportes = soportes;
    },
    setId(state, value) {
        state.id = value;
    },
    setSize(state, value) {
      state.size = value;
    },

    setNombre(state, value) {
        state.nombre = value;
    },

    setEmail(state, value) {
      state.email = value;
    },

    setVisto(state, value) {
      state.visto = value;
    },
    setArchivo(state, archivo) {
      state.archivo = archivo;
    },

    setNombre2(state, value) {
        state.nombre2 = value;
    },

    setConsulta(state, value) {
        state.consulta = value;
    }, 

    setRespuestas(state, value){
      state.respuestas = value;
    },

    setEstado(state, value) {
        state.estado = value;
    },

    setAbierto(state, value) {
      state.abierto = value;
    },

    setPaginate(state, value) {
      state.page = value;
    },

    setCargando(state, value) {
      state.cargando = value;
    },
    
    setrowsPerPage(state, value) {
        state.rowsPerPage = value;
    },
    
    setFechaInicio(state, value) {
      state.fechaDesde = value;
    },

    setFechaFin(state, value) {
      state.fechaHasta = value;
    },
    setErrord(state, value) {
        state.errored = value;
    },

    setTotal(state, value) {
      state.total = value;
    },

    setNotificaciones(state, value){
      state.notificaciones = value;
    }

    },

    actions: {

      //Traer Soportes
      async getSoportes({ commit, state }) {
        try {
          commit("setCargando", true);
          commit("setErrord", false);
          const params = {Number:state.page, Size:state.rowsPerPage}
          if (store.state.rol.includes('psp.config.clientes.wee')) {
            params.rol = 'psp.config.clientes.wee';
            }else{
              params.email = store.state.email;
            }           
          // Llamas al servicio para obtener los soportes
          await Soportes.getSoportes(params).then((response) => {
            
            // si la cuenta no contiene soportes se actualiza store a valor vacio y efecto cargando en false
            if (response?.data?.data?.Soportes == null) {
              commit("setSoportes", []);
              commit("setNotificaciones",0)
              commit("setPaginate", 1);
              commit("setTotal", 0);
              commit("setCargando", false);
            } else {
              // si la consulta es distinto de null se actualiza el store mutations soportes
              
                commit("setSoportes", response?.data?.data?.Soportes);
                commit("setNotificaciones",response?.data?.data?.Notificaciones)
                commit("setPaginate", response.data?.data?.Meta.page.currentPage);
                commit("setTotal", response.data?.data?.Meta.page.lastPage);
            }
          })
          .catch((error) => {
            if (error.response) {
              var data = error.response.status;
              if (data == 403) {
                async () => {
                  try {
                    await Refresh.getRefreshToken().then(() => {
                      //
                    });
                  } catch (error) {
                    //
                  }
                };
              }
            } else {
              commit("setErrord", true);
            }
          })
          .finally(() => commit("setCargando", false));
  
        } catch (error) {
          // Manejo de errores
          // console.error("Error al obtener los soportes??:", error);
          // Puedes propagar el error hacia arriba si es necesario
          throw error;
        }
      },


      // Traer Archivo
      async getSoporteFile({ commit }, archivo) {
        // console.log("archivo: recibido", archivo)
        try {
          // Verifica si state.archivo está definido y no es vacío
          if (archivo) {
            // Llama al servicio para obtener el archivo de soporte
            const response = await Soportes.getSoporteFile(archivo);
      
            if (response.data == null) {
              commit("setArchivo", []);
            } else {
              commit("setArchivo", response.data);
            }
      
            // console.log("Archivo traído con Éxito!", response);
            return response;
          } else {
            // No hay archivo, no se realiza ninguna llamada
            // console.log("No hay archivo para obtener.");
            return null; // O cualquier otro valor que desees devolver
          }
        } catch (error) {
          // console.error("Error obteniendo archivo de soporte Store:", error);
          if (error.response) {
            const data = error.response.status;
            if (data == 403) {
              // Realizar el manejo del token de refresco aquí
              // console.log("Token de refresco requerido.");
            }
          } else {
            commit("setErrord", true);
          }
          throw error;
        }
      },
      

      //Crear Soporte
        async createSoporte({ commit }, payload) {
          try {
            // Llamar al servicio para crear un nuevo soporte
            const response = await Soportes.createSoporte(payload);
            
            // Agregar el nuevo soporte al estado Vuex
            // console.log(response.data);
            // Devolver la respuesta si es necesario
            return response;
          } catch (error) {
            // Manejar cualquier error que ocurra durante la creación del soporte
            // console.error("Error creating soporte Store:", error);
            // Lanza el error nuevamente para que el componente pueda manejarlo
            throw error;
          }
        },

        //Crear Soporte Respuesta
        async createSoporteRespuesta({ commit }, respuestaData) {
          try {
            const response = await Soportes.createSoporteRespuesta(respuestaData);
      
            // Si es necesario, actualiza el estado de la aplicación con la nueva respuesta
            
            // Devuelve la respuesta si es necesario
            return response;
          } catch (error) {
            // console.error('Error al crear respuesta de soporte Store:', error);
            throw error; // Lanza el error nuevamente para que el componente pueda manejarlo
          }
        },

        



        //Editar Soporte
        async editarSoporte({ commit }, { nuevosDatos }) {
          try {
            const response = await Soportes.putSoporte( nuevosDatos);
            // Llamar a una mutación para actualizar el estado del soporte editado
            commit('actualizarSoporte', response?.data); // Suponiendo que tu mutación se llama 'actualizarSoporte'
            return response;
          } catch (error) {
            // console.error("Error editando soporte Store:", error);
            throw error;
          }
        }


      },


      

getters: {
    fechaDesde: (state) => state.fechaDesde,
    fechaHasta: (state) => state.fechaHasta,
    pages: (state) => state.page,
    nombre: (state) => state.nombre,
    email: (state) => state.email,
    visto: (state) => state.visto,
    consulta: (state) => state.consulta,
    respuestas:(state) => state.respuestas,
    estado: (state) => state.estado,
    abierto: (state) => state.abierto,
    archivo: (state) => state.archivo,
    notificaciones:(state) => state.notificaciones,
    soportes:(state) => state.soportes
    
        },
      modules: {},
}