<template>
  <div class="content">
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Transferencias</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                shaped
                transition="fade-transition"
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section>
              <v-card>
                <!-- filtros table  -->
                <v-expansion-panels v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <template v-slot:actions>
                        <span class="primary--text text-subtitle-2 mt-0"
                          >Filtros</span
                        >
                        <v-icon size="33" color="success"> search </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="pt-0 mx-5">
                        <v-col cols="6" md="2">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="ml-5"
                                label="Fecha transferencia desde"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                persistent-hint
                                :value="FormatDateDesde"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date1"
                              locale="es-ar"
                              no-title
                              scrollable
                              :max="new Date().toISOString().substr(0, 10)"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="red" @click="menu1 = false">
                                Cancelar
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="inicio(date1)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" md="2">
                          <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="ml-2"
                                :value="FormatDateHasta"
                                label="Fecha transferencia hasta"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                persistent-hint
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date2"
                              locale="es-ar"
                              no-title
                              scrollable
                              :min="fechaDesdeT"
                              :max="new Date().toISOString().substr(0, 10)"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="red" @click="menu2 = false">
                                Cancelar
                              </v-btn>
                              <v-btn text color="primary" @click="fin(date2)">
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" md="3" align="start">
                          <div class="ml-8">
                            <v-btn color="success" small @click="exportExcel()"
                              >Exportar
                              <v-icon right dark> get_app </v-icon></v-btn
                            >
                            <v-checkbox
                              v-model="export_details"
                              label="Exportar con detalles"
                              class="mt-2 checkbox-exportar"
                            ></v-checkbox>
                          </div>
                        </v-col>
                        <v-col cols="12" md="5">
                          <v-alert
                            dense
                            elevation="3"
                            icon="paid"
                            outlined
                            prominent
                            text
                            type="success"
                            class="mx-5"
                            style="font-family: Montserrat, sans-serif; font-size: 1.2em;"
                          >
                            Monto transferido:
                            <span class="font-weight-bold">
                              {{ formatImportFloat(montoTotal) }}
                            </span>
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- end filtros table  -->
                <v-data-table
                  :headers="headers"
                  :items="alltransferencias"
                  :items-per-page="rowsPerPage"
                  hide-default-footer
                  class="elevation-1 trow"
                  :loading="cargando"
                  loading-text="Cargando... Espere por favor"
                  no-results-text="No se encontraron registros"
                  no-data-text="No se encontraron registros"
                  :expanded.sync="expanded"
                  item-key="referencia_bancaria"
                  @click:row="rowClick"
                  show-expand
                >
                  <!-- FECHA -->
                  <template v-slot:[`item.fecha`]="{ item }">
                    {{ formatFecha(item.fecha) }}
                  </template>

                  <!--MONTO O TOTAL TRANSFERIDO-->
                  <template v-slot:[`item.monto`]="{ item }">
                    {{ saldoMovimiento(item.monto) }}
                  </template>

                  <!-- CONTENIDO DE LA FILA ACUMULADA -->
                  <template v-slot:expanded-item="{ headers, item }">
                    <td>Movimientos</td>
                    <td
                      :colspan="headers.length"
                      style="padding: 1%"
                      align="center"
                    >
                      <thead>
                        <tr>
                          <th>Concepto</th>
                          <th>Referencia de Pago</th>

                          <th>Canal de Pago</th>
                          <th>Importe</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="dat in item.MovimientoReponse"
                          v-bind:key="dat.id"
                          :class="
                            dat.monto_mov >= 0 ? '' : 'red lighten-5 mb-5 py-3'
                          "
                          height="35px"
                        >
                          <td v-text="dat.concepto" width="30%"></td>
                          <td v-text="dat.referenciaPago" width="20%"></td>

                          <td width="10%">{{ dat.canal_pago }}</td>
                          <td
                            width="25%"
                            :class="dat.monto_mov >= 0 ? 'green--text' : ''"
                          >
                            {{ saldoMovimiento(dat.monto_mov) }}
                          </td>
                          <td width="30%">
                            {{ dat.monto_mov >= 0 ? "" : "Reversión" }}
                          </td>
                        </tr>
                      </tbody>
                    </td>
                  </template>

                  <template v-slot:[`item.pdf`]="{ item }">
                    <div
                      @mousemove="generatePdf = true"
                      @mouseout="generatePdf = false"
                    >
                      <TransferenciaPDF :item="item" :expanded="expanded" />
                    </div>
                  </template>

                  <!-- cantidad de registros por pagina -> actualizar numero de registros por pagina -->
                  <template v-slot:footer>
                    <v-row class="no-gutters mt-2 mx-2">
                      <v-flex xs12>
                        <span>Mostrar</span>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              dark
                              text
                              color="primary"
                              class="ml-2"
                              v-on="on"
                            >
                              {{ rowsPerPage }}
                              <v-icon>arrow_drop_down</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(number, index) in rowsPerPageArray"
                              :key="index"
                              @click="updateRowsPerPage(number)"
                            >
                              <v-list-item-title>{{
                                number
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-flex>
                    </v-row>
                  </template>
                </v-data-table>
                <div class="text-center pt-2">
                  <v-pagination
                    :value="page"
                    :length="total"
                    :total-visible="visible"
                    @input="onPageChange($event)"
                  ></v-pagination>
                </div>
              </v-card>
            </section>
          </div>

          <!--Tabla utilizada para exportacion-->
          <table id="exportTabla" style="display: none">
            <thead>
              <tr>
                <th>CBU origen</th>
                <th>CBU destino</th>
                <th>Referencia bancaria</th>
                <th>Fecha operación</th>
                <th>Total transferido</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in export_data" :key="index">
              <tr>
                <td>{{ item.cbu_destino + "'" }}</td>
                <td>{{ item.cbu_origen + "'" }}</td>
                <td>{{ item.referencia_bancaria + "'" }}</td>
                <td>{{ item.fecha }}</td>
                <td>{{ saldoMovimiento(item.monto, true) }}</td>
              </tr>
              <tr v-if="export_details">
                <th></th>
                <th>Concepto</th>
                <th>Referencia pago</th>
                <th>Canal de pago</th>
                <th>Importe</th>
              </tr>
              <tr
                v-for="(detalle, index) in item.MovimientoReponse"
                v-if="export_details"
                :key="index"
              >
                <td></td>
                <td>{{ detalle.concepto }}</td>
                <td>{{ detalle.referenciaPago }}</td>
                <td>{{ detalle.canal_pago }}</td>
                <td>{{ saldoMovimiento(detalle.monto_mov, true) }}</td>
              </tr>
              <tr v-if="export_details">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <!--DIALOG PARA VER DETALLE DE TRANSFERENCIA-->
          <v-dialog v-model="dialogDetalle" max-width="65%">
            <v-card>
              <v-card-title style="font-family: Montserrat;"
                >Movimientos <v-divider class="mx-5" vertical /> Total
                transferido:
                <span class="font-weight-bold ml-3">{{
                  formatImport(itemDetalles.total)
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  hide-default-footer
                  :headers="headersDetalle"
                  :items="itemDetalles.detalles"
                  no-data-text="Sin registros para mostrar"
                  class="py-2"
                  disable-pagination
                  :item-class="rowColor"
                >
                  <template v-slot:[`item.monto_mov`]="{ item }">
                    {{ formatImport(item.monto_mov) }}
                  </template>

                  <template v-slot:[`item.reversion`]="{ item }">
                    {{ item.monto_mov < 0 ? "Reversión" : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import TransferenciaPDF from "./TransferenciaPDF.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import "table2excel";
import formatImport, { formatImportFloat } from "../../utils/formatImport";

export default {
  name: "AllTransferencia",
  components: { TransferenciaPDF },
  data() {
    return {
      // panel de filtros
      panel: 0,
      readonly: false,

      // datapicker filtro fechas
      menu1: false, // atributo que permite desplegar el date picker fecha desde
      menu2: false, // atributo que permite desplegar el date picker fecha hasta
      // campos para hacer referencia a valor de datapicker
      date1: "",
      date2: "",

      //  numero de registros por pagina
      rowsPerPageArray: [10, 20, 30],
      expanded: [],

      generatePdf: false,
      //  cabecera del datatable
      headers: [
        {
          text: "Fecha operación",
          align: "start",
          sortable: true,
          value: "fecha",
        },
        {
          text: "CBU origen",
          align: "center",
          sortable: false,
          value: "cbu_origen",
        },
        {
          text: "CBU destino",
          align: "center",
          sortable: false,
          value: "cbu_destino",
        },
        {
          text: "Referencia bancaria",
          align: "center",
          sortable: false,
          value: "referencia_bancaria",
        },

        {
          text: "Total transferido",
          align: "start",
          sortable: true,
          value: "monto",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "pdf",
        },
      ],

      headersDetalle: [
        {
          text: "REFERENCIA DE PAGO",
          value: "referenciaPago",
          sortable: false,
        },
        { text: "CONCEPTO", value: "concepto", sortable: false },
        { text: "CANAL DE PAGO", value: "canal_pago", sortable: false },
        { text: "MONTO", value: "monto_mov", sortable: false },
        { text: "", value: "reversion", sortable: false },
      ],

      export_details: false, //utilizado para saber si la exportacion es con detalles

      itemDetalles: [],
      dialogDetalle: false,
      formatImport,
      formatImportFloat,
    };
  },

  methods: {
    ...mapActions("transferencias", ["getAllTransferencias"]),
    async exportExcel() {
      await this.getAllTransferencias(true);

      const tabla = document.getElementById("exportTabla");
      const Table2Excel = window.Table2Excel;
      const excel = new Table2Excel();

      excel.export(tabla, "transferencias");
    },

    // paginar datatable
    onPageChange(event) {
      this.$store.commit("transferencias/setPaginate", event);
      this.getAllTransferencias();
    },

    // registros por paginas (actualizar numero de registros por pagina) (listo)
    updateRowsPerPage(number) {
      this.$store.commit("transferencias/setrowsPerPage", number);
      this.$store.commit("transferencias/setPaginate", 1);
      this.getAllTransferencias();
    },

    formatFecha(value) {
      var date = new Date(value);
      let fecha = date.toISOString().substring(0, 10);
      var datePart = fecha.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    },
    // Este metodo devuelve un string, que corresponde a una clase css, dependiendo del valor del movimiento
    // Permite asignar de forma dinamica una clase css en relacion a un valor

    inicio(value) {
      this.$store.commit("transferencias/setFechaInicioT", value);
      this.menu1 = false;
      this.getAllTransferencias();
    },

    fin(value) {
      this.$store.commit("transferencias/setFechaFinT", value);
      this.menu2 = false;
      this.getAllTransferencias();
    },

    saldoMovimiento(monto, sinSigno = false) {
      var numero = monto / 100;
      if (!sinSigno) {
        return numero.toLocaleString("es-ar", {
          style: "currency",
          currency: "ARS",
          currencySign: "accounting",
          minimumFractionDigits: 2,
        });
      } else {
        return numero
          .toLocaleString("es-ar", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
          })
          .replace(/[$ ]/g, "")
          .replace(/-/g, "")
          .replace(/\s+/g, "");
      }
    },
    //metodo que se encarga de desplegar el detalle de transferencia al hacer click sobre una fila en la tabla
    rowClick(item) {
      if (!this.generatePdf) {
        this.dialogDetalle = true;
        this.itemDetalles = {
          detalles: item.MovimientoReponse,
          total: item.monto,
        };
      }
    },

    rowColor(item) {
      if (item.monto_mov < 0) return "red lighten-5";
    },
  },

  watch: {
    getCurrentAccountId() {
      this.getAllTransferencias();
    },
  },

  mounted() {
    if (this.alltransferencias.length == 0) {
      this.getAllTransferencias(); // Proviene del mapActions del modulo transferencias del store
    }
  },

  computed: {
    ...mapState("transferencias", [
      "alltransferencias",
      "page",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
      "errored",
    ]),

    ...mapGetters("transferencias", [
      "fechaDesdeT",
      "fechaHastaT",
      "export_data",
      "montoTotal",
    ]),
    ...mapGetters(["getCurrentAccountId"]),

    paginaActual: {
      get() {
        return this.$store.getters["transferencias/pages"];
      },
    },

    FormatDateDesde() {
      return this.fechaDesdeT
        ? moment(this.fechaDesdeT).format("DD/MM/YYYY")
        : "";
    },
    FormatDateHasta() {
      return this.fechaHastaT
        ? moment(this.fechaHastaT).format("DD/MM/YYYY")
        : "";
    },
  },
};
</script>
<style scope>
::v-deep .checkbox-exportar .v-label {
  font-size: 13px !important;
}
.pending {
  color: rgba(213, 216, 21, 0.863);
}
.processing {
  color: rgba(20, 33, 211, 0.863);
}
.paid {
  color: rgba(77, 236, 130, 0.863);
}
.accredited {
  color: rgb(7, 161, 58);
}
.loader {
  /* Loader Div Class */
  position: absolute;
  top: 50px;
  right: 0px;
  width: 100%;
  height: 80%;
  background-color: #eceaea;
  background-image: url("/assets/img/cargando.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.outlinenone {
  outline: none;
  background-color: rgb(220, 228, 225);
  border: 0;
}

.theme--dark.v-sheet {
  background-color: #db5050;
  border-color: #1e1e1e;
  color: #ffffff;
}

.trow tbody tr :hover {
  cursor: pointer;
}
</style>
