 <template>
 <div class="content">
    <v-app>
      <v-main>
          <v-container fluid>
         <br>
          <v-row justify="left">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Ingresar mes
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Seleccionar mes</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <input type="month" v-model="fecha_inicio" class="form-control">
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  v-on:click="datos_supervision"
                >
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
          
    <br>
    <!-- DATATABLE -->
          <div class="card">
            <!-- RI CUENTA CLIENTE -->
            <div class="card-header card-header-success">
              <h4 class="card-title">RI Cuenta Cliente</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
               <v-alert
                  border="left"
                  class="mt-3 mx-3 elevation-3"
                  color="red lighten-2"
                  dark
                >
                  Lo sentimos, no es posible obtener la información en este
                  momento, por favor intente nuevamente mas tarde  <a class="text-info" @click="intento()">
                  Volver a intentar
                  </a>
                </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
                <div v-if="procesando_datos"> 
                <loader object="#4caf50" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="40" disableScrolling="false" name="box"></loader>
                </div>
               <table class="table table-hover" width=100%>
                  <thead v-if="data.length != 0">
                    <tr>
                      <td  width="5%">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox"  value="" v-model="RectificativaCuentaCliente">
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td class="text-left"> <p class="card-category">Rectificativa</p> </td>
                      </tr>
                  </thead>
                  <thead>
                    <tr>
                    <th><p class="card-category">Cod Partida</p></th>
                    <th><p class="card-category">Concepto / Cuentas de pago de clientes</p></th>
                    <th><p class="card-category">Saldo</p> </th>
                    <th><p class="card-category">Cantidad</p></th>
                    <th><p class="card-category">CBU/Nro registro del Fondo</p></th>
                  </tr>
                  </thead>
                  <tbody v-for="dat in data"  v-bind:key="dat.id">
                    <tr>
                      <td> {{dat.CodigoPartida}}</td>
                      <td> {{traducir_concepto(dat.CodigoPartida)}}   </td>
                      <td> <input  class="outlinenone" v-model="dat.Saldo"> </td>
                      <td> {{dat.Cantidad}}</td>
                      <td><input  class="outlinenone" v-model="dat.CBU"></td>
                      <!-- <td><p class="card-text" v-if="dat.CBU != 'NULO'" > <input class="outlinenone" v-model="dat.CBU"> </p><p class="card-text" v-else></p></td> -->
                    </tr>
                  </tbody>
                </table>
            <br>
           <!-- RI datos fondos -->
            <div class="card-header card-header-success">
            <h4 class="card-title">RI Datos fondo</h4>
            <p class="card-category"></p>
            </div>
                <table class="table table-hover">
                    <thead v-if="data.length != 0">
                    <tr>
                      <td  width="5%">
                        <div class="form-check">
                          <label class="form-check-label">
                            <input class="form-check-input" type="checkbox"  value="" v-model="RectificativaDatosFondo">
                            <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td class="text-left"><p class="card-category">Rectificativa</p></td>
                      </tr>
                  </thead>
                  <thead>
                    <tr>
                    <th><p class="card-category">Agente</p></th>
                    <th><p class="card-category">Cuit</p> </th>
                    <th><p class="card-category">Denominación</p> </th>
                    <th><p class="card-category">Denominación agente</p> </th>
                    <th><p class="card-category">Número</p></th>
                  </tr>
                  </thead>
                  <tbody v-for="dataFondo in dataDatosFondo"  v-bind:key="dataFondo.id">
                    <tr>
                      <td><p class="card-text" v-text="dataFondo.Agente"></p></td>
                      <td><p class="card-text" v-text="dataFondo.CuitAgente"></p></td>
                      <td><p class="card-text" v-text="dataFondo.Denominacion">  </p></td>
                      <td><p class="card-text" v-text="dataFondo.DenominacionAgente"></p></td>
                      <td><p class="card-text" v-text="dataFondo.Numero"></p></td>
                    </tr>
                  </tbody>
                </table>
               <!-- adjuntar archivo y guardar cambios -->
              <div class="row ml-1" v-if="data.length != 0">
                    <div class="col-md-8">
                      <table class="table">
                                <tbody>
                                      <tr>
                                        <td width="5%">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                  <input class="form-check-input" type="checkbox"  value="" v-model="RectificativaCuentaCliente">
                                                  <span class="form-check-sign">
                                                    <span class="check"></span>
                                                  </span>
                                                </label>
                                              </div>
                                            </td>
                                            <td class="text-left"><p class="card-category">Rectificativa</p></td>
                                            <td  class="text-left"> <input type="file" accept="application/pdf" id="file" ref="file" v-on:change="handleFileUpload()"/></td>
                                      </tr>
                                </tbody>
                        </table>
                    </div>
              </div>
              <div class="row"  v-if="data.length != 0">
                 <div class="col-md-12" align="right" >
                 <button v-on:click="enviar_informe" class="btn btn-success btn-round mr-2">Guardar</button>
                 </div>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import moment from 'moment'
import { Bcra } from '../../services/bcra.js';
  export default {
    name: 'Supervision',
    data () {
      return {
            fecha_inicio: '',
            data: [],
            dataDatosFondo : [],
            error: false,
            errored: false,
            procesando_datos: false,
            file: null,
            RectificativaCuentaCliente: false,
            RectificativaDatosFondo: false,
            RectificativaInfEspecia: false,
            dialog: false,
         }
     },
     methods: {
             async datos_supervision(){
              const params  = {
              FechaInicio : moment(this.fecha_inicio).startOf('month').format('YYYY-MM-DD')+'T00:00:00.000-00:00',
              FechaFin :  moment(this.fecha_inicio).endOf('month').format('YYYY-MM-DD')+'T00:00:00.000-00:00'  
            }
             this.error = false;
             this.checkinput();
             this.dialog = false;
             this.procesando_datos = true;
             Bcra.getSupervision(params)
             .then(response => { 
                if(response.data.RiCuentaCliente == null) {
                 this.$toastr.i("El mes selecionado no contiene registros");
                //  inicializar todas las variables
                this.data = [],
                this.dataDatosFondo = [],
                this.error= false,
                this.procesando_datos= false,
                this.RectificativaCuentaCliente= false,
                this.RectificativaDatosFondo=false,
                this.file= null,
                this.dialog = false,
                 e.preventDefault().finally(() => this.procesando_datos = false);
               }else{
                this.data = response.data.RiCuentaCliente;
                this.dataDatosFondo = response.data.RiDatosFondos;
                this.loading = false;
                this.dialog = false;
               }        
             })
             .catch(error => {
               var data = error.response.data;
               this.error = true;
               this.$toastr.e(data.message);
             }).finally(() => this.procesando_datos = false); 
           
            },
            handleFileUpload() {
            this.file = this.$refs.file.files[0];
            },
           //  enviar informes
            async enviar_informe() {
              var data = {
                RiCuentaCliente: this.data,
                RiDatosFondos: this.dataDatosFondo,
                RectificativaCuentaCliente: this.RectificativaCuentaCliente,
                RectificativaDatosFondo: this.RectificativaDatosFondo, 
                RectificativaInfEspecial: this.RectificativaInfEspecial,
                OperaCuentaCliente: true,
                OperaDatosFondos: true,
                OperaInfEspecial: true,
                Periodo:moment(this.fecha_inicio).endOf('month').format('YYYY-MM-DD'),
              };

              // enviar 2 objetos (data y el pdf)
              const formData = new FormData();
              formData.append('data', JSON.stringify(data));
              formData.append('infespecial', this.file);
               this.error = false
               this.checkfile();
               this.loading = true,
               Bcra.post_supervision(formData)
                .then((response) => {               
              //  si la respuesta es exitosa se limpian todas las variables
                this.data = [],
                this.dataDatosFondo = [],
                this.error= false,
                this.loading= false,
                this.RectificativaCuentaCliente= false,
                this.RectificativaDatosFondo=false,
                this.file= null,
                this.dialog = false,
                // var url=response.data;
                // window.open(url, '_blank');
                this.$toastr.s("Se genero el archivo zip correctamente");
                })
                .catch((error) => {
                  var data = error.response.data;
                  this.error = true;
                  this.$toastr.e(data.message);
                }).finally(() => this.loading = false);
            },
            //  validacion de campos(validacion del input y file)
            checkinput(e){
              this.error = false
              if(!this.fecha_inicio){
                this.error = true
                this.$toastr.e("El campo mes no puede ser vacio");
                // detener el envio del formulario
                e.preventDefault();
              } 
              },
            checkfile(e){
              this.error = false
              if(!this.file){
                this.error = true
                this.$toastr.e("El campo archivo es obligatorio");
                e.preventDefault();
              } 
              },

             traducir_concepto(value) {
                if (value.substring(0,4) == '1000') {
                  return "Sumatoria de saldos de cuentas de pago de clientes";
                }
                else if(value.substring(0,4) == '5000') {
                     return "Cantidad total de cuentas de pago de los clientes";
                }
                else if(value.substring(0,4) == '2000') {
                     return "Saldos en cuentas a la vista por entidad financiera";
                }
                  else if(value.substring(0,4) == '3000') {
                     return "Sumatoria de saldos invertidos en fondos comunes de dinero";
                }
                else if(value.substring(0,4) == '4000') {
                     return "Saldos invertidos por fondo común de dinero";
                }

              },
            // error de api , esta funcion vuelve a recargar la pagina 
            intento() {
              this.$router.go()
              },
          },

  }
</script>
<style scoped>
.outlinenone {
  outline: none;
  text-align: center;
  background-color: rgb(249, 255, 253);
  border: 0;
  
}

.v-application .primary {
    background-color: #66bb6a!important;
    border-color: #66bb6a !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    margin-left: 15px;
}

</style>