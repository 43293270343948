<template>
  <!-- DIALOG CAJA -->
  <v-dialog v-model="showCaja" persistent max-width="max-content">
    <v-card>
      <v-card-title>
        <v-row class="my-0" style="width: 100%">
          <span class="text-h5 me-2">Cajas</span>

          <v-spacer></v-spacer>

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="cierreCaja()" icon v-bind="attrs" v-on="on">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-row>
        <v-row>
          <div align="right" style="width:100%; padding-inline: 12px;">
            <v-btn color="primary" dark @click="openNewCaja()">
              Nuevo
            </v-btn>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text class="my-5">
        <v-container>
          <v-row justify="center" ref="onestep" style="margin-bottom: 20px;">
            <transition name="slide-fade">
              <div
                style="width: 100%;box-shadow:0 1px 4px 0 rgba(0, 0, 0, 0.14); padding:20px"
                v-show="showFormCaja"
              >
                <v-form ref="form_create" v-model="valid2" lazy-validation>
                  
                    <v-text-field
                      v-model="currentCaja.descripcion"
                      :counter="45"
                      :rules="namecuenta"
                      label="Nombre caja*"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-if="false"
                        v-model="currentCaja.correo"
                        :counter="50"
                        label="Correo"
                        required
                    ></v-text-field>    
                    <div style="max-width: 600px!important;">
                        <v-select
                          v-if="true"
                          v-model="currentCaja.cuenta"
                          :items="usuarios"
                          item-text="Nombre"
                          item-value="Id"
                          label="Usuarios"
                          multiple                          
                          no-data-text="No hay datos disponibles"
                        >                       
                        </v-select>                                                                
                    </div>                
                <v-row justify="center">
                  <v-btn class="text-gray" text @click="closeNewCaja()">
                      Cerrar
                    </v-btn>
                  
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!valid2"
                      @click="editCaja ? handleUpdate() : handleStore()"
                    >
                      Guardar
                    </v-btn>
                </v-row>
                </v-form>
              </div>
            </transition>
          </v-row>
          <v-row justify="center" style="gap: 20px" ref="onestep">
            <div>
              <v-data-table
                :search="search"
                :headers="headersCaja"
                :items="itemsCajaFormatted"
                :loading="cargandoCaja"
                :items-per-page="10"
                height="258px"
                item-key="Id"
                single-expand
                hide-default-footer
                show-expand
                disable-sort
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-1 trow tablaCajas"
              >
                <template v-slot:no-results>
                  <v-alert color="error" icon="warning">
                    found no results.
                  </v-alert>
                </template>
                <!-- ACCIONES BOTONES -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-row class="ms-1">
                    <!-- 1 - boton editar -->
                    <v-tooltip  top color="orange">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mr-2"
                          fab
                          :dark="item.activo == 'Activo' ? true : false"
                          x-small
                          color="orange"
                          @click="openEdit(item.Id)"
                          v-bind="attrs"
                          :disabled="item.activo == 'Inactivo' ? true : false"
                          v-on="on"
                        >
                          <v-icon dark> create </v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                    <!-- 2 - boton eliminar -->
                    <!-- <v-tooltip top color="red">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mr-2"
                          fab
                          dark
                          x-small
                          color="red"
                          @click="deleteCaja(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark> delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip> -->
                    <!-- 3 - boton quitar activo -->
                    <v-tooltip top :color="item.activo == 'Activo'? 'red': 'green'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mr-2"
                          fab
                          dark
                          x-small
                          :color="item.activo == 'Activo'? 'red': 'green'"
                          @click="invertirActive(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark> {{item.activo == 'Activo'? 'mdi-alpha-x-circle-outline' : 'mdi-check-circle-outline' }} </v-icon>
                        </v-btn>
                      </template>
                      <span>{{item.activo == 'Activo'? 'Desactivar': 'Activar'}}</span>
                    </v-tooltip>
                  </v-row>
                </template>   
                
                    <!-- Slot chip activo -->
                <template v-slot:[`item.activo`]="{ item }">
                  <v-chip
                    dark
                    :color="item.activo == 'Activo' ? 'green' : 'red'"
                  >
                    {{ item.activo == 'Activo' ? 'Activo' : 'Inactivo' }}
                  </v-chip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td style="padding-block: 10px" :colspan="headers.length">
                    <div v-if="item.usuarios">
                      <v-simple-table dense >
                          <thead style="border: solid 1px #D1D1D1 ">
                            <tr>
                              <th>Nombre</th>
                              <th>Correo</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody style="border: solid 1px #D1D1D1; ">
                            <tr
                              v-for="(i, index) in item.usuarios"
                              :key="index"
                              class="grey lighten-4"
                            >
                              <td style="text-align: left!important">{{ i.Nombre }}</td>
                              <td style="text-align: left!important">{{ i.User }}</td>                            
                              <td>
                                <v-tooltip top color="red">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="mr-2 my-2"
                                      fab
                                      dark
                                      x-small
                                      color="red"
                                      @click="cerrarSesion(i)"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon dark> mdi-logout </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Cerrar Sesión</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                      </v-simple-table>                    
                    </div>
                    <div v-else>
                      No hay usuarios asignados.
                    </div>
                  </td>
                </template>
                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12 class="align-items-center">
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"                            
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </div>            
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { Cuentas } from "../../../../../services/cuentas";
import formatDate from "../../../../../utils/formatDate";
import autoAnimate from "@formkit/auto-animate";

export default {
  components: {
    name: "ModalCaja",
  },
  props: {
    showCaja: {
      default: false,
      type: Boolean,
    },
    sucursal: {
      type: Object,
      default: () => ({
        codigo_sucursal_link: "",
        fecha_alta: "",
        fecha_creacion: "",
        id: 0,
        nombre: "",
      }),
    },
    usuarios:{
      type: Array,
      default: [],
    }
  },
  data: function() {
    return {
      cliente_id: this.$route.params.cliente_id,
      showFormCaja: false,
      errored: false,
      menu2: false,
      itemID: "",
      cuenta: [],        
      expandedItems: [],      
      formatDate,
      currentCaja: {
        descripcion: "",
        fecha_alta: "",
        correo: "",
        cuenta: []
      },
      fechaMinima: moment().format("YYYY-MM-DD"),
      // page: 1,
      search: "",
      valid2: false,
      editCaja: false,
      headersCaja: [
        { text: "Código de caja", value: "codigo_caja" },
        { text: "Nombre", value: "descripcion" },
        { text: "Fecha de vigencia", value: "fecha_alta" },
        // { text: "Código de link pos", value: "codigo_pos_link" },
        { text: "Estado", value: "activo" },
        { text: "Opciones", value: "actions" },
        { text: 'Usuarios', value: 'data-table-expand' },
      ],
      itemsPerPage: 10,
      pageCount: 0,
      // paginacion api
      pagination: {
        page: 1,
        total: 0,
        visible: 7,
      },
      //  numero de registros por pagina - Permisos.vue
      rowsPerPageArray: [10, 20, 30],
      rowsPerPage: 10,
      cargandoCaja: false,
      itemsCaja: [],
      namecuenta: [
        (v) => !!v || "Cuenta es requerido",
        (v) =>
          (v && v.length <= 45) || "Cuenta no debe superar los 45 caracteres",
        (v) =>
          /^[A-Za-z0-9.-\s]+$/.test(v) ||
          "Solo numero y letras, sin ñ o acentos.",
      ],
      fecha_alta: [(v) => !!v || "La fecha es requerida"],
      correo: [
        (v) => !!v || "Correo es requerido",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Correo debe ser válido",
      ],
    };
  },
  watch: {
    showCaja(newVal) {
      if (newVal) {
        this.getDatosCaja(this.sucursal.id);
        // autoAnimate(this.$refs.onestep);
      }
      return this.showCaja;
    },
  },
  computed: {
    itemsCajaFormatted() {
      return this.itemsCaja.map((item) => ({
        ...item,
        fecha_alta: formatDate(item.fecha_alta.substring(0, 10)),
      }));
    },
  },
  methods: {
    onPageChange() {
      this.getDatosCaja(this.sucursal.id);
    },
    openEdit(idCaja) {
      if (this.showFormCaja) this.closeNewCaja();

      const item = this.itemsCaja.find((c)=> c.Id == idCaja)           
      item.cuenta = (item.usuarios?.map(u => u.Id));
      
      this.editCaja = true;
      this.currentCaja = { ...item };      
      // this.currentCaja.fecha_alta = moment(this.currentCaja.fecha_alta).format("YYYY-MM-DD");
      this.showFormCaja = !this.showFormCaja;
    },
    openNewCaja() {
      if (this.showFormCaja) this.closeNewCaja();
      this.showFormCaja = !this.showFormCaja;
    },
    cierreCaja() {
      this.$emit("closeModalCaja");
      this.itemsCaja = [];
      this.showFormCaja = false;
    },
    closeNewCaja() {
      this.editCaja = false;
      this.showFormCaja = false;
      this.$refs.form_create.resetValidation();
      this.currentCaja = {
        descripcion: "",
        fecha_alta: "",
        correo: "",
        cuenta: [],
      };
    },
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.getDatosCaja(this.sucursal.id);
    },
    formatearFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DDT00:00:00Z");
    },
    toast(name, msg, type, color) {
      this.$toastr.Add({
        name: name,
        title: "",
        msg: msg,
        clickClose: true,
        type: type,
        preventDuplicates: true,
        style: {
          color: "white",
          backgroundColor: color,
          width: "300px",
        },
      });
    },
    handleUpdate() {
      if (this.$refs.form_create.validate()) {
        this.updateCaja();
      } else {
        this.toast(
          "ErrorRequest",
          "Debe completar todos los datos",
          "info",
          "primary"
        );
      }
    },
    handleStore() {
      if (this.$refs.form_create.validate()) {
        this.storeCaja();
      } else {
        this.toast(
          "ErrorRequest",
          "Debe completar todos los datos",
          "info",
          "primary"
        );
      }
    },
    async deleteCaja(item) {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "Esta acción eliminará el contacto",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed) this.delete(item.Id);
    },
    getDatosCaja: function(id_caja) {
      this.cargandoCaja = true;
      let params = {
        number: this.pagination.page,
        size: this.rowsPerPage,
        PuntosVentaId: id_caja,
      }
      Cuentas.getDatosCaja(params)
        .then((response) => {
          if (response.data.data.CajasCobro) {
            this.itemsCaja = response.data.data.CajasCobro.map((c) => ({
              ...c,
              activo: c.activo? 'Activo':'Inactivo'
            }));
            this.pagination.page = response.data.data.meta.page.currentPage;
            this.pagination.total = response.data.data.meta.page.lastPage;
          } else {
            this.itemsCaja = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            // this.showFormCaja = true;
          }
        })
        .catch(() => {
          this.errored = true;
        })
        .finally(() => {
          this.cargandoCaja = false;
        });
    },
    updateCaja: function() {
      this.currentCaja.fecha_alta = moment().format('YYYY-MM-DD') + 'T00:00:00Z';
      this.cargandoCaja = true;
      let body = {
        descripcion: this.currentCaja.descripcion,
        fecha_alta: this.currentCaja.fecha_alta, //fecha minima actual
        Id: this.currentCaja.Id,
      };     

      if(this.currentCaja.cuenta){
        body.usuariosAsignados = this.currentCaja.cuenta.toString();
      }
      
      Cuentas.updateCaja(body)
        .then((response) => {
          if (response.status == 200) {
            this.getDatosCaja(this.sucursal.id);
            this.toast(
              "SuccessRequest",
              "Se actualizaron los datos correctamente",
              "success",
              "green"
            );
            this.closeNewCaja();
          }
        })
        .catch(() => {
          this.errored = true;
          this.toast('ErrorRequest', 'Ocurrio un error al actualizar la caja', 'warning', 'danger')
        })
        .finally(() => {
          this.cargandoCaja = false;
        });
    },
    storeCaja: function() {            
      this.cargandoCaja = true;
      let body = {
        descripcion: this.currentCaja.descripcion,
        fecha_alta: moment().format('YYYY-MM-DD') + 'T00:00:00Z', //fecha minima actual
        puntos_ventas_id: this.sucursal.id,
        sucursal_codigo: this.sucursal.codigo_sucursal_link,
        cuenta_id: this.cliente_id,
      };

      if(this.currentCaja.cuenta){
        body.usuariosAsignados = this.currentCaja.cuenta.toString();
      }

      Cuentas.storeCaja(body)
        .then((response) => {
          if (response.status == 200) {
            this.getDatosCaja(this.sucursal.id);
            this.toast(
              "SuccessRequest",
              "La caja cobro se creo correctamente",
              "success",
              "green"
            );
            this.closeNewCaja();
          }
        })
        .catch((error) => {
          this.errored = true;     
          // console.log(error);     
          this.toast('ErrorRequest', error.response?.data?.statusMessage, 'warning', 'danger')
        })
        .finally(() => {
          this.cargandoCaja = false;
        });
    },
    delete: function(id_caja) {
      this.cargandoCaja = true;
      Cuentas.deleteCaja(id_caja)
        .then((response) => {
          if (response.status == 200) {
            this.toast(
              "SuccessRequest",
              "Caja cobro eliminado correctamente",
              "success",
              "primary"
            );
            this.getDatosCaja(this.sucursal.id);
          }
        })
        .catch((error) => {
          this.errored = true;
          this.toast('ErrorRequest', error, 'warning', 'danger')
        })
        .finally(() => {
          this.cargandoCaja = false;
        });
    },
    invertirActive: async function (item){ 
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: item.activo == 'Activo' ? "Esta acción inhabilitará la caja" : "Esta acción habilitará la caja",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed){
        this.cargandoCaja = true;      
        const state = item.activo == 'Activo' ? true : false;            
        let body = {
          Id: item.Id,
          activo: !state,        
        };     
        if(item.usuarios){
          body.usuariosAsignados = item.usuarios.map(u => u.Id).toString();
        } 
        Cuentas.updateCaja(body)
          .then((response) => {
            if (response.status == 200) {
              this.getDatosCaja(this.sucursal.id);
              this.toast(
                "SuccessRequest",
                "Se actualizaron los datos correctamente",
                "success",
                "green"
              );
              this.closeNewCaja();
            }
          })
          .catch(() => {
            this.errored = true;
          })
          .finally(() => {
            this.cargandoCaja = false;
          });
      }
    },
    cerrarSesion: async function (item) {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "La caja se cerrará y la sesión actual se cerrará",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });      
      if (isConfirmed) {
        this.cargandoCaja = true;
        Cuentas.cerrarSesion(item.Id)
          .then((response) => {
            if (response.status == 200) {
              this.getDatosCaja(this.sucursal.id);
              this.$toastr.s("Cierre de sesion exitoso");                          
            }
          })
          .catch(() => {            
            this.$toastr.e("Ocurrió un error al cerrar la sesión")
          })
          .finally(() => {
            this.cargandoCaja = false;
          });
      }
    }
  },
};
</script>
<style scoped>
a[data-v-1b84c964] {
  color: #e6f1e3;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}
.v-dialog {
  transition: width 0.3s ease;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.form-container {
  overflow: hidden;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content{
  box-shadow: unset!important;
}
</style>
