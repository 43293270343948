<template>
  <div id="imagen_fondo">
    <div class="page-header header-filter clear-filter ">
      <!-- data-parallax="true" -->
      <v-row class="col-12">
        <v-col cols="6" class="pa-2 align-self-center pl-5" start>
          <h1 class="info">
            <h1>
              <br />
              UNA
              <br />PLATAFORMA,
            </h1>
            <h1>
              <div class="font-weight-bold"><b>TODAS LAS</b></div>
            </h1>
            <h1>
              <div class="font-weight-bold"><b>SOLUCIONES</b></div>
            </h1>
          </h1>
        </v-col>
        <v-col
          cols="6"
          class="pa-2 align-self-center"
          outlined
          tile
          align="center"
          style="position: relative;"
        >
          <img class="img" src="/assets/img/celu.png" style="max-width: 100%;"/>
        </v-col>
      </v-row>

      <!-- Aca Agregar lo otros datos de la pagina -->
    </div>
    <!-- <div
        id="imagen_fondo"
        class="page-header header-filter clear-filter justify-content-end"
        data-parallax="true"
      >
        <v-btn
          color="black"
          class="gradient-button gradient-button-1 button-move"
          href="/solicitar-cuenta"
          style="z-index: 1"
        >
          Enviar solicitud
        </v-btn>
      </div> -->

    <div class="col-md-12 ml-auto mr-auto ">
      <v-row>
        <v-col align="center">
          <v-row class="pl-5">
            <v-col cols="6" md="6">
              <img class="img" src="/assets/img/image_person1.png" />
            </v-col>
            <v-col
              cols="6"
              md="6"
              class="d-flex flex-column align-center text-right mb-4 pr-5"
            >
              <h3 class="info-title4">
                Nuestra plataforma de
                <br />procesamiento de pagos <strong><b>integra </b></strong
                ><br /><strong> <b>tecnologías innovadoras</b></strong> que
                <br />simplifican los procesos de
                <br />
                conciliación y mejoran la
                <br />experiencia de pago del cliente.
              </h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="5">
          <!-- Tipo de funcionalidades Pagos -->
          <!-- Pago Online -->
          <v-row>
            <!-- Pago Online -->
            <v-col cols="6" sm="6">
              <div class="icon icon-success">
                <img src="/assets/img/feacture1.png" height="250vw" />
              </div>
            </v-col>
            <v-col cols="6" sm="6" class="text-left pt-5">
              <h4 class="info-title text-left">Pagos Online</h4>
              <h5 class="info-title3">
                Botón y Link de Pago para incluir<br />
                en sitios web, enviar por email, <br />
                redes sociales o chat.
              </h5>
            </v-col>
          </v-row>
          <v-row>
            <!-- Pago en Efectivo -->
            <v-col cols="6" sm="6">
              <div class="icon icon-success">
                <img src="/assets/img/feacture2.png" height="250vw" />
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="text-left pt-5">
              <h4 class="info-title text-left">Pagos en efectivo</h4>
              <h5 class="info-title3">
                Códigos de barra y cupones de<br />
                pago para incluir en tus facturas o<br />
                enviar por email.
              </h5>
            </v-col>
          </v-row>
          <v-row>
            <!-- Debito Directo en CBU -->
            <v-col cols="6" sm="6">
              <div class="icon icon-success">
                <img src="/assets/img/feacture3.png" height="250vw" />
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="text-left pt-5 pr-5">
              <h4 class="info-title text-left">Débito Directo en CBU</h4>
              <h5 class="info-title3">
                Automatiza tus cobranzas con <br />
                débito en cuenta bancaria de <br />
                cualquier banco de Argentina.
              </h5>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          class="pa-2 align-self-center text-right"
          outlined
          tile
          align="right"
        >
          <div>
            <img class="img" src="/assets/img/image_person2.png" style="max-width: 100%;"/>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-col> </v-col>
        <v-spacer></v-spacer>
        <v-col cols-6>
          <v-row class="texto-con-fondo img">
            <h1>
              Un boton de
              <br />pago para <br />simpificar <br />la forma en <br />que
              cobras
            </h1>
          </v-row>
          <v-row class="pb-5">
            <img src="/assets/img/smile.png" />
          </v-row>
        </v-col>
      </v-row>
      
    </div>

    <!-- <div class="sharing-area text-center">
            <div class="row justify-content-center">
              <h3>Nuestras redes sociales</h3>
            </div>
            <a
              id="twitter"
              class="btn btn-raised btn-twitter"
              href="https://twitter.com/telcorrientes"
              rel="nofollow"
            >
              <i class="fa fa-twitter"></i>
              Tweet
            </a>
            <a
              id="facebook"
              class="btn btn-raised btn-facebook"
              href="https://twitter.com/telcorrientes"
              rel="nofollow"
            >
              <i class="fa fa-facebook-square"></i>
              Facebook
            </a>
            <a
              id="instagram"
              class="btn btn-raised btn-instagram"
              href="https://twitter.com/telcorrientes"
              rel="nofollow"
            >
              <i class="fa fa-instagram"></i>
              Instagram
            </a>
          </div> -->
    <br />

    <Footer2 />
  </div>
</template>
<script>
import Footer2 from "@/views/Layouts/Footer2.vue";

export default {
  name: "NewHome",
  components: {
    Footer2,
  },
};
</script>
<style scoped>
.texto-con-fondo {
  background-image: radial-gradient(
    circle,
    #c2d501 38px,
    transparent 38px
  ); /* Fondo curvo con borde */
  background-size: 76px 76px; /* Tamaño de la curva (el doble del tamaño del borde) */
  padding: 38px; /* Ajuste de espaciado para que el contenido no se superponga con el borde */
}
.gradient-button {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: xx-large;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  font-weight: 900;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-image: linear-gradient(
    to right,
    #c2d501 0%,
    #1db6c8 50.52%,
    #00b1eb 100%
  );
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
}
.gradient-button:hover {
  background-image: linear-gradient(
    to right #00b1eb 0% #1db6c8 50.52%,
    #c2d501 100%
  );
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.titulo_landing1 {
  font-style: normal;
  font-weight: 900;
  font-size: 2em;
  line-height: 55px;
  text-align: center;
  color: #00b1eb;
}
.info {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 1000;
  font-size: 10em;
  line-height: 50px;
  text-align: left;
  color: #ffffff;
}
.info-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 2em;
  line-height: 37px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}
.info-title2 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 39.01px;
  text-align: right;
  text-transform: uppercase;
  color: #000000;
}
.info-title3 {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 20px;
  line-height: 39.01px;
  text-align: left;
  color: #000000;
}
.info-title4 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 2em;
  line-height: 37px;
  text-align: end;
  align-content: center;
  color: #000000;
}
.img {
  width: 733px;
  padding-top: -22px;
}
@media (max-width: 420px) {
  .gradient-button {
    font-size: small;
  }
  .img {
    width: 80%;
    padding-bottom: 5%;
    top: -22px;
  }
  #celw {
    width: 100%;
  }
}
@media (min-width: 421px) and (max-width: 1000px) {
  .gradient-button {
    font-size: larger;
  }
}
@media (max-width: 300px) {
  .gradient-button {
    font-size: x-small;
  }
}
</style>
