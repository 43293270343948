import axios from 'axios';
export const AUTHENTICATION = process.env.VUE_APP_URLAUTHENTICATION;
export const Login = {
  // crear nueva cliente
  authenticator(payload) {
    return axios.post(AUTHENTICATION + "/users/login", payload)
  },

  restoreuser(payload) {
    return axios.post(AUTHENTICATION + "/users/restore-pass", payload)
  },


  //restauracion de contraseña desde el formulario de login
  async forgotPassword(username) {
    const req = await axios.post(`${AUTHENTICATION}/users/send-code-from-outside`, username);
    return req.data;
  }
}
