<template>
  <section class="home-container">
    <div>
      <p class="home-title text-white animate-fade-in-right animate-delay-1000">
        UNA <br />
        PLATAFORMA, <br />
        <span class="bold">
          TODAS LAS <br />
          SOLUCIONES
        </span>
      </p>
      <div style="align-self: flex-start; ">
        <div class="animate-fade-in-up animate-delay-500 relative z-50">
          <img
            src="../../../public/assets/img/celu.png"
            alt="home image"
            class="home-image"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrincipalHome",
};
</script>

<style scoped>
.home-container {
  background: #00b1eb;
}

.home-container > div:nth-child(1) {
  display: flex;
  justify-content: space-between;

  max-width: 1440px;
  margin: auto;
}

.home-title {
  font-size: 90px;
  line-height: 110px;
  align-self: center;
  padding-left: 3%;
  margin-bottom: 10%;
}

.bold {
  font-weight: bold;
}

.home-image {
  max-width: 100%;
  position: relative;
  z-index: 1000000;
}

@media (max-width: 1536px) {
  .home-container {
    background: #00b1eb;
    margin-top: -100px;
  }
  .home-title {
    font-size: 60px;
    line-height: 80px;
    padding-left: 6%;
  }

  .home-image {
    transform: scale(0.8);
  }
}

@media (max-width: 1280px) {
  .home-title {
    font-size: 45px;
    line-height: 63px;
    padding-left: 10%;
  }

  .home-image {
    transform: scale(0.7);
  }
}

@media (max-width: 1024px) {
  .home-title {
    font-size: 35px;
    line-height: 53px;
    padding-left: 10%;
  }

  .home-image {
    transform: scale(0.6);
  }
}

@media (max-width: 768px) {
  .home-title {
    font-size: 25px;
    line-height: 40px;
    padding-left: 12%;
  }

  .home-image {
    transform: scale(0.55);
  }
}

@media (max-width: 640px) {
  .home-container {
    margin-top: -20px;
  }
  .home-title,
  .home-image {
    margin-bottom: 90px;
  }

  .home-title {
    padding-left: 7%;
  }
}

@media (max-width: 450px) {
  .home-title,
  .home-image {
    margin-bottom: 120px;
  }
  .home-image {
    transform: scale(0.95);
    padding-right: 7%;
  }
}
</style>
