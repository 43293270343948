const formatPorcentaje = (valor) => {
    const porcentaje = valor / 100;
  
    const format = porcentaje.toLocaleString("es-ar", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) + "%";
  
    return format;
  };
  
  export const formatPorcentajeFloat = (valor) => {
    const format = valor.toLocaleString("es-ar", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) + "%";
  
    return format;
  };
  
  export default formatPorcentaje;
  