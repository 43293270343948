<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-profile">
          <Avatar />
          <div class="card-body">
            <h3 class="card-category text-gray">Bienvenidos a WEE!</h3>
            <!-- <h4 class="card-title">Bienvenidos a WEE!!</h4> -->
            <br /><br />
            <p class="card-description" align="left">
              WEE! permite aceptar y realizar pagos digitales de forma segura,
              rápida y fácil. Nuestra plataforma de pago está diseñada para
              facilitar transacciones con los principales medios de pago. Desde
              su cuenta virtual podrá gestionar y conciliar todos los pagos
              recibidos. Explore todas las funcionalidades que hemos diseñado y
              comience a operar hoy mismo!
            </p>
            <a href="" class="d-lg-none">
              <router-link to="/plazos-acreditacion">
                <p class="font-bold fontF">
                  Plazos de acreditación
                </p>
              </router-link></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "./Avatar.vue";
export default {
  components: { Avatar },
  data() {
    return {
      content: "<h1>Contenido</h1>",
    };
  },
};
</script>
<style scoped>
.card .card-title {
  text-align: center;
}
.fontF {
  color: #00b1eb;
  text-decoration: underline;
  margin-top: 20px;
}
</style>
