var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPage',{attrs:{"title":"Reporte de cliente"}},[_c('v-row',{staticClass:"pt-0 mx-5"},[_c('v-col',{attrs:{"md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Fecha desde","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaDesde)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","max":new Date().toISOString().substr(0, 10),"color":"blue","dark":""},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.formatDate(_vm.fechaHasta),"label":"Fecha hasta","readonly":"","persistent-hint":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","min":_vm.fechaDesde,"max":new Date().toISOString().substr(0, 10),"dark":"","color":"blue"},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"outlined":"","color":"success","block":""},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-send-outline")]),_vm._v(" Solicitar ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-alert',{staticStyle:{"font-family":"Montserrat, sans-serif !important","font-size":"1.2em"},attrs:{"dense":"","elevation":"3","icon":"paid","outlined":"","prominent":"","text":"","type":"success"}},[_vm._v(" Total rendido: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatImport(_vm.totalRendido))+" ")])])],1)],1),_c('div',{staticClass:"mb-2 mt-3 mx-6 d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"green mx-2",attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.downloadExcel()}}},on),[_c('v-icon',[_vm._v("mdi-tray-arrow-down")])],1)]}}])},[_c('span',[_vm._v("Descargar excel")])]),_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"label":"Ocultar columnas","items":_vm.headers,"dense":"","outlined":"","hide-details":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.hiddenColumns.length - 1)+" seleccionados) ")]):_vm._e()]}}]),model:{value:(_vm.hiddenColumns),callback:function ($$v) {_vm.hiddenColumns=$$v},expression:"hiddenColumns"}}),_c('v-checkbox',{staticClass:"ma-0 ml-5",attrs:{"hide-details":"","dense":""},on:{"change":_vm.getData},model:{value:(_vm.allClients),callback:function ($$v) {_vm.allClients=$$v},expression:"allClients"}}),_c('p',{staticClass:"mb-0",staticStyle:{"cursor":"pointer","margin-top":"5px"},on:{"click":function($event){_vm.allClients = !_vm.allClients;
        _vm.getData();}}},[_vm._v(" Operaciones de todos los clientes ")])],1),_c('v-data-table',{attrs:{"headers":_vm.currentColumns,"items":_vm.data,"no-data-text":"Sin registros","disable-sort":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.fecha_pago",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_pago))+" ")]}},{key:"item.fecha_presentacion",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.fecha_presentacion ? _vm.formatDate(item.fecha_presentacion) : "-")+" ")]}},{key:"item.fecha_acreditacion",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.fecha_acreditacion ? _vm.formatDate(item.fecha_acreditacion) : "-")+" ")]}},{key:"item.fecha_rendicion",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.fecha_rendicion ? _vm.formatDate(item.fecha_rendicion) : "-")+" ")]}},{key:"item.nro_establecimiento",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.nro_establecimiento ? item.nro_establecimiento : "-")+" ")]}},{key:"item.nro_liquidacion",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.nro_liquidacion ? item.nro_liquidacion : "-")+" ")]}},{key:"item.importe_a_pagar",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.importe_a_pagar))+" ")]}},{key:"item.importe_pagado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.importe_pagado))+" ")]}},{key:"item.importe_neto_cobrado",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.importe_neto_cobrado))+" ")]}},{key:"item.retencion_iva",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.retencion_iva))+" ")]}},{key:"item.comision_proveedor",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.comision_proveedor))+" ")]}},{key:"item.iva_comision_proveedor",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.iva_comision_proveedor))+" ")]}},{key:"item.comision_telco",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.comision_telco))+" ")]}},{key:"item.iva_comision_telco",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.iva_comision_telco))+" ")]}},{key:"item.referencia_bancaria",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.referencia_bancaria ? item.referencia_bancaria : "-")+" ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center  ma-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage,"total-visible":15},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.pagination.number),callback:function ($$v) {_vm.$set(_vm.pagination, "number", $$v)},expression:"pagination.number"}}),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":_vm.rowsPerPageArray,"label":"Mostrar","dense":"","hide-details":""},on:{"input":function($event){return _vm.getData(true)}},model:{value:(_vm.pagination.size),callback:function ($$v) {_vm.$set(_vm.pagination, "size", $$v)},expression:"pagination.size"}})],1)]},proxy:true}])}),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"object":"#4caf50","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"40","disableScrolling":"false","name":"box"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }