var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPage',{attrs:{"title":"Reversiones"}},[_c('v-expansion-panels',{staticClass:"mb-3",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{key:0},[_c('v-expansion-panel-header',{staticClass:"outline_none",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',{staticClass:"primary--text text-subtitle-1 mt-0"},[_vm._v("Filtros")]),_c('v-icon',{attrs:{"size":"33","color":"success"}},[_vm._v(" search ")])]},proxy:true}])}),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"pt-0 mx-5"},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"label":"Fecha de inicio","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaInicio)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","max":new Date().toISOString().substr(0, 10)},on:{"change":function($event){{
                    _vm.page = 1;
                    _vm.getContracargos();
                  }}},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"label":"Fecha de fin","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaFin)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","min":_vm.fechaInicio,"max":new Date().toISOString().substr(0, 10)},on:{"change":function($event){{
                    _vm.page = 1;
                    _vm.getContracargos();
                  }}},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1)],1)],1)],1)],1)],1),(!_vm.loading)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pago_tipo_data.length > 0),expression:"pago_tipo_data.length > 0"}]},[_c('p',{staticClass:"text-subtitle-1 text-start px-5 mt-4 mb-0"},[_vm._v(" Cuenta: "),_c('b',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.cuenta))])]),_c('div',{staticClass:"d-flex px-5 mb-1 align-center mt-1"},[_c('p',{staticClass:"text-subtitle-1 text-start mb-0",staticStyle:{"width":"120px"}},[_vm._v(" Pagos tipo: ")]),_c('v-tabs',{attrs:{"hide-slider":"","height":"37","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.pago_tipo),function(item){return _c('v-tab',{key:item.id,attrs:{"active-class":"orange rounded-pill white--text","href":("#" + (item.id))}},[_vm._v(" "+_vm._s(item.pagotipo)+" ")])}),1)],1)]):_c('v-skeleton-loader',{staticClass:"px-3 mt-4",attrs:{"type":"heading","max-width":"400"}}),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.pago_tipo_data,"loading":_vm.loading,"hide-default-footer":"","loading-text":"Cargando...","no-data-text":"Sin registros para mostrar","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha.substring(0, 10)))+" ")]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.amount))+" ")]}},{key:"item.estado",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.estado ? item.estado : "-")+" ")]}},{key:"item.referencia_bancaria",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.referencia_bancaria ? item.referencia_bancaria : "-")+" ")]}},{key:"item.intento",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"orange darken-2","min-width":"140"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"orange","fab":"","dark":"","x-small":""},on:{"click":function($event){return _vm.showPagoIntento(item)}}},on),[_c('v-icon',[_vm._v("mdi-eye-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Intento ID: "+_vm._s(item.pago_intento.id))])])]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"subheading font-weight-bold",staticStyle:{"font-family":"Montserrat"}},[_vm._v(" Detalle pago intento ")]),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"text-subtitle-1",staticStyle:{"height":"400px"}},[_c('v-data-table',{staticClass:"hide-header",attrs:{"headers":_vm.headerItento,"items":_vm.pago_intento,"hide-default-footer":"","mobile-breakpoint":"100000","disable-sort":"","disable-filtering":"","no-data-text":"Sin datos para mostrar"},scopedSlots:_vm._u([{key:"item.external_id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.external_id ? item.external_id : "-")+" ")]}},{key:"item.barcode",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.barcode ? item.barcode : "-")+" ")]}},{key:"item.barcode_url",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.barcode_url ? item.barcode_url : "-")+" ")]}},{key:"item.holder_cbu",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.holder_cbu ? item.holder_cbu : "-")+" ")]}},{key:"item.site_id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.site_id ? item.site_id : "-")+" ")]}},{key:"item.paid_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.paid_at))+" ")]}},{key:"item.report_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.report_at))+" ")]}},{key:"item.available_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.available_at))+" ")]}},{key:"item.reverted_at",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.reverted_at))+" ")]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.amount))+" ")]}},{key:"item.valorcupon",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.valorcupon))+" ")]}},{key:"item.is_available",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_available ? "Si" : "No")+" ")]}}],null,true)})],1),_c('v-divider',{staticClass:"mb-0"}),_c('v-card-actions',{staticClass:"white justify-end"},[_c('v-btn',{staticClass:"my-1 text-subtitle-1 mr-2",attrs:{"outlined":"","rounded":"","color":"green"},on:{"click":function($event){return _vm.showCierreLote(_vm.pago_intento[0])}}},[_vm._v(" VER CIERRE DE LOTE ")]),_c('v-btn',{staticClass:"my-1 text-subtitle-1",attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" CERRAR ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"950px","scrollable":""},model:{value:(_vm.dialogCierreLote),callback:function ($$v) {_vm.dialogCierreLote=$$v},expression:"dialogCierreLote"}},[_c('v-card',[_c('v-card-title',{staticClass:"subheading font-weight-bold",staticStyle:{"font-family":"Montserrat"}},[_vm._v(" Detalle cierre de lote ")]),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',{staticClass:"text-subtitle-1",staticStyle:{"height":"400px"}},[_c('v-data-table',{staticClass:"hide-header",attrs:{"headers":_vm.headerCierreLote,"items":_vm.cierre_lote,"hide-default-footer":"","mobile-breakpoint":"100000","disable-sort":"","disable-filtering":"","no-data-text":"Sin datos para mostrar"},scopedSlots:_vm._u([{key:"item.fecha_pago",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_pago))+" ")]}},{key:"item.fecha_cierre",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha_cierre))+" ")]}},{key:"item.fechaoperacion",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fechaoperacion))+" ")]}},{key:"item.montofinal",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.montofinal))+" ")]}},{key:"item.monto",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.monto))+" ")]}},{key:"item.conciliacion",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.conciliacion ? "Si" : "No")+" ")]}},{key:"item.disputa",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.disputa ? "Si" : "No")+" ")]}},{key:"item.estadomovimineto",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.estadomovimineto ? "Si" : "No")+" ")]}},{key:"item.reversion",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.reversion ? "Si" : "No")+" ")]}}],null,true)})],1),_c('v-divider',{staticClass:"mb-0"}),_c('v-card-actions',{staticClass:"white justify-end"},[_c('v-btn',{staticClass:"my-1 text-subtitle-1",attrs:{"text":"","rounded":""},on:{"click":function($event){_vm.dialogCierreLote = false}}},[_vm._v(" CERRAR ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }