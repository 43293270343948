<template>
  <div class="wrapper">
    <div
      class="sidebar"
      data-color="purple"
      data-background-color="white"
      style="z-index: 0;position:fixed;"
    >
      <!-- <div class="logo">
        <a href="#" class="simple-text logo-normal"> TelCo Pagos </a>
      </div> -->
      <div class="sidebar-wrapper ps-container ps-theme-default">
        <Ayuda />
      </div>
      <div
        class="sidebar-background"
        style="background-image: url(/assets/img/fondo.jpg)"
      ></div>
    </div>
    <div class="main-panel ps-container ps-theme-default ps-active-y">
      <div data-app class="content">
        <Index />
      </div>

      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
      <Footer />
    </div>
  </div>
</template>

<script>
import Ayuda from "@/views/Layouts/Ayuda.vue";
import Index from "@/components/Ayuda/Index.vue";
import Footer from "@/views/Layouts/Footer.vue";

export default {
  name: "AyudaView",
  components: {
    Footer,
    Ayuda,
    Index,
  },
};
</script>
