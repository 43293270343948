import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Reportes = {
  // Datos de reporte con fecha desde hasta
  getReporteDesdeHasta(params) {
    return axios.get(PASARELA + "/reporte/cliente", { params });
  },
};

const reporteMovimiento = async (params) => {
  const data = await axios.get(PASARELA + "/reporte/movimientos-comisiones", {
    params,
  });

  return data;
};

const reporteMovimientoTemporal = async (params) => {
  const data = await axios.get(
    PASARELA + "/reporte/movimientos-comisiones-temporales",
    {
      params,
    }
  );

  return data;
};

const reporteCobranza = async (params) => {
  const { data } = await axios.get(PASARELA + "/reporte/cobrazas-cliente", {
    params,
  });

  return data;
};

const reporteRendiciones = async (params) => {
  const { data } = await axios.get(PASARELA + "/reporte/rendiciones-cliente", {
    params,
  });

  return data;
};

//Reportes para Cuentas Split
const reporteRendicionesSubcuentas = async (params) => {
  const { data } = await axios.get(PASARELA + "/reporte/rendiciones-cliente-subcuentas", {
    params,
  });

  return data;
};


const reporteReversiones = async (params) => {
  const { data } = await axios.get(PASARELA + "/reporte/reversiones-cliente", {
    params,
  });

  return data;
};


const reporteClientes = async (params) => {
  const { data } = await axios.get(PASARELA + "/reporte/cliente", {
    params,
  });

  return data;
};

export default {
  reporteMovimiento,
  reporteCobranza,
  reporteRendiciones,
  reporteRendicionesSubcuentas,
  reporteReversiones,
  reporteMovimientoTemporal,
  reporteClientes
};
