import axios from 'axios';
import store from '../store';


axios.interceptors.request.use(function(config) {
  const api_key_prueba = store.state.api_key_prueba;
      config.headers.common = {
        "apiKey": api_key_prueba,
        "Content-Type": "application/json"
      };
    return config;
}, function(err) {
    return Promise.reject(err);
});