import { Notificaciones } from "../../services/notificaciones.js";
import { Refresh } from "../../services/refreshToken.js";
import moment from "moment";
import store from "../index.js";


export default {
    namespaced: true,
  state:{ 
      avisos: [],
      avisosNl:[],
  //  numero de pagina y total de registros
      number: 1,
      size: 10,
      page: 1,
      total: 0,
      visible: 7,
      read:undefined,
  // numero de registro por pagina
      rowsPerPage: 9,
  
  //errores de api
      errored: false,
      error: false,
  
      //  filter fechas inicio y fin
      fechaInicio: moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD"),
      fechaFin: moment().format("YYYY-MM-DD"),
      email:"",
    
      abierto: false,
      cargando: false,
      notificaciones:"",
      
      
  
  },
  
  mutations :{

    actualizarAviso(state, avisoActualizado) {
      // Buscar el índice del aviso en el estado basado en su ID
      const indice = state.avisos.findIndex(aviso => aviso.avisos_id === avisoActualizado.id);
      
      // Si se encuentra el aviso en el estado, actualizarlo de manera segura
      if (indice !== -1) {
        // Crear un nuevo objeto aviso con los datos actualizados
        const avisoActualizadoConID = {
          ...state.avisos[indice],  // Copiar los datos del aviso existente
          ...avisoActualizado  // Aplicar los datos actualizados
        };
        
        // Crear un nuevo arreglo de avisos con el aviso actualizado
        const nuevosAvisos = [
          ...state.avisos.slice(0, indice),  // Copiar los avisos anteriores al aviso actualizado
          avisoActualizadoConID,  // Agregar el aviso actualizado
          ...state.avisos.slice(indice + 1)  // Copiar los avisos posteriores al aviso actualizado
        ];
    
        // Asignar el nuevo arreglo de avisos al estado
        state.avisos = nuevosAvisos;
      }
    },

      eliminarAviso(state, id) {
        // Encuentra el índice del aviso con el ID proporcionado
        const index = state.avisos.findIndex(aviso => aviso.avisos_id === id);
        // Si se encontró el aviso, elimínalo del array de avisos
        if (index !== -1) {
          state.avisos.splice(index, 1);
        }
      },

      // mutations aviso
      setAvisos(state, avisos) {
        state.avisos = avisos;
      },

      setAvisosNoleidos(state,avisosNl){
        state.avisosNl = avisosNl;
      },


      setId(state, value) {
          state.id = value;
      },
      setSize(state, value) {
        state.size = value;
      },
  
      setNombre(state, value) {
          state.nombre = value;
      },
  
      setVisto(state, value) {
        state.visto = value;
      },
     
      setAbierto(state, value) {
        state.abierto = value;
      },
  
      setPaginate(state, value) {
        state.page = value;
      },
  
      setCargando(state, value) {
        state.cargando = value;
      },
      
      setrowsPerPage(state, value) {
          state.rowsPerPage = value;
      },
      
      setFechaInicio(state, value) {
        state.fechaInicio = value;
      },
  
      setFechaFin(state, value) {
        state.fechaFin = value;
      },
      setErrord(state, value) {
          state.errored = value;
      },
  
      setTotal(state, value) {
        state.total = value;
      },
  
      setNotificaciones(state, value){
        state.notificaciones = value;
      },
      actualizarEstadoRead(state, value) {
        state.read = value;
      },

      },
  
      actions: {
        
        // Traer avisos
        async getAvisos({ commit, state }) {
          try {
            commit("setCargando", true);
            commit("setErrord", false);
            let response;
            
            const params = {
                UserId: store.state.UserId,
                Number: state.page,
                Size: state.rowsPerPage,
                OrderFechaBool: true,
                OrderByFecha: 'desc',
                leido: state.read
            };

          if (state.read === undefined) delete params.leido
          
            
            // Llamo al servicio para obtener los avisos
            response = await Notificaciones.getAvisos(params);
        
            // Verificar si la respuesta contiene datos de avisos
            if (!response?.data?.data?.Avisos) {
              // Si no hay avisos, actualiza el store con un array vacío y otros valores
              commit("setAvisos", []);
              commit("setPaginate", 1);
              commit("setTotal", 0);
              commit("setCargando", false);
            } else {
              // Si hay avisos, actualiza el store con los datos recibidos
              commit("setAvisos", response.data.data.Avisos);
              commit("setPaginate", response.data.data.meta.page.currentPage);
              commit("setTotal", response.data.data.meta.page.lastPage);
            }
          } catch (error) {
            // Manejo de errores
            // console.error("Error al obtener los Avisos Store:", error);
            // Verificar si el error es un 403 (Forbidden)
            if (error.response && error.response.status === 403) {
              // Llamar a la función para obtener un nuevo token de refresco
              try {
                await Refresh.getRefreshToken();
                // Una vez obtenido el nuevo token, puedes intentar nuevamente la llamada a getAvisos
                return await dispatch('getAvisos', { commit, state });
              } catch (refreshError) {
                // console.error("Error al obtener el token de refresco:", refreshError);
                // Manejar el error de obtención de token de refresco según sea necesario
              }
            } else {
              // Lanza el error nuevamente para que pueda ser manejado por componentes superiores
              throw error;
            }
          } finally {
            // Finalmente, actualiza el estado de 'cargando' a false
            commit("setCargando", false);
          }
        },


        async getAvisosNoleidos({ commit, state }) {
          try {
            commit("setCargando", true);
            commit("setErrord", false);
            const params = {UserId:store.state.UserId, Number: state.page, Size: state.rowsPerPage, OrderFechaBool: true, OrderByFecha: 'desc',leido: false};
            // Llamas al servicio para obtener los avisos
            const response = await Notificaciones.getAvisos(params);
            commit("setAvisosNoleidos", response.data.data.Avisos);
            return response
          } catch (error) {
            // Manejo de errores
            // console.error("Error al obtener los Avisos Store:", error);
            // Verificar si el error es un 403 (Forbidden)
            if (error.response && error.response.status === 403) {
              // Llamar a la función para obtener un nuevo token de refresco
              try {
                await Refresh.getRefreshToken();
                // Una vez obtenido el nuevo token, puedes intentar nuevamente la llamada a getAvisos
                return await dispatch('getAvisosNoleidos', { commit, state });
              } catch (refreshError) {
                // console.error("Error al obtener el token de refresco:", refreshError);
                // Manejar el error de obtención de token de refresco según sea necesario
              }
            } else {
              // Lanza el error nuevamente para que pueda ser manejado por componentes superiores
              throw error;
            }
          } finally {
            // Finalmente, actualiza el estado de 'cargando' a false
            commit("setCargando", false);
          }
        },
        
        
        // Crear aviso
        async createAviso({ commit }, payload) {
          try {
            // Llama al servicio para crear un nuevo aviso
            const response = await Notificaciones.createAviso(payload);
            // Devuelve la respuesta si es necesario
            return response;
          } catch (error) {
            // Manejar cualquier error que ocurra durante la creación del aviso
            // console.error("Error creating aviso Store:", error);
            // Lanza el error nuevamente para que pueda ser manejado por componentes superiores
            throw error;
          }
        },
      
        // Editar Aviso
        async editarAviso({ commit }, nuevosAvisos) {
          try {
            const response = await Notificaciones.putAviso(nuevosAvisos);
            // Llamar a una mutación para actualizar el estado del aviso editado
            commit('actualizarAviso', response?.data); // Suponiendo que tu mutación se llama 'actualizaraviso'
            return response;
          } catch (error) {
            // console.error("Error editando aviso Store:", error);
            throw error;
          }
        },
      
        // Eliminar Aviso
        async eliminarAviso({ commit }, { id }) {
          try {
            await Notificaciones.deleteAviso({ avisoid: id });
            commit('eliminarAviso1', id);
          } catch (error) {
            // console.error("Error eliminando aviso Store:", error);
            throw error;
          }
        },

      },
  
  getters: {
      fechaInicio: (state) => state.fechaInicio,
      fechaFin: (state) => state.fechaFin,
      pages: (state) => state.page,
      visto: (state) => state.visto,
      read:(state) => state.read,
  
      // notificaciones:(state) => state.notificaciones,
      avisos:(state) => state.avisos,
      avisosNl:(state) => state.avisosNl,
          },
        modules: {},
  }
