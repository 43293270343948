
<template> 
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card card-profile">
                 <Avatar/>
                <div class="card-body">
                <div id="navigation-pills">
                <div class="title">
                  <h3>Introducción</h3>
                </div>
                <div class="title"></div>
                    <div class="row">
                      <div class="col-md-12 col-xl-12">
                        <div class="row">
                          <div class="col-md-2 col-xl-1">
                            <ul class="nav nav-pills nav-pills-icons" role="tablist">
                              <li class="nav-item">
                                <a class="nav-link active show" href="#dashboard-2" role="tab" data-toggle="tab" aria-selected="true">
                                  <i class="material-icons">dashboard</i>
                                  Preguntas Frecuentes
                                </a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link" href="#schedule-2" role="tab" data-toggle="tab" aria-selected="false">
                                  <i class="material-icons">schedule</i>
                                  Medios de Pago
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-10 col-xl-11">
                            <div class="tab-content">
                              <div class="tab-pane active show" id="dashboard-2">
                                <div class="card card-nav-tabs">
                                  <div class="card-header card-header-success">
                                    <div class="nav-tabs-navigation">
                                      <div class="nav-tabs-wrapper">
                                        <ul class="nav nav-tabs" data-tabs="tabs">
                                          <li class="nav-item">
                                            <a class="nav-link active show" href="#profile" data-toggle="tab">
                                              <i class="material-icons">face</i>
                                              Tarifas
                                            <div class="ripple-container"></div></a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" href="#messages" data-toggle="tab">
                                              <i class="material-icons">chat</i>
                                              Retirar saldo
                                            <div class="ripple-container"></div></a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" href="#settings" data-toggle="tab">
                                              <i class="material-icons">build</i>
                                              Cuentas
                                            <div class="ripple-container"></div></a>
                                          </li>
                                           <li class="nav-item">
                                            <a class="nav-link" href="#settings1" data-toggle="tab">
                                              <i class="material-icons">build</i>
                                              Cobrar
                                            <div class="ripple-container"></div></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body ">
                                    <div class="tab-content text-center">
                                      <div class="tab-pane" id="profile">
                                        <p align="left"><strong>¿Tiene costo una Cuenta Wee!?</strong><br> No. Todas las Cuentas Wee! son gratuitas. Sin costos de alta, configuración, mantenimiento o cierre.<br><br> <strong>¿Debo considerar otros costos de los medios de pago?</strong> <br>No. La tarifa de procesamiento de Wee! incluye aranceles, impuestos y retenciones de los medios de pagos. </p>
                                      </div>
                                      <div class="tab-pane active show" id="messages">
                                        <p align="left"> <strong>¿Puedo retirar saldo a múltiples cuentas bancarias? </strong><br>Sí, puede vincular todas sus cuentas bancarias y elegir en cada momento donde recibir sus fondos.</p>
                                      </div>
                                      <div class="tab-pane" id="settings">
                                        <p align="left"> <strong>¿Qué se necesita para abrir una cuenta? </strong><br> Solo debe contar con CUIT/CUIL y una dirección de email.</p>
                                      </div>
                                        <div class="tab-pane" id="settings1">
                                        <p align="left"> <strong>¿Debo integrarme con cada medio de pago? </strong><br> No. Con Wee! tiene la ventaja de acceder a todos los medios de pago disponibles sin realizar convenios ni integraciones adicionales.</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="tab-pane" id="schedule-2">
                                  <div class="card card-nav-tabs">
                                  <div class="card-header card-header-success">
                                    <div class="nav-tabs-navigation">
                                      <div class="nav-tabs-wrapper">
                                        <ul class="nav nav-tabs" data-tabs="tabs">
                                          <li class="nav-item">
                                            <a class="nav-link" href="#profile" data-toggle="tab">
                                              Medios de pagos habilitados y homologados para realizar transacciones de forma segura a través de telcopagos.com son:
                                            <div class="ripple-container"></div></a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body ">
                                    <div class="tab-content text-center">
                                      <div class="tab-pane active show" id="messages">
                                        <div class="card-body table-responsive">
                                          <table class="table table-hover">
                                            <thead class="text-success">
                                              <tr><th>Medio de Pago</th>
                                              <th>Marcas</th>
                                            </tr></thead>
                                            <tbody>
                                              <tr>
                                                <td>Tarjetas de Crédito</td>
                                                <td>Visa, Mastercard, American Express, Cabal, Naranja, Argencard, Nativa, Cordobesa, Cencosud, CMR Falabella y Diners Club</td>
                                              </tr>
                                              <tr>
                                                <td>Tarjetas de Débito</td>
                                                <td>Visa Débito, Mastercard Debit, Maestro y Cabal Débito</td>
                                               
                                              </tr>
                                              <tr>
                                                <td>Redes de pago en efectivo</td>
                                                <td>Cobro Express, Rapipago, Pago Fácil y Provincia NET Pagos.</td>
                                              </tr>
                                              <tr>
                                                <td>Medios de pago bancarios</td>
                                                <td>Link Pagos (Red Link), Pago Mis Cuentas (Banelco), DEBIN y Débito en CBU en cualquier cuenta bancaria de Argentina.</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>
<script>
import Avatar from '@/components/Ayuda/Avatar.vue'
 export default {
    name: 'Introduccion',
     components: {
    Avatar,
  },
  }
</script>
<style scoped>
.card .card-title {
     text-align: center;
}
.nav-pills .nav-item .nav-link.active {
    color: #ffffff;
    background-color: #66bb6a;;
    box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%), 0 13px 24px -11px rgb(156 39 176 / 60%);
}
</style>