<template>
  <div>
    <h2>Consultar el estado de un Pago</h2>

    <p style="text-align: left">
      Las solicitudes de pagos que se realizan por la Api pasan por una serie de
      estados, el mismo puede ser consultado para verificar su condición.
      <br /><br />

      Para poder hacer uso del servicio
      <span style="font-weight: bold">consultar pago estado</span> necesita
      obtener un token por medio del
      <span style="font-weight: bold">servicio de login</span>. El token
      obtenido junto con una Api-Key son necesarios para consumir el recurso
      <span style="font-weight: bold">consultar pago estado </span>y de esta
      manera obtener el estado de uno (1) o varios pagos.
    </p>

    <!-- 
    |---------------------------------------------------------------------
    | Solicitud de Token
    |--------------------------------------------------------------------- 
    -->

    <h4 align="left"><strong>Solicitud de Token</strong></h4>
    <h5 align="left">
      Url:
      <p
        style="
          display: inline;
          font-size: 1rem;
          color: #6495ed;
          font-weight: bold;
        "
      >
        https://devlogin.telco.com.ar/
      </p>
    </h5>

    <p style="text-align: left">
      Realizar un POST a
      <span style="font-weight: bold">users/login</span> enviando usuario,
      contraseña y un id de sistema para obtener un token. El id de sistema es
      10 para las pruebas.
    </p>

    <h4 style="text-align: left">
      <strong>Ejemplo de POST para solicitar token</strong
      ><a
        style="color: grey; font-size: 1rem"
        class="font-weight-bold"
        href="#solicitar_token"
      >
        (ir al ejemplo)</a
      >
    </h4>

    <!-- TABLA SOLICITAR TOKEN -->
    <div class="card">
      <!-- TABLA DE Header TOKEN -->
      <div class="table-responsive">
        <table id="request_table" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="font-weight-bold text-center header_background"
                style="font-size: 1rem"
                colspan="5"
              >
                Header
              </th>
            </tr>
            <tr>
              <th class="text-secondary text-xxs font-weight-bolder opacity-7">
                Campos
              </th>
              <th
                class="
                  text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-2
                "
              >
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">Content-Type</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">application/json</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- TABLA DE BODY REQUEST TOKEN -->
      <div class="table-responsive">
        <table id="request_table" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="font-weight-bold text-center header_background"
                style="font-size: 1rem"
                colspan="5"
              >
                Body Request
              </th>
            </tr>
            <tr>
              <th class="text-secondary text-xxs font-weight-bolder opacity-7">
                Campos
              </th>
              <th
                class="
                  text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-2
                "
              >
                Descripción
              </th>
              <th
                class="
                  text-center text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                Obligatorio
              </th>
              <th
                class="
                  text-center text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                Tipo
              </th>
              <th class="text-secondary opacity-7">Ejemplo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">username</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  Nombre de usuario Es un e-mail
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm badge-success">Si</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-normal"
                  >String</span
                >
              </td>
              <td class="align-middle text-center text-sm pr-2">
                "demo-dev@telco.com.ar"
              </td>
            </tr>

            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">password</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  Contraseña generada por el sistema desde soporte técnico de
                  Wee!!
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm badge-success">Si</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-normal"
                  >String</span
                >
              </td>
              <td class="align-middle">"pass123456"</td>
            </tr>

            <tr>
              <td>
                <div class="d-flex px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">sistema_id</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  Identificador del sistema También generado por soporte técnica
                  de Wee!!
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm badge-success">Si</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-normal"
                  >String</span
                >
              </td>
              <td class="align-middle">"10"</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- TABLA DE RESPONSE TOKEN -->
      <div class="table-responsive">
        <table id="request_table" class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="font-weight-bold text-center header_background"
                style="font-size: 1rem"
                colspan="5"
              >
                Response
              </th>
            </tr>
            <tr>
              <th class="text-secondary text-xxs font-weight-bolder opacity-7">
                Campos
              </th>
              <th
                class="
                  text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  ps-2
                "
              >
                Valor
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">token</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..."
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">refreshtoken</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  "eyJhbGci...gxe-aa9L3ltMIRGvpPdv4"
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">activo</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">""</p>
              </td>
            </tr>

            <tr>
              <td>
                <div class="px-2 py-1">
                  <p class="mb-0 text-xs nombre_campo">confirmar</p>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">fasle</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <v-divider></v-divider>
    <!-- 
    |---------------------------------------------------------------------
    | Consultar un Pago
    |--------------------------------------------------------------------- 
    -->

    <h4 align="left"><strong>Consultar estado pago/s</strong></h4>
    <h5 align="left">
      Url:
      <p
        style="
          display: inline;
          font-size: 1rem;
          color: #6495ed;
          font-weight: bold;
        "
      >
        https://devlogin.telco.com.ar/
      </p>
    </h5>
    <p style="text-align: left">
      Realizar un GET a
      <span style="font-weight: bold">administracion/consulta/pago</span> para
      obtener información de los estados de los pagos.
    </p>
    <h4 style="text-align: left">
      <strong>Ejemplo de GET para consultar pago/s</strong>
      <a
        style="color: grey; font-size: 1rem"
        class="font-weight-bold"
        href="#consultar_pagos"
      >
        (ir al ejemplo)</a
      >
    </h4>
    <div class="mt-4"></div>
    <!-- HEADERS -->
    <h6>Headers</h6>
    <table class="table table-hover">
      <tbody>
        <tr>
          <td>apiKey</td>
          <td>"abcde-abcde-abcde-abcde"</td>
        </tr>
        <tr>
          <td>Content-Type</td>
          <td>application/json</td>
        </tr>
        <tr>
          <td>Cache-Control</td>
          <td>no-cache</td>
        </tr>
        <tr>
          <td>Authorization</td>
          <td>Bearer "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9..."</td>
        </tr>
      </tbody>
    </table>
    <!-- END HEADERS -->
    <v-divider color="primary"></v-divider>
    <!-- BODY REQUEST -->
    <h6>Body Request</h6>
    <table class="table table-hover">
      <thead class="text-success">
        <tr>
          <th>Campo</th>
          <th>Descripción</th>
          <th>Oblig</th>
          <th>Ejemplo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>uuid</td>
          <td>Identificador interno único de pago</td>
          <td>NO</td>
          <td>"abcde-abcde-abcde-abcde"</td>
        </tr>
        <tr>
          <td>external_reference</td>
          <td>Referencia externa indicada al crear el pago</td>
          <td>NO</td>
          <td>"155557"</td>
        </tr>
        <tr>
          <td>fecha_desde</td>
          <td>Fecha inicial del período a consultar</td>
          <td>NO</td>
          <td>
            {{
              moment()
                .subtract(10, "days")
                .format("DD-MM-YYYY")
            }}
          </td>
        </tr>
        <tr>
          <td>fecha_hasta</td>
          <td>Fecha final del período a consultar</td>
          <td>SI</td>
          <td>{{ moment().format("DD-MM-YYYY") }}</td>
        </tr>
        <tr>
          <td>uuids</td>
          <td>Conjunto de identificadores únicos de pago</td>
          <td>NO</td>
          <td>["abcde-abcde-abcde-abcde", "aabbcc-ddeeff-ggffhh-oobbcc"]</td>
        </tr>
        <tr>
          <td>external_references</td>
          <td>Conjunto de referencias externas, indicada al crear el pago</td>
          <td>NO</td>
          <td>["155557", "112233", "998877", "665544", "119966", "227711"]</td>
        </tr>
      </tbody>
    </table>
    <!-- END BODY REQUEST -->
    <v-divider color="primary"></v-divider>
    <!-- RESPONSE -->
    <h6>Response</h6>
    <div class="tab-pane" id="tab2">
      <table class="table table-hover">
        <thead class="text-success">
          <tr>
            <th class="primary--text">Campo</th>
            <th class="primary--text">Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>uuid</td>
            <td>Identificador único de pago</td>
          </tr>
          <tr>
            <td>external_reference</td>
            <td>Identificador externo indicado</td>
          </tr>
          <tr>
            <td>metadata</td>
            <td>Información agregada para uso externo</td>
          </tr>
          <tr>
            <td>fecha</td>
            <td>Fecha de la última actualización del pago</td>
          </tr>
          <tr>
            <td>estado</td>
            <td>Estado actual del Pago</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END RESPONSE -->
    <p style="text-align: left">
      <strong>Nota:</strong> Los parámetros pasados por la request dependen de
      si desea buscar el estado de un pago en específico o el estado de un grupo
      de pagos que se encuentre en un rango de fecha. Para el caso de realizar
      una consulta por un pago debe enviar el uuid o external-reference, en el
      caso de realizar una consulta por los pagos en un rango de fecha debe
      utilizar fecha_desde y fecha_hasta.
    </p>
    <GetPago />
  </div>
</template>

<script>
import GetPago from "./GetPago.vue";

export default {
  name: "ConsultaPago",
  components: {
    GetPago,
  },
};
</script>
<style scoped>
#request_table {
  -moz-box-shadow: inset -3px -3px 6px #86b;
  -webkit-box-shadow: inset -3px -3px 6px #86b;
  box-shadow: inset -3px -3px 6px #86b;
}
.header_background {
  background: #8866bb5e;
}
.nombre_campo {
  font-weight: bold;
  font-style: italic;
}
</style>
