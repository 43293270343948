import { Facturacion } from '../../services/facturacion.js';
import { Refresh } from '../../services/refreshToken';
export default {
  namespaced: true,
  state: {
    id_clietne: 1,
    data: [],
    ivafacturacion: [],
    iibbfacturacion: [],
    cuenta: [],
    loading: false,
    error: false,
  },
  mutations: {
    // mutation de facturacion 
    setFacturacion(state, response) {
      state.data = response.data;
      state.iva = response.data.iva;
      state.iibb = response.data.iibb;
      state.cuenta = response.data.cuenta;
    },
    setIdCliente(state, id) {
      state.id_clietne = id;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setError(state, value) {
      state.error = value;
    }

  },
  actions: {
    // esta funcion recibe rootState (estado que contiene el clienteID del store/index)
    async fetchFacturacion({ commit, state }) {
      const params = {
        id: state.id_clietne,
        CargarCuentas: true,
        CargarCuentas: true,
        CargarRubros: true
      }
      commit('setLoading', true);
      commit('setError', false);
      await Facturacion.informacion_facturacion(params)
        .then(response => {
          commit('setFacturacion', response);
        }).catch(error => {
          var data = error.response.status;
          if (data == 403) {
            async () => {
              try {
                await Refresh.getRefreshToken()
                  .then(response => {
                    //
                  })

              } catch (error) {
                //
              }
            }

          } else {
            commit('setError', true)
          }
        }).finally(() => commit('setLoading', false));
    },
  },
  modules: {
  }
}
