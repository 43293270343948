<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-profile">
          <Avatar />
          <div class="card-body">
            <div id="navigation-pills">
              <div class="title">
                <h3>Pagos</h3>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <ul
                        class="nav nav-pills nav-pills-icons"
                        role="tablist"
                        style="justify-content: center"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active show"
                            href="#solicitud"
                            role="tab"
                            data-toggle="tab"
                            aria-selected="true"
                          >
                            <i class="material-icons">schedule_send</i>

                            Solicitud
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link show"
                            href="#consulta"
                            role="tab"
                            data-toggle="tab"
                            aria-selected="true"
                          >
                            <i class="material-icons">reply_all</i>
                            Consulta
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-10">
                      <div class="tab-content">
                        <div class="tab-pane active show" id="solicitud">
                          <div class="card card-nav-tabs">
                            <div class="card-header card-header-success">
                              <div class="nav-tabs-navigation">
                                <div class="nav tabs-wrapper">
                                  <ul class="nav nav-tabs" data-tbs="tabs">
                                    <li class="nav-item">
                                      <a
                                        class="nav-link"
                                        href="#"
                                        data-toggle="tab"
                                      >
                                        Solicitud
                                        <div class="ripple-container"></div>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="tab-content text-center">
                                <div class="tab-pane active show" id="solic">
                                  <div class="card-body">
                                    <SolicitudPago />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="consulta">
                          <div class="card card-nav-tabs">
                            <div class="card-header card-header-success">
                              <div class="nav-tabs-navigation">
                                <div class="nav-tabs-wrapper">
                                  <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item">
                                      <a
                                        class="nav-link"
                                        href="#cons"
                                        data-toggle="tab"
                                      >
                                        Consulta
                                        <div class="ripple-container"></div>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="tab-content text-center">
                                <div class="tab-pane active show" id="cons">
                                  <div
                                    class="card-body"
                                    style="overflow: scroll"
                                  >
                                    <ConsultaPago />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/Ayuda/Avatar.vue";
import SolicitudPago from "./SolicitudPago.vue";
import ConsultaPago from "./ConsultaPago.vue";
export default {
  name: "AyudaPagos",
  components: {
    Avatar,
    SolicitudPago,
    ConsultaPago,
  },
};
</script>
<style scoped>
.card .card-title {
  text-align: center;
}
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #00b1eb;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(17 82 40 / 60%);
}
</style>
