<template>
  <div>
    <div class="title-footer">
      <h3>¿Querés más información? <br />¡Contactate con nosotros!</h3>
    </div>

    <div style="background: #fff; padding-bottom: 100px;">
      <section id="pre-footer">
        <div>
          <h5>Para adquirir o consultar sobre Wee! Pagos:</h5>
          <p class="mb-2 pre-text">Envianos un mail a:</p>
          <p class="pre-text mb-2">
            <span class="bold">info@telco.com.ar</span> o
            <span class="bold">comercial@telco.com.ar</span> con la siguiente
            información:
          </p>
          <ul class="list-disc">
            <li>Nombre y apellido</li>
            <li>CUIT</li>
            <li>Razón social</li>
            <li>Email</li>
            <li>Teléfono</li>
          </ul>
        </div>

        <div>
          <h5>Para consultas por pagos realizados:</h5>
          <p class="mb-2 pre-text">Envianos un mail a:</p>
          <p class="pre-text mb-2">
            <span class="bold">wee@telco.com.ar</span> o
            <span class="bold">reclamoswee@telco.com.ar</span> con la siguiente
            información:
          </p>
          <ul class="list-disc">
            <li>Nombre y apellido</li>
            <li>DNI</li>
            <li>Comprobante de pago</li>
            <li>Email</li>
            <li>Teléfono</li>
          </ul>
        </div>
      </section>
    </div>

    <div style="background: #00b1eb;">
      <Footer />
    </div>

    <!-- <section id="footer">
      <footer>
        <div class="footer-item">
          <v-icon class="footer-icon">mdi-map-marker-outline</v-icon>
          <p class="mb-0">Dr. R. Carrillo 444</p>
        </div>
        <div class="footer-item">
          <v-icon class="footer-icon">mdi-phone</v-icon>
          <p class="mb-0">0810-555-8352</p>
        </div>

        <div class="footer-item">
          <v-icon class="footer-icon">mdi-web</v-icon>
          <p class="mb-0">telco.com.ar</p>
        </div>
      </footer>
    </section> -->
  </div>
</template>

<script>
import Footer from "../../views/Layouts/Footer.vue";

export default {
  name: "FooterHome",
  components: { Footer },
};
</script>

<style scoped>
/* FOOTER */

.title-footer {
  display: flex;
  justify-content: center;
  background: #fff;
}

.title-footer > h3 {
  color: #fff;
  font-size: 35px;
  background: #00b1eb;
  padding: 25px 40px;
  border-radius: 60px;
  font-weight: bold;
}

/* FOOTER */
#footer {
  background: #fff;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1600px;
  margin: auto;
  padding: 120px 40px 50px;
}

.footer-item {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.footer-icon {
  color: #fff;
  background: #00b1eb;
  font-size: 30px;

  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.footer-item > p,
.pre-text {
  color: #000;
  font-size: 18px;
}

#pre-footer {
  display: flex;
  justify-content: space-between;
  max-width: 1250px;
  margin: auto;
  padding-top: 50px;
  gap: 25px;
}

#pre-footer > div {
  border: 2px solid #00b1eb;
  flex-grow: 1;
  padding: 30px;
  border-radius: 20px;
  color: #000;
}

h5 {
  font-weight: bold;
  color: #00b1eb;
  font-size: 24px;
}

.bold {
  font-weight: bold;
}

ul {
  font-size: 18px;
  margin-left: 15px;
}

li {
  margin-bottom: 5px;
}

@media (max-width: 1550px) {
  footer {
    max-width: 100%;
    padding: 50px 5% 80px;
  }

  .title-footer > h3 {
    font-size: 30px;
    padding: 25px 40px;
    border-radius: 50px;
  }

  .footer-icon {
    font-size: 22px;
    max-height: 33px;
    min-width: 33px;
    max-width: 33px;
  }
}

@media (max-width: 1280px) {
  .title-footer > h3 {
    font-size: 25px;
    padding: 20px 35px;
    border-radius: 40px;
  }

  .footer-icon {
    font-size: 18px;
    max-height: 28px;
    min-width: 28px;
    max-width: 28px;
  }

  #pre-footer {
    max-width: 100%;
    padding: 50px 5% 10px;
  }

  .pre-text {
    font-size: 16px;
  }

  ul {
    font-size: 16px;
  }

  h5 {
    font-size: 19px;
  }
}

@media (max-width: 1024px) {
  .title-footer > h3 {
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 25px;
  }

  footer {
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;

    padding: 30px 5% 50px;
  }

  .footer-item {
    max-width: 50%;
    gap: 10px;
    justify-content: flex-start;
    flex-grow: 1;
  }

  .footer-item > p {
    font-size: 13px;
  }

  .footer-icon {
    font-size: 13px;
    max-height: 22px;
    min-width: 22px;
    max-width: 22px;
  }

  #pre-footer {
    flex-wrap: wrap;
  }
}
</style>
