<template>
  <div class="fondo-color">
    <div class="tam2">
      <v-card :elevation="24" class="box-shadow custom-rounded ">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="align-self-center" tile>
            <!-- Imagen que sobresale -->
            <v-img
              class="img celu-img"
              src="/assets/img/celu2.png"
              max-width="650"
              contain
            ></v-img>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="p-2 align-self-center"
            outlined
            tile
            align="center"
          >
            <!-- Texto -->
            <v-row no-gutters>
              <h2 class="tam">
                <b><strong>Wee!</strong></b> es un botón de pago desarrollado
                íntegramente por
                <b><strong>TelCo y creada por correntinos</strong></b>
                y permite que cualquier organismo o empresa pueda realizar
                <b
                  ><strong
                    >cobros electrónicos de forma simple rápida y
                    segura.</strong
                  ></b
                >
              </h2>
            </v-row>
            <!-- Imágenes debajo del texto en pantallas grandes -->
            <v-row class="d-md-flex">
              <v-col cols="6">
                <v-img
                  src="/assets/img/logo-telco.png"
                  max-width="200"
                  max-height="100"
                  contain
                ></v-img>
              </v-col>
              <v-col cols="6">
                <!-- Cambiado a 6 para que sumen 12 -->
                <v-img
                  src="/assets/img/wee_logo.png"
                  max-width="200"
                  max-height="100"
                  contain
                >
                </v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/views/Layouts/Footer.vue";

export default {
  name: "SobreNosotrosView",
  components: {
    Footer,
  },
};
</script>

<style scoped>
.celu-img {
  transform: rotate(-10deg);
  position: absolute;
  top: -410px; /* Ajusta el margen superior negativo para que la imagen sobresalga */
  left: -160px; /* Ajusta el margen izquierdo negativo para que la imagen sobresalga */
  z-index: 1;
}

.tam2 {
  max-width: 1340px !important;
  margin: auto;
  margin-bottom: 80px;
}

.fondo-color {
  background-color: white;
}
.tam2 {
  padding-bottom: 60px;
}
.custom-rounded {
  border-radius: 76px;
  background: #f9f9f9;
  height: 780px;
  display: flex;
  position: relative;
}

h2 {
  font-family: Montserrat;
  font-size: 38px;
  line-height: 55px;
}

@media (max-width: 1400px) {
  .tam2 {
    max-width: 100% !important;
    padding: 0 5%;
  }

  h2 {
    font-size: 25px !important;
  }

  .celu-img {
    transform: scale(0.8);
  }
}

@media (max-width: 1280px) {
  .tam2 {
    padding-top: 40px;
  }

  h2 {
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .celu-img {
    transform: scale(0.7);
    left: -220px;
  }
}

@media (max-width: 960px) {
  .celu-img {
    transform: none; /* Eliminamos la rotación en pantallas pequeñas */
    top: -50px; /* Ajusta el margen superior para que la imagen no sobresalga en pantallas pequeñas */
    left: 0; /* Ajusta el margen izquierdo para que la imagen no sobresalga en pantallas pequeñas */
    /* Vuelve al ancho automático en pantallas pequeñas */
  }
  .img {
    transform: scale(0.7);
    position: inherit;
    margin: auto;
  }
  .tam {
    font-family: Montserrat;
    font-size: 18px !important;
    line-height: 30px !important;
  }

  .custom-rounded {
    flex-direction: column;
    height: auto;
    padding: 15px !important;
    border-radius: 56px !important;
  }

  h2 {
    margin-top: -90px;
    margin-bottom: 40px;
  }
}
</style>
