<template>
  <div class="content">
    <div class="container-fluid">
      <v-app>
        <v-main>
          <!--CABECERA DE SALDO PENDIENTE Y SALDO DISPONIBLE-->
          <v-row no-gutters class="ma-4">
            <v-col cols="12">
              <v-alert
                elevation="3"
                icon="paid"
                outlined
                prominent
                text
                type="success"
                class="mr-3"
                style="font-family: Montserrat, sans-serif !important ; font-size: 1.1em;"
              >
                Saldo pendiente para ser autorizado:
                <span class="font-weight-bold">
                  {{ saldoPagos(saldoPendiente) }}
                </span>
              </v-alert>
            </v-col>
            <v-col cols="12" md="6" style="display: none;">
              <v-alert
                elevation="3"
                icon="paid"
                outlined
                prominent
                text
                type="success"
              >
                Saldo Disponible:
                <span class="font-weight-bold">
                  {{ saldoPagos(saldoDisponible) }}
                </span>
              </v-alert>
            </v-col>
          </v-row>

          <!--END CABECERA DE PAGOS-->

          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Pagos</h4>
              <p class="card-category"></p>
            </div>
            <!-- datos -->
            <!-- error toastr  -->
            <!-- <div v-if="error">
              <div class="container"></div>
            </div> -->

            <!-- ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde
              </v-alert>
            </section>
            <!-- END ERROR SECTION -->

            <!-- PAGOS DATA TABLE SECTION -->
            <section v-else>
              <!-- filtros table  -->
              <v-expansion-panels v-model="panel">
                <v-expansion-panel :key="0">
                  <v-expansion-panel-header
                    class="outline_none"
                    disable-icon-rotate
                  >
                    <template v-slot:actions>
                      <span class="primary--text text-subtitle-2 mt-0"
                        >Filtros</span
                      >
                      <v-icon size="33" color="success"> search </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- 1 fila de filtros -->
                    <v-row class="pt-0 mx-5">
                      <!-- Filtro fecha Desde -->
                      <v-col cols="6" md="3">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="ml-5"
                              label="Fecha pago desde"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              persistent-hint
                              :value="FormatDateDesde"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date1"
                            locale="es-ar"
                            no-title
                            scrollable
                            :max="new Date().toISOString().substr(0, 10)"
                            @change="inicio(date1)"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Filtro fecha Hasta -->
                      <v-col cols="6" md="3">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="ml-5"
                              label="Fecha pago hasta"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              persistent-hint
                              :value="FormatDateHasta"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date2"
                            locale="es-ar"
                            no-title
                            scrollable
                            :min="fechaDesde"
                            :max="new Date().toISOString().substr(0, 10)"
                            @change="fin(date2)"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- <v-col cols="6" md="3">
                            <v-select
                                :items="EstadosPago"
                                 item-text="nombre"
                                 :item-value="'ID'"
                                 v-model="pagos_estados"
                                 label="Estado del pago"
                              ></v-select>
                          </v-col> -->

                      <!-- Filtro fecha Pago Estado -->
                      <v-col cols="6" md="3">
                        <v-autocomplete
                          v-model="pagos_estados"
                          item-text="nombre"
                          item-value="ID"
                          :items="EstadosPago"
                          label="Estados del pago"
                          multiple
                          chips
                          small-chips
                          deletable-chips
                          clearable
                        >
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggleState"
                            >
                              <v-list-item-action>
                                <v-icon color="teal darken-2">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  TODOS
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                          <template
                            v-slot:append-outer
                            v-if="estado.length !== 2"
                          >
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                :key="`icon-${isEditing}`"
                                :color="'black'"
                                @click="limpiar_filtro()"
                                >mdi-reload</v-icon
                              >
                            </v-slide-x-reverse-transition>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="6" md="3">
                        <v-tooltip top color="success">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :loading="loadingExcel"
                              icon
                              class="bg-success white--text"
                              large
                              v-bind="attrs"
                              v-on="on"
                              @click="getPagosImporte()"
                            >
                              <v-icon> mdi-microsoft-excel </v-icon>
                            </v-btn>
                          </template>
                          <span>Exportar Excel</span>
                        </v-tooltip>

                        <v-tooltip top color="error">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :loading="loadingPDF"
                              icon
                              large
                              class="ma-3 bg-danger white--text"
                              v-bind="attrs"
                              v-on="on"
                              @click="getPagosImporte(true)"
                            >
                              <v-icon dark> mdi-file-pdf-box </v-icon>
                            </v-btn>
                          </template>
                          <span>Exportar PDF</span>
                        </v-tooltip>
                      </v-col>

                      <!-- <v-col cols="6" md="3" align="start">
                        <div class="ml-7">
                          <v-btn
                            color="success"
                            class="mb-0 white--text"
                            small
                            @click="csvExport()"
                          >
                            Exportar
                            <v-icon right dark> get_app </v-icon>
                          </v-btn>
                          <v-checkbox
                            label="Exportar con detalles"
                            v-model="export_details"
                            class="mt-2 checkbox-exportar "
                            style="font-size: 11px; font-family: Montserrat, sans-serif; color: #000"
                          ></v-checkbox>
                        </div>
                      </v-col> -->
                    </v-row>

                    <!-- 2 fila de filtros -->
                    <v-row class="mx-10">
                      <!-- filtro nombre de CUIT/DNI  -->
                      <v-col cols="6" md="3">
                        <v-text-field
                          v-model="holder_number"
                          label="CUIT/DNI"
                          single-line
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                      <!-- filtro nombre de pagador  -->
                      <v-col cols="6" md="3">
                        <v-text-field
                          v-model="searchByNombre"
                          label="Nombre"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <!-- filtro referencia  -->
                      <v-col cols="6" md="3">
                        <v-text-field
                          v-model="searchByReferencia"
                          label="Referencia"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <!-- filtro canales de pago  -->
                      <v-col cols="6" md="3">
                        <v-select
                          :items="canalespago"
                          v-model="medios_pago"
                          item-text="Nombre"
                          item-value="Id"
                          label="Canales de pago"
                        >
                          <template v-slot:append-outer v-if="medio_pago > 0">
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                :key="`icon-${isEditingCanal}`"
                                :color="'red'"
                                click="limpiar_filtro_canales()"
                                >mdi-delete_sweep</v-icon
                              >
                            </v-slide-x-reverse-transition>
                          </template>
                        </v-select>
                      </v-col>
                                       
                      <!-- filtro puntos de venta  -->
                      <v-col cols="6" md="3">
                        <v-select
                          :items="itemsPuntos"
                          v-show="cuentaPosHabilitada()"
                          v-model="selected_puntos_de_venta"   
                          :disabled="itemsPuntos.length == 0"                            
                          clearable                 
                          item-text="nombre"
                          item-value="id"
                          label="Punto de venta"
                          @change="cargaCajas()"
                        >                          
                        </v-select>
                      </v-col>

                      <!-- filtro cajas cobro -->
                      <v-col cols="6" md="3">
                        <v-select
                          :items="itemsCajas"
                          v-model="selected_cajas_cobro"
                          v-show="cuentaPosHabilitada()"
                          :disabled="itemsCajas.length == 0"
                          item-text="descripcion"
                          item-value="codigo_caja"
                          label="Caja de cobro"
                          @change="cargarPagos()"
                        >                          
                        </v-select>
                      </v-col>

                      <v-col cols="6" md="3">
                        <v-checkbox
                          v-if="isSuperAdmin"
                          v-model="checkbox"
                          @click="visualizar_pendientes(checkbox)"
                          label="Pagos pendientes"
                          style="font-family: Montserrat, sans-serif"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- end filtros table  -->

              <!-- TABLAS DE DATOS-->
              <v-data-table
                :headers="computedHeaders"
                :items="pagos"
                :items-per-page="rowsPerPage"
                hide-default-footer
                class="elevation-1 trow row-pointer"
                :loading="cargando"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :expanded.sync="expanded"
                item-key="identificador"
                ref="tabla_pagos"
                hide-overlay
                @click:row="rowClick"
              >
                <template v-slot:[`item.fecha_pago`]="{ item }">
                  {{ formatFecha(item) }}
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <span class="primary--text text-subtitle-2">
                    {{ saldoPagos(item.amount) }}</span
                  >
                </template>

                <template v-slot:[`item.nombre_estado`]="{ item }">
                  <v-tooltip
                    top
                    :color="getEstadoColor(item.nombre_estado)"
                    min-width="280px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip
                        small
                        :color="getEstadoColor(item.nombre_estado)"
                        outlined
                        v-on="on"
                      >
                        {{
                          item.nombre_estado === "AUTORIZADO" &&
                          item.fecha_transferencia
                            ? "TRANSFERIDO"
                            : item.nombre_estado
                        }}
                      </v-chip>
                    </template>
                    <span>{{ getEstadoLeyenda(item) }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.fecha_transferencia`]="{ item }">
                  {{
                    item.fecha_transferencia
                      ? formatDate(item.fecha_transferencia.substring(0, 10))
                      : "-"
                  }}
                </template>

                <!-- Detalles -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom color="black" text>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        link
                        v-if="
                          item.comprobante && item.nombre_estado !== 'PENDIENTE'
                        "
                        :href="item.comprobante"
                        target="_blank"
                        color="blue"
                        @click.stop
                        ><v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Descargar comprobante</span>
                  </v-tooltip>
                </template>

                <!-- Elemento de la tabla "Referencias" -->
                <template v-slot:[`item.external_reference`]="{ item }">
                  <span class="hover:underline "
                    >{{ item.external_reference }}
                    <v-btn
                      icon
                      v-on="on"
                      link
                      v-if="item.external_reference"
                      color="blue"
                      @click.stop="copyToClipboard(item.external_reference)"
                      ><v-icon small>mdi-content-copy</v-icon>
                    </v-btn></span
                  >
                </template>

                <!-- Elemento de la tabla "Cuil/DNI" -->
                <template v-slot:[`item.holder_number`]="{ item }">
                  {{ item.holder_number ? item.holder_number : "-" }}
                </template>

                <!-- CONTENIDO DE LA FILA ACUMULADA -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    :colspan="headers.length"
                    style="padding: 1%"
                    align="center"
                  >
                    <thead>
                      <tr>
                        <th>Descripcion</th>
                        <th>Identificador</th>
                        <th>Cantidad</th>

                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(dat, index) in item.pago_items"
                        v-bind:key="index + item.Id"
                      >
                        <td v-text="dat.Descripcion" width="30%"></td>
                        <td v-text="dat.Identificador" width="20%"></td>
                        <td v-text="dat.Cantidad" width="30%"></td>

                        <td width="30%">
                          <p class="text-success">
                            {{ saldoPagos(dat.Monto) }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </td>
                </template>

                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
              <!-- END PAGOS DATA TABLE SECTION -->
              <!-- PAGINACION -->
              <div class="text-center pt-2">
                <v-pagination
                  :value="page"
                  :length="total"
                  :total-visible="visible"
                  @input="onPageChange($event)"
                ></v-pagination>
              </div>
              <!-- END PAGINACION -->
            </section>
          </div>
        </v-main>
      </v-app>
    </div>

    <!--TABLA UTILIZADA PARA LA EXPORTACION DE EXCEL DE PAGOS CON DETALLES-->
    <table
      id="tablaDetalles"
      data-excel-name="COBRANZAS"
      :style="{ display: 'none' }"
    >
      <thead>
        <tr>
          <th>Fecha de pago</th>
          <th>Nombre</th>
          <th>Referencia</th>
          <th>Concepto</th>
          <th>Canal de pago</th>
          <th>Importe</th>
          <th>Estado</th>
          <th>Fecha de transferencia</th>
        </tr>
      </thead>
      <tbody v-for="item in export_data" :key="item.referencia">
        <tr>
          <td class="text-left">{{ item.fechaPago }}</td>
          <td class="text-left">{{ item.nombre }}</td>
          <td class="text-center">{{ item.referencia }}</td>
          <td class="text-left">{{ item.concepto }}</td>
          <td class="text-left">{{ item.canalPago }}</td>
          <td class="text-left">{{ item.importe }}</td>
          <td class="text-left">{{ item.estado }}</td>
          <td class="text-center">{{ item.fechaTransferencia }}</td>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>Descripcion</th>
          <th>Identificador</th>
          <th>Cantidad</th>
          <th>Monto</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(detalle, index) in item.detalle" :key="index">
          <td></td>
          <td></td>
          <td>{{ detalle.descripcion }}</td>
          <td>{{ detalle.identificador }}</td>
          <td>{{ detalle.cantidad }}</td>
          <td>{{ detalle.monto }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <!--DIALOG PARA VER DETALLE DE PAGO-->
    <v-dialog v-model="dialogDetalle" max-width="1200px">
      <v-card>
        <v-card-title style="font-family: Montserrat;" v-show="!loadingItems">
          Detalle de pago <v-divider class="mx-5" vertical /> ID:
          <span class="font-weight-bold ml-3">{{ itemDetalles.id }}</span>
          <v-divider class="mx-5" vertical />Nombre:
          <span class="font-weight-bold ml-3">{{ itemDetalles.nombre }}</span
          ><v-divider class="mx-5" vertical />Canal de pago:
          <span class="font-weight-bold mx-3">{{ itemDetalles.canal }}</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="headersDetalle"
            :items="itemDetalles.detalles"
            no-data-text="Sin registros para mostrar"
            class="py-2"
            v-show="!loadingItems"
          >
            <template v-slot:[`item.Descripcion`]="{ item }">
              {{ item.Descripcion ? item.Descripcion : "-" }}
            </template>
            <template v-slot:[`item.Identificador`]="{ item }">
              {{ item.Identificador ? item.Identificador : "-" }}
            </template>
            <template v-slot:[`item.Monto`]="{ item }">
              {{ formatImport(item.Monto) }}
            </template>
          </v-data-table>

          <v-skeleton-loader
            type="article"
            v-show="loadingItems"
            class="pt-4"
          ></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import "table2excel";

import moment from "moment-timezone";
import formatDate from "../../utils/formatDate";
import { Pagos } from "../../services/pagos";
import { Cuentas } from "../../services/cuentas";

import formatImport, { formatImportFloat } from "../../utils/formatImport";
import formatNumReporte from "../../utils/formatNumReporte";

import ExcelJS from "exceljs";
import saveAs from "file-saver";

import pdfMake from "pdfmake/build/pdfmake";

// import PDFObject from "pdfobject";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Pagos",

  data() {
    return {
      // Cabeceras de las columnas de la tabla
      headers: [
        {
          text: "CUIL/DNI",
          align: "start",
          sortable: false,
          value: "holder_number",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "payer_name",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: false,
          value: "external_reference",
        },
        {
          text: "Concepto",
          align: "start",
          sortable: false,
          value: "pagotipo",
        },
        {
          text: "Canal de pago",
          align: "start",
          sortable: false,
          value: "nombre_channel",
        },
        {
          text: "Importe",
          align: "center",
          sortable: false,
          value: "amount",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "nombre_estado",
          // filter: this.estadoFilter, // metodo que permite filtrar al select
        },
        {
          //modificar cuando la api devuelva ese dato
          text: "Fecha de transferencia",
          align: "center",
          sortable: false,
          value: "fecha_transferencia",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],

      headersDetalle: [
        { text: "DESCRIPCIÓN", value: "Descripcion", sortable: false },
        { text: "IDENTIFICADOR", value: "Identificador", sortable: false },
        {
          text: "CANTIDAD",
          value: "Cantidad",
          sortable: false,
        },
        { text: "MONTO", value: "Monto", sortable: false },
      ],

      itemDetalles: [],

      expanded: [],

      formatDate,

      pagoImporte: [],

      // Estas propiedades customizan el footer por default
      footerProps: {
        "items-per-page-options": [5, 10, 20, 30],
        "items-per-page-text": "Mostrar",
        "prev-icon": "",
        "next-icon": "",
        "page-text": "{0}-{1} de {2}",
      },

      // Orden por defecto de la tabla
      sortBy: "",
      sortDesc: [false],

      // Filter models.
      searchByNombre: null,
      searchByReferencia: null,
      holder_number: "",
      // filter select estado
      pagos_estados: [4, 7],
      isEditing: true,
      // filter canales de pago
      medios_pago: 0,
      isEditingCanal: true,

      //filter puntos_venta
      itemsPuntos: [],
      selected_puntos_de_venta: [],

      //filter cajasCobro
      itemsCajas: [],
      selected_cajas_cobro: [],
      
      // filter_pagos_pendientes
      checkbox: false,

      menu1: false, // atributo que permite desplegar el date picker 1
      menu2: false, // atributo que permite desplegar el date picker 2

      // campos para hacer referencia a valor de datapicker
      date1: "",
      date2: "",
      //  numero de registros por pagina
      rowsPerPageArray: [30, 50, 100],

      // datos que se exportan en excel
      export_data: [],

      export_details: false, // utilizado para exportar los detalles de los pagos

      panel: 0,

      dialogDetalle: false,

      loadingItems: false,

      loadingExcel: false,
      loadingPDF: false,
      base64String: "",

      formatImport,
    };
  },

  beforeRouteLeave(to, from, next) {
    // Limpia el filtro cuando cambio de ruta
    // Establece el filtro en vacío
    this.$store.commit("pagos/setNombre", "");
    this.$store.commit("pagos/setReferencia", "");
    this.$store.commit("pagos/setHolderNumber", "");
    next();
  },

  watch: {
    // usar Watchers cambios en los datos : en los cambios de estados de una variable se realiza peticion a api
    searchByNombre(newValue) {
      // console.log("watch nombre");
      const query = newValue;

      this.$store.commit("pagos/setNombre", query);
      this.getPagos();
    },

    searchByReferencia(newValue) {
      // console.log("watch referencia");
      const query = newValue;

      this.$store.commit("pagos/setReferencia", query);
      this.getPagos();
    },
    holder_number(newValue) {
      const query = newValue;

      this.$store.commit("pagos/setHolderNumber", query);
      this.getPagos();
    },

    pagos_estados(newValue) {
      // console.log("watch pago estado");
      this.$store.commit("pagos/setEstado", newValue);
      this.$store.commit("pagos/setPaginate", 1);
      this.isEditing = false;
      this.getPagos();
    },

    medios_pago: function(newValue) {
      // console.log("watch medio pago");
      this.$store.commit("pagos/setMedioPago", newValue);
      this.$store.commit("pagos/setPaginate", 1);
      this.isEditingCanal = false;
      this.getPagos();
    },

    sortDesc(newSort) {
      if (!newSort[0]) {
        this.sortBy = ["fecha_pago"];
        newSort = [false];
      }

      // si la columna que deseamos ordenar es la de fecha
      if (this.sortBy[0] === "fecha_pago") {
        // actualizamos los valores de "ordenar" y "descendente"
        switch (newSort[0]) {
          case true:
            this.setDescendente(1);
            this.setOrdenar(1);
            break;
          case false:
            this.setDescendente(0);
            this.setOrdenar(1);
            break;
          default:
            this.setOrdenar(0);
        }

        // obtenermos los pagos
        this.getPagos();

        // console.log("watch sort");
      }
    },

    getCurrentAccountId() {
      this.clearAfterChange();
      this.getPagos();    
      if(this.cuentaPosHabilitada()) {        
        this.cargaPuntos();
      }
    },
  },

  methods: {
    // mapActions mapea (pone a disposicion) actions de vuex. El primer parametro es el modulo del Store "pagos" que gestiona lo refereidos a los pagos
    ...mapActions("pagos", [
      "getPagos",
      "getChannels",
      "getEstados",
      "setOrdenar",
      "setDescendente",
    ]),
    clearAfterChange() {
      this.itemsPuntos = [];
      this.itemsCajas = [];
      this.limpiarFiltro();
    },
    //Para copiar al portapapeles
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$toastr.s("Referencia copiada exitosamente");
      } catch ($e) {
        this.$toast.error("Error al copiar en porta papeles");
      }
    },

    exportTableToExcel() {
      let tabla = document.getElementById("tablaDetalles");
      const Table2Excel = window.Table2Excel;
      const excel = new Table2Excel();

      excel.export(tabla, "pagosConDetalles");
    },

    resetFilters() {
      this.$store.commit("pagos/setMedioPago", 0);
      this.$store.commit("pagos/setEstado", [4, 7]);
    },

    csvExport() {
      this.getPagos(true).then(() => {
        let csvContent = "";
        this.format_export(this.pagosCsv, this.export_details);
        // if (this.export_details) {
        //   setTimeout(() => this.exportTableToExcel(), 1000);
        // } else {
        csvContent += [
          Object.keys(this.export_data[0]).join(";"),
          ...this.export_data.map((item) => Object.values(item).join(";")),
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURIComponent(csvContent);
        const link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + data
        );
        link.setAttribute("download", "pagos.csv");
        link.click();
        // }
      });
    },

    convertImageToBase64() {
      const imagePath = "/assets/img/wee_logo.png"; // Ajusta el path a tu imagen
      fetch(imagePath)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64String = reader.result;
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          // console.error("Error fetching image:", error)
        });
          
    },

    // Generador de Excel
    async generateExcel() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Sheet1");

      // Add the image to the workbook
      const imageId = workbook.addImage({
        base64: this.base64String,
        extension: "png",
      });

      sheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        br: { col: 1, row: 4 },
      });

      // Data for the table
      const tableData = [
        ["", "", "", ""], // línea sombreada para la línea 6
        ["Cliente:", this.CLIENTE_CUENTA_DATA.cliente],
        [
          "Cuenta:",
          this.CLIENTE_CUENTA_DATA.cuenta.find(
            (c) => c.id == this.getCurrentAccountId
          ).cuenta,
        ],
        ["Nro de cuenta:", formatNumReporte(this.getCurrentAccountId), "", ""],
        ["Saldo pendiente:", this.saldoPagos(this.saldoPendiente), "", ""],
        [
          "Período:",
          `${this.FormatDateDesde} al ${this.FormatDateHasta}`,
          "",
          "",
        ],

        ["", "", "", ""], // línea en blanco
        [
          "Fecha de pago",
          "CUIL/DNI",
          "Nombre",
          "Referencia",
          "Concepto",
          "Canal de Pago",
          "Importe",
          "Estado",
          "Fecha de Transferencia",
        ],
        ...this.pagoImporte.map((pago) => [
          moment(pago.fecha_pago).format("DD/MM/YYYY"),
          pago.holder_number,
          pago.payer_name,
          pago.external_reference,
          pago.pagotipo,
          pago.nombre_channel,
          formatImport(pago.amount),
          pago.nombre_estado,
          pago.fecha_transferencia || "-",
        ]),
      ];

      // Add the table data to the sheet
      sheet.addRows(tableData);

      // Estilo para la fila de encabezado (línea 13)
      const headerRow = sheet.getRow(13);
      headerRow.font = { bold: true };
      headerRow.alignment = { horizontal: "center" };
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "CCCCCC" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      // Estilo para la fila en la línea 6 (sin texto, en columnas A y B)
      const emptyRow = sheet.getRow(6);
      emptyRow.eachCell((cell, colNumber) => {
        if (colNumber === 1 || colNumber === 2) {
          // Aplica el estilo solo a las columnas A y B
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCCCCC" },
          };
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } }, // Borde negro
            left: { style: "thin", color: { argb: "000000" } }, // Borde negro
            bottom: { style: "thin", color: { argb: "000000" } }, // Borde negro
            right: { style: "thin", color: { argb: "000000" } }, // Borde negro
          };
        }
      });

      sheet.getColumn(2).width = 15; // Ajusta el ancho de la columna CUIL/DNI
      sheet.getColumn(3).width = 26; // Ajusta el ancho de la columna Nombre
      sheet.getColumn(8).width = 18; // Ajusta el ancho de la columna Estado

      // Ajustar el ancho de las demás celdas según su contenido
      sheet.columns.forEach((column, index) => {
        if (index !== 1 && index !== 7) {
          // Evitar ajuste para CUIL/DNI y Estado
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const length = cell.value ? cell.value.toString().length : 10;
            if (length > maxLength) {
              maxLength = length;
            }
          });
          column.width = maxLength < 10 ? 10 : maxLength + 2; // Ajustar ancho
        }
      });

      // Generar el archivo Excel y descargarlo
      const buffer = await workbook.xlsx.writeBuffer();

      saveAs(
        new Blob([buffer]),
        `pagos_${this.CLIENTE_CUENTA_DATA.cliente
          .toLowerCase()
          .replaceAll(" ", "_")}.xlsx`
      );
    },

    //Generador de PDF
    generatePDF() {
      const contenido = [
        {
          alignment: "justify",
          margin: [0, 20, 0, 0],
          columns: [
            {
              stack: [
                {
                  image: this.base64String,
                  width: 60,
                  absolutePosition: { x: 30, y: 20 },
                },
              ],
            },
          ],
        },
        {
          text: `Cliente: ${this.CLIENTE_CUENTA_DATA.cliente}`,
          style: "table",
          margin: [0, 10, 0, 4],
        },
        {
          text: `Cuenta: ${
            this.CLIENTE_CUENTA_DATA.cuenta.find(
              (c) => c.id == this.getCurrentAccountId
            ).cuenta
          }`,
          style: "table",
          margin: [0, 0, 0, 4],
        },
        {
          text: `Nro de cuenta: ${formatNumReporte(this.getCurrentAccountId)}`,
          style: "table",
          margin: [0, 0, 0, 4],
        },
        {
          text: `Saldo pendiente: ${this.saldoPagos(this.saldoPendiente)}`,
          style: "table",
          margin: [0, 0, 0, 4],
        },
        {
          text: `Período: ${this.FormatDateDesde} al ${this.FormatDateHasta}`,
          style: "table",
          margin: [0, 0, 0, 15],
        },
        {
          table: {
            widths: [
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
              "auto",
            ],
            headerRows: 1,
            body: [
              [
                "Fecha de pago",
                "CUIL/DNI",
                "Nombre",
                "Referencia",
                "Concepto",
                "Canal de Pago",
                "Importe",
                "Estado",
                "Fecha de Transferencia",
              ],

              ...this.pagoImporte.map((pago) => [
                moment(pago.fecha_pago).format("DD/MM/YYYY"),
                pago.holder_number,
                pago.payer_name,
                pago.external_reference,
                pago.pagotipo,
                pago.nombre_channel,
                formatImport(pago.amount),
                pago.nombre_estado,
                pago.fecha_transferencia || "-",
              ]),
            ],
          },
          layout: "lightHorizontalLines",
          style: "table",
        },
      ];

      const styles = {
        header: {
          fontSize: 8,
          alignment: "left",
          margin: [0, 5, 0, 0],
        },
        subheader: {
          fontSize: 7,
          bold: true,
          alignment: "left",
          margin: [0, 5, 0, 10],
        },
        table: {
          fontSize: 6,
          bold: false,
        },
      };

      const docDefinition = {
        content: contenido,
        styles: styles,
      };

      pdfMake
        .createPdf(docDefinition)
        .download(
          `pagos_${this.CLIENTE_CUENTA_DATA.cliente
            .toLowerCase()
            .replaceAll(" ", "_")}.pdf`
        );
    },

    //Para generar el PDF o Excel recibiendo del endpoint los datos que necesito
    async getPagosImporte(pdf = false) {
      try {
        const params = {
          CuentaId: this.getCurrentAccountId,
          FechaPagoInicio: `${this.fechaDesde}T00:00:00-03:00`,
          FechaPagoFin: `${this.fechaHasta}T00:00:00-03:00`,
          Descendente: true,
          MedioPagoId: this.medios_pago,
          PagoEstadosIds: this.pagos_estados,
          Nombre: this.searchByNombre,
          ExternalReference: this.searchByReferencia,
          HolderNumber: this.holder_number,
        };

        pdf ? (this.loadingPDF = true) : (this.loadingExcel = true);

        // Llamar al servicio Pagos.getPagos
        const { data } = await Pagos.getPagos(params);

        const fechaHoy = moment().format("DD-MM-yyyy");

        // Filtrar los pagos de la fecha actual
        // this.export_data = data.filter(
        //   (p) => moment(p.fecha).format("DD-MM-yyyy") !== fechaHoy
        // );

        this.pagoImporte = data.data.filter((p) => p.fecha != fechaHoy);

        pdf ? this.generatePDF() : this.generateExcel();

        this.loadingExcel = false;
        this.loadingPDF = false;
      } catch (error) {
        // console.error(error);
        this.loadingExcel = false;
        this.loadingPDF = false;
      }
    },

    format_export(arrData, detalles = false) {
      if (detalles) {
        this.export_data = arrData.map((item) => ({
          fechaPago: moment(item.fecha).format("DD/MM/YYYY"), //item.company.name
          nombre: item.payer_name, //item.address.city,
          referencia: item.external_reference, //item.company.name
          concepto: item.cuenta,
          canalPago: item.nombre_channel, //item.company.name
          importe: this.saldoPagos(item.amount, true), //item.company.name
          estado: item.nombre_estado,
          fechaTransferencia: item.fecha_transferencia || "-",
          detalle: item.pago_items.map((detalle) => ({
            identificador: detalle.Identificador,
            descripcion: detalle.Descripcion,
            cantidad: detalle.Cantidad,
            monto: this.saldoPagos(detalle.Monto, true),
          })),
        }));
      } else {
        this.export_data = arrData.map((item) => ({
          "Fecha de pago": moment(item.fecha).format("DD/MM/YYYY"), //item.company.name
          Nombre: item.payer_name, //item.address.city,
          Nro_Referencia: item.external_reference, //item.company.name
          Concepto: item.cuenta,
          Medio_Pago: item.nombre_channel, //item.company.name
          Importe: this.saldoPagos(item.amount, true), //item.company.name
          Estado: item.nombre_estado,
          "Fecha de transferencia": item.fecha_transferencia || "-",
        }));
      }
    },

    // limpiar_filtro estados del pago
    limpiar_filtro() {
      this.$store.commit("pagos/setEstado", [4, 7]);
      this.$store.commit("pagos/setPaginate", 1);
      this.pagos_estados = [4, 7];
      this.getPagos();
    },

    // limpiar_filtro canale de pago
    limpiar_filtro_canales() {
      this.$store.commit("pagos/setMedioPago");
      this.$store.commit("pagos/setPaginate", 1);
      this.medios_pago = 0;
      this.getPagos();
    },

    // paginar
    onPageChange(event) {
      this.$store.commit("pagos/setPaginate", event);
      this.getPagos();
    },

    // registros por paginas
    updateRowsPerPage(number) {
      this.$store.commit("pagos/setrowsPerPage", number);
      this.$store.commit("pagos/setPaginate", 1);
      this.getPagos();
    },

    //  method filter pagos
    visualizar_pendientes(checkbox) {
      this.$store.commit("pagos/setPagosPendientes", checkbox);
      this.$store.commit("pagos/setPaginate", 1);
      this.getPagos();
    },

    inicio(value) {
      this.$store.commit("pagos/setFechaInicio", value);
      this.menu1 = false;
      this.getPagos();
    },

    fin(value) {
      this.$store.commit("pagos/setFechaFin", value);
      this.menu2 = false;
      this.getPagos();
    },

    saldoPagos(monto, sinSigno = false) {
      var str = monto / 100;
      if (!sinSigno) {
        return str.toLocaleString("es-ar", {
          style: "currency",
          currency: "ARS",
          minimumFractionDigits: 2,
        });
      } else {
        return str
          .toLocaleString("es-ar", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
          })
          .replace(/[$ ]/g, "")
          .replace(/\s+/g, "");
      }
    },

    // cambiar color segun el estado del pago
    getEstadoColor(estado) {
      switch (estado) {
        case "PENDIENTE":
          return "orange";
        case "PROCESANDO":
          return "deep-orange";
        case "PAGADO":
          return "green darken-2";
        case "AUTORIZADO":
          return "green darken-2";
        case "RECHAZADO":
          return "red";
        case "CADUCADO":
          return "red";
        case "REVERTIDO":
          return "red";
        case "APROBADO":
          return "light-blue";
        default:
          return estado;
      }
    },
    // cambia la leyenda dependiendo del estado que tiene pago
    getEstadoLeyenda(pago) {
      switch (pago.nombre_estado) {
        case "PENDIENTE":
          return "El cliente genera una solicitud de pago a ser pagado por el pagador.";

        case "PROCESANDO":
          return pago.nombre_channel === "DEBIN"
            ? "En proceso de confirmación por el pagador"
            : pago.nombre_channel === "OFFLINE"
            ? "En espera de ser pagado en rapipago"
            : "Pago realizado en proceso de aprobación.";

        case "AUTORIZADO":
          return !pago.fecha_transferencia
            ? "El pago está disponible para ser transferido."
            : "El pago ya fue transferido.";

        case "RECHAZADO":
          return "Pago fue rechazado por alguna inconsistencia.";

        case "CADUCADO":
          return "Solicitud de pago realizada y no procesada.";

        case "REVERTIDO":
          return "Pago desconocido por el pagador con cualquier tipo de tarjeta.";

        case "APROBADO":
          return "La transacción realizada fue exitosa.";
      }
    },

    formatFecha(item) {
      // varifica si la fecha de pago es vacia
      return !item.fecha_pago.includes("0001-01-01")
        ? moment(item.fecha_pago.substring(0, 10)).format("l") // formatea la fecha de pago
        : moment(item.fecha.substring(0, 10)).format("l");
    },

    //metodo que se encarga de desplegar el detalle de pago al hacer click sobre una fila en la tabla
    async rowClick(item) {
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(indexExpanded, 1);
      } else {
        const params = {
          PagoId: item.identificador,
        };

        this.dialogDetalle = true;
        this.loadingItems = true;

        const { data } = await Pagos.getItemsPago(params);

        this.loadingItems = false;

        this.itemDetalles = {
          id: item.identificador,
          nombre: item.payer_name,
          canal: item.nombre_channel,
          detalles: data.data,
        };

        //this.expanded.push(item);
      }
    },
    //Para filtro de Estados de Pago
    toggleState() {
      if (this.selectAllStates) {
        this.pagos_estados = [];
      } else {
        this.pagos_estados = this.EstadosPago.map((item) => item.ID);
      }
    },
    cuentaPosHabilitada() {
      const cuentaActual = this.CLIENTE_CUENTA_DATA.cuenta.find(f => f.id === this.getCurrentAccountId);
      if (!cuentaActual) {
        return false;
      }
      return cuentaActual.cobro_app || cuentaActual.cobro_pos;
    },
    async cargaPuntos() {         
      this.loadingItems = true;
      await Cuentas.getPuntosdeVenta(1, 10000, this.getCurrentAccountId).then((response) => {                
        if (response.data.data.puntos_ventas) {
          this.itemsPuntos = response.data.data.puntos_ventas;
        }
      })
      .catch((error) => {
        this.$toastr.e("Error al cargar puntos de venta");        
      })
      .finally(() => {
        this.loadingItems = false;
      })
    },
    limpiarFiltro(){              
        this.selected_puntos_de_venta = "";
        this.selected_cajas_cobro = "";
        this.$store.commit("pagos/setPuntoDeVenta", "");
        this.$store.commit("pagos/setCaja", "");        
    },
    async cargaCajas () {
      this.itemsCajas = [];
      if(this.selected_puntos_de_venta){
        if(this.cuentaPosHabilitada()){
          let params = {
            number:1,
            size:1000,
            PuntosVentaId: this.selected_puntos_de_venta,
          }          
          await Cuentas.getDatosCaja(params).then((response) => {        
            if (response.data.data.CajasCobro) {
              this.itemsCajas = response.data.data.CajasCobro;
            }
          })
          .catch((error) => {
            this.$toastr.e("Error al cargar cajas");
          })          
        }
      }else{
        this.limpiarFiltro();
        this.getPagos();
      }
    },
    cargarPagos(){
      if( this.selected_puntos_de_venta && this.selected_cajas_cobro){
        let codigo_sucursal_link_selected = this.itemsPuntos.find(f => f.id === this.selected_puntos_de_venta).codigo_sucursal_link;        
        this.$store.commit("pagos/setPuntoDeVenta", codigo_sucursal_link_selected);      
        this.$store.commit("pagos/setCaja", this.selected_cajas_cobro);        
        this.getPagos();
        }
    }
  },

  mounted() {
    this.convertImageToBase64();
    this.resetFilters();
    // this.getPagos();
    this.cargaPuntos();

    if (this.medios_pagos.length <= 1) this.getChannels();

    if (this.estados_pagos.length <= 3) this.getEstados();

    if (this.ordenar === 1) this.sortBy = ["fecha_pago"];

    if (this.descendente === 1) this.sortDesc = [true];
  },
  computed: {
    ...mapGetters(["isSuperAdmin"]),
    ...mapState("pagos", [
      "pagos",
      "pagosCsv",
      "page",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
      "errored",
      "medios_pagos",
      "medio_pago",
      "estados_pagos",
      "estado",
      "saldoDisponible",
      "saldoPendiente",
      "ordenar",
      "descendente",
      "puntoDeVenta",
      "caja",
    ]),

    ...mapGetters("pagos", [
      "fechaDesde",
      "fechaHasta",
      "estadoPagos",
      "canalespago",
    ]),

    ...mapGetters(["getCurrentAccountId", "CLIENTE_CUENTA_DATA"]),
    /**
     * Modificar los header de la tabla segun sea super administrador para mostrar el Id del pago
     */
    computedHeaders() {
      let headers = this.headers;
      if (this.isSuperAdmin) {
        headers.unshift({
          text: "Id",
          align: "start",
          sortable: false,
          value: "identificador",
          divider: true,
        });
      }

      headers.unshift({
        text: "Fecha Pago",
        align: "start",
        sortable: true,
        value: "fecha_pago",
        width: "120px",
      });

      return headers;
    },

    /**
     * Retorna Estados de Pago
     * getter del modulo pagos
     * */
    EstadosPago() {
      return this.estadoPagos;
    },

    paginaActual: {
      get() {
        return this.$store.getters["pagos/pages"];
      },
    },

    FormatDateDesde() {
      return this.fechaDesde
        ? moment(this.fechaDesde).format("DD/MM/YYYY")
        : "";
    },
    FormatDateHasta() {
      return this.fechaHasta
        ? moment(this.fechaHasta).format("DD/MM/YYYY")
        : "";
    },

    //Para seleccionar todos los datos del v-autocomplete retorna un V o F, comparo la cantidad de elementos de los arreglos
    selectAllStates() {
      return this.pagos_estados.length === this.estadoPagos.length;
    },
    selectSomeState() {
      return this.pagos_estados.length > 0 && !this.selectAllStates;
    },
    icon() {
      if (this.selectAllStates) return "mdi-close-box";
      if (this.selectSomeState) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },    
    puntos_asignados(){
      return []
    },
    cajasAsignadas(){
      return []
    }
  },
};
</script>

<style scoped>
.small-alert {
  font-size: 12px; /* Tamaño de fuente más pequeño */
  padding: 1px; /* Margen más pequeño */
}

::v-deep .checkbox-exportar .v-label {
  font-size: 13px;
}
.outline_none {
  outline: none;
}
.pending {
  color: rgba(213, 216, 21, 0.863);
}
.processing {
  color: rgba(20, 33, 211, 0.863);
}
.paid {
  color: rgba(77, 236, 130, 0.863);
}
.accredited {
  color: rgb(7, 161, 58);
}
.outlinenone {
  outline: none;
  background-color: rgb(220, 228, 225);
  border: 0;
}

/* .trow {
  transition: transform 0.3s;
}

.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */

.v-application .text-subtitle-2 {
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0.0071428571em !important;
  font-family: "Roboto", sans-serif !important;
}

.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 0px;
  min-height: inherit;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 50px;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
