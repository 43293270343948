
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-profile">
          <Avatar />
          <div class="card-body">
            <div id="navigation-pills">
              <div class="title"></div>
              <div class="title"></div>
              <div class="row">
                <div class="col-md-12 ml-auto mr-auto">
                  <h2 class="text-center title">Generar pago prueba</h2>
                  <!-- efecto cargando peticion -->
                  <div v-if="loading">
                    <loader
                      object="#4caf50"
                      color1="#ffffff"
                      color2="#17fd3d"
                      size="5"
                      speed="2"
                      bg="#343a40"
                      objectbg="#999793"
                      opacity="40"
                      disableScrolling="false"
                      name="box"
                    ></loader>
                  </div>
                  <v-form ref="form" v-model="valid">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="payer_name"
                            label="payer_name"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="description"
                            label="description"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- <v-row>
                            <v-col
                            cols="12"
                            md="12"
                            >
                            <v-text-field
                                v-model="first_total"
                                label="first_total"
                                required
                            ></v-text-field>
                            </v-col>
                        </v-row> -->

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="first_due_date"
                            label="first_due_date"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="external_reference"
                            label="external_reference"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <br />

                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="second_due_date"
                            label="second_due_date"
                            required
                          ></v-text-field>
                        </v-col>

                        <!-- <v-col
                            cols="12"
                            md="6"
                            >
                            <v-text-field
                                v-model="second_total"
                                label="second_total"
                                required
                            ></v-text-field>
                            </v-col> -->
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="payer_email"
                            label="payer_email"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="payment_type"
                            label="payment_type"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-row align="center">
                          <v-col cols="12" sm="12"> </v-col>

                          <v-col cols="12" sm="12">
                            <v-select
                              v-model="e7"
                              item-text="amount"
                              :item-value="'amount'"
                              :items="states"
                              label="Seleccionar"
                              multiple
                              chips
                              persistent-hint
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                    <br />
                    <v-row>
                      <v-col cols="12" md="12" align="center">
                        <v-btn
                          :loading="dialog"
                          color="success"
                          class="mr-4"
                          @click="enviar_solicitud()"
                        >
                          Generar pago
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/Ayuda/Avatar.vue";
import { Prueba } from "../../services/pruebapago.js";
export default {
  name: "Generate",
  components: {
    Avatar,
  },
  data: () => ({
    redirect: "",
    dialog: false,
    loading: false,
    error: false,
    errored: false,
    //   respuesta validacion campos
    res: false,
    valid: true,
    //   v-model campos
    payer_name: "Jose Lopez",
    description: "Pago de productos varios",
    //   primer valor calculado
    first_total: 0,
    first_due_date: "29-10-2021",
    external_reference: "15716",
    second_due_date: "15-11-2021",
    //   segundo valor calculado
    second_total: 0,
    payer_email: "jose.lopez@telco.com.ar",
    payment_type: "SELLOS",
    items: [],
    e7: [],
    states: [
      {
        quantity: 1,
        description: "Audio",
        amount: 100000,
      },
      {
        quantity: 1,
        description: "Video",
        amount: 300000,
      },
      {
        quantity: 1,
        description: "LED",
        amount: 400000,
      },
      {
        quantity: 1,
        description: "TV",
        amount: 500000,
      },
      {
        quantity: 1,
        description: "TV",
        amount: 50000,
      },
    ],
  }),
  methods: {
    async enviar_solicitud() {
      var items = [];
      var total = 0;
      this.e7.forEach(function (elemento, indice) {
        items.push({
          quantity: 1,
          description: "audio" + indice,
          amount: elemento,
        });
        total = total + elemento;
      });
      this.first_total = total;
      this.second_total = total + 100;

      var payload = {
        payer_name: this.payer_name,
        description: this.description,
        first_total: this.first_total,
        first_due_date: this.first_due_date,
        external_reference: this.external_reference,
        second_total: this.second_total,
        payer_email: this.payer_email,
        payment_type: this.payment_type,
        items: items,
      };
      await Prueba.enviar_pago(payload)
        .then((response) => {
          this.$toastr.s(response.data.message);
          this.redirect = response.data.data.checkout_url;
          window.location.href = this.redirect;
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },
  },
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--has-bg {
  background-color: #54d490;
}
</style>