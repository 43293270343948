<template>
  <v-app>
    <v-main>
      <v-container fluid style="overflow: scroll">
        <h4 align="left"><strong>Request Body</strong></h4>
        <AceEditor
          v-model="formulario"
          lang="json"
          theme="chrome"
          width="600px"
          height="300px"
          :options="options"
        ></AceEditor>
        <!-- <br />
        <v-btn color="success" @click="test" > Probar </v-btn>
        <br /> -->
      
       <br/>
       <v-row class="ma-0" justify="left">
         <v-btn  value="left" color="success" @click="test" > Probar </v-btn>
       </v-row>
       <br/>
        

        <h4 align="left"><strong>Response</strong></h4>
        <AceEditor
          v-model="respuesta"
          v-show="respuesta != ''"
          lang="json"
          theme="chrome"
          width="500px"
          height="300px"
          :options="option2"
        ></AceEditor>
        <br />
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <p style="color: black !important">Código de ejemplo</p>
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-tabs v-model="tab">
                  <v-tab>Javascript</v-tab>
                  <v-tab>Go</v-tab>
                  <v-tab>C#</v-tab>
                  <v-tab>Java</v-tab>
                </v-tabs>
                <v-card-text>
                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <AceEditor
                        v-model="javascript"
                        lang="javascript"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></AceEditor>
                    </v-tab-item>
                    <v-tab-item>
                      <AceEditor
                        v-model="golang"
                        lang="golang"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></AceEditor>
                    </v-tab-item>
                    <v-tab-item>
                      <AceEditor
                        v-model="csharp"
                        lang="csharp"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></AceEditor>
                    </v-tab-item>
                    <v-tab-item>
                      <AceEditor
                        v-model="java"
                        lang="java"
                        theme="chrome"
                        height="200px"
                        :options="option2"
                      ></AceEditor>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AceEditor from "vue2-ace-editor";
import endent from "endent";
export default {
  components: { AceEditor, endent },
  data() {
    const first_date = new Date();
    const second_date = new Date();
    // Primer vencimiento
    const date_now_add_five_days = first_date.setDate(first_date.getDate() + 5);
    const date_now_add_five_days_formatted = new Date(date_now_add_five_days);
    // Segundo Vencimiento
    const date_now_add_ten_days = second_date.setDate(
      second_date.getDate() + 10
    );
    const date_now_add_ten_days_formatted = new Date(date_now_add_ten_days);
    // Ejemplo es el objeto JSON de la POST REQUEST que se muestra como modelo
    const ejemplo = {
      payer_name: "Jose Lopez",
      description: "Pago de productos varios",
      first_total: 1960510,
      first_due_date:
        `${date_now_add_five_days_formatted.getDate()}`.padStart(2, "0") +
        "-" +
        `${date_now_add_five_days_formatted.getMonth() + 1}`.padStart(2, "0") +
        "-" +
        date_now_add_five_days_formatted.getFullYear(),
      external_reference: "15716",
      second_due_date:
        `${date_now_add_ten_days_formatted.getDate()}`.padStart(2, "0") +
        "-" +
        `${date_now_add_ten_days_formatted.getMonth() + 1}`.padStart(2, "0") +
        "-" +
        date_now_add_ten_days_formatted.getFullYear(),
      second_total: 2060510,
      payer_email: "jose.lopez@telco.com.ar",
      payment_type: "sellos",
      items: [
        {
          quantity: 1,
          description: "Audio",
          amount: 1000000,
        },
        {
          quantity: 1,
          description: "Video",
          amount: 960510,
        },
      ],
    };
    return {
      formulario: JSON.stringify(ejemplo, null, 2),
      respuesta: "",
      options: {
        autoScrollEditorIntoView: true,
        highlightActiveLine: true,
        highlightSelectedWord: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
      },
      option2: {
        readOnly: true,
        autoScrollEditorIntoView: true,
        highlightActiveLine: true,
        highlightSelectedWord: true,
      },
      tab: null,
      // Esto es para el Codigo de Ejemplo de la request en los cuatro lenguajes de ejemplo
      javascript: endent`var data = JSON.stringify({
        "payer_name":"Jose Lopez",
        "description": "Pago de productos varios", 
        "first_total": 1960510, 
        "first_due_date": "10-10-2021",
        "external_reference": "15716", 
        "second_due_date": "10-11-2021", 
        "second_total": 2060510, 
        "payer_email": "jose.lopez@telco.com.ar",
        "payment_type": "sellos",
        "items": [ { "quantity": 1,
                    "description":"Audio",
                    "amount": 1000000
                    },
                    { "quantity": 1,
                    "description":"Video",
                    "amount": 960510
                    }
                ]
        });

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
        if (this.readyState === this.DONE) {
            console.log(this.responseText);
        }
        });

        xhr.open("POST", "https://127.0.0.1:3300/checkout");
        xhr.setRequestHeader("apikey", "123123123123123");
        xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("cache-control", "no-cache");

        xhr.send(data);`,
      golang: endent`package main

        import (
            "fmt"
            "strings"
            "net/http"
            "io/ioutil"
        )

        func main() {

            url := "https://127.0.0.1:3300/checkout"

            payload := strings.NewReader("{\"payer_name\":\"Jose Lopez\", \"description\": \"Pago de productos varios\", \"first_total\": 1960510, \"first_due_date\": \"10-10-2021\",
                \"external_reference\": \"15716\", \"second_due_date\": \"10-11-2021\", \"second_total\": 2060510, \"payer_email\": \"jose.lopez@telco.com.ar\",
                \"payment_type\": \"electro\",\"items\": [ { \"quantity\": 1,\"description\":\"Audio\",\"amount\": 100000},{\"quantity\": 1,\"description\":\"Video\",
                \"amount\": 960510}]}")
            

            req, _ := http.NewRequest("POST", url, payload)

            req.Header.Add("apikey", "123123123123123")
            req.Header.Add("content-type", "application/json")
            req.Header.Add("cache-control", "no-cache")

            res, _ := http.DefaultClient.Do(req)

            defer res.Body.Close()
            body, _ := ioutil.ReadAll(res.Body)

            fmt.Println(res)
            fmt.Println(string(body))

        }`,
      csharp: endent`var client = new RestClient("https://127.0.0.1:3300/checkout");
        var request = new RestRequest(Method.POST);
        request.AddHeader("cache-control", "no-cache");
        request.AddHeader("content-type", "application/json");
        request.AddHeader("apikey", "123123123123123");
        request.AddParameter("application/json", "{\"payer_name\":\"Jose Lopez\", \"description\": \"Pago de productos varios\", \"first_total\": 1960510, \"first_due_date\": \"10-10-2021\",
                \"external_reference\": \"15716\", \"second_due_date\": \"10-11-2021\", \"second_total\": 2060510, \"payer_email\": \"jose.lopez@telco.com.ar\",
                \"payment_type\": \"electro\",\"items\": [ { \"quantity\": 1,\"description\":\"Audio\",\"amount\": 100000},{\"quantity\": 1,\"description\":\"Video\",
                \"amount\": 960510}]}", ParameterType.RequestBody);
        IRestResponse response = client.Execute(request);`,
      java: endent`HttpResponse<String> response = Unirest.post("https://127.0.0.1:3300/checkout")
        .header("apikey", "123123123123123")
        .header("content-type", "application/json")
        .header("cache-control", "no-cache")
        .body("{\"payer_name\":\"Jose Lopez\", \"description\": \"Pago de productos varios\", \"first_total\": 1960510, \"first_due_date\": \"10-10-2021\",
                \"external_reference\": \"15716\", \"second_due_date\": \"10-11-2021\", \"second_total\": 2060510, \"payer_email\": \"jose.lopez@telco.com.ar\",
                \"payment_type\": \"electro\",\"items\": [ { \"quantity\": 1,\"description\":\"Audio\",\"amount\": 100000},{\"quantity\": 1,\"description\":\"Video\",
                \"amount\": 960510}]}")
        .asString();`,
    };
  },
  methods: {
    test() {
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        headers: {
          apiKey: "123123123123123",
          "Content-Type": "application/json",
        },
        body: this.formulario,
      };
      fetch("https://dev-back-pagos.telco.com.ar/checkout", requestOptions)
        .then((response) => response.json())
        .then((data) => (this.respuesta = JSON.stringify(data, null, 2)));
    },
  },
};
</script>