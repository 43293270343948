<template>
  <div>
    <h3>Solicitar un Pago</h3>
    <!-- Diagrama de solicitud -->
    <vue-mermaid-string :value="diagram" />

    <h3>
      <strong>Ejemplo</strong>
    </h3>

    <div class="card card-nav-tabs card-plain">
      <div class="card-header" style="background-color: #239b56">
        <!-- colors: "header-primary", "header-info", "header-success", "header-warning", "header-danger" -->
        <div class="nav-tabs-navigation">
          <div class="nav-tabs-wrapper">
            <ul
              class="nav nav-tabs"
              data-tabs="tabs"
              style="justify-content: space-around"
            >
              <li class="nav-item">
                <a class="nav-link active" href="#post" data-toggle="tab"
                  >Post</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#response" data-toggle="tab"
                  >Response</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#headers" data-toggle="tab"
                  >Headers</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="tab-content text-center">
          <div class="tab-pane active" id="post" style="overflow: scroll">
            <table class="table table-hover">
              <thead class="text-success">
                <tr>
                  <th>Campo</th>
                  <th>Descripción</th>
                  <th>Obligatorio</th>
                  <th>Tipo de dato</th>
                  <th>Ejemplo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>payer_name</td>
                  <td>Nombre del pagador</td>
                  <td>SI</td>
                  <td>string</td>
                  <td>"Juan Perez"</td>
                </tr>
                <tr>
                  <td>description</td>
                  <td>Descripción</td>
                  <td>SI</td>
                  <td>string</td>
                  <td>"Cobro de producto final"</td>
                </tr>
                <tr>
                  <td>first_total</td>
                  <td>
                    Monto total antes de vencimiento. Valor entero. Los dos
                    últimos dígitos se consideran decimales.
                  </td>
                  <td>SI</td>
                  <td>integer</td>
                  <td>300000</td>
                </tr>
                 <tr>
                  <td>first_due_date</td>
                  <td>Fecha de 1º Vencimiento</td>
                  <td>SI</td>
                  <td>string con formato dd/mm/aaaa</td>
                  <td>"01-10-2021"</td>
                </tr>
                 <tr>
                  <td>external_reference</td>
                  <td>
                    Identificador unico de sistema cliente.
                  </td>
                  <td>SI</td>
                  <td>string o integer</td>
                  <td>15716</td>
                </tr>
                  <tr>
                  <td>second_due_date</td>
                  <td>Fecha de 2º Vencimiento</td>
                  <td>NO</td>
                  <td>string con formato dd/mm/aaaa</td>
                  <td>"10-10-2021"</td>
                </tr>
                <tr>
                  <td>second_total</td>
                  <td>
                    Monto total, valor entero. Los dos
                    últimos dígitos se consideran decimales. Este valor se incluye solamente si existe fecha de 2º vencimiento.
                  </td>
                  <td>NO</td>
                  <td>integer</td>
                  <td>350000</td>
                </tr>
                <tr>
                  <td>payer_email</td>
                  <td>Correo electrónico del pagador</td>
                  <td>SI</td>
                  <td>string</td>
                  <td>"juan.perez@telco.com.ar"</td>
                </tr>
                <tr>
                  <td>payment_type</td>
                  <td>Tipificación del Cobro</td>
                  <td>SI</td>
                  <td>string</td>
                  <td>"sellos"</td>
                </tr>
                <tr>
                  <td>items</td>
                  <td>Lista detallada de productos o servicios</td>
                  <td>SI</td>
                  <td></td>
                  <td>
                    "{["quantity": 2, "description": "audio", "amount":
                    200000]}"
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane" id="response" style="overflow: scroll">
            <table class="table table-hover">
              <thead class="text-success">
                <tr>
                  <th class="primary--text">Campo</th>
                  <th class="primary--text">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>id</td>
                  <td>Identificador único de pago</td>
                </tr>
                <tr>
                  <td>estado</td>
                  <td>Estado Inicial del Pago</td>
                </tr>
                <tr>
                  <td>description</td>
                  <td>Descripción del cobro indicado</td>
                </tr>
                <tr>
                  <td>first_due_date</td>
                  <td>Fecha de Vencimiento</td>
                </tr>
                <tr>
                  <td>first_total</td>
                  <td>Monto total antes de vencimiento</td>
                </tr>
                <tr>
                  <td>second_due_date</td>
                  <td>Fecha límite de validez del cobro</td>
                </tr>
                <tr>
                  <td>second_total</td>
                  <td>Monto total luego de vencimiento</td>
                </tr>
                <tr>
                  <td>payer_email</td>
                  <td>Correo electrónico del pagador</td>
                </tr>
                <tr>
                  <td>external_reference</td>
                  <td>Identificador externo indicado</td>
                </tr>
                <tr>
                  <td>metadata</td>
                  <td>Información agregada para uso externo</td>
                </tr>
                <tr>
                  <td>uuid</td>
                  <td>Identificador Único de checkout</td>
                </tr>
                <tr>
                  <td>checkout_url</td>
                  <td>Dirección web de acceso al checkout</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane" id="headers">
            <table class="table">
              <tbody>
                <tr>
                  <td>apiKey</td>
                  <td>"abcde-abcde-abcde-abcde"</td>
                </tr>
                <tr>
                  <td>Content-Type</td>
                  <td>application/json</td>
                </tr>
                <tr>
                  <td>Cache-Control</td>
                  <td>no-cache</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- El component NewPago contiene el ejemplo concreto de la Post Request para un pago -->
    <NewPago />
  </div>
</template>
<script>
import VueMermaidString from "vue-mermaid-string";
import NewPago from "./NewPago.vue";
import endent from "endent";

export default {
  name: "SolicitudPago",
  components: {
    VueMermaidString,
    NewPago,
  },
  computed: {
    diagram: () => endent`
        sequenceDiagram
            participant B as Aplicación Cliente
            participant N as Navegador
            participant T as Wee! Pagos
            B ->> T: apiKey/NuevoPago
            T -->> B: ok/CheckoutURL
            B ->> N: redirect/CheckoutURL
            N ->> T: Uuid/Pago
            T -->> N: respuesta/RedirectURL
        `,
  },
};
</script>