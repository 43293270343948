<template>
  <LayoutPage title="Reporte de cliente">
    <v-row class="pt-0 mx-5">
      <v-col md="3">
        <!-- BEGIN MENU FECHA DESDE -->
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              prepend-icon="mdi-calendar"
              label="Fecha desde"
              readonly
              v-bind="attrs"
              v-on="on"
              persistent-hint
              :value="formatDate(fechaDesde)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaDesde"
            locale="es-ar"
            no-title
            scrollable
            :max="new Date().toISOString().substr(0, 10)"
            color="blue"
            dark
          >
          </v-date-picker>
        </v-menu>

        <!-- END MENU FECHA DESDE -->
      </v-col>
      <v-col md="3">
        <!-- BEGIN MENU FECHA HASTA -->
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              prepend-icon="mdi-calendar"
              :value="formatDate(fechaHasta)"
              label="Fecha hasta"
              readonly
              v-bind="attrs"
              v-on="on"
              persistent-hint
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fechaHasta"
            locale="es-ar"
            no-title
            scrollable
            :min="fechaDesde"
            :max="new Date().toISOString().substr(0, 10)"
            dark
            color="blue"
          >
          </v-date-picker>
        </v-menu>
        <!-- END MENU FECHA HASTA -->
      </v-col>
      <v-col md="2">
        <v-btn outlined color="success" class="mt-2" @click="getData()" block>
          <v-icon left>mdi-file-send-outline</v-icon>
          Solicitar
        </v-btn>
      </v-col>

      <v-col cols="12" md="6" lg="4">
        <v-alert
          dense
          elevation="3"
          icon="paid"
          outlined
          prominent
          text
          type="success"
          style="font-family: Montserrat, sans-serif !important; font-size: 1.2em;"
        >
          Total rendido:
          <span class="font-weight-bold">
            {{ formatImport(totalRendido) }}
          </span>
        </v-alert>
      </v-col>
    </v-row>

    <div class="mb-2 mt-3 mx-6 d-flex align-center">
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on }">
          <v-btn
            color="white"
            icon
            class="green mx-2"
            v-on="on"
            @click="downloadExcel()"
          >
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </template>
        <span>Descargar excel</span>
      </v-tooltip>

      <v-select
        label="Ocultar columnas"
        :items="headers"
        style="max-width: 300px;"
        dense
        outlined
        hide-details
        multiple
        v-model="hiddenColumns"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.text }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text text-caption">
            (+{{ hiddenColumns.length - 1 }} seleccionados)
          </span>
        </template>
      </v-select>

      <v-checkbox
        v-model="allClients"
        hide-details
        @change="getData"
        dense
        class="ma-0 ml-5"
      ></v-checkbox>
      <p
        class="mb-0"
        style="cursor: pointer; margin-top: 5px;"
        @click="
          allClients = !allClients;
          getData();
        "
      >
        Operaciones de todos los clientes
      </p>
    </div>

    <v-data-table
      :headers="currentColumns"
      :items="data"
      no-data-text="Sin registros"
      disable-sort
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.fecha_pago="{ item }">
        {{ formatDate(item.fecha_pago) }}
      </template>

      <template v-slot:item.fecha_presentacion="{ item }">
        {{
          item.fecha_presentacion ? formatDate(item.fecha_presentacion) : "-"
        }}
      </template>

      <template v-slot:item.fecha_acreditacion="{ item }">
        {{
          item.fecha_acreditacion ? formatDate(item.fecha_acreditacion) : "-"
        }}
      </template>

      <template v-slot:item.fecha_rendicion="{ item }">
        {{ item.fecha_rendicion ? formatDate(item.fecha_rendicion) : "-" }}
      </template>

      <template v-slot:item.nro_establecimiento="{ item }">
        {{ item.nro_establecimiento ? item.nro_establecimiento : "-" }}
      </template>

      <template v-slot:item.nro_liquidacion="{ item }">
        {{ item.nro_liquidacion ? item.nro_liquidacion : "-" }}
      </template>

      <template v-slot:item.importe_a_pagar="{ item }">
        {{ formatImport(item.importe_a_pagar) }}
      </template>

      <template v-slot:item.importe_pagado="{ item }">
        {{ formatImport(item.importe_pagado) }}
      </template>

      <template v-slot:item.importe_neto_cobrado="{ item }">
        {{ formatImport(item.importe_neto_cobrado) }}
      </template>

      <template v-slot:item.retencion_iva="{ item }">
        {{ formatImport(item.retencion_iva) }}
      </template>

      <template v-slot:item.comision_proveedor="{ item }">
        {{ formatImport(item.comision_proveedor) }}
      </template>

      <template v-slot:item.iva_comision_proveedor="{ item }">
        {{ formatImport(item.iva_comision_proveedor) }}
      </template>

      <template v-slot:item.comision_telco="{ item }">
        {{ formatImport(item.comision_telco) }}
      </template>

      <template v-slot:item.iva_comision_telco="{ item }">
        {{ formatImport(item.iva_comision_telco) }}
      </template>

      <template v-slot:item.referencia_bancaria="{ item }">
        {{ item.referencia_bancaria ? item.referencia_bancaria : "-" }}
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-space-between align-center  ma-3">
          <v-pagination
            v-model="pagination.number"
            :length="pagination.lastPage"
            :total-visible="15"
            @input="getData()"
          ></v-pagination>

          <v-select
            :items="rowsPerPageArray"
            label="Mostrar"
            v-model="pagination.size"
            style="max-width: 150px"
            dense
            hide-details
            @input="getData(true)"
          ></v-select>
        </div>
      </template>
    </v-data-table>

    <loader
      object="#4caf50"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="40"
      disableScrolling="false"
      name="box"
      v-show="isLoading"
    ></loader>
  </LayoutPage>
</template>

<script>
// import LayoutPage from "@/components/LayoutPage.vue";
import LayoutPage from "../../../components/LayoutPage.vue";
import moment from "moment";
import formatDate from "../../../utils/formatDate";
import formatImport, { formatImportFloat } from "../../../utils/formatImport";
import { headers } from "./headerReporte";
import reportesService from "../../../services/reportes";
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";

export default {
  name: "ReporteCliente",
  components: { LayoutPage },
  data() {
    return {
      fechaDesde: moment()
        .subtract(1, "days")
        .subtract(1, "months")
        .format("YYYY-MM-DD"),

      fechaHasta: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),

      menu1: false,
      menu2: false,

      data: [],
      excelData: [],
      hiddenColumns: [],

      totalRendido: 0,

      allClients: false,
      isLoading: false,

      pagination: {
        number: 1,
        size: 10,
        lastPage: 0,
      },

      rowsPerPageArray: [10, 30, 50],

      headers,
      formatDate,
      formatImport,
    };
  },
  computed: {
    ...mapGetters(["getCurrentClientId"]),

    currentColumns() {
      return headers.filter((h) => !this.hiddenColumns.includes(h.value));
    },

    daysDifference() {
      return moment(this.fechaHasta).diff(moment(this.fechaDesde), "days");
    },
  },
  watch: {
    // realiza el fetch datos cuando se cambia de usuario
    getCurrentClientId() {
      this.getData(true);
    },
  },

  mounted() {
    this.$nextTick(() => this.getData());
  },

  methods: {
    async getData(resetNumber = false, excelData = false) {
      if (resetNumber) this.pagination.number = 1;

      if (this.daysDifference > 31) {
        this.$toastr.e("El rango de fechas no puede ser mayor a 31 días");
        return;
      }

      const params = {
        FechaInicio: this.fechaDesde + "T00:00:00-03:00",
        FechaFin: this.fechaHasta + "T23:59:59-03:00",
        ClienteId: this.getCurrentClientId,
        Number: this.pagination.number,
        Size: this.pagination.size,
      };

      if (excelData) {
        delete params.Number;
        delete params.Size;
      }

      if (this.allClients) delete params.ClienteId;
      try {
        this.isLoading = true;
        const { data } = await reportesService.reporteClientes(params);
        this.isLoading = false;

        if (excelData) return (this.excelData = data?.pagos || []);

        this.data = data?.pagos || [];
        this.totalRendido = data?.total_rendido || 0;
        this.pagination.lastPage = data?.meta?.page?.lastPage;
      } catch (error) {
        this.$toastr.e("Error al obtener pagos");
        // console.error(error);
      }
    },

    downloadExcel() {
      // console.log("hola");
      var workbook = XLSX.utils.book_new(); // Crear un nuevo libro de trabajo

      this.getData(false, true)
        .then(() => {
          const excelData = this.excelData.map((pago) => this.formatPago(pago));

          var worksheet = XLSX.utils.json_to_sheet(excelData); // Convertir el array de objetos a una hoja de cálculo
          XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte_pagos"); // Agregar la hoja de cálculo al libro de trabajo}
          XLSX.writeFile(
            workbook,
            `reporte_from_${this.fechaDesde}_to_${this.fechaHasta}.xlsx` // filename
          );

          this.$toastr.s("Excel generado con éxito");
        })
        .catch(() => this.$toastr.e("Error al generar excel"));
    },

    formatPago(pago) {
      const fecha_pago = pago.fecha_pago
        ? formatDate(pago.fecha_pago.substr(0, 10))
        : "-";

      const fecha_rendicion = pago.fecha_rendicion
        ? formatDate(pago.fecha_rendicion.substr(0, 10))
        : "-";

      const fecha_acreditacion = pago.fecha_acreditacion
        ? formatDate(pago.fecha_acreditacion.substr(0, 10))
        : "-";

      const fecha_presentacion = pago.fecha_presentacion
        ? formatDate(pago.fecha_presentacion.substr(0, 10))
        : "-";

      // ocultar porcentaje comision proveedor cuando sea medio pago debin
      const porcentaje_comision_proveedor =
        pago.medio_pago == "DEBIN" ? "-" : pago.porcentaje_comision_proveedor;

      return {
        ...pago,
        fecha_pago,
        fecha_rendicion,
        fecha_presentacion,
        fecha_acreditacion,
        porcentaje_comision_proveedor,
        iva_comision_proveedor: formatImportFloat(
          pago.iva_comision_proveedor,
          true
        ),
        comision_proveedor: formatImportFloat(pago.comision_proveedor, true),
        importe_pagado: formatImport(pago.importe_pagado, true),
        importe_a_pagar: formatImport(pago.importe_a_pagar, true),
        retencion_iva: formatImport(pago.retencion_iva, true),
        retencion_ganancias: formatImport(pago.retencion_ganancias, true),
        retencion_iibb: formatImport(pago.retencion_iibb, true),
        comision_telco: formatImport(pago.comision_telco, true),
        iva_comision_telco: formatImport(pago.iva_comision_telco, true),
        importe_neto_cobrado: formatImport(pago.importe_neto_cobrado, true),
        importe_rendido: formatImport(pago.importe_rendido, true),
      };
    },
  },
};
</script>
