<template>
  <LayoutPage title="Reversiones">
    <!-- filtros table  -->
    <v-expansion-panels v-model="panel" class="mb-3">
      <v-expansion-panel :key="0">
        <v-expansion-panel-header class="outline_none" disable-icon-rotate>
          <template v-slot:actions>
            <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
            <v-icon size="33" color="success"> search </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 1 fila de filtros -->
          <v-row class="pt-0 mx-5">
            <v-col cols="6" md="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaInicio)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  locale="es-ar"
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getContracargos();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaFin)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="fechaInicio"
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getContracargos();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--end filtros-->

    <div v-if="!loading" v-show="pago_tipo_data.length > 0">
      <p class="text-subtitle-1 text-start px-5 mt-4 mb-0">
        Cuenta: <b class="mx-2">{{ cuenta }}</b>
      </p>

      <div class="d-flex px-5 mb-1 align-center mt-1">
        <p class="text-subtitle-1 text-start mb-0" style="width: 120px;">
          Pagos tipo:
        </p>
        <v-tabs hide-slider height="37" show-arrows v-model="tab">
          <v-tab
            v-for="item in pago_tipo"
            :key="item.id"
            active-class="orange rounded-pill white--text"
            :href="`#${item.id}`"
          >
            {{ item.pagotipo }}
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <v-skeleton-loader
      type="heading"
      max-width="400"
      class="px-3 mt-4"
      v-else
    ></v-skeleton-loader>

    <v-data-table
      :headers="headers"
      :items="pago_tipo_data"
      :loading="loading"
      hide-default-footer
      loading-text="Cargando..."
      no-data-text="Sin registros para mostrar"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      class="mt-4"
    >
      <template v-slot:[`item.fecha`]="{ item }">
        {{ formatDate(item.fecha.substring(0, 10)) }}
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        {{ formatImport(item.amount) }}
      </template>

      <template v-slot:[`item.estado`]="{ item }">
        {{ item.estado ? item.estado : "-" }}
      </template>

      <template v-slot:[`item.referencia_bancaria`]="{ item }">
        {{ item.referencia_bancaria ? item.referencia_bancaria : "-" }}
      </template>

      <template v-slot:[`item.intento`]="{ item }">
        <v-tooltip left color="orange darken-2" min-width="140">
          <template v-slot:activator="{ on }">
            <v-btn
              color="orange"
              fab
              dark
              x-small
              v-on="on"
              @click="showPagoIntento(item)"
            >
              <v-icon>mdi-eye-plus</v-icon>
            </v-btn>
          </template>
          <span>Intento ID: {{ item.pago_intento.id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </template>
    </v-data-table>

    <!-- MODAL DIALOG  PAGO INTENTO -->
    <v-dialog v-model="dialog" max-width="600px" scrollable>
      <v-card>
        <v-card-title
          class="subheading font-weight-bold"
          style="font-family: Montserrat"
        >
          Detalle pago intento
        </v-card-title>

        <v-divider class="mt-0"></v-divider>

        <v-card-text style="height: 400px" class="text-subtitle-1">
          <v-data-table
            :headers="headerItento"
            :items="pago_intento"
            hide-default-footer
            mobile-breakpoint="100000"
            disable-sort
            disable-filtering
            no-data-text="Sin datos para mostrar"
            class="hide-header"
          >
            <template v-slot:[`item.external_id`]="{ item }">
              {{ item.external_id ? item.external_id : "-" }}
            </template>
            <template v-slot:[`item.barcode`]="{ item }">
              {{ item.barcode ? item.barcode : "-" }}
            </template>
            <template v-slot:[`item.barcode_url`]="{ item }">
              {{ item.barcode_url ? item.barcode_url : "-" }}
            </template>
            <template v-slot:[`item.holder_cbu`]="{ item }">
              {{ item.holder_cbu ? item.holder_cbu : "-" }}
            </template>
            <template v-slot:[`item.site_id`]="{ item }">
              {{ item.site_id ? item.site_id : "-" }}
            </template>
            <template v-slot:[`item.paid_at`]="{ item }">
              {{ formatDate(item.paid_at) }}
            </template>
            <template v-slot:[`item.report_at`]="{ item }">
              {{ formatDate(item.report_at) }}
            </template>
            <template v-slot:[`item.available_at`]="{ item }">
              {{ formatDate(item.available_at) }}
            </template>
            <template v-slot:[`item.reverted_at`]="{ item }">
              {{ formatDate(item.reverted_at) }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ formatImport(item.amount) }}
            </template>
            <template v-slot:[`item.valorcupon`]="{ item }">
              {{ formatImport(item.valorcupon) }}
            </template>
            <template v-slot:[`item.is_available`]="{ item }">
              {{ item.is_available ? "Si" : "No" }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider class="mb-0"></v-divider>
        <v-card-actions class="white justify-end">
          <v-btn
            outlined
            rounded
            color="green"
            class="my-1 text-subtitle-1 mr-2"
            @click="showCierreLote(pago_intento[0])"
          >
            VER CIERRE DE LOTE
          </v-btn>
          <v-btn
            text
            rounded
            class="my-1 text-subtitle-1"
            @click="dialog = false"
          >
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MODAL DIALOG  CIERRE LOTE -->
    <v-dialog v-model="dialogCierreLote" max-width="950px" scrollable>
      <v-card>
        <v-card-title
          class="subheading font-weight-bold"
          style="font-family: Montserrat"
        >
          Detalle cierre de lote
        </v-card-title>

        <v-divider class="mt-0"></v-divider>

        <v-card-text style="height: 400px" class="text-subtitle-1">
          <v-data-table
            :headers="headerCierreLote"
            :items="cierre_lote"
            hide-default-footer
            mobile-breakpoint="100000"
            disable-sort
            disable-filtering
            no-data-text="Sin datos para mostrar"
            class="hide-header"
          >
            <template v-slot:[`item.fecha_pago`]="{ item }">
              {{ formatDate(item.fecha_pago) }}
            </template>
            <template v-slot:[`item.fecha_cierre`]="{ item }">
              {{ formatDate(item.fecha_cierre) }}
            </template>
            <template v-slot:[`item.fechaoperacion`]="{ item }">
              {{ formatDate(item.fechaoperacion) }}
            </template>
            <template v-slot:[`item.montofinal`]="{ item }">
              {{ formatImport(item.montofinal) }}
            </template>
            <template v-slot:[`item.monto`]="{ item }">
              {{ formatImport(item.monto) }}
            </template>
            <template v-slot:[`item.conciliacion`]="{ item }">
              {{ item.conciliacion ? "Si" : "No" }}
            </template>
            <template v-slot:[`item.disputa`]="{ item }">
              {{ item.disputa ? "Si" : "No" }}
            </template>
            <template v-slot:[`item.estadomovimineto`]="{ item }">
              {{ item.estadomovimineto ? "Si" : "No" }}
            </template>
            <template v-slot:[`item.reversion`]="{ item }">
              {{ item.reversion ? "Si" : "No" }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider class="mb-0"></v-divider>
        <v-card-actions class="white justify-end">
          <v-btn
            text
            rounded
            class="my-1 text-subtitle-1"
            @click="dialogCierreLote = false"
          >
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </LayoutPage>
</template>

<script>
// Imports
import { mapState } from "vuex";
import { Refunds } from "@/services/refund.js";
import formatImport from "../../../utils/formatImport";
import formatDate from "../../../utils/formatDate";
import LayoutPage from "../../../components/LayoutPage.vue";

export default {
  name: "Refund",
  components: { LayoutPage },
  data: () => ({
    tab: 0,
    cuenta: "",
    expanded: [],
    pago_tipo: [], // array de objetos PagoTipo
    pago_intento: [],
    cierre_lote: [], // array de cierre de lotes
    cierre_lote_data: null, // datos de cierre de lote
    idCierreLote: null,
    dialog: false,
    dialogCierreLote: false,

    headers: [
      { text: "Fecha", value: "fecha", sortable: false },
      { text: "Referencia", value: "external_reference", sortable: false },
      { text: "Pagador", value: "payer_name", sortable: false },
      { text: "Monto", value: "amount", sortable: false },
      { text: "Estado", value: "estado", sortable: false },
      { text: "Ref. bancaria", value: "referencia_bancaria", sortable: false },
      { text: "Ver intento", value: "intento", sortable: false },
    ],

    headerItento: [
      { text: "ID", value: "id" },
      { text: "ID Medio de pago", value: "mediopagos_id" },
      { text: "ID Detalle", value: "installmentdetails_id" },
      { text: "External id", value: "external_id" },
      { text: "Fecha de pago", value: "paid_at" },
      { text: "Fecha de reporte", value: "report_at" },
      { text: "Disponible", value: "is_available" },
      { text: "Monto", value: "amount" },
      { text: "Valor de cupón", value: "valorcupon" },
      { text: "Estado", value: "state_comment" },
      { text: "Código de barra", value: "barcode" },
      { text: "Código de barra URL", value: "barcode_url" },
      { text: "Fecha disponible", value: "available_at" },
      { text: "Fecha de reversión", value: "reverted_at" },
      { text: "Nombre", value: "holder_name" },
      { text: "Correo", value: "holder_email" },
      { text: "Tipo", value: "holder_type" },
      { text: "Numero de identificación", value: "holder_number" },
      { text: "CBU", value: "holder_cbu" },
      { text: "Número de ticket", value: "ticket_number" },
      { text: "Código de autorización", value: "authorization_code" },
      {
        text: "Últimos 4 dígitos de la tarjeta",
        value: "card_last_four_digits",
      },
      { text: "ID Transacción", value: "transaction_id" },
      { text: "ID Sitio", value: "site_id" },
    ],

    headerCierreLote: [
      { text: "ID", value: "Id" },
      { text: "ID Pago estado externo", value: "pagoestadoexternos_id" },
      { text: "ID Canal arancel", value: "channelaranceles_id" },
      { text: "ID Impuesto", value: "impuestos_id" },
      {
        text: "ID Prisma movimiento detalles",
        value: "prismamovimientodetalles_id",
      },
      {
        text: "ID Prisma movimiento detalle",
        value: "prismamovimientodetalle_id",
      },
      { text: "ID Prisma dos pagos", value: "prismatrdospagos_id" },
      { text: "ID Banco externo", value: "banco_external_id" },
      { text: "Tipo de registro", value: "tiporegistro" },
      { text: "Pago UUID", value: "pagos_uuid" },
      { text: "Medio de pago externo", value: "externalmediopago" },
      { text: "Número de tarjeta", value: "nrotarjeta" },
      { text: "Tipo de operación", value: "tipooperacion" },
      { text: "Fecha de operación", value: "fechaoperacion" },
      { text: "Monto", value: "monto" },
      { text: "Monto final", value: "montofinal" },
      { text: "Código de autorización", value: "codigoautorizacion" },
      { text: "Número de ticket", value: "nroticket" },
      { text: "ID Sitio", value: "site_id" },
      { text: "ID Lote externo", value: "externallote_id" },
      { text: "Número de cuotas", value: "nrocuota" },
      { text: "Fecha de cierre", value: "fecha_cierre" },
      { text: "Número de establecimiento", value: "nroestablecimiento" },
      { text: "ID Cliente externo", value: "externalcliente_id" },
      { text: "Nombre del archivo lote", value: "nombrearchivolote" },
      { text: "Match", value: "match" },
      { text: "Fecha de pago", value: "fecha_pago" },
      { text: "Disputa", value: "disputa" },
      { text: "Reversión", value: "reversion" },
      { text: "ID Detalle de movimiento", value: "detallemovimineto_id" },
      { text: "ID Detalle de pago", value: "detallepago_id" },
      { text: "Descripción de contracargo", value: "descripcioncontracargo" },
      { text: "ID Banco externo reversión", value: "extbancoreversion_id" },
      { text: "Conciliación", value: "conciliacion" },
      { text: "Estado de movimiento", value: "estadomovimineto" },
      { text: "Descripción de banco", value: "descripcionbanco" },
    ],

    //filtro fecha
    menu1: false,
    menu2: false,
    panel: null,

    fechaInicio: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    fechaFin: moment().format("YYYY-MM-DD"),

    loading: false,

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    formatImport,
    formatDate,
  }),

  computed: {
    ...mapState(["cliente_ID", "cuenta_ID"]),

    pago_tipo_data() {
      const pago = this.pago_tipo.find((row) => row.id === Number(this.tab));
      return pago?.pagos?.reverse() || [];
    },
  },

  watch: {
    cliente_ID() {
      this.getContracargos();
    },
  },

  created() {
    this.getContracargos();
    // this.initialize();
  },

  methods: {
    async getContracargos() {
      try {
        const params = {
          IdCliente: this.cliente_ID,
          IdCuenta: this.cuenta_ID,
          FechaInicio: moment(this.fechaInicio).format("DD-MM-YYYY"),
          FechaFin: moment(this.fechaFin).format("DD-MM-YYYY"),
          FechaCierre: true,
        };

        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;
        const { data } = await Refunds.getContracargos(params);

        this.pago_tipo = data?.data?.cuenta?.PagoTipo || [];
        this.cuenta = data?.data?.cuenta?.cuenta || "";
        this.loading = false;

        if (this.pago_tipo.length > 0) this.tab = this.pago_tipo[0].id + ""; // seleccionando primera pestaña
      } catch (error) {
        // console.error("error: ", error);
        this.loading = false;
      }
    },

    showPagoIntento({ pago_intento }) {
      this.pago_intento = [pago_intento];
      this.dialog = true;
    },

    showCierreLote({ cierre_lote }) {
      this.cierre_lote = [cierre_lote];
      this.dialogCierreLote = true;
    },
  },
};
</script>

<style scoped>
.hide-header >>> thead {
  display: none;
}
</style>
