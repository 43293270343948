<template>
  <div class="content">
    <v-app class="bg-transparent">
      <div>
        <v-main>
          <!-- ---------------LOADER PROCESANDO DATOS ---------------- -->
          <div v-if="cargando">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>
          <div class="flex justify-end ">
            <v-btn
              class="mb-3 mr-3 text-white"
              color="green"
              @click="dialog = true"
              v-if="isSuperAdmin()"
              >Nuevo
              <v-icon class="ml-1" prepend-icon small
                >mdi-bell-plus-outline</v-icon
              >
            </v-btn>
          </div>
          <v-container fluid>
            <div class="card">
              <div class="card-header card-header-success">
                <h4 class="card-title">Notificaciones</h4>
                <p class="card-category"></p>
              </div>
              <!-- SHOW ERROR SECTION -->
              <section v-if="errored">
                <v-alert
                  shaped
                  transition="fade-transition"
                  border="left"
                  class="mt-3 mx-3 elevation-3"
                  color="red lighten-2"
                  dark
                >
                  Lo sentimos, no es posible obtener la información en este
                  momento, por favor intente nuevamente mas tarde.
                </v-alert>
              </section>
              <v-container fluid>
                <v-row justify="end" class="py-2 mr-4">
                  <div>
                    <v-select
                      color="green"
                      v-model="seleccion"
                      :items="opciones"
                      label="Filtrar"
                      @change="actualizarEstado"
                    >
                    </v-select>
                  </div>
                </v-row>

                <v-row class="p-3">
                  <div v-if="avisos.length === 0" class="flex justify-center">
                    <div class="flex items-center justify-center">
                      <v-divider class="mr-4"></v-divider>
                      <div>
                        <h1 class="py-3 text-lg font-semibold text-gray-700">
                          No hay nuevas Notificaciones
                        </h1>
                      </div>
                      <v-divider class="ml-4"></v-divider>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
                  >
                    <v-card
                      v-for="(aviso, index) in avisos"
                      :key="aviso.avisos_id"
                      class="mx-auto"
                      elevation="4"
                      outlined
                      rounded
                      width="400"
                      :loading="cargando"
                      loading-text="Cargando... Espere por favor"
                      no-results-text="No se encontraron avisos"
                      no-data-text="No se encontraron avisos"
                    >
                      <v-container fluid>
                        <v-row
                          justify="center"
                          class="bg-gradient-to-r from-cyan-500 to-green-600 white--text rounded-top"
                        >
                          <v-col cols="12">
                            <v-card-title>
                              <v-row align="center">
                                <v-icon>mdi-bullhorn-outline</v-icon>
                                <span class="ml-2 ">{{
                                  aviso.nombre | truncate(25)
                                }}</span>
                                <div class="ml-auto" v-if="isSuperAdmin()">
                                  <v-btn
                                    v-model="showSpeedDial[index]"
                                    color="green darken-2"
                                    dark
                                    fab
                                    small
                                    @click="toggleFab(index)"
                                  >
                                    <v-icon v-if="showSpeedDial[index]"
                                      >mdi-close</v-icon
                                    >
                                    <v-icon v-else>mdi-cards-outline</v-icon>
                                  </v-btn>
                                  <v-speed-dial
                                    v-model="showSpeedDial[index]"
                                    :direction="'bottom'"
                                    bottom
                                    v-if="showSpeedDial[index]"
                                    class="mt-1"
                                  >
                                    <v-btn
                                      fab
                                      dark
                                      small
                                      color="blue"
                                      @click="handleEditClick(aviso)"
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                      fab
                                      dark
                                      small
                                      color="red"
                                      class="mt-0"
                                      @click="handleDeleteClick(aviso)"
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </v-speed-dial>
                                </div>
                              </v-row>
                            </v-card-title>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12">
                            <v-card-text class="text-start">
                              <h1 style="font-size:1rem">
                                {{ aviso.descripcion | truncate(60) }}
                              </h1>
                            </v-card-text>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12">
                            <v-card-actions>
                              <div>
                                <span class="flex justify-start"
                                  ><h1>Fecha Implementacion</h1></span
                                >
                                <span class="flex justify-start"
                                  ><h1>
                                    {{
                                      formatFecha(aviso.fecha_implementacion)
                                    }}
                                  </h1></span
                                >
                              </div>
                              <div class="flex justify-end">
                                <v-btn
                                  text
                                  large
                                  rounded
                                  icon
                                  @click="abrirModal(aviso)"
                                >
                                  <v-icon large
                                    >mdi-arrow-right-circle-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                            </v-card-actions>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>

                    <!-- Modal de Editar Aviso -->
                    <v-dialog
                      v-model="dialogEdit"
                      max-width="1200px"
                      @click:esc="dialogEdit = false"
                    >
                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          <v-icon class="mr-2" large color="cyan"
                            >mdi-bullhorn-outline</v-icon
                          >
                          Editar Notificacion
                        </v-card-title>
                        <v-card-text>
                          <div class="text-left font-bold mt-2">
                            Editando Notificacion
                          </div>
                          <v-col>
                            <v-row>
                              <v-text-field
                                v-model="nombreE"
                                label="Nombre de Notificacion"
                                :rules="reglaAviso"
                              ></v-text-field>
                            </v-row>
                            <v-row>
                              <v-textarea
                                v-model="descripcionE"
                                label="Ingrese descripcion de Notificacion"
                                :rules="reglaAviso"
                              ></v-textarea>
                            </v-row>
                            <v-row>
                              <v-col cols="3">
                                <v-menu
                                  v-model="menuE"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="fechaE"
                                      :value="formatFecha2(fechaE)"
                                      label="Fecha Implementacion"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="fechaE"
                                    :min="today"
                                    @input="menuE = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="blue darken-1"
                            @click="editarAvisoID()"
                            outlined
                          >
                            Guardar Cambios
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-row>

                <template>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
                <!-- Paginacion -->
                <div class="text-center mt-2">
                  <v-pagination
                    :value="page"
                    :length="total"
                    :total-visible="visible"
                    @input="onPageChange($event)"
                  ></v-pagination>
                </div>
              </v-container>

              <!-- Modal de nuevo aviso -->
              <v-dialog
                v-model="dialog"
                max-width="50vw"
                @click:outside="resetAviso()"
                @click:esc="dialog = false"
              >
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <v-icon class="mr-2 " color="green">mdi-bell-ring</v-icon>
                    Notificación
                  </v-card-title>
                  <v-card-text>
                    <v-col>
                      <v-row>
                        <v-text-field
                          v-model="nombre"
                          label="Nombre"
                          color="green darken-5"
                          :rules="reglaAviso"
                        >
                        </v-text-field>
                      </v-row>
                      <v-row>
                        <v-textarea
                          v-model="descripcion"
                          label="Descripcion"
                          :rules="reglaAviso"
                        ></v-textarea>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="selectedCliente"
                            :items="opcionesCliente"
                            prepend-icon="mdi-account-multiple-plus-outline"
                            item-text="cliente"
                            item-value="id"
                            label="Seleccionar cliente..."
                            multiple
                            chips
                            clearable
                            @change="handleSelectionChange"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item ripple @click="toggleAll">
                                <v-list-item-action>
                                  <v-icon
                                    :color="
                                      allSelected ? 'indigo darken-4' : ''
                                    "
                                  >
                                    {{
                                      allSelected
                                        ? "mdi-checkbox-marked"
                                        : "mdi-checkbox-blank-outline"
                                    }}
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    TODOS
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fecha"
                                label="Fecha Implementacion"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fecha"
                              :min="today"
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>

                      <v-row> </v-row>
                    </v-col>
                  </v-card-text>

                  <v-card-actions class="justify-end">
                    <v-btn
                      color="green darken-1 text-white"
                      :loading="cargandobtn"
                      @click="save"
                      outlined
                      text
                    >
                      Crear
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- Modal de Eliminar -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2"
                    ><v-icon large color="red" class="mr-2"
                      >mdi-alert-outline</v-icon
                    >Seguro desea eliminar esta Notificacion?</v-card-title
                  >
                  <v-card-actions class="py-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      outlined
                      text
                      @click="deleteConfirm"
                      >Eliminar</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      outlined
                      text
                      @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- Modal de Card -->
              <v-dialog v-model="vistaModal" max-width="700">
                <v-card>
                  <v-card-title class="bg-gray-200">
                    <v-icon class="mr-2" large>mdi-bullhorn-outline</v-icon>
                    <h1>{{ selectedAviso ? selectedAviso.nombre : "" }}</h1>
                    <div class="flex justify-end" v-if="this.leido === false">
                      <v-icon>mdi-check-all</v-icon>
                    </div>
                    <div class="flex justify-end" v-else>
                      <v-icon color="blue">mdi-check-all</v-icon>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div class="flex justify-start py-4">
                      <h1 class="break-all whitespace-pre-line text-left">
                        {{ selectedAviso ? selectedAviso.descripcion : "" }}
                      </h1>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <div class="flex justify-end">
                      <v-btn
                        color="green darken-1"
                        outlined
                        text
                        @click="
                          cerrarModal(
                            selectedAviso.avisos_id,
                            selectedAviso.leido
                          )
                        "
                        >Cerrar</v-btn
                      >
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-container></v-main
        >
      </div>
    </v-app>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { Clientes } from "../../services/clientes";
import { Notificaciones } from "../../services/notificaciones.js";

export default {
  name: "Notificaciones",
  data: () => ({
    selectedAviso: null,
    //Modales
    vistaModal: false,
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    //Para Editar
    nombreE: "",
    descripcionE: "",
    fechaE: "",
    menuE: false,
    today: new Date().toISOString().substr(0, 10),

    fab: false, // Estado del Speed Dial
    showSpeedDial: [],
    aviso: null, // Aviso seleccionado
    //v-models
    id: "",
    nombre: "",
    descripcion: "",
    fecha: "",
    menu: false,
    usuarios: "",
    user_id: "",
    nombre_mail: "",
    datosCliente: [], // Arreglo donde se guardarán los datos de clientes obtenidos de la API
    selectedCliente: [], // Variable para almacenar los clientes seleccionados en el select
    allSelected: false,
    leido: "",
    leidos: "",
    //Para funciones
    cargandobtn: false,
    mostrarRegla: false,
    rowsPerPageArray: [9, 18, 45, 200],
    footerProps: {
      "items-per-page-options": [5, 10, 20, 30],
      "items-per-page-text": "Mostrar",
      "prev-icon": "",
      "next-icon": "",
      "page-text": "{0}-{1} de {2}",
    },
    editedIndex: -1,
    seleccion: "",
    opciones: [
      { value: "toos", text: "Todos" },
      { value: "leido", text: "Leído" },
      { value: "noLeido", text: "No Leído" },
    ],
    headers: [
      {
        text: "Nro",
        align: "start",
        sortable: false,
        value: "id",
      },

      { text: "Nombre", sorteble: false, value: "nombre" },
      { text: "Descripcion", sorteble: false, value: "descripcion" },
    ],
  }),

  computed: {
    //Estados de Avisos Store
    ...mapState("notificaciones", [
      "avisos",
      "avisosNl",
      "page",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
      "read",
      "errored",
    ]),

    ...mapGetters(["roles", "getUserId"]),

    //Para Manejar el select con la opcion de TODOS
    opcionesCliente() {
      return this.datosCliente;
    },

    icon() {
      if (this.likesAllClientes) return "mdi-close-box";
      if (this.likesSomeClientes) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    likesAllClientes() {
      return this.selectedCliente.length === this.datosCliente.length;
    },

    likesSomeClientes() {
      return this.selectedCliente.length > 0 && !this.likesAllClientes;
    },

    //Paginacion
    paginaActual: {
      get() {
        return this.$store.getters["notificaciones/pages"];
      },
    },

    existenNotificaciones() {
      return this.notificaciones.length > 0;
    },

    //Regla de campo vacio
    reglaAviso() {
      return this.mostrarRegla
        ? [(v) => !!v || "Este campo no puede estar vacio"]
        : [];
    },
  },

  created() {
    this.getAvisos();
  },
  mounted() {
    this.getDatosCliente();
    this.user_id = parseInt(this.getUserId);
  },

  //Para truncar la cantidad de elementos y no me muestre todos los datos en el card de la pagina
  filters: {
    truncate(value, maxLength) {
      if (value.length > maxLength) {
        return value.substring(0, maxLength) + "...";
      } else {
        return value;
      }
    },
  },

  methods: {
    //Acciones de Store de Avisos
    ...mapActions("notificaciones", [
      "createAviso",
      "getAvisos",
      "getAvisosNoleidos",
      "editarAviso",
      "eliminarAviso",
      "cambiarEstadoAvisos",
      "actualizarEstadoRead",
    ]),

    actualizarEstado() {
      let valor;
      switch (this.seleccion) {
        case "todos":
          valor = "";
          break;
        case "leido":
          valor = true;
          break;
        case "noLeido":
          valor = false;
          break;
        default:
          break;
      }

      this.$store.commit("notificaciones/actualizarEstadoRead", valor);
      this.getAvisos();
    },

    //Funcion para Select "TODOS"
    toggleAll() {
      if (this.allSelected) {
        // Si 'Todos' está seleccionado, deselecciona todos los clientes
        this.selectedCliente = [];
        this.allSelected = false;
      } else {
        // Si 'Todos' no está seleccionado, selecciona todos los clientes
        this.selectedCliente = this.datosCliente.map((cliente) => cliente.id);
        this.allSelected = true;
      }
    },

    handleSelectionChange() {
      // Si se selecciona la opción 'Todos', selecciona todos los clientes
      if (this.selectedCliente.includes("todos")) {
        this.selectedCliente = this.datosCliente.map((cliente) => cliente.id);
        this.allSelected = true;
      } else {
        // Si no se selecciona 'Todos', deselecciona 'Todos' si está seleccionado
        if (this.allSelected) {
          this.allSelected = false;
        }
      }
    },

    //Manejo de usuario rol SuperAdmin
    isSuperAdmin() {
      if (this.roles.find((r) => r == "psp.config.clientes.wee")) {
        return true; //true
      }
      return false; //false
    },

    //Paginacion
    onPageChange(event) {
      this.$store.commit("notificaciones/setPaginate", event);
      this.getAvisos();
    },

    // registros por paginas (actualizar numero de registros por pagina)
    updateRowsPerPage(number) {
      this.$store.commit("notificaciones/setrowsPerPage", number);
      this.$store.commit("notificaciones/setPaginate", 1);
      this.getAvisos();
    },

    // Alternar la visibilidad del Speed Dial(Opciones en cards
    toggleFab(index) {
      // Cambiamos el estado de visibilidad para el aviso en el índice específico
      this.$set(this.showSpeedDial, index, !this.showSpeedDial[index]);
    },

    // Manejar el clic en el botón de edición
    handleEditClick(aviso) {
      this.seleccionarID(aviso);
      this.OpenDialog(aviso);
      this.toggleFab(); // Ocultar el Speed Dial después de hacer clic
    },
    // Manejar el clic en el botón de eliminación
    handleDeleteClick(aviso) {
      this.deleteAviso1(aviso);
      this.toggleFab(); // Ocultar el Speed Dial después de hacer clic
    },

    //Traer datos de cliente para Select
    async getDatosCliente() {
      try {
        const params = {};
        const response = await Clientes.getClientes(params);
        if (response.data.data == null) {
          this.datosCliente = [];
        } else {
          // Si hay datos en la respuesta, guárdalos en el arreglo 'datosCliente'
          this.datosCliente = response.data.data;
        }
      } catch (error) {
        // console.error("Error al obtener los clientes:", error);
      }
    },

    //Para abrir Modal de Card
    abrirModal(aviso) {
      this.selectedAviso = aviso;
      this.leido = this.selectedAviso.leido;
      this.vistaModal = true;
    },

    //Para Cerrar Modal de Card
    cerrarModal(id, read) {
      this.cambiarLeido(id, read);
      this.selectedAviso = null;
      this.vistaModal = false;
    },

    //Para Resetear los campos del formulario de Avisos Nuevo
    resetAviso() {
      this.nombre = "";
      this.descripcion = "";
      (this.selectedCliente = []),
        (this.fecha = ""),
        (this.allSelected = false);
      this.mostrarRegla = false;
    },

    seleccionarID(aviso) {
      this.id = aviso.avisos_id;
    },

    //Para modal de Card
    OpenDialog(aviso) {
      (this.id = aviso.avisos_id),
        (this.dialogEdit = true),
        (this.nombreE = aviso.nombre);
      this.descripcionE = aviso.descripcion;
      this.fechaE = this.formatFecha2(aviso.fecha_implementacion);
    },

    //Formato de fecha Dia Mes y Año
    formatFecha(value) {
      var date = new Date(value);
      let fecha = date.toISOString().substring(0, 10);
      var datePart = fecha.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1],
        day = datePart[2];

      return day + "-" + month + "-" + year;
    },

    //Formato de fecha  Año Mes y Dia
    formatFecha2(value) {
      var date = new Date(value);
      let fecha = date.toISOString().substring(0, 10);
      var datePart = fecha.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1],
        day = datePart[2];

      return year + "-" + month + "-" + day;
    },

    //Crear Aviso
    async save() {
      try {
        // Verificar si la consulta está vacía
        if (
          !this.descripcion.trim() ||
          !this.nombre.trim() ||
          !this.fecha.trim()
        ) {
          // Si la consulta está vacía, muestra un mensaje de error o realiza alguna acción adecuada
          // console.error("Los campos de Notificaciones no puede estar vacía");
          this.$toastr.e("Los campos de Notificaciones no puede estar vacía");
          this.mostrarRegla = true;
          return;
        }
        const formData = new FormData();
        formData.append("nombre", this.nombre);
        formData.append("descripcion", this.descripcion);
        formData.append("ClientesId", this.selectedCliente);
        formData.append("descripcion", this.descripcion);
        // formData.append("NombreMail", this.nombre_mail);
        formData.append("fechaimplementacion", this.fecha + "T00:00:00Z");
        this.cargandobtn = true;

        await this.createAviso(formData);
        this.cargandobtn = false;
        this.dialog = false;
        this.$toastr.s("Notificacion Creado con Éxito!");
        this.getAvisos();
        this.getAvisosNoleidos();
        this.resetAviso();
      } catch (error) {
        this.cargandobtn = false;
        // Manejar cualquier error que ocurra durante la creación de la notificacion
        // console.error("Error creando Notificacion View:", error);
        this.$toastr.e("Error creando Notificacion");
      }
    },

    //Editar Aviso
    async editarAvisoID() {
      try {
        if (
          !this.descripcionE.trim() ||
          !this.nombreE.trim() ||
          !this.nombre_mailE.trim() ||
          !this.fechaE.trim()
        ) {
          // Si la consulta está vacía, muestra un mensaje de error o realiza alguna acción adecuada
          // console.error("Los campos de Notificaciones no puede estar vacía");
          this.mostrarRegla = true;
          return;
        }
        // Creo un objeto con los nuevos datos del aviso
        const nuevosDatos = {
          avisoid: this.id,
          nombre: this.nombreE,
          descripcion: this.descripcionE,
          fechaimplementacion: this.fechaE + "T00:00:00Z",
        };
        await this.$store.dispatch("notificaciones/editarAviso", nuevosDatos);
        this.$toastr.s("Notificacion editado con éxito!");
        // Mensaje de éxito
        console.log("Notificacion editado con éxito");
        this.getAvisos();
        this.getAvisosNoleidos();
      } catch (error) {
        this.$toastr.e("Error editado Notificacion!");
        // console.error("Error editando Notificacion:", error);
      }
    },

    //Camnbiar a Leido aviso
    async cambiarLeido(id, read) {
      if (!read) {
        try {
          const params = { AvisoId: id, UserId: parseInt(this.user_id) };
          const response = await Notificaciones.putCambiarEstadoAviso(params);
          this.getAvisos();
          this.getAvisosNoleidos();
          return response;
        } catch (error) {
          // console.error("Error cambiando Estado del Leido Store");
          throw error;
        }
      }
    },

    //Para Eliminar Aviso
    async deleteAviso1(aviso) {
      // Guarda el aviso que se va a eliminar
      this.id = aviso.avisos_id;
      // Abre el modal de confirmación
      this.dialogDelete = true;
    },

    //Eliminar aviso
    async deleteConfirm() {
      try {
        // Llama al servicio para eliminar el aviso
        await this.$store.dispatch("notificaciones/eliminarAviso", {
          id: this.id,
        });

        // Elimina el aviso de la lista localmente(store)
        const index = this.avisos.findIndex(
          (aviso) => aviso.avisos_id === this.id
        );
        if (index !== -1) {
          this.avisos.splice(index, 1);
        }
        this.$toastr.s("Notificacion Eliminado");
        // Cierra el modal de confirmación
        this.closeDelete();
      } catch (error) {
        // Maneja cualquier error que ocurra durante la eliminación
        this.$toastr.e("Error al Eliminar Notificacion");
        // console.error("Error eliminando Notificacion:", error);
      }
    },

    closeDelete() {
      // Cierra el modal de confirmación y reinicia los valores
      this.dialogDelete = false;
      this.editedItem = null;
    },
  },
};
</script>
<style>
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
