import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    customVariables: ["src/sass/styles.sass"],
    treeShake: true,

    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#50b565",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
});
