<template>
  <div>
    <v-btn icon :loading="loading" class="mx-2 " @click="generatePDF()">
      <v-icon>
        mdi-file-download
      </v-icon>
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="transferencia_wee"
      :pdf-quality="10"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="pdf-content">
        <div class="container">
          <img
            src="../../../public/assets/img/wee_logo.png"
            width="100px"
            class="logo"
          />
          <span class="container-detalle" style="text-align: center;">
            Detalle de transacción
          </span>
          <div class="section-container">
            <i class="material-icons" style="color: #00B1EB">business</i>
            <div>
              <p>{{ CLIENTE_CUENTA_DATA.cliente }}</p>
              <p>CUIT: {{ CLIENTE_CUENTA_DATA.cuit }}</p>
            </div>
          </div>
          <div class="section-container">
            <i class="material-icons" style="color: #00B1EB">assignment</i>
            <div>
              <p class="title-pdf">
                Información de transferencia del
                <span style="font-weight: 600;">{{
                  formatDate(item.fecha)
                }}</span>
              </p>
              <p class="sub-title">Cantidad de transacciones</p>
              <p>{{ item.MovimientoReponse.length }}</p>
              <p class="sub-title">Total transferido</p>
              <p>{{ formatImport(item.monto) }}</p>
            </div>
          </div>
          <div class="section-container">
            <i class="material-icons" style="color: #00B1EB">account_balance</i>
            <div>
              <p class="title-pdf">Detalle de Transferencia</p>
              <p class="sub-title">Referencia Bancaria</p>
              <p>{{ item.referencia_bancaria }}</p>
              <p class="sub-title">Cuenta origen</p>
              <p>CBU: {{ item.cbu_origen }}</p>
              <p class="sub-title">Cuenta destino</p>
              <p>CBU: {{ item.cbu_destino }}</p>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import formatImport from "../../utils/formatImport";
import formatDate from "../../utils/formatDate";
import { mapGetters } from "vuex";

export default {
  name: "App",
  props: {
    item: Object,
  },
  data: () => ({ loading: false, formatImport, formatDate }),
  methods: {
    onProgress(event) {
      // console.log(`Processed: ${event} / 100`);
      if (event === 100) this.loading = false;
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
    },
  },

  components: {
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters(["CLIENTE_CUENTA_DATA"]),
  },
};
</script>

<style scoped>
p {
  margin: 0;
  text-align: start;
  color: #000;
  font-size: 14px !important;
}
.pdf-content {
  font-family: "Montserrat", sans-serif;
  background: #ffff;
  padding: 60px 25px;
  margin: auto;

  position: relative;
  min-height: 100vh;
}

.logo {
  margin: auto;
}

.container-detalle {
  background: #00b1eb;
  color: #fff;
  padding: 10px 0;
}
.container {
  width: 460px;
  background: #fefefe;
  border-radius: 10px;
  border: 2px dashed #ccc;

  padding: 50px 30px;

  display: flex;
  flex-direction: column;
  gap: 30px;
}

.section-container {
  display: flex;
  gap: 30px;
}

.sub-title {
  margin-top: 10px;
  color: #555;
  font-weight: 600;
  font-size: 13px !important;
}

.title-pdf {
  font-size: 16px;
}
</style>
