<template>
  <div class="content">
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">
                {{
                  lastPreference
                    ? "Preferencias Checkout Actuales"
                    : "Preferencias Checkout"
                }}
              </h4>
            </div>
            <div v-if="procesando_datos">
              <loader
                object="#4caf50"
                color1="#ffffff"
                color2="#17fd3d"
                size="5"
                speed="2"
                bg="#343a40"
                objectbg="#999793"
                opacity="40"
                disableScrolling="false"
                name="box"
              ></loader>
            </div>

            <section>
              <v-form ref="form_pref" v-model="valid" lazy-validation>
                <v-row class="ma-2" style="flex: 0 0 auto; align-items: center">
                  <v-col cols="6" v-if="!lastPreference">
                    <!-- Ingreso de archivo -->
                    <v-file-input
                      v-model="logo_file"
                      accept="image/png, image/jpeg"
                      :rules="inputRules"
                      label="Seleccione un logo"
                      show-size
                      prepend-icon="mdi-camera"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small>
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- puede solicitar es una propiedad computada -->

                        <v-btn
                          :disabled="!valid"
                          outlined
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                          @click="setPreferences()"
                          v-show="!lastPreference"
                        >
                          <v-icon left>
                            mdi-file-download-outline
                          </v-icon>
                          Guardar
                        </v-btn>
                      </template>
                      <span>
                        Guardar las preferencias seleccionadas
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-row style="flex: 0 0 auto" v-if="!lastPreference">
                  <v-col cols="6">
                    <!-- COLOR PRINCIPAL -->
                    <v-card>
                      <v-card-title> Color Principal </v-card-title>
                      <v-card-text>
                        <v-color-picker
                          v-model="mainColor"
                          :mode.sync="mode"
                          class="ma-2"
                          hide-mode-switch
                        ></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <!-- COLOR SECUNDARIO -->
                    <v-card>
                      <v-card-title> Color Secundario </v-card-title>
                      <v-card-text>
                        <v-color-picker
                          v-model="secondaryColor"
                          :mode.sync="mode"
                          class="ma-2"
                          hide-mode-switch
                        ></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-card
                  class="mx-auto mb-5 pa-3"
                  max-width="700"
                  outlined
                  v-if="lastPreference"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-row dense>
                        <v-col cols="6">
                          <v-card :color="lastMainColor" dark>
                            <v-card-title class="text-h9">
                              Color Principal
                            </v-card-title>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text> {{ lastMainColor }} </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>

                        <v-col cols="6">
                          <v-card :color="lastSecondaryColor" dark>
                            <v-card-title class="text-h9">
                              Color Secundario
                            </v-card-title>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text>
                                {{ lastSecondaryColor }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-avatar class="ma-3 " size="200">
                    <v-img
                      :src="`data:image/png;base64,${imagen}`"
                      :lazy-src="`data:image/png;base64,${imagen}`"
                      cover
                    ></v-img>
                  </v-avatar>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="error"
                          @click="deletePreference()"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon left>
                            mdi-delete-empty-outline
                          </v-icon>
                          Eliminar
                        </v-btn>
                      </template>
                      <span>
                        Eliminar las preferencias seleccionadas
                      </span>
                    </v-tooltip>
                  </v-card-actions>
                </v-card>
              </v-form>
            </section>
          </div>
          <v-snackbar v-model="snackbar" :timeout="2000">
            <v-layout align-center pr-4>
              <v-icon class="pr-3" dark large> file_upload_off </v-icon>
              <h5 align="center">{{ mensaje_error }}</h5>
            </v-layout>
          </v-snackbar>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Preferencia } from "@/services/preferencias.js";
import { mapState } from "vuex";
import formatColor from "@/utils/formatColor.js";

export default {
  name: "Preference",
  data() {
    return {
      mensaje_error: "",
      procesando_datos: false,
      snackbar: false,
      valid: true,
      logo_file: null,
      mode: "hexa",
      mainColor: "",
      secondaryColor: "",
      inputRules: [
        (value) =>
          !value ||
          value.size < 1000000 ||
          "El tamaño del logo debe ser menor a 1 MB!",
      ],

      //ultimas preferencias
      imagen: "",
      lastMainColor: "",
      lastSecondaryColor: "",

      lastPreference: false,
    };
  },
  computed: {
    ...mapState(["cliente_ID"]),
  },
  mounted() {
    this.lastPreference ? null : this.getLastPreference();
  },
  methods: {
    validate() {
      return this.$refs.form_pref.validate();
    },
    //ultima preferencia del cliente
    async getLastPreference() {
      const params = {
        ClientId: this.cliente_ID,
      };

      try {
        this.procesando_datos = true;
        const { data } = await Preferencia.getLastPreference(params);

        this.procesando_datos = false;
        this.lastPreference = true;
        this.imagen = data.data.logo;
        this.lastMainColor = data.data.mainColor;
        this.lastSecondaryColor = data.data.secondaryColor;
      } catch (error) {
        this.procesando_datos = false;
        this.lastPreference = false;
      }
    },
    //eliminar preferencia actual
    async deletePreference() {
      const params = {
        ClientId: this.cliente_ID,
      };

      try {
        this.procesando_datos = true;
        const { data } = await Preferencia.deleteAllPreferences(params);
        this.procesando_datos = false;
        this.lastPreference = false;
        this.$toastr.s(data.message);
      } catch (error) {
        this.procesando_datos = false;
        if (error.response) {
          this.$toastr.e(error.response.data.message);
        } else {
          this.$toastr.e("Error en el proceso de eliminar las preferencias.");
        }
      }
    },
    //guardar preferencias
    setPreferences() {
      if (!this.logo_file) {
        this.mensaje_error = "Seleccione un archivo válido por favor";
        this.snackbar = true;
        return;
      }

      const formData = new FormData();
      formData.append("clientId", this.cliente_ID); // logo del branding
      formData.append("file", this.logo_file); // logo del branding
      formData.append("mainColor", formatColor(this.mainColor)); // Color principal
      formData.append("secondaryColor", formatColor(this.secondaryColor)); // Color secundario

      if (!this.validate()) {
        this.mensaje_error =
          "El formulario no es válido, revise los campos por favor";
        this.snackbar = true;
        return;
      }

      this.procesando_datos = true;
      Preferencia.setPreference(formData)
        .then((response) => {
          if (response && response.status == 200) {
            const dataRes = response.data;
            this.$toastr.s(dataRes.message);
            this.$refs.form_pref.reset();
            this.getLastPreference();
          }
        })
        .catch((error) => {
          this.procesando_datos = false;
          if (error.response) {
            this.$toastr.e(error.response.data.message);
          } else {
            this.$toastr.e("Error en el proceso de guardar las preferencias.");
          }
        })
        .finally(() => (this.procesando_datos = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
