<template>
  <div id="cobranzas">
    <h3 class="pt-4">Reportes</h3>
    <p>
      En esta sección se cubrirán aspectos técnicos y de flujo de información,
      para para obtener diferentes reportes con la API de WEE!
    </p>

    <!-- COBRANZAS -->
    <v-divider></v-divider>
    <h4 class="bold mb-2">Cobranzas</h4>
    <p>Retorna todas las cobranzas para el día especificado.</p>
    <h4 class="bold my-4">REQUEST</h4>

    <!-- -------------HEADERS--------------- -->
    <h4 class="my-4">Headers</h4>
    <v-simple-table fixed-header height="150px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              PATTERN
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in headersCobranzas" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>
              <code
                style="background-color: #f5f5f5; padding: 2px 4px; border-radius: 4px; color: #000"
                >{{ item.pattern }}
              </code>
            </td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------PARAMS----------- -->
    <p class="my-4">Path parameters</p>
    <v-simple-table fixed-header height="100px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              REQUERIDO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paramsCobranzas" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------RESPONSE----------- -->
    <h4 class="bold my-4">RESPONSE</h4>
    <p class="bold">Atributos del objeto de respuesta.</p>
    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in responseCobranzas" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response ----------: objeto result -->
    <p class="pt-4">Atributos del objeto <span class="italic">result</span>.</p>
    <v-simple-table fixed-header height="340px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objectResultCobranzas" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- response----objeto result.data -->
    <p class="pt-4">Atributos del objeto <span class="italic">data</span>.</p>
    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ATRIBUTO
            </th>
            <th class="text-left">
              TIPO
            </th>
            <th class="text-left">
              DESCRIPCIÓN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in objDataCobranzas" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- GET Obtener Cobranzas -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <h4 class="bold mb-4" id="crear-solicitud-pago">
      <span class="metodo-get">GET</span> Obtener Cobranzas
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p
        class="
      d-flex
        align-items-center
        justify-content-center
        mb-0
      "
      >
        https://sb-wee.ar/v1/reporte/cobranzas?Date=03-01-2023
      </p>
    </div>
    <!-- Headers -->
    <h4 class="bold mt-4">HEADERS</h4>
    <v-divider></v-divider>
    <div>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Content-Type</p>
        </v-col>
        <v-col cols="6">
          <p>application/json</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Authorization</p>
        </v-col>
        <v-col cols="6">
          <p>Bearer <span>{</span>{api_key}<span>}</span></p>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: RESPONSE --------------------- -->
    <div>
      <h6 class="bold mt-4">Example Response</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
{
    "message": "Solicitud cobranzas generada",
    "result": {
        "account_id": "000004",
        "report_date": "2023-01-03T00:00:00Z",
        "total_collected": 12303.23,
        "total_gross_fee": 201.98,
        "total_net_amount": 12101.25,
        "data": [
            {
                "informed_date": "2023-01-03T00:00:00Z",
                "request_id": 494,
                "external_reference": "iEXb72Whd",
                "payer_name": "VANASCO JOSÉ LUIS",
                "description": "CONEXIÓN ID 0028 - MES: ENERO",
                "payment_date": "2023-01-03T18:00:00Z",
                "channel": "VISA",
                "amount_paid": 6900,
                "net_fee": 110.4,
                "iva_fee": 23.18,
                "net_amount": 6766.42,
                "available_at": "2023-01-20 03:00:02"
            },
            {
                "informed_date": "2023-01-03T00:00:00Z",
                "request_id": 483,
                "external_reference": "tqgQSorfm",
                "payer_name": "SUÁREZ AGUSTÍN RAMÓN",
                "description": "CONEXIÓN ID 0013 - MES: ENERO",
                "payment_date": "2023-01-03T14:39:00Z",
                "channel": "VISA DÉBITO",
                "amount_paid": 503.23,
                "net_fee": 9.99,
                "iva_fee": 2.1,
                "net_amount": 491.14,
                "available_at": "2023-01-06 13:03:39"
            },
            {
                "informed_date": "2023-01-03T00:00:00Z",
                "request_id": 469,
                "external_reference": "pJkamU74X",
                "payer_name": "HERRERA CRISTINA ANABEL",
                "description": "CONEXIÓN ID 0012 - MES: ENERO",
                "payment_date": "2023-01-03T09:53:00Z",
                "channel": "VISA DÉBITO",
                "amount_paid": 4900,
                "net_fee": 46.54,
                "iva_fee": 9.77,
                "net_amount": 4843.69,
                "available_at": "2023-01-06 13:03:39"
            }
        ]
    },
    "status": true
}
    </code>
  </pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewAyudaRepCobranzas",
  data() {
    return {
      headersCobranzas: [
        {
          atributo: "Content-Type",
          tipo: "String",
          requerido: "SI",
          pattern: "application/json",
          descripcion:
            "El tipo MIME del contenido del cuerpo enviado. Por defecto JSON es Unicode UTF-8. No se debe configurar otro charset.",
        },
        {
          atributo: "Authorization",
          tipo: "String",
          requerido: "SI",
          pattern: "Bearer",
          descripcion: "aa7943e1-a60c-99e7-8812-xxxxxxxxxxxx",
        },
      ],
      paramsCobranzas: [
        {
          atributo: "Date",
          tipo: "String",
          requerido: "SI",
          descripcion:
            "Fecha de cobranzas. Solo se puede pedir este reporte para una fecha anterior al día actual. Formato DD-MM-YYYY",
        },
      ],
      responseCobranzas: [
        {
          atributo: "message",
          tipo: "String",
          descripcion: "Mensaje de la respuesta.",
        },
        {
          atributo: "result",
          tipo: "Object",
          descripcion: "Objeto con la información de la respuesta.",
        },
        {
          atributo: "status",
          tipo: "Boolean",
          descripcion: "Estado de la respuesta.",
        },
      ],
      objectResultCobranzas: [
        {
          atributo: "account_id",
          tipo: "String",
          descripcion: "ID de la cuenta propietaria del Reporte.",
        },
        {
          atributo: "report_date",
          tipo: "String",
          descripcion: "Fecha en la que se informaron los cobros.",
        },
        {
          atributo: "total_collected",
          tipo: "Float",
          descripcion:
            "Importe total cobrado. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "total_gross_fee",
          tipo: "Float",
          descripcion:
            "Importe total de comisión. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "total_net_amount",
          tipo: "Float",
          descripcion:
            "Importe total por acreditar. Equivalente a Importe total cobrado menos importe total de comisión. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "data",
          tipo: "Array [Object]",
          descripcion: "Arreglo de objetos con información de cada cobro.",
        },
      ],
      objDataCobranzas: [
        {
          atributo: "informed_date",
          tipo: "DateTime",
          descripcion: "Fecha en la que se informó el cobro.",
        },
        {
          atributo: "request_id",
          tipo: "Integer",
          descripcion: "ID de la solicitud.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          descripcion:
            "Este atributo es utilizado como referencia para identificar la solicitud y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "payer_name",
          tipo: "String",
          descripcion: "Nombre del pagador.",
        },
        {
          atributo: "description",
          tipo: "String",
          descripcion: "Descripción o concepto cobrado.",
        },
        {
          atributo: "payment_date",
          tipo: "DateTime",
          descripcion: "Fecha de pago.",
        },
        {
          atributo: "channel",
          tipo: "String",
          descripcion: "Nombre del medio de pago utilizado.",
        },
        {
          atributo: "amount_paid",
          tipo: "Float",
          descripcion: "Importe pagado. Formato...",
        },
        {
          atributo: "net_fee",
          tipo: "Float",
          descripcion:
            "	Comisión neta. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "iva_fee",
          tipo: "Float",
          descripcion:
            "IVA de la comisión. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "net_amount",
          tipo: "Float",
          descripcion:
            "Importe Neto. Formato: 00000000.00 (hasta 8 enteros y 2 decimales, utilizando punto “.” como separador decimal).",
        },
        {
          atributo: "available_at",
          tipo: "DateTime",
          descripcion:
            "Fecha y hora en la que el saldo de la transacción queda disponible.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.metodo-get {
  color: rgb(0, 127, 49);
}
.url-container {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  height: 40px;
  display: flex;
  padding-left: 10px;
}
.code-block {
  font-size: 14px;
}
</style>
