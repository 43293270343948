import { Pagos } from "../../services/pagos.js";
import { Channels } from "../../services/channels.js";
import { Refresh } from "../../services/refreshToken";
import moment from "moment";
export default {
  namespaced: true,
  state: {
    // state de movimientos
    pagos: [],

    // pagos que se van exportar en csv
    pagosCsv: [],

    //datos a export (boton en filtros)
    items: [],

    //  filter fechas inicio y fin
    fechaDesde: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),

    fechaHasta: moment().format("YYYY-MM-DD"),

    //  numero de pagina y total de registros
    page: 1,
    total: 0,
    visible: 7,

    // numero de registro por pagina
    rowsPerPage: 30,

    //loading en peticiones
    cargando: false,

    //errores de api
    errored: false,
    error: false,

    //  filtros
    nombre: "",
    holder_number: "",
    estado: [4, 7],
    referencia: "",
    medio_pago: 0,
    pendiente: false,
    medios_pagos: [],
    estados_pagos: [4, 7],
    ordenar: 1, // se envia 1 cuando se quiere obtener listado ya sea en formas DESC o ASC, 0 para recibir los datos de forma predeterminada
    descendente: 1, // 1 para obtener los datos en forma ASC y 0 para DESC

    saldoPendiente: 0,
    saldoDisponible: 0,
    puntoDeVenta: "",
    caja: "",
  },
  mutations: {
    // mutations pagos
    setPagos(state, pagos) {
      state.pagos = pagos;
    },
    setPagosCsv(state, pagos) {
      state.pagosCsv = pagos;
    },
    setPagosPendientes(state, value) {
      state.pendiente = value;
    },
    setNombre(state, value) {
      state.nombre = value;
    },
    setReferencia(state, value) {
      state.referencia = value;
    },
    setEstado(state, value = [4, 7]) {
      state.estado = value;
    },

    setMedioPago(state, value = 0) {
      state.medio_pago = value;
    },
    setPaginate(state, value) {
      state.page = value;
    },

    setTotal(state, value) {
      state.total = value;
    },
    setrowsPerPage(state, value) {
      state.rowsPerPage = value;
    },
    setCargando(state, value) {
      state.cargando = value;
    },
    setErrord(state, value) {
      state.errored = value;
    },

    setFechaInicio(state, value) {
      state.fechaDesde = value;
    },

    setFechaFin(state, value) {
      state.fechaHasta = value;
    },
    setMediosPagos(state, medios_pagos) {
      state.medios_pagos = medios_pagos;
    },
    setEstadosPagos(state, estados_pagos) {
      state.estados_pagos = estados_pagos;
    },

    setSaldoPendiente(state, saldoP) {
      state.saldoPendiente = saldoP;
    },
    setSaldoDisponible(state, saldoD) {
      state.saldoDisponible = saldoD;
    },

    setOrdenar(state, ordenar) {
      state.ordenar = ordenar;
    },
    setDescendente(state, descendente) {
      state.descendente = descendente;
    },
    setHolderNumber(state, holder_number) {
      state.holder_number = holder_number;
    },
    setPuntoDeVenta(state, puntoDeVenta) {
      state.puntoDeVenta = puntoDeVenta;
    },
    setCaja(state, caja) {
      state.caja = caja;
    },
  },
  actions: {
    //exportCsv es parametro booleano que recibe en los casos en que el usuario desea exportar los datos a un arhivo CSV
    async getPagos({ commit, rootState, state }, exportCsv) {
      // filter pagos
      const idcuenta = rootState.cuenta_ID;
      const number = state.page;
      const size = state.rowsPerPage;
      const fechaI = moment(state.fechaDesde).format();
      const fechaH = moment(state.fechaHasta).format();
      const visualizar_pagos_pendientes = state.pendiente;
      const pagoEstadosId = state.estado;
      const Nombre = state.nombre;
      const externalReference = state.referencia;
      const medioPagoId = state.medio_pago;
      const ordenar = state.ordenar;
      const descendente = state.descendente;
      const puntoDeVenta = state.puntoDeVenta;
      const caja = state.caja;

      let data = new FormData();
      data.append("Number", number);
      !exportCsv && data.append("Size", size);
      data.append("FechaPagoInicio", fechaI);
      data.append("FechaPagoFin", fechaH);
      data.append("CuentaId", idcuenta);
      data.append("VisualizarPendientes", visualizar_pagos_pendientes);
      data.append("PagoEstadosIds", pagoEstadosId);
      data.append("Nombre", Nombre);
      data.append("ExternalReference", externalReference);
      data.append("HolderNumber", state.holder_number);
      data.append("MedioPagoId", medioPagoId);
      data.append("CargarPagosItems", true); //true para traer los detalles del pago
      data.append("Ordenar", ordenar);
      data.append("Descendente", descendente);
      if(puntoDeVenta && caja){
        data.append("PuntoDeVenta", puntoDeVenta);
        data.append("Caja", caja);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      commit("setErrord", false);
      commit("setCargando", true);
      await Pagos.getPagos(data, config)
        .then((response) => {
          // si la cuenta no contiene movimientos se actualiza store a valor vacio y efecto cargando en false
          if (response.data.data == null) {
            commit("setPagos", []);
            commit("setPaginate", 1);
            commit("setTotal", 0);
            commit("setCargando", false);
            commit("setSaldoDisponible", 0);
            commit("setSaldoPendiente", 0);
          } else {
            // si la consulta es distinto de null se actualiza el store mutations movimientos
            if (exportCsv) {
              commit("setPagosCsv", response.data.data);
            } else {
              commit("setPagos", response.data.data);
              commit("setSaldoDisponible", response.data.saldo_disponible);
              commit("setSaldoPendiente", response.data.saldo_pendiente);

              // state.pagos.forEach(function(elemento, indice) {
              //   items.push({quantity:1,description:"audio"+indice, amount:elemento });
              // })
              commit("setPaginate", response.data.meta.page.currentPage);
              commit("setTotal", response.data.meta.page.lastPage);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            var data = error.response.status;
            if (data == 403) {
              async () => {
                try {
                  await Refresh.getRefreshToken().then(() => {
                    //
                  });
                } catch (error) {
                  //
                }
              };
            }
          } else {
            commit("setErrord", true);
          }
        })
        .finally(() => commit("setCargando", false));
    },

    // cargar los medios de pagos filter datatable
    async getChannels({ commit }) {
      const params = {
        Number: 1,
        Size: 20,
      };
      await Channels.getChannels(params)
        .then((response) => {
          commit("setMediosPagos", response.data.data);
        })
        .catch((error) => {
          var data = error.response.status;
          // alert(data);
        });
    },

    async getEstados({ commit }) {
      await Pagos.getEstadoPagos()
        .then((response) => {
          commit("setEstadosPagos", response.data.estados);
        })
        .catch((error) => {
          var data = error.response.status;
          // alert(data);
        });
    },

    setOrdenar({ commit }, ordenar) {
      commit("setOrdenar", ordenar);
    },
    setDescendente({ commit }, descendente) {
      commit("setDescendente", descendente);
    },
  },
  getters: {
    fechaDesde: (state) => state.fechaDesde,
    fechaHasta: (state) => state.fechaHasta,
    pages: (state) => state.page,
    saldoDisponible: (state) => state.saldoDisponible,
    saldoPendiente: (state) => state.saldoPendiente,
    ordernar: (state) => state.ordernar,
    descendente: (state) => state.descendente,
    puntoDeVenta: (state) => state.puntoDeVenta,
    caja: (state) => state.caja,

    canalespago(state) {
      state.medios_pagos.push({
        Id: 0,
        Nombre: "TODOS",
      });
      return state.medios_pagos;
    },

    estadoPagos(state) {
      //state.estados_pagos.push({ ID: 0, nombre: "TODOS" });
      return state.estados_pagos;
    },

    estados(state) {
      return state.estados_pagos;
    },
  },
};
