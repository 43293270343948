<template>
  <div class="d-flex">
    <div>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        width="300px"
      >
        <v-list>
          <v-list-group>
            <v-icon slot="prependIcon" class="mr-4">mdi-folder-outline</v-icon>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Solicitudes de Pago </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              link
              active-class="active1"
              class="px-3"
              @click="goToSolicitudPago"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Crear Solicitud de Pago
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              active-class="active1"
              class="px-3"
              @click="goToWebHooks"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Integración con webHooks
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              active-class="active1"
              class="px-3"
              @click="goToObtenerSolicitud"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Consultar Estado del Pago
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              active-class="active1"
              class="px-3"
              @click="goToRedireccion"
            >
              <v-list-item-content>
                <v-list-item-title>
                  Redirecciones de la Transacción
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- REPORTES: cobranzas, rendiciones -->
          <v-list-group>
            <v-icon slot="prependIcon" class="mr-4">mdi-folder-outline</v-icon>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title> Reportes </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              link
              active-class="active1"
              class="px-5"
              @click="goToCobranzas"
            >
              <v-list-item-content>
                <v-list-item-title>Cobranzas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              active-class="active1"
              class="px-5"
              @click="goToReversiones"
            >
              <v-list-item-content>
                <v-list-item-title>Reversiones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              active-class="active1"
              class="px-5"
              @click="goToRendiciones"
            >
              <v-list-item-content>
                <v-list-item-title>Rendiciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              active-class="active1"
              class="px-5"
              @click="goToRetenciones"
            >
              <v-list-item-content>
                <v-list-item-title>Retenciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div class="pb-5 d-flex flex-column mx-4 px-2">
      <NewAyudaPagos />
      <ConsultarEstadoPago />
      <NewAyudaRepCobranzas />
      <NewAyudaRepReversiones />
      <NewAyudaRepRendiciones />
      <NewAyudaRepRetenciones />
      <div>
        <v-btn
          fab
          small
          dark
          fixed
          bottom
          right
          @click="goToTop"
          v-show="scY > 300"
          style="background-color: #50b565;"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import NewAyudaPagos from "@/components/Ayuda/NewAyudaPagos.vue";
import NewAyudaRepCobranzas from "@/components/Ayuda/NewAyudaRepCobranzas.vue";
import NewAyudaRepRendiciones from "@/components/Ayuda/NewAyudaRepRendiciones.vue";
import ConsultarEstadoPago from "@/components/Ayuda/ConsultarEstadoPago.vue";
import NewAyudaRepRetenciones from "@/components/Ayuda/NewAyudaRepRetenciones.vue";
import NewAyudaRepReversiones from "@/components/Ayuda/NewAyudaRepReversiones.vue";
export default {
  name: "NewDesarrolladores",
  components: {
    NewAyudaPagos,
    NewAyudaRepCobranzas,
    NewAyudaRepRendiciones,
    ConsultarEstadoPago,
    NewAyudaRepRetenciones,
    NewAyudaRepReversiones,
  },

  data() {
    return {
      drawer: true,
      mini: false,

      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    goToCobranzas() {
      document.querySelector("#cobranzas").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToRendiciones() {
      document.querySelector("#rendiciones").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToRetenciones() {
      document.querySelector("#retenciones").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToReversiones() {
      document.querySelector("#reversiones").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToSolicitudPago() {
      document.querySelector("#solicitud-pago").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToObtenerSolicitud() {
      document.querySelector("#obtener-solicitud").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToRedireccion() {
      document.querySelector("#redirecciones-transaccion").scrollIntoView({
        behavior: "smooth",
      });
    },
    goToWebHooks() {
      document.querySelector("#webhooks").scrollIntoView({
        behavior: "smooth",
      });
    },

    handleScroll: function() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    goToTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped></style>
